<template>
  <AppAjaxContent :is-loading="!data">
    <AppForm v-if="data" v-bind="managementFormProps" v-on="formEvents">
      <template #group="{ id, inputId, errors }">
        <template
          v-if="id === 'allowed_author_type'"
          class="AppForm__group-field"
        >
          <input
            type="hidden"
            name="management_settings[allowed_author_type]"
            :value="formObject.allowed_author_type"
          />
          <AppSelectRadio
            :id="inputId"
            v-model="formObject.allowedAuthorType"
            :options="ALLOWED_AUTHOR_TYPE_OPTIONS"
          >
            <template #label="{ value, checked }">
              <template
                v-if="
                  formObject.enable_review_allowed_author_type_delivery_finished &&
                    value === ReviewAllowedAuthorType.BUYER_ONLY
                "
              >
                <i18n path="review_allowed_author_type_dropdown">
                  <AppSelect
                    v-model="formObject.allowedSince"
                    :disabled="!checked"
                    :options="ALLOWED_SINCE_OPTIONS"
                  />
                </i18n>
              </template>
            </template>
            <template #sub-item="{ value, checked: subOptionChecked }">
              <div
                v-if="value === ReviewAllowedAuthorType.BUYER_ONLY"
                class="AppForm__sub-group"
              >
                <div class="AppForm__sub-group-field">
                  <input
                    type="hidden"
                    name="management_settings[allowed_days_from_delivery]"
                    :disabled="!subOptionChecked"
                    :value="formObject.allowed_days_from_delivery"
                  />
                  <AppSelectRadio
                    v-model="formObject.allowedUntil"
                    :disabled="!subOptionChecked"
                    :options="ALLOWED_UNTIL_OPTIONS"
                  >
                    <template #label="{ value, checked }">
                      <template v-if="value === 'limited'">
                        <i18n path="allowed_days_type.limited">
                          <AppNumberInput
                            v-model="formObject.allowedDays"
                            :disabled="!checked || !subOptionChecked"
                            :digits="2"
                            :invalid="!!errors.allowedDays"
                            @blur="validateField('allowedDays')"
                            @change="validateField('allowedDays')"
                          />
                        </i18n>
                        <AppFormError :error="errors.allowedDays" />
                      </template>
                    </template>
                  </AppSelectRadio>
                  <div class="AppForm__sub-group-hint">
                    {{ $t('allowed_days_hint') }}
                  </div>
                </div>
              </div>
            </template>
          </AppSelectRadio>
        </template>
        <template
          v-else-if="id === 'duplicate_review_type'"
          class="AppForm__group-field"
        >
          <AppSelectRadio
            v-model="formObject.duplicate_review_type"
            name="management_settings[duplicate_review_type]"
            :options="DuplicateReviewType.options()"
          >
            <template #sub-item="{ value, checked: subOptionChecked }">
              <div
                v-if="
                  value === DuplicateReviewType.ALLOW_REVIEW_BY_PRODUCT_COUNT
                "
                class="AppForm__sub-group"
              >
                <div class="AppForm__sub-group-field">
                  <AppCheckbox
                    v-model="formObject.show_review_popup_by_products_count"
                    :disabled="!subOptionChecked"
                    name="management_settings[show_review_popup_by_products_count]"
                    :label="
                      $t(
                        'duplicate_review_type.show_review_popup_by_products_count'
                      )
                    "
                  />
                </div>
              </div>
            </template>
          </AppSelectRadio>
        </template>
        <template v-else-if="id === 'review_option_types'">
          <div class="AppForm__group-description">
            {{ $t('review_option_types.group_description') }}
          </div>
          <div class="AppForm__group-field">
            <div
              v-if="isReviewOptionTypesButtonAccessible"
              :class="[
                'table-head-control',
                isShopifyBrand ? '' : 'super-admin__item'
              ]"
            >
              <AppButton
                :label="$t('review_option_types.settings_button')"
                @click="openReviewOptionTypeSettingsDialog"
              />
            </div>
            <AppSmallDeviceScrollable>
              <AppTable
                table-id="review-option-types-table"
                :columns="[
                  {
                    id: 'id',
                    label: $t('review_option_types.id'),
                    align: 'left'
                  },
                  {
                    id: 'name',
                    label: $t('review_option_types.name'),
                    align: 'left'
                  },
                  {
                    id: 'field_type',
                    label: $t('review_option_types.field_type'),
                    align: 'left'
                  },
                  {
                    id: 'search_options_with_categories',
                    label: $t(
                      'review_option_types.search_options_with_categories'
                    ),
                    align: 'left'
                  },
                  {
                    id: 'storable',
                    label: $t('review_option_types.storable'),
                    type: 'check'
                  },
                  {
                    id: 'searchable',
                    label: $t('review_option_types.searchable'),
                    type: 'check'
                  },
                  {
                    id: 'displayable',
                    label: $t('review_option_types.displayable'),
                    type: 'check'
                  },
                  {
                    id: 'required',
                    label: $t('review_option_types.required'),
                    type: 'check'
                  }
                ]"
                :rows="
                  data.review_option_types.map(optionType => ({
                    ...optionType,
                    search_options_with_categories: optionType.search_options
                      .map(o => o[0])
                      .join(', ')
                  }))
                "
                :no-data="$t('review_option_types.no_data')"
              >
                <template #cell="{ row, column, value }">
                  <template v-if="column.id === 'name'">
                    <span>
                      <ul>
                        <li class="table-line">
                          <AppBadge
                            v-if="row.global_review_option_type_name"
                            badge-style="grey-outline"
                            :label="row.global_review_option_type_name"
                          />
                          <AppBadge
                            v-else
                            :label="
                              $t('review_option_types.no_user_property_type')
                            "
                          />
                        </li>
                        <li class="table-line--mt4">
                          {{ value }}
                        </li>
                      </ul>
                    </span>
                  </template>
                  <template v-else-if="column.id === 'field_type'">
                    <span>
                      {{ $t(`review_option_types.field_types.${value}`) }}
                    </span>
                  </template>
                  <template
                    v-else-if="column.id === 'search_options_with_categories'"
                  >
                    <ul>
                      <li class="table-line">
                        {{ row.search_options_with_categories }}
                      </li>
                      <li class="table-line table-line--mt8">
                        <span>
                          {{ optionTypeCategoriesDescription(row) }}
                        </span>
                      </li>
                    </ul>
                  </template>
                </template>
              </AppTable>
            </AppSmallDeviceScrollable>
          </div>
        </template>
        <template v-else-if="id === 'category_ids_without_review_contents'">
          <div class="AppForm__group-description">
            {{ $t('category_ids_without_review_contents.group_description') }}
            <AppInlineButton
              :label="$t('app.detail')"
              button-style="caption"
              @click="openCategoryIdsWithoutReviewContentsMessageDialog"
            />
          </div>
          <div class="AppForm__group-field">
            <AppSelectProductCategory
              v-model="formObject.category_ids_without_review_contents"
              name="management_settings[category_ids_without_review_contents][]"
              multiple
            />
          </div>
        </template>
        <template v-else-if="id === 'message_dictionary'">
          <div class="AppForm__group-field">
            <div class="table-head-control">
              <AppButton
                :label="$t('message_dictionary.add_button')"
                @click="addMessageDictionaryRule"
              />
            </div>
            <AppSmallDeviceScrollable>
              <AppTable
                valign-baseline
                :columns="
                  isShopifyBrand
                    ? [
                        {
                          id: 'old_word',
                          label: $t('message_dictionary.old_word')
                        },
                        {
                          id: 'new_word',
                          label: $t('message_dictionary.new_word')
                        },
                        {
                          id: 'actions',
                          label: $t('app.actions')
                        }
                      ]
                    : [
                        {
                          id: 'category',
                          label: $t('message_dictionary.category')
                        },
                        {
                          id: 'old_word',
                          label: $t('message_dictionary.old_word')
                        },
                        {
                          id: 'new_word',
                          label: $t('message_dictionary.new_word')
                        },
                        {
                          id: 'actions',
                          label: $t('app.actions')
                        }
                      ]
                "
                :rows="formObject.message_dictionary"
                :no-data="$t('message_dictionary.no_data')"
              >
                <template #cell="{ row, column, rowIndex }">
                  <template v-if="column.id === 'category'">
                    <AppSelectProductCategory
                      v-model="
                        formObject.message_dictionary[rowIndex]
                          .product_category_id
                      "
                      :placeholder="$t('app.all_categories')"
                      :name="
                        `management_settings[message_dictionary][][product_category_id]`
                      "
                    />
                  </template>
                  <template v-else-if="column.id === 'old_word'">
                    <AppTextInput
                      v-model="row.old_word"
                      :invalid="
                        !!errors[`message_dictionary[${row.id}][old_word]`]
                      "
                      :name="
                        `management_settings[message_dictionary][][old_word]`
                      "
                      @blur="
                        validateField(`message_dictionary[${row.id}][old_word]`)
                      "
                      @change="
                        validateField(`message_dictionary[${row.id}][old_word]`)
                      "
                    />
                    <AppFormError
                      :error="errors[`message_dictionary[${row.id}][old_word]`]"
                    />
                  </template>
                  <template v-else-if="column.id === 'new_word'">
                    <AppTextInput
                      v-model="row.new_word"
                      :invalid="
                        !!errors[`message_dictionary[${row.id}][new_word]`]
                      "
                      :name="
                        `management_settings[message_dictionary][][new_word]`
                      "
                      @blur="
                        validateField(`message_dictionary[${row.id}][new_word]`)
                      "
                      @change="
                        validateField(`message_dictionary[${row.id}][new_word]`)
                      "
                    />
                    <AppFormError
                      :error="errors[`message_dictionary[${row.id}][new_word]`]"
                    />
                  </template>
                  <template v-else-if="column.id === 'actions'">
                    <AppButton
                      button-style="red-outline"
                      :label="$t('app.delete')"
                      @click="formObject.message_dictionary.splice(rowIndex, 1)"
                    />
                  </template>
                </template>
              </AppTable>
            </AppSmallDeviceScrollable>
            <input
              v-if="!formObject.message_dictionary.length"
              type="hidden"
              name="management_settings[message_dictionary][]"
            />
          </div>
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';
import { mapGetters, mapActions, mapState } from 'vuex';
import ReviewAllowedAuthorType from '@/enums/ReviewAllowedAuthorType';
import DuplicateReviewType from '@/enums/DuplicateReviewType';
import ReviewEditableType from '@/enums/ReviewEditableType';
import ReviewDeletableType from '@/enums/ReviewDeletableType';
import AppTextInput from '../../../components/AppTextInput.vue';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'ReviewSettingsManage',
  components: { AppTextInput },
  mixins: [FormView, SettingsView],
  data() {
    return {
      data: null,
      SETTINGS_ID: 'review_settings_manage',
      ALLOWED_AUTHOR_TYPE_OPTIONS: ReviewAllowedAuthorType.options([
        ReviewAllowedAuthorType.EVERYONE,
        ReviewAllowedAuthorType.MEMBER_ONLY,
        ReviewAllowedAuthorType.BUYER_ONLY
      ]),
      ALLOWED_SINCE_OPTIONS: [
        {
          label: this.$t('allowed_since.delivery_finished_buyer_only'),
          value: ReviewAllowedAuthorType.DELIVERY_FINISHED_BUYER_ONLY
        },
        {
          label: this.$t('allowed_since.buyer_only'),
          value: ReviewAllowedAuthorType.BUYER_ONLY
        }
      ],
      ALLOWED_UNTIL_OPTIONS: [
        { label: this.$t('allowed_days_type.unlimited'), value: 'unlimited' },
        { value: 'limited' }
      ],
      ENABLE_EDIT_OPTIONS: [
        {
          label: this.$t('enable_edit.always'),
          value: ReviewEditableType.ALWAYS
        },
        {
          label: this.$t('enable_edit.before_mileage'),
          value: ReviewEditableType.BEFORE_MILEAGE
        },
        {
          label: this.$t('enable_edit.never'),
          value: ReviewEditableType.NEVER
        }
      ],
      ENABLE_DELETE_OPTIONS: [
        {
          label: this.$t('enable_delete.always'),
          value: ReviewDeletableType.ALWAYS
        },
        {
          label: this.$t('enable_delete.before_mileage'),
          value: ReviewDeletableType.BEFORE_MILEAGE
        },
        {
          label: this.$t('enable_delete.never'),
          value: ReviewDeletableType.NEVER
        }
      ],
      ENABLE_USER_COMMENTS_OPTIONS: [
        {
          label: this.$t('enable_user_comments.false'),
          value: false
        },
        {
          label: this.$t('enable_user_comments.true'),
          value: true
        }
      ],
      ADMIN_SHOW_ENTIRE_MESSAGE_OPTIONS: [
        { label: this.$t('admin_show_entire_message.false'), value: false },
        { label: this.$t('admin_show_entire_message.true'), value: true }
      ],
      DISPLAY_WRITE_FORM_NOT_ALLOWED_USER_OPTIONS: [
        {
          label: this.$t('display_write_form_not_allowed_user.false'),
          value: false
        },
        {
          label: this.$t('display_write_form_not_allowed_user.true'),
          value: true
        }
      ],
      ENABLE_NONMEMBER_EVALUATION_OPTIONS: [
        {
          label: this.$t('enable_nonmember_evaluation_options.false'),
          value: false
        },
        {
          label: this.$t('enable_nonmember_evaluation_options.true'),
          value: true
        }
      ],
      HIDE_IF_OPTIONS: [
        { id: 'hide_if_out_of_stock', label: this.$t('hide_if_out_of_stock') },
        { id: 'hide_if_undisplayed', label: this.$t('hide_if_undisplayed') },
        {
          id: 'hide_if_price_is_zero',
          label: this.$t('hide_if_price_is_zero', [this.formatCurrency(0)])
        },
        {
          id: 'hide_if_no_product_image',
          label: this.$t('hide_if_no_product_image')
        }
      ]
    };
  },
  computed: {
    ...mapState('session', ['currentUser']),
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('session', ['isShopifyBrand']),
    ...mapGetters('productCategory', ['productCategoryName']),
    ReviewAllowedAuthorType: () => ReviewAllowedAuthorType,
    DuplicateReviewType: () => DuplicateReviewType,
    ReviewEditableType: () => ReviewEditableType,
    ReviewDeletableType: () => ReviewDeletableType,
    managementFormProps() {
      return {
        ...this.formProps,
        id: this.SETTINGS_ID,
        submitButton: true,
        objectId: 'management_settings',
        formStyle: 'hor'
      };
    },
    groupOptions() {
      const {
        allowedAuthorType,
        allowedUntil,
        give_mileage_expires_after_days,
        message_sending_enabled,
        sending_delay_after_delivery_in_days
      } = this.formObject;

      return {
        allowed_author_type: {
          fields:
            allowedAuthorType === ReviewAllowedAuthorType.BUYER_ONLY &&
            allowedUntil === 'limited'
              ? [
                  {
                    id: 'allowedDays',
                    validate: [
                      'required',
                      'positive_integer',
                      {
                        rule: 'gte',
                        allowed: give_mileage_expires_after_days,
                        errorMessage: this.$t(
                          'allowed_days_must_be_gte_give_mileage_expires_after_days',
                          [give_mileage_expires_after_days]
                        )
                      },
                      message_sending_enabled &&
                      sending_delay_after_delivery_in_days
                        ? {
                            rule: 'gt',
                            allowed: sending_delay_after_delivery_in_days,
                            errorMessage: this.$t(
                              'allowed_days_must_be_gt_sending_delay_after_delivery_in_days',
                              [sending_delay_after_delivery_in_days]
                            )
                          }
                        : null
                    ].filter(v => v)
                  }
                ]
              : []
        },
        duplicate_review_type: {
          type: 'select_radio',
          groupDescription: this.$t('duplicate_review_type.description')
        },
        enable_edit: {
          type: 'select_radio',
          options: this.ENABLE_EDIT_OPTIONS,
          hint: this.$t('enable_edit.hint')
        },
        enable_delete: {
          type: 'select_radio',
          options: this.ENABLE_DELETE_OPTIONS,
          hint: this.$t('enable_delete.hint')
        },
        enable_user_comments: {
          type: 'select_radio',
          options: this.ENABLE_USER_COMMENTS_OPTIONS,
          hint: this.$t('enable_user_comments.hint')
        },
        advanced_tip_box: {
          type: 'tip_box',
          label: '',
          value: this.$t('review_settings_advanced_tip_box.text')
        },
        require_confirmation: {
          type: 'checkbox',
          groupDescription: this.$t('require_confirmation_description')
        },
        admin_show_entire_message: {
          type: 'select_radio',
          options: this.ADMIN_SHOW_ENTIRE_MESSAGE_OPTIONS
        },
        comment_presets: {
          type: 'button',
          value: this.$t(
            'settings.review_settings_manage.groups.comment_presets'
          ),
          clickHandler: this.openCommentPresetSettingsDialog,
          groupDescription: this.$t('comment_presets_group_description')
        },
        use_makeshop_smart_mileage: {
          type: 'checkbox',
          groupDescription: this.$t('use_makeshop_smart_mileage_description')
        },
        display_write_form_not_allowed_user: {
          type: 'select_radio',
          options: this.DISPLAY_WRITE_FORM_NOT_ALLOWED_USER_OPTIONS,
          hint: this.$t('display_write_form_not_allowed_user.hint')
        },
        enable_nonmember_evaluation: {
          type: 'select_radio',
          options: this.ENABLE_NONMEMBER_EVALUATION_OPTIONS
        },
        hide_if: {
          type: 'hash_select_checkbox',
          options: this.HIDE_IF_OPTIONS,
          groupDescription: this.$t('hide_if_group_description'),
          hint: this.$t('hide_if_hint')
        },
        category_ids_without_review_contents: {
          superAdmin: !this.formObject
            .allow_category_ids_without_review_contents
        },
        use_sub_brand_filter_setting: {
          type: 'checkbox',
          description: this.$t('use_sub_brand_filter_setting_description')
        },
        seoul_ventures_review_codes_to_import_as_visible: {
          type: 'multiple_select',
          groupDescription: this.$t(
            'seoul_ventures_review_codes_to_import_as_visible_description'
          ),
          options: this.data.seoul_ventures_review_code_prefixes.map(c => ({
            label: `${c.service_name} (${c.reviews_count})`,
            value: c.code_prefix
          })),
          superAdmin: true
        },
        excluding_category_ids: {
          type: 'select_product_category',
          multiple: true
        },
        excluding_product_ids: {
          type: 'select_product',
          multiple: true,
          products: this.formObject ? this.formObject.excluding_products : [],
          eventHandlers: {
            'change-items': products =>
              this.$set(this.formObject, 'excluding_products', products)
          }
        },
        profanity_filters: {
          type: 'tags',
          placeholder: this.$t('profanity_filters.placeholder'),
          groupDescription: this.$t('profanity_filters.group_description')
        },
        writing_whitelist_usernames: {
          type: 'tags',
          placeholder: this.$t('writing_whitelist_usernames.placeholder'),
          groupDescription: this.$t(
            'writing_whitelist_usernames.group_description'
          )
        },
        message_dictionary: {
          groupDescription: this.$t('message_dictionary_group_description'),
          fields: this.formObject
            ? _.flatten(
                this.formObject.message_dictionary.map(rule => [
                  {
                    id: `message_dictionary[${rule.id}][old_word]`,
                    value: () => rule.old_word,
                    validate: ['required']
                  },
                  {
                    id: `message_dictionary[${rule.id}][new_word]`,
                    value: () => rule.new_word,
                    validate: ['required']
                  }
                ])
              )
            : []
        },
        product_name_blacklist: {
          type: 'tags',
          groupDescription: this.$t('product_name_blacklist_group_description')
        }
      };
    },
    isReviewOptionTypesButtonAccessible() {
      return this.isShopifyBrand || this.isSuperAdminAccessible;
    }
  },
  watch: {
    'formObject.allowedAuthorType'() {
      this.resetAllowedAuthorType();
    },
    'formObject.allowedSince'() {
      this.resetAllowedAuthorType();
    },
    'formObject.allowedUntil'() {
      this.resetAllowedDaysFromDelivery();
    },
    'formObject.allowedDays'() {
      this.resetAllowedDaysFromDelivery();
    },
    'formObject.message_dictionary'() {
      this.formObject.message_dictionary.forEach(rule => {
        if (rule.id) return;
        rule.id = _.uniqueId();
      });
    }
  },
  mounted() {
    api.get('/review/management_settings').then(({ data }) => {
      this.data = data;
      this.setOrgFormObject(data.management_settings);
    });
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    ...mapActions('session', ['updateReviewSettings']),
    submit(formData) {
      this.isLoading = true;
      api
        .patch('/review/management_settings', formData)
        .then(() => {
          this.orgFormObject = _.cloneDeep(this.formObject);
          this.updateReviewSettings(this.formObject);
          this.createToast(this.$t('app.saved'));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setOrgFormObject(orgFormObject) {
      const {
        allowed_author_type,
        allowed_days_from_delivery,
        enable_review_allowed_author_type_delivery_finished
      } = orgFormObject;

      this.orgFormObject = {
        ...orgFormObject,
        allowedAuthorType:
          allowed_author_type ===
          ReviewAllowedAuthorType.DELIVERY_FINISHED_BUYER_ONLY
            ? ReviewAllowedAuthorType.BUYER_ONLY
            : allowed_author_type,
        allowedSince:
          enable_review_allowed_author_type_delivery_finished &&
          allowed_author_type ===
            ReviewAllowedAuthorType.DELIVERY_FINISHED_BUYER_ONLY
            ? ReviewAllowedAuthorType.DELIVERY_FINISHED_BUYER_ONLY
            : ReviewAllowedAuthorType.BUYER_ONLY,
        allowedUntil:
          allowed_days_from_delivery === 0 ? 'unlimited' : 'limited',
        allowedDays:
          allowed_days_from_delivery === 0 ? null : allowed_days_from_delivery
      };
    },
    resetAllowedAuthorType() {
      const { allowedAuthorType, allowedSince } = this.formObject;
      this.formObject.allowed_author_type =
        allowedAuthorType === ReviewAllowedAuthorType.BUYER_ONLY
          ? allowedSince
          : allowedAuthorType;
    },
    resetAllowedDaysFromDelivery() {
      const { allowedUntil, allowedDays } = this.formObject;
      this.formObject.allowed_days_from_delivery =
        allowedUntil === 'limited' ? allowedDays : 0;
    },
    openCommentPresetSettingsDialog() {
      this.openDialog('ReviewSettingsCommentPresetDialog');
    },
    optionTypeCategoriesDescription(option_type) {
      if (this.isShopifyBrand) return '';
      const categoryNames = this.optionTypeCategoryNames(option_type)
        .map(name => `'${name}'`)
        .join(', ');

      return this.$t(
        `review_option_types.${
          option_type.category_inclusion
            ? 'include_categories'
            : 'exclude_categories'
        }`,
        { categories: categoryNames }
      );
    },
    optionTypeCategoryNames(option_type) {
      if (!option_type.display_category_ids.length) {
        return [this.$t('app.all')];
      } else {
        return option_type.display_category_ids.map(id =>
          this.productCategoryName(id)
        );
      }
    },
    openReviewOptionTypeSettingsDialog() {
      this.openDialog('ReviewSettingsOptionTypeDialog').then(eventBus => {
        eventBus.$on(
          'change',
          reviewOptionTypes =>
            (this.data.review_option_types = reviewOptionTypes)
        );
      });
    },
    openCategoryIdsWithoutReviewContentsMessageDialog() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'alert',
          title: this.$t('category_ids_without_review_contents.title'),
          width: DialogSize.SMALL,
          markdownText: this.$t(
            'category_ids_without_review_contents.message_markdown'
          )
        }
      ]);
    },
    addMessageDictionaryRule() {
      this.formObject.message_dictionary.push({
        product_category_id: null,
        old_word: null,
        new_word: null
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewSettingsManage__select_type_options {
  vertical-align: middle;
  margin-left: 8px;
}

::v-deep {
  .review-option-types-table__name {
    width: 156px;
  }

  .review-option-types-table__field-type,
  .review-option-types-table__storable,
  .review-option-types-table__searchable,
  .review-option-types-table__displayable,
  .review-option-types-table__required {
    width: 67px;
    vertical-align: middle;
  }
}
</style>

<i18n locale="ko">
{
  "allowed_since": {
    "delivery_finished_buyer_only": "배송 완료 시점부터",
    "buyer_only": "구매 시점부터"
  },
  "allowed_days_type": {
    "unlimited": "작성 기한 제한 없음",
    "limited": "배송 완료 {0} 일 후 까지 리뷰 작성 허용"
  },
  "allowed_days_hint": "배송기간은 상황에 따라 달라짐으로 작성 기간 제한은 배송 완료일 기준으로만 설정 가능합니다.",
  "allowed_days_must_be_gte_give_mileage_expires_after_days": "'적립금 설정' 메뉴의 '적립급 지급 기간' 설정값 {0}일 보다 크거나 같은 값을 입력하세요.",
  "allowed_days_must_be_gt_sending_delay_after_delivery_in_days": "'메시지 관리 > 메시지 설정' 메뉴의 '메시지 발송 시간' 설정값 {0}일 보다 큰 값을 입력하세요.",
  "review_allowed_author_type_dropdown": "쇼핑몰 회원만 {0} 작성 가능",
  "duplicate_review_type": {
    "description": "같은 주문 건 안에서 하나의 상품에 대해",
    "show_review_popup_by_products_count": "리뷰 작성 팝업 및 목록에서 중복 노출"
  },
  "enable_edit": {
    "always": "항상 수정 가능",
    "before_mileage": "적립금 지급 전까지만 수정 가능",
    "never": "수정 불가",
    "hint": "수정이 불가능할 경우, 법적 문제가 있을 수 있으니 유의하시기 바랍니다."
  },
  "enable_delete": {
    "always": "항상 삭제 가능",
    "before_mileage": "적립금 지급 전까지만 삭제 가능",
    "never": "삭제 불가",
    "hint": "삭제가 불가능할 경우, 법적 문제가 있을 수 있으니 유의하시기 바랍니다."
  },
  "enable_user_comments": {
    "false": "관리자만 댓글 작성 가능",
    "true": "고객 및 관리자 모두 댓글 작성 가능",
    "hint": "관리자 댓글은 관리자 페이지에서만 작성이 가능합니다."
  },
  "require_confirmation_description": "옵션을 사용하면 신규 리뷰를 위젯에 바로 진열하지 않고 관리자 허가 후 진열합니다.",
  "admin_show_entire_message": {
    "false": "1줄로 줄여서 표시",
    "true": "모든 내용 표시"
  },
  "comment_presets_group_description": "고객이 남긴 리뷰에 남길 감사 댓글을 미리 설정합니다.",
  "use_makeshop_smart_mileage_description": "메이크샵 솔루션에서 먼저 변경한 다음 설정을 켜야 적립금 지급에 오류가 발생하지 않습니다.",
  "display_write_form_not_allowed_user": {
    "false": "작성 대상자에게만 표시",
    "true": "모두에게 표시",
    "hint": "영향을 받는 위젯: 상품리뷰 리스트, 게시판 위젯"
  },
  "enable_nonmember_evaluation_options": {
    "false": "회원",
    "true": "회원 및 비회원"
  },
  "hide_if_group_description": "모든 리뷰 위젯에 적용합니다.",
  "hide_if_hint": "상품 개수가 많은 경우, 설정이 반영되기까지 시간이 소요될 수 있습니다.",
  "hide_if_out_of_stock": "품절 상품의 리뷰",
  "hide_if_undisplayed": "미진열 상품의 리뷰",
  "hide_if_price_is_zero": "판매가가 {0}인 상품의 리뷰",
  "hide_if_no_product_image": "상품 이미지가 없는 리뷰",
  "seoul_ventures_review_codes_to_import_as_visible_description": "외부 연동 리뷰를 위젯에 바로 노출합니다. 위젯 노출 여부는 쇼핑몰의 책임하에 설정이 가능합니다.",
  "review_option_types": {
    "id": "ID",
    "settings_button": "추가 정보 설정",
    "no_user_property_type": "미입력",
    "field_type": "입력 방식",
    "field_types": {
      "text": "텍스트",
      "number": "숫자",
      "select": "선택창"
    },
    "include_categories": "적용 카테고리: {categories} 카테고리에 표시됩니다.",
    "exclude_categories": "제외 카테고리: {categories} 카테고리에 표시가 제외됩니다.",
    "no_data": "설정된 추가 수집정보가 없습니다.",
    "group_description": "상품에 카테고리가 설정되어 있지 않은 경우, 추가 정보 설정이 적용되지 않습니다."
  },
  "category_ids_without_review_contents": {
    "group_description": "식품의약품안전처의 리뷰 노출 및 활용 제재 대상 상품 등의 이유로 리뷰 본문과 포토/동영상을 노출하지 않을 카테고리를 설정하세요. ",
    "title": "리뷰 본문, 포토/동영상을 노출하지 않는 카테고리 설정",
    "message_markdown": "설정한 카테고리의 상품은 다음과 같은 동작이 적용됩니다.<br /><br />1. 모든 위젯에서 리뷰 본문 및 포토/동영상을 노출하지 않습니다.<br />2. 본문을 입력하지 않아도 리뷰 작성이 가능하게 됩니다.<br />3. 리뷰 작성창 본문 및 포토/동영상 첨부 항목에 아래와 같은 안내 문구를 표시합니다.<br /><br />![](https://assets.cre.ma/m/admin/v2/preview_restricted_content_type_review.png)"
  },
  "use_sub_brand_filter_setting_description": "모든 전체 리뷰 위젯 설정에 지정 서브 브랜드 노출 설정을 표시합니다.",
  "profanity_filters": {
    "placeholder": "금지어를 입력해주세요.",
    "group_description": "작성 팝업 및 위젯에서 리뷰 작성 시 설정한 단어를 포함한 리뷰는 등록할 수 없습니다."
  },
  "writing_whitelist_usernames": {
    "placeholder": "ID를 입력해주세요.",
    "group_description": "리뷰 작성은 상품 상세페이지에서만 가능합니다. 해당 아이디로 접속한 경우에는 위젯 숨김 설정을 하더라도 리뷰 작성을 위해 작성 폼과 위젯이 함께 노출됩니다."
  },
  "message_dictionary_group_description": "고객이 작성한 리뷰 및 리뷰 댓글에 특정단어(광고성 등)를 담당자가 원하는 단어로 대체하여 위젯에 보여지는 기능입니다. 관리자 페이지에서는 원래 단어로 검색이 가능합니다.",
  "message_dictionary": {
    "add_button": "+ 필터링 단어 추가",
    "category": "적용 카테고리",
    "old_word": "필터링할 단어",
    "new_word": "필터링 후 위젯 내 표시단어",
    "no_data": "필터링이 설정된 단어가 없습니다."
  },
  "product_name_blacklist_group_description": "상품명에 설정한 단어가 포함된 경우, 상품에 작성된 리뷰가 위젯에 바로 표시되지 않고 자동으로 숨김 처리됩니다."
}
</i18n>
