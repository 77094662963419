import { constructEnum } from '@/lib/enum';

const InstallationPresetPlacementType = constructEnum(
  'InstallationPresetPlacementType',
  {
    REPLACE_REVIEW_SECTION: 10,
    BEFORE_REVIEW_SECTION: 20,
    AFTER_BUY_BUTTON: 30,
    PREPEND_PRODUCT_DETAIL: 40,
    AFTER_THUMBNAIL_IMAGE: 50,
    APPEND_PAGE: 60,
    REPLACE_PAGE: 70,
    REPLACE_PAGE_EXCEPT_TITLE: 80,
    REPLACE_PAGE_EXCEPT_TITLE_AND_IMAGE: 90,
    BEFORE_LIST_WIDGET: 100,
    BEFORE_GALLERY_WIDGET: 110,
    BEFORE_PRODUCTS_GALLERY_WIDGET: 120,
    CUSTOM: 130,
    APPEND_PRODUCT_INFO: 140,
    AFTER_PRODUCT_NAME: 150
  }
);

export default InstallationPresetPlacementType;
