<template>
  <div>
    <ReviewReviewMileageButton :review="review" />
    <ReviewReviewCouponButton
      v-if="isCouponLinkVisible"
      class="ReviewReviewsActionsCell__coupon-button"
      :review="review"
    />
    <ReviewReviewManageButton
      class="ReviewReviewsActionsCell__manage-button"
      :review="review"
      :resource-params="resourceParams"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ReviewReviewMileageButton from './ReviewReviewMileageButton';
import ReviewReviewManageButton from './ReviewReviewManageButton';
import ReviewReviewCouponButton from './ReviewReviewCouponButton';
import ReviewPointType from '@/enums/ReviewPointType';

export default {
  name: 'ReviewReviewsActionsCell',
  components: {
    ReviewReviewMileageButton,
    ReviewReviewManageButton,
    ReviewReviewCouponButton
  },
  props: {
    review: { type: Object, required: true },
    resourceParams: { type: Object, required: true }
  },
  computed: {
    ...mapState('session', ['reviewSettings', 'currentBrand']),
    isCouponLinkVisible() {
      return !!(
        this.reviewSettings.review_point_type === ReviewPointType.COUPON &&
        this.review.brand_user
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewReviewsActionsCell__coupon-button,
.ReviewReviewsActionsCell__manage-button {
  margin-left: 4px;
}
</style>
