<template>
  <div class="ReviewCautionReviewsReportedReviews">
    <ReviewColumnChart v-bind="REPORTED_REVIEWS_DATA" />
  </div>
</template>

<script>
export default {
  name: 'ReviewCautionReviewsReportedReviews',
  data() {
    return {
      REPORTED_REVIEWS_DATA: {
        data: [
          { label: '크리마몰', value: 0.51, color: '#e17055' },
          { label: '전체평균', value: 1.47, color: '#6c5ce7' }
        ],
        title: '리뷰 10,000건당 신고 건 수',
        xAxisLabel: '최근 1년 기준'
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewCautionReviewsReportedReviews {
  min-width: 240px;
  max-width: 298px;
  padding: 18px 22px;
  border-radius: 10px;
  border: 1px solid $color-grey-25;
  background-color: $color-review-white;
}
</style>
