<template>
  <SlotModalForm
    class="InstallRequestRenewalSlotMainBottomDialog"
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    is-maximized-by-default
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #head>
      <SlotInfoCard :image-url="cardImageUrl" :name="cardName" />
    </template>
    <template #group="{ id }">
      <template v-if="id === 'pc_main_bottom'">
        <SlotTable :device-type="WidgetDeviceType.PC" :slot-type="slotType" />
      </template>
      <template v-else-if="id === 'mobile_main_bottom'">
        <SlotTable
          :device-type="WidgetDeviceType.MOBILE"
          :slot-type="slotType"
        />
      </template>
    </template>
  </SlotModalForm>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import SlotModalForm from './components/SlotModalForm';
import SlotInfoCard from '../components/SlotInfoCard';
import WidgetPageType from '@/enums/WidgetPageType';
import InstallSlotType from '@/enums/InstallSlotType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';

export default {
  name: 'InstallRequestRenewalSlotMainBottomDialog',
  components: { SlotModalForm, SlotInfoCard },
  mixins: [DialogFormView],
  data() {
    return {
      pageType: WidgetPageType.MAIN,
      slotType: InstallSlotType.MAIN_BOTTOM,
      cardImageUrl:
        'https://assets.cre.ma/m/admin/v2/install_request/main_bottom_slot@3x.png',
      oldInstallations: [],
      oldWidgets: []
    };
  },
  computed: {
    ...mapState('installRequest', ['installDeviceTypes', 'currentSlotId']),
    ...mapGetters('installRequest', [
      'currentWidgetsBySlotId',
      'currentInstallationsBySlotId',
      'hasInstallationsSelected'
    ]),
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    InstallSlotType() {
      return InstallSlotType;
    },
    cardName() {
      return this.$t('card_name');
    },
    formSections() {
      return [
        {
          id: 'bottom_slot',
          label: this.$t('select_widget'),
          groups: [
            {
              id: 'tip',
              label: null,
              type: 'tip_box',
              value: this.$t('select_widget_tip')
            },
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.PC)
              ? [
                  {
                    id: 'pc_main_bottom',
                    label: this.$t('app.pc'),
                    validate: [
                      { rule: 'required', value: () => true },
                      {
                        rule: () =>
                          this.hasInstallationsSelected(WidgetDeviceType.PC),
                        errorMessage: this.$t('validations.required')
                      }
                    ]
                  }
                ]
              : []),
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)
              ? [
                  {
                    id: 'mobile_main_bottom',
                    label: this.$t('app.mobile'),
                    validate: [
                      { rule: 'required', value: () => true },
                      {
                        rule: () =>
                          this.hasInstallationsSelected(
                            WidgetDeviceType.MOBILE
                          ),
                        errorMessage: this.$t('validations.required')
                      }
                    ]
                  }
                ]
              : [])
          ]
        }
      ];
    },
    isFormDataChanged() {
      const orgSortedInstallations = _.sortBy(this.oldInstallations, v => [
        v.id,
        v.widget_device_type
      ]);
      const sortedInstallations = _.sortBy(
        this.currentInstallationsBySlotId,
        v => [v.id, v.widget_device_type]
      );
      const result = !_.isEqual(orgSortedInstallations, sortedInstallations);
      return result;
    }
  },
  watch: {
    currentInstallationsBySlotId: {
      handler(installations) {
        if (!installations?.length) return;

        if (this.installDeviceTypes?.includes(WidgetDeviceType.PC)) {
          this.validateField('pc_main_bottom');
        }
        if (this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)) {
          this.validateField('mobile_main_bottom');
        }
      }
    }
  },
  created() {
    this.SET_CURRENT_PAGE_TYPE(this.pageType);
    this.ensureSlotId(this.slotType);
    this.oldWidgets = this.currentWidgetsBySlotId;
    this.oldInstallations = this.currentInstallationsBySlotId;
  },
  methods: {
    ...mapActions('installRequest', ['ensureSlotId']),
    ...mapMutations('installRequest', [
      'ADD_WIDGETS_BY_SLOT_ID',
      'REMOVE_WIDGETS_BY_SLOT_ID',
      'APPEND_DIRECTION_WITH_SLOT_ID',
      'REMOVE_DIRECTIONS_BY_SLOT_ID',
      'SET_CURRENT_PAGE_TYPE'
    ]),
    beforeCloseDialog() {
      this.REMOVE_WIDGETS_BY_SLOT_ID(this.currentSlotId);
      if (this.oldWidgets.length > 0) {
        this.ADD_WIDGETS_BY_SLOT_ID(this.oldWidgets);
      }
    },
    appendDirections() {
      this.installDeviceTypes.forEach(deviceType => {
        this.APPEND_DIRECTION_WITH_SLOT_ID({
          device_type: deviceType,
          page_type: this.pageType,
          slot_type: this.slotType
        });
      });
    },
    submit() {
      this.REMOVE_DIRECTIONS_BY_SLOT_ID(this.currentSlotId);
      if (this.currentInstallationsBySlotId.length > 0) {
        this.appendDirections();
      }
      this.close(true);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppForm__group:last-child {
    margin-top: 32px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "설치 위젯 선택",
  "sub_title": "메인 페이지",
  "card_name": "페이지 최하단",
  "select_widget": "위젯 선택",
  "select_widget_tip": "설치할 위젯을 선택해 주세요. 여러개 위젯을 선택한 경우 설치 순서도 확인해주세요."
}
</i18n>
