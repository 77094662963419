<template>
  <div class="TheReviewSettingsTutorialHead">
    <AppBubble v-if="showBubble" class="TheReviewSettingsTutorialHead__bubble"
      >{{ $t('bubble') }}
    </AppBubble>
    <div class="TheReviewSettingsTutorialHead__title">{{ $t('title') }}</div>
    <div class="TheReviewSettingsTutorialHead__icon-wrapper">
      <button
        type="button"
        class="TheReviewSettingsTutorialHead__icon"
        @click="$emit('toggle-minimize')"
      >
        <AppSvgIcon name="icon-minimize" :width="15" :height="15" />
      </button>
      <button
        type="button"
        class="TheReviewSettingsTutorialHead__icon"
        @click="$emit('close')"
      >
        <AppSvgIcon name="icon-close" :width="15" :height="15" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheReviewSettingsTutorialHead',
  props: {
    showBubble: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_dialog.scss';

.TheReviewSettingsTutorialHead {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 27px 23px;
}

.TheReviewSettingsTutorialHead__title {
  @include text-label;
}

.TheReviewSettingsTutorialHead__icon-wrapper {
  display: flex;
  gap: 8px;
}

.TheReviewSettingsTutorialHead__icon {
  color: $color-grey-40;
}
</style>

<i18n locale="ko">
{
  "title": "리뷰 기본 설정 가이드",
  "bubble": "아직 완료하지 않은 주요 기본 설정 3개가 있습니다"
}
</i18n>
