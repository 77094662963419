<template>
  <AppDataSection :title="$t('title')">
    <div
      class="ReviewMessagesNthStepReviewCampaignCategorySettings__button-wrapper"
    >
      <AppButton
        :label="$t('app.settings')"
        @click="openCategorySettingsDialog"
      />
    </div>
    <div>
      <label v-text="$t('included_category')" />
      <span
        class="ReviewMessagesNthStepReviewCampaignCategorySettings__categories"
        >{{ includingCategories }}</span
      >
    </div>
    <div>
      <label v-text="$t('excluded_category')" />
      <span
        class="ReviewMessagesNthStepReviewCampaignCategorySettings__categories"
        >{{ excludingCategories }}</span
      >
    </div>
  </AppDataSection>
</template>

<script>
import api from '@/lib/api';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ReviewMessagesNthStepReviewCampaignCategorySettings',
  props: {
    campaignId: { type: Number, required: true }
  },
  data() {
    return {
      campaignSettings: {}
    };
  },
  computed: {
    ...mapGetters('productCategory', ['productCategoryName']),
    includingCategories() {
      const {
        use_including_all_product_categories,
        including_product_category_ids
      } = this.campaignSettings;

      if (use_including_all_product_categories) {
        return this.$t('all');
      } else {
        return (including_product_category_ids || [])
          .map(id => this.productCategoryName(id))
          .join(', ');
      }
    },
    excludingCategories() {
      const {
        use_excluding_product_category_ids,
        excluding_product_category_ids
      } = this.campaignSettings;

      if (use_excluding_product_category_ids) {
        return (excluding_product_category_ids || [])
          .map(id => this.productCategoryName(id))
          .join(', ');
      } else {
        return this.$t('empty');
      }
    }
  },
  mounted() {
    api
      .get(`/review/campaigns/${this.campaignId}/settings`)
      .then(({ data }) => {
        this.campaignSettings = { ...data.settings };
      });
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openCategorySettingsDialog() {
      this.openDialog([
        'ReviewMessagesNthStepReviewCampaignCategoryDialog',
        { campaignId: this.campaignId, campaignSettings: this.campaignSettings }
      ]).then(eventBus =>
        eventBus.$on(
          'submit',
          newSettings => (this.campaignSettings = { ...newSettings })
        )
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewMessagesNthStepReviewCampaignCategorySettings__button-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
}

.ReviewMessagesNthStepReviewCampaignCategorySettings__categories {
  @include text-label;
}
</style>

<i18n locale="ko">
{
  "title": "발송 대상 카테고리",
  "included_category": "포함 카테고리: ",
  "excluded_category": "제외 카테고리: ",
  "all": "모든 상품 카테고리",
  "empty": "-"
}
</i18n>
