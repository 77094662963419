var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{staticClass:"ReviewMessagesNthStepReviewCampaignStatCards",attrs:{"is-loading":_vm.isLoading}},[_c('AppStatCard',_vm._b({staticClass:"ReviewMessagesNthStepReviewCampaignStatCards__card",attrs:{"title":_vm.$t('stats.review_message_rate', [1])}},'AppStatCard',
      _vm.nthStepReviewRate(
        _vm.stats.first_step_reviews_count,
        _vm.stats.sub_orders_count
      )
    ,false)),_c('AppStatCard',_vm._b({staticClass:"ReviewMessagesNthStepReviewCampaignStatCards__card",attrs:{"title":_vm.$t('stats.review_message_rate', [2])}},'AppStatCard',
      _vm.nthStepReviewRate(
        _vm.stats.second_step_reviews_count,
        _vm.stats.second_step_messages_sent_count
      )
    ,false)),_c('AppStatCard',_vm._b({staticClass:"ReviewMessagesNthStepReviewCampaignStatCards__card",attrs:{"title":_vm.$t('stats.review_message_rate_over_third_step')}},'AppStatCard',
      _vm.nthStepReviewRate(
        _vm.stats.over_third_step_reviews_count,
        _vm.stats.over_third_step_messages_sent_count
      )
    ,false)),(_vm.isSuperAdminAccessible)?_c('AppStatCard',{class:[
      'ReviewMessagesNthStepReviewCampaignStatCards__card',
      'super-admin__item'
    ],attrs:{"title":_vm.$t('stats.more_reviews_click'),"stat":_vm.stats.nth_step_click_more_reviews_count,"unit":_vm.StatUnit.COUNT,"tooltip":_vm.$t('stats.tooltip')}}):_vm._e(),_c('div',{staticClass:"ReviewMessagesNthStepReviewCampaignStatCards__hint"},[_vm._v(" "+_vm._s(_vm.$t('date_range', [ _vm.formatDate(_vm.stats.start_date), _vm.formatDate(_vm.stats.end_date) ]))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }