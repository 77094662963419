import { constructEnum } from '@/lib/enum';

export default constructEnum('ReviewSort', {
  MOST_LIKES: 10,
  MOST_HELPS: 15,
  MOST_TOTAL_HELPS: 16,
  RECENTLY_CREATED: 20,
  MOST_SCORES: 30,
  ALPHABETICAL: 40,
  MOST_REVIEWED: 50
});
