import TutorialStatus from '@/enums/TutorialStatus';

const state = {
  introStatus: TutorialStatus.NONE,
  reviewImportStatus: TutorialStatus.NONE,
  isReviewSettingsTutorialVisible: false
};

const mutations = {
  SET_IS_REVIEW_SETTINGS_TUTORIAL_VISIBLE(
    state,
    isReviewSettingsTutorialVisible
  ) {
    state.isReviewSettingsTutorialVisible = isReviewSettingsTutorialVisible;
  },
  SET_INTRO_STATUS(state, introStatus) {
    state.introStatus = introStatus;
  },
  SET_REVIEW_IMPORT_STATUS(state, reviewImportStatus) {
    state.reviewImportStatus = reviewImportStatus;
  }
};

const actions = {
  showTutorialDialog(_, dialog) {
    const targetElement = document.getElementById(dialog.targetElementId);
    if (!targetElement) return;

    dialog.$mount();
    targetElement.appendChild(dialog.$el);

    const dialogStyle = {
      position: 'absolute',
      textAlign: 'start',
      top: 'auto',
      right: 'auto',
      bottom: 'auto',
      left: 'auto'
    };

    switch (dialog.position) {
      case 'top':
        dialogStyle.bottom = 'calc(100%)';
        dialogStyle.left = '-10px';
        break;
      case 'bottom':
        dialogStyle.top = 'calc(100%)';
        dialogStyle.left = '-10px';
        break;
    }

    Object.assign(dialog.$el.style, dialogStyle);
  }
};

export default { namespaced: true, state, mutations, actions };
