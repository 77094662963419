<template>
  <AppModalFormLivePreview
    :title="title"
    :sub-title="$t('subtitle')"
    :form-props="{ ...formProps, objectId: 'message' }"
    width="440px"
    :is-loading="isLoading"
    v-on="formEvents"
  >
    <input type="hidden" name="message[campaign_type]" :value="campaignType" />
    <input
      type="hidden"
      name="message[biz_message_template_type]"
      :value="formObject.biz_message_template_type"
    />
    <input
      type="hidden"
      name="message[biz_message_template_id]"
      :value="formObject.biz_message_template_id"
    />
    <template #left>
      <AppAjaxContent :is-loading="isLoadingPreview">
        <BizMessageTemplatePreview :template="template" />
      </AppAjaxContent>
    </template>
    <template #group="group">
      <template v-if="group.id === 'dispatch_recipient'">
        <Component
          :is="dispatchRecipientComponent"
          class="AppForm__group-field"
          :message="message"
          :error="group.errors.dispatch_days"
          @validate="validateField('dispatch_days')"
        />
      </template>
      <template v-else-if="group.id === 'dispatch_hour'">
        <div class="AppForm__group-field">
          <i18n path="dispatch_hour.description">
            <AppSelectHour
              v-model="message.dispatch_hour"
              name="message[dispatch_hour]"
              :selectable-hours="SELECTABLE_DISPATCH_HOURS"
            />
          </i18n>
        </div>
      </template>
      <template v-else-if="group.id === 'biz_message_template_id'">
        <ReviewMessagesBizMessageTemplateSelectTable
          class="AppForm__group-field"
          :template="message"
          :campaign-type="campaignType"
          @change="onChangeTemplate"
        />
      </template>
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import api from '@/lib/api';
import { mapState, mapActions } from 'vuex';
import BizMessageTemplatePreview from '@/views/biz_messages/dialogs/BizMessageTemplatePreview';
import BizMessageTemplateShowView from '@/mixins/BizMessageTemplateShowView';
import DialogFormView from '@/mixins/DialogFormView';
import ReviewCampaignType from '@/enums/ReviewCampaignType';
import CremaServiceType from '@/enums/CremaServiceType';

export default {
  name: 'ReviewMessagesMessageFormDialog',
  components: { BizMessageTemplatePreview },
  mixins: [DialogFormView, BizMessageTemplateShowView],
  props: {
    campaignType: { type: Number, required: true },
    campaignMessageId: { type: Number, default: null }
  },
  data: () => ({
    isLoadingMessage: true,
    isLoadingTemplates: true,
    isLoadingPreview: false
  }),
  computed: {
    ...mapState('bizMessageTemplate', ['templateType']),
    message() {
      return this.formObject;
    },
    isLoading() {
      return this.isLoadingMessage || this.isLoadingTemplates;
    },
    title() {
      return ReviewCampaignType.t(this.campaignType);
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'dispatch_recipient',
              label: this.$t('dispatch_recipient'),
              fields: [
                {
                  id: 'dispatch_days',
                  validate: ['required', { rule: 'lte', allowed: 365 }]
                }
              ]
            },
            ...(this.campaignType === ReviewCampaignType.NTH_STEP_REVIEW
              ? [
                  {
                    id: 'exclude_negative_review',
                    label: this.$t('exclude_negative_review.label'),
                    type: 'checkbox',
                    titleBadge: {
                      label: this.$t('app.crema_ai'),
                      badgeStyle: 'red'
                    },
                    hint: this.$t('exclude_negative_review.hint')
                  }
                ]
              : []),
            {
              id: 'dispatch_hour',
              label: this.$t('dispatch_hour.label'),
              hint: this.$t('dispatch_hour.hint')
            },
            {
              id: 'biz_message_template_id',
              label: this.$t('biz_message_template_id'),
              validate: ['required']
            }
          ]
        }
      ];
    },
    campaignMessageIdExist() {
      return Boolean(this.campaignMessageId);
    },
    dispatchRecipientComponent() {
      switch (this.campaignType) {
        case ReviewCampaignType.MEMBER_REVIEW:
          return 'ReviewMessagesMemberReviewDispatchRecipient';
        case ReviewCampaignType.NTH_STEP_REVIEW:
          return 'ReviewMessagesNthStepReviewDispatchRecipient';
        default:
          return '';
      }
    }
  },
  watch: {
    'formObject.biz_message_template_id'() {
      this.setTemplateData();
      this.validateField('biz_message_template_id');
    }
  },
  mounted() {
    this.fetchTemplates({
      serviceType: CremaServiceType.REVIEW
    }).finally(() => (this.isLoadingTemplates = false));

    api
      .get(
        this.campaignMessageIdExist
          ? `/review/campaign/messages/${this.campaignMessageId}/edit`
          : `/review/campaign/messages/new?campaign_type=${this.campaignType}`
      )
      .then(({ data }) => (this.orgFormObject = { ...data.message }))
      .finally(() => (this.isLoadingMessage = false));
  },
  created() {
    this.SELECTABLE_DISPATCH_HOURS = [...Array(12).keys()].map(h => h + 8);
  },
  methods: {
    ...mapActions('bizMessageTemplate', ['fetchTemplates', 'fetchTemplate']),
    submit(formData) {
      this.isSubmitting = true;
      const request = this.campaignMessageIdExist
        ? api.patch(
            `/review/campaign/messages/${this.campaignMessageId}`,
            formData
          )
        : api.post('/review/campaign/messages', formData);

      request
        .then(() => {
          this.emit('submit');
          this.close(true);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    setTemplateData() {
      const {
        biz_message_template_id,
        biz_message_template_type
      } = this.formObject;
      if (this.formObject.biz_message_template_id) {
        this.isLoadingPreview = true;
        this.fetchTemplate({
          id: biz_message_template_id,
          templateType: biz_message_template_type
        })
          .then(
            ({ data }) => (this.template = this.formatTemplate(data.template))
          )
          .finally(() => (this.isLoadingPreview = false));
      } else this.template = { buttons: [] };
    },
    onChangeTemplate(template) {
      this.formObject = { ...this.formObject, ...template };
    }
  }
};
</script>

<i18n locale="ko">
{
  "subtitle": "발송 메시지 설정",
  "dispatch_recipient": "발송 대상",
  "exclude_negative_review": {
    "label": "부정 리뷰 작성한 경우 제외",
    "hint": "크리마 AI 분석 결과 부정 리뷰로 판단된 경우 메시지 발송 대상에서 제외합니다."
  },
  "dispatch_hour": {
    "label": "메시지 발송 시간",
    "description": "{0} 시에 발송합니다.",
    "hint": "Tip. 쇼핑몰 고객이 여유를 가지고 리뷰를 작성할 수 있는 시간을 설정해보세요."
  },
  "biz_message_template_id": "발송할 메시지"
}
</i18n>
