import { constructEnum } from '@/lib/enum';

export default constructEnum('ReviewVendorPrefix', {
  ALPHA_REVIEW: 'AR',
  SNAP_REVIEW: 'SR',
  V_REVIEW: 'VR',
  ETC_REVIEW: 'ER',
  SHOPIFY_REVIEW: 'SFR',
  JUDGE_ME_REVIEW: 'JMR'
});
