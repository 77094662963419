<template>
  <div class="ReviewNewReviewsMonthlyTrend">
    <ReviewMiniLineChart
      class="ReviewNewReviewsMonthlyTrend__chart"
      v-bind="chartData"
    />
  </div>
</template>

<script>
import ReviewMiniLineChart from '@/views/review/components/ReviewMiniLineChart';

export default {
  name: 'ReviewNewReviewsMonthlyTrend',
  components: { ReviewMiniLineChart },

  data() {
    return {
      chartData: {
        series: [
          {
            name: this.$t('chart.new_reviews'),
            data: [20, 30, 20, 15, 14]
          },
          {
            name: this.$t('chart.best_ai_reviews'),
            data: [8, 12, 8, 5, 5]
          },
          {
            name: this.$t('chart.first_written_reviews'),
            data: [10, 5, 5, 1, 1]
          }
        ],
        categories: ['10월', '11월', '12월', '1월', '2월'],
        title: this.$t('chart.title')
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.ReviewNewReviewsMonthlyTrend {
  height: 236px;
  min-width: 255px;
  max-width: 310px;
  padding: 18px 10px;
  border-radius: 10px;
  border: 1px solid $color-grey-25;
  background-color: $color-review-white;
}

.ReviewNewReviewsMonthlyTrend__chart {
  width: 100%;
}
</style>

<i18n locale="ko">
{
  "chart": {
    "title": "월간 추이",
    "new_reviews": "신규 리뷰",
    "best_ai_reviews": "AI 분석 우수 리뷰",
    "first_written_reviews": "처음 작성한 리뷰"
  }
}
</i18n>
