import { constructEnum } from '@/lib/enum';

const TutorialStatus = constructEnum('TutorialStatus', {
  NONE: 0,
  PENDING: 10,
  IN_PROGRESS: 20,
  COMPLETE: 1000
});

export default TutorialStatus;
