var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'AppChartTooltip',
    { 'AppChartTooltip--in-dialog': _vm.isInDialog },
    _vm.className
  ],style:(_vm.positionStyle)},[(_vm.title)?_c('div',{staticClass:"AppChartTooltip__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('div',{staticClass:"AppChartTooltip__body"},[_c('table',{staticClass:"AppChartTooltip__table"},_vm._l((_vm.items),function(item,i){return _c('tr',{key:i,class:['AppChartTooltip__item', item.className]},[_c('td',{staticClass:"AppChartTooltip__name"},[(item.symbol)?_c('svg',{class:[
              'AppChartTooltip__icon',
              ("highcharts-color-" + (item.hasOwnProperty('colorIndex') ? item.colorIndex : i))
            ],attrs:{"viewBox":"0 0 8 8"}},[(item.symbol === 'square')?_c('rect',{attrs:{"width":"8","height":"8"}}):(item.symbol === 'line')?_c('line',{attrs:{"x1":"0","y1":"4","x2":"8","y2":"4","stroke-width":"2"}}):_c('circle',{attrs:{"cx":"4","cy":"4","r":"3","stroke":"none"}})]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(item.name)}})]),_c('td',{staticClass:"AppChartTooltip__value"},[(typeof item.value === 'string')?_c('div',{domProps:{"innerHTML":_vm._s(item.value)}}):_c('AppNumber',_vm._b({},'AppNumber',item,false))],1)])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }