<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppTable
      class="SlotTable AppForm__group-field AppForm__group-field--mt8"
      :columns="columns"
      :rows="rows"
    >
      <template #cell="{ column, row }">
        <template v-if="column.id === 'order'">
          {{ row.order || '-' }}
        </template>
        <template v-else-if="column.id === 'widget_style'">
          <span>{{ row.widget_style_name }}</span>
          <AppBadge
            v-if="row.widget_id"
            class="SlotTable__badge"
            badge-style="mint-green-outline"
            badget-size="small"
            :label="$t('using_widget')"
          />
        </template>
        <template v-else-if="column.id === 'select'">
          <AppSwitch
            :value="row.id"
            :checked="row.selected"
            @change="updateSelectedRowIds(row, $event)"
          />
        </template>
        <template v-else-if="column.id === 'actions'">
          <AppButtonDraggable
            :label="$t('app.sort')"
            :disabled="!row.selected"
            @sort="sort"
          />
        </template>
      </template>
    </AppTable>
  </AppAjaxContent>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { arrayMoveIndex } from '@/lib/array';
import InstallRequestRenewalSlotTableView from '@/mixins/InstallRequestRenewalSlotTableView';

export default {
  name: 'SlotTable',
  mixins: [InstallRequestRenewalSlotTableView],
  props: {
    slotType: { type: Number, required: true }
  },
  data() {
    return {
      selectedRowIds: []
    };
  },
  computed: {
    ...mapGetters('installRequest', ['currentWidgetsBySlotId']),
    columns() {
      return [
        {
          id: 'order',
          label: this.$t('form.order'),
          width: '15%'
        },
        {
          id: 'widget_style',
          label: this.$t('form.widget_style'),
          width: '25%'
        },
        {
          id: 'widget_info',
          type: 'html',
          label: this.$t('form.widget_info'),
          width: '30%'
        },
        {
          id: 'select',
          label: this.$t('app.select'),
          width: '10%'
        },
        {
          id: 'actions',
          label: this.$t('app.actions'),
          width: '20%'
        }
      ];
    },
    selectedSlotInstallationIds() {
      return this.currentWidgetsBySlotId
        .filter(
          i =>
            i.widget_device_type === this.deviceType &&
            i.slot_type === this.slotType &&
            i.selected
        )
        .map(i => i.id);
    },
    rows() {
      let index = 0;
      return this.widgets.map(row => {
        if (this.selectedRowIds.includes(row.id)) {
          index += 1;
          return { ...row, order: index, selected: true };
        } else {
          return { ...row, order: null, selected: false };
        }
      });
    }
  },
  watch: {
    rows() {
      this.updateWidgetsBySlotId();
    }
  },
  methods: {
    ...mapMutations('installRequest', ['ADD_WIDGETS_BY_SLOT_ID']),
    ...mapActions('installRequest', ['removeDeviceSlotWidgetsBySlotId']),
    updateSelectedRowIds(row, enabled) {
      const selectedWidgetIds = this.selectedRowIds;
      if (enabled) {
        selectedWidgetIds.push(row.id);
      } else {
        const index = selectedWidgetIds.findIndex(w => w === row.id);
        selectedWidgetIds.splice(index, 1);
      }

      this.selectedRowIds = selectedWidgetIds;
    },
    sort({ srcIndex, dstIndex }) {
      this.widgets = arrayMoveIndex(this.widgets, srcIndex, dstIndex);
    },
    updateWidgetsBySlotId() {
      this.removeDeviceSlotWidgetsBySlotId({
        deviceType: this.deviceType,
        slotType: this.slotType
      });
      if (this.rows.length > 0) this.ADD_WIDGETS_BY_SLOT_ID(this.rows);
    },
    setDefaultWidget() {
      if (this.selectedSlotInstallationIds.length > 0) {
        this.selectedRowIds = this.selectedSlotInstallationIds;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.SlotTable__badge {
  margin-left: 4px;
}
</style>

<i18n locale="ko">
{
  "form": {
    "order": "설치 순서",
    "widget_style": "위젯",
    "widget_info": "위젯 정보"
  },
  "widget_style": "{0} 위젯",
  "widget_info": "위젯 ID: {0}<br>위젯 이름: {1}",
  "using_widget": "사용 중"
}
</i18n>
