<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{
      submitLabel: $t('agree'),
      ...formProps
    }"
    hide-close-button
    v-on="formEvents"
  >
    <AppInfoBox class="TheTermsAgreementDialog__info-box">
      <AppText :text="$t('info')" />
    </AppInfoBox>
    <template #group="{ error }">
      <AppTermsAgreementCheckbox
        id="terms"
        v-model="formObject.terms"
        name="terms[]"
        :invalid="!!error"
        @change="validateField('terms')"
      />
    </template>
    <template #description>
      <AppText class="TheTermsAgreementDialog__caption" :text="$t('caption')" />
    </template>
  </AppModalForm>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'TheTermsAgreementDialog',
  mixins: [DialogFormView],
  data() {
    return {
      isSubmitting: false
    };
  },
  computed: {
    formSections() {
      return [
        {
          id: 'terms',
          groups: [
            {
              id: 'terms',
              label: '',
              validate: [
                {
                  rule: v =>
                    _.difference(['age', 'use', 'privacy', 'outsource'], v)
                      .length === 0,
                  errorMessage: this.$t('validations.required')
                }
              ]
            }
          ]
        }
      ];
    }
  },
  methods: {
    submit() {
      this.isSubmitting = true;

      api
        .patch('/terms_agreements', {
          terms: this.formObject.terms
        })
        .then(() => {
          this.eventBus.$emit('submit');
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/mixins/_texts.scss';

.TheTermsAgreementDialog__info-box {
  @include text-label;

  margin-bottom: 24px;
}

.TheTermsAgreementDialog__caption {
  @include text-caption;

  margin-top: 32px;
}
</style>

<i18n locale="ko">
  {
    "title": "크리마 이용 약관 동의 안내",
    "info": "💡 결제 전 원활한 크리마 서비스 이용을 위해 약관 동의를 다시 진행해주세요.",
    "caption": "동의할 경우 브랜드 전체에 적용되어, 쇼핑몰에 다른 담당자가 있을 경우 추가 동의 없이 이용할 수 있습니다.",
    "agree": "동의"
  }
</i18n>
