<template>
  <AppModalForm
    :title="$t(isEditing ? 'title_edit' : 'title_new')"
    :form-props="{ ...formProps, objectId: 'smart_installation' }"
    v-on="formEvents"
  >
    <input
      type="hidden"
      name="smart_installation[installation_type]"
      :value="installation_type"
    />
  </AppModalForm>
</template>

<script>
import SmartInstallationType from '@/enums/SmartInstallationType';
import SmartInstallationFormView from '@/mixins/SmartInstallationFormView';
import WidgetPageType from '@/enums/WidgetPageType';
import api from '@/lib/api';
import _ from 'lodash';

export default {
  name: 'MyPendingReviewsCountFormDialog',
  mixins: [SmartInstallationFormView],
  props: {
    myPendingReviewsCount: {
      type: Object,
      default: () => ({
        reviews_count_display_format: '%{count}'
      })
    }
  },
  computed: {
    groupIds() {
      return this.formObject.page_type === WidgetPageType.SPECIFIC
        ? [
            'device_type',
            'page_type',
            'page_path',
            'element_selector',
            'skin_id',
            'reviews_count_display_format'
          ]
        : [
            'device_type',
            'page_type',
            'element_selector',
            'skin_id',
            'reviews_count_display_format'
          ];
    },
    pageTypeOptions() {
      return WidgetPageType.options([
        WidgetPageType.MYSHOP_MAIN,
        WidgetPageType.MY_REVIEWS,
        WidgetPageType.MY_ORDERS,
        WidgetPageType.SPECIFIC
      ]);
    },
    keywords() {
      return [
        {
          key: 'count',
          desc: this.$t('keywords.count'),
          value: '%{count}'
        }
      ];
    },
    isEditing() {
      return !!this.formObject.id;
    }
  },
  created() {
    this.installation_type = SmartInstallationType.MY_PENDING_REVIEWS_COUNT;
  },
  mounted() {
    this.orgFormObject = _.cloneDeep(this.myPendingReviewsCount);
  },
  methods: {
    submit(formData) {
      if (this.isEditing) {
        api
          .patch(`/smart_installations/${this.formObject.id}`, formData, {
            successMessage: this.$t('app.saved')
          })
          .then(({ data }) => {
            this.eventBus.$emit('update', data.smart_installation);
            this.close(true);
          });
      } else {
        api
          .post('/smart_installations', formData, {
            successMessage: this.$t('app.created')
          })
          .then(({ data }) => {
            this.eventBus.$emit('save', data.smart_installation);
            this.close(true);
          });
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "title_new": "작성가능한 리뷰 수 교체 설치 추가",
  "title_edit": "작성가능한 리뷰 수 교체 설치 설정",
  "keywords": {
    "count": "작성가능한 리뷰 수"
  }
}
</i18n>
