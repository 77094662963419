<template>
  <div class="ReviewNewReviewsActionsCell">
    <div>
      <ReviewNewReviewsPinButton v-bind="{ review, resourceParams }" />
      <ReviewNewReviewsCommentButton :review="review" />
      <ReviewNewReviewsManageButton
        :review="review"
        :resource-params="resourceParams"
      />
    </div>
    <div>
      <ReviewReviewMileageButton :review="review" />
      <ReviewReviewCouponButton
        v-if="isCouponLinkVisible"
        class="ReviewNewReviewsActionsCell__coupon-button"
        :review="review"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ReviewPointType from '@/enums/ReviewPointType';

export default {
  name: 'ReviewNewReviewsActionsCell',
  props: {
    review: { type: Object, required: true },
    resourceParams: { type: Object, default: () => ({}) }
  },
  computed: {
    ...mapState('session', ['reviewSettings', 'currentBrand']),
    isCouponLinkVisible() {
      return !!(
        this.reviewSettings.review_point_type === ReviewPointType.COUPON &&
        this.review.brand_user
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewNewReviewsActionsCell {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ReviewNewReviewsActionsCell__coupon-button {
  margin-left: 4px;
}
</style>
