<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{ formStyle: 'wide', ...formProps }"
    v-on="formEvents"
  >
    <template #group="{id, errors}">
      <template v-if="id === 'nth_step_review_allowed_days_after_send'">
        <i18n path="nth_step_review_allowed_days_after_send.description">
          <AppNumberInput
            v-model="formObject.nth_step_review_allowed_days_after_send"
            name="campaign_settings[nth_step_review_allowed_days_after_send]"
            :digits="3"
            :invalid="!!errors.nth_step_review_allowed_days_after_send"
            inline
            @blur="validateField('nth_step_review_allowed_days_after_send')"
            @change="validateField('nth_step_review_allowed_days_after_send')"
          />
          <AppFormError
            :error="errors.nth_step_review_allowed_days_after_send"
          />
        </i18n>
      </template>
      <template v-else-if="id === 'nth_step_review_mileage_expiry_days'">
        <i18n path="nth_step_review_mileage_expiry_days.description">
          <AppNumberInput
            v-model="formObject.nth_step_review_mileage_expiry_days"
            name="campaign_settings[nth_step_review_mileage_expiry_days]"
            :digits="3"
            :invalid="!!errors.nth_step_review_mileage_expiry_days"
            inline
            @blur="validateField('nth_step_review_mileage_expiry_days')"
            @change="validateField('nth_step_review_mileage_expiry_days')"
          />
          <AppFormError :error="errors.nth_step_review_mileage_expiry_days" />
        </i18n>
      </template>
      <template v-else-if="id === 'nth_step_review_additional_mileage_cents'">
        <label
          v-text="
            $t('nth_step_review_additional_mileage_cents.description', [
              formObject.nth_step_review_additional_mileage_cents
            ])
          "
        />
        <AppFormHint
          :message="$t('nth_step_review_additional_mileage_cents.hint')"
        />
        <AppExternalLinkWithIcon
          :to="pathWithBrandParams('/v2/review/settings/mileage')"
        >
          <AppInlineButton
            button-style="caption"
            :label="$t('nth_step_review_additional_mileage_cents.link')"
          />
        </AppExternalLinkWithIcon>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import { mapGetters } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ReviewMessagesNthStepReviewCampaignMileageDialog',
  mixins: [DialogFormView],
  props: {
    campaignId: { type: Number, required: true },
    campaignSettings: { type: Object, default: () => {} }
  },
  computed: {
    ...mapGetters(['pathWithBrandParams']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'nth_step_review_allowed_days_after_send',
              label: this.$t('nth_step_review_allowed_days_after_send.label'),
              validate: [
                'required',
                { rule: 'min_max_integer', min: 1, max: 365 }
              ]
            },
            {
              id: 'nth_step_review_mileage_expiry_days',
              label: this.$t('nth_step_review_mileage_expiry_days.label'),
              validate: [
                'required',
                { rule: 'min_max_integer', min: 1, max: 365 }
              ]
            },
            {
              id: 'nth_step_review_additional_mileage_cents',
              label: this.$t('nth_step_review_additional_mileage_cents.label')
            }
          ]
        }
      ];
    }
  },
  created() {
    this.orgFormObject = {
      ...this.campaignSettings
    };
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(
          `/review/campaigns/${this.campaignId}/settings/update_mileage_settings`,
          formData,
          {
            successMessage: this.$t('app.saved')
          }
        )
        .then(({ data }) => {
          this.orgFormObject = data.settings;
          this.close(true);

          this.eventBus.$emit('submit', data.settings);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "리뷰 작성 및 적립금 지급",
  "nth_step_review_allowed_days_after_send": {
    "label": "리뷰 작성 기간",
    "description": "쇼핑몰 회원만 메시지 발송 완료 후, {0}일 까지 리뷰 작성 1회 허용"
  },
  "nth_step_review_mileage_expiry_days": {
    "label": "적립금 지급 기간",
    "description": "메시지 발송 완료 후, {0}일 이내 작성한 리뷰만 적립금 지급"
  },
  "nth_step_review_additional_mileage_cents":{
    "label": "적립금",
    "description": "작성 시, {0}원 적립",
    "hint": "Tip. 적립금은 [리뷰 설정 - 기본 설정 - 적립금]에서 설정할 수 있습니다.",
    "link": "적립금 설정 메뉴 새탭에서 열기"
  }
}
</i18n>
