<template>
  <AppModalForm
    :title="$t(isEditing ? 'title_edit' : 'title_new')"
    :sub-title="
      isEditing
        ? $t('sub_title_edit', [formatUser(comment)])
        : $t('sub_title_new', [formatUser(review)])
    "
    :form-props="{ ...formProps, objectId: 'comment', focusGroupId: 'message' }"
    v-on="formEvents"
  >
    <AppDataList
      v-if="isAiReviewManagerEnabled"
      class="ReviewReviewEditCommentDialog__review-info"
      :data="infoData"
    >
      <template #value="{type, value}">
        <template v-if="type === 'user_info'">
          <div class="ReviewReviewEditCommentDialog__review-info-user-info">
            <div>
              {{ value }}
              <AppBadge
                v-if="isManagerReview"
                v-bind="managerReviewBadgeProps"
              />
              <AppBadge v-else v-bind="userGradeBadgeProps" />
              <AppBadge
                v-if="
                  sentimentAnalysisReport &&
                    brandUserSentiment &&
                    !isManagerReview
                "
                :label="brandUserSentimentLabel"
                :badge-style="formatBadgeStyle(brandUserSentiment.mode)"
              />
            </div>
            <AppButton
              v-if="review.user_username"
              type="external_link"
              :to="brandUserReviewsPath"
            >
              {{ $t('show_brand_user_reviews') }}
              <AppExternalLinkIcon />
            </AppButton>
          </div>
        </template>
      </template>
    </AppDataList>
    <AppDropdownMenuButton
      v-if="useAiReviewComment"
      :label="$t('ai_comment')"
      :menu-items="menuItems"
      :disabled="aiReviewCommentDisabled"
      :tooltip="
        !aiReviewCommentDisabled
          ? null
          : {
              message: $t('ai_comment_disabled_tooltip'),
              textAlign: 'left'
            }
      "
      button-style="mango-outline"
    />
    <AppButton
      :label="$t('add_random_message_button')"
      @click="addRandomMessage"
    />
    <template v-if="useAiReviewComment" #head>
      <AppButton
        class="ReviewReviewEditCommentDialog__help-button"
        button-style="grey-clear"
        :label="$t('ai_comment_help')"
        @click="openAiCommentHelpDialog"
      />
    </template>
  </AppModalForm>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import _ from 'lodash';
import qs from 'qs';
import DialogFormView from '@/mixins/DialogFormView';
import ReviewSentimentTypeHelper from '@/mixins/ReviewSentimentTypeHelper';
import ReviewSentimentType from '@/enums/ReviewSentimentType';
import AuthorGrade from '@/enums/AuthorGrade';
import ReviewSource from '@/enums/ReviewSource';

export default {
  name: 'ReviewReviewEditCommentDialog',
  mixins: [DialogFormView, ReviewSentimentTypeHelper],
  props: {
    review: {
      type: Object,
      required: true
    },
    orgComment: {
      type: Object,
      default: null
    },
    sentimentAnalysisReport: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      orgFormObject: this.orgComment || {},
      messageBuffer: [],
      messageBufferMaxSize: 10
    };
  },
  computed: {
    ...mapState('session', ['laboratory']),
    ...mapState('session', ['currentBrand', 'currentUser']),
    ...mapGetters(['pathWithBrandParams']),
    ...mapGetters('session', ['isAiReviewManagerEnabled']),
    useAiReviewComment() {
      return this.laboratory.ai_review_comment;
    },
    aiReviewCommentDisabled() {
      const {
        ai_review_comment_given_amount,
        ai_review_comment_used_amount
      } = this.laboratory;

      return (
        ai_review_comment_given_amount &&
        ai_review_comment_given_amount - ai_review_comment_used_amount <= 0
      );
    },
    menuItems() {
      const aiCommentTypes = [
        'comment_auto_generate',
        'comment_enrichment',
        'comment_auto_correct'
      ];

      return aiCommentTypes.map(type => {
        const disabled =
          type === 'comment_auto_generate' ? false : !this.comment.message;

        return {
          label: this.$t(`${type}.button_text`),
          tooltip: {
            message: disabled
              ? this.$t(`${type}.tooltip_when_disabled`)
              : this.$t(`${type}.tooltip`),
            textAlign: 'left',
            info:
              this.laboratory.ai_review_comment_given_amount && !disabled
                ? this.$t('ai_comment_tooltip_info', [
                    this.laboratory.ai_review_comment_given_amount -
                      this.laboratory.ai_review_comment_used_amount
                  ])
                : null
          },
          disabled,
          clickHandler: ({ close }) => {
            this.generateAiComment(type);
            close();
          }
        };
      });
    },
    formSections() {
      let placeholder = this.$t('message_placeholder');
      if (this.useAiReviewComment)
        placeholder += '\n' + this.$t('message_placeholder_ai_comment');

      return [
        {
          groups: [
            {
              id: 'message',
              label: null,
              type: 'textarea',
              rows: 7,
              value: this.comment.message,
              placeholder,
              validate: ['required']
            }
          ]
        }
      ];
    },
    comment() {
      return this.formObject;
    },
    isEditing() {
      return !!this.comment.id;
    },
    infoData() {
      return [
        {
          label: this.$t('review.user_info'),
          type: 'user_info',
          value: this.userInfo
        },
        { label: this.$t('review.message'), value: this.review.message }
      ];
    },
    isNonmember() {
      return this.review.author_grade === AuthorGrade.NONMEMBER;
    },
    userInfo() {
      const { user_name, user_username } = this.review;
      return this.isNonmember ? user_name : `${user_name} (${user_username})`;
    },
    userGradeBadgeProps() {
      return this.isNonmember
        ? {
            label: AuthorGrade.t(this.review.author_grade),
            badgeStyle: 'grey-outline'
          }
        : {
            label: this.review.brand_user_grade_name,
            badgeStyle: 'default'
          };
    },
    brandUserSentiment() {
      return this.sentimentAnalysisReport.brand_user;
    },
    brandUserSentimentLabel() {
      switch (this.brandUserSentiment.mode) {
        case ReviewSentimentType.POSITIVE:
        case ReviewSentimentType.STRONG_POSITIVE:
          return this.$t('brand_user_sentiment.positive');
        case ReviewSentimentType.NEUTRAL:
          return this.$t('brand_user_sentiment.neutral');
        case ReviewSentimentType.NEGATIVE:
        case ReviewSentimentType.STRONG_NEGATIVE:
          return this.$t('brand_user_sentiment.negative');
        default:
          return '';
      }
    },
    brandUserReviewsPath() {
      const params = qs.stringify({
        search_type: 'user_id',
        search_query: this.review.user_username
      });
      return this.pathWithBrandParams(`/v2/review/reviews?${params}`);
    },
    isManagerReview() {
      return this.review.review_source === ReviewSource.MANAGER;
    },
    managerReviewBadgeProps() {
      return {
        badgeStyle: 'grey-outline',
        label: ReviewSource.t(this.review.review_source)
      };
    }
  },
  mounted() {
    if (!this.useAiReviewComment) return;

    this.$cable.subscribe({
      channel: 'AiCommentChannel',
      brand_id: this.currentBrand.id,
      user_id: this.currentUser.id
    });
  },
  channels: {
    AiCommentChannel: {
      received({ content, seq_num, ai_review_comment_used_amount }) {
        const streamingFinished = !!ai_review_comment_used_amount;

        if (streamingFinished) {
          this.appendCommentMessageFromBuffer(this.messageBuffer.length);
          this.updateLaboratory({ ai_review_comment_used_amount });
        } else {
          this.messageBuffer.push({ content, seq_num });
          if (this.messageBuffer.length === this.messageBufferMaxSize) {
            this.appendCommentMessageFromBuffer(this.messageBufferMaxSize / 2);
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('session', ['updateLaboratory']),
    ...mapActions('review', ['createCommentReview']),
    ...mapActions('dialog', ['openDialog']),
    generateAiComment(type) {
      const { ai_review_comment_used_amount } = this.laboratory;
      this.updateLaboratory({
        ai_review_comment_used_amount: ai_review_comment_used_amount + 1
      });
      this.$cable.perform({
        channel: 'AiCommentChannel',
        action: 'send_message',
        data: {
          review_message: this.review.message,
          comment_message: this.comment.message,
          comment_type: type
        }
      });
      this.$set(this.comment, 'message', '');
    },
    addRandomMessage() {
      api
        .get('/review/comments/random_comment_message', {
          params: {
            review_id: this.review.id,
            use_comment_preset: true
          }
        })
        .then(({ data }) => {
          if (data.random_message)
            this.$set(this.comment, 'message', data.random_message);
        });
    },
    openAiCommentHelpDialog() {
      this.openDialog('TheAiCommentHelpDialog');
    },
    submit(formData) {
      this.isSubmitting = true;

      const promise = this.isEditing
        ? api.patch(`/review/comments/${this.comment.id}`, formData, {
            successMessage: this.$t('created_comment')
          })
        : this.createCommentReview({
            reviewId: this.review.id,
            params: formData
          });

      promise
        .then(({ data }) => {
          this.eventBus.$emit('save', data.comment);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    appendCommentMessageFromBuffer(size) {
      this.sortMessageBuffer();

      const orgMessage = this.comment.message || '';
      this.$set(
        this.comment,
        'message',
        orgMessage + this.flushedMessageFromBuffer(size)
      );
    },
    sortMessageBuffer() {
      this.messageBuffer = _.sortBy(
        this.messageBuffer,
        element => element.seq_num
      );
    },
    flushedMessageFromBuffer(size) {
      return this.messageBuffer
        .splice(0, size)
        .map(el => el.content)
        .join('');
    }
  }
};
</script>

<i18n locale="ko">
{
  "title_edit": "댓글 수정",
  "title_new": "댓글 작성",
  "sub_title_edit": "{0}님의 댓글",
  "sub_title_new": "{0}님의 리뷰",
  "message_placeholder": "댓글을 입력해주세요.",
  "message_placeholder_ai_comment": "AI 댓글 기능을 사용하면 높은 품질의 댓글을 간편하게 작성할 수 있습니다. (기능 실행 시점에 사용 횟수 1회 차감)",
  "ai_comment": "AI 댓글",
  "ai_comment_disabled_tooltip": "사용 가능한 횟수를 모두 사용하셨습니다.<br>오른쪽 ‘AI 댓글 이란?’ 버튼을 클릭한 다음 ‘정식 출시 대기 신청’ 버튼을<br>클릭해 신청하시면 정식 출시 후 빠르게 사용 안내 드리겠습니다.<br>신청이 많을 수록 정식 출시 시기가 빨라집니다.",
  "ai_comment_help": "AI 댓글 이란?",
  "ai_comment_tooltip_info": "앞으로 {0}회 사용 가능합니다.",
  "comment_auto_generate": {
    "button_text": "댓글 자동 작성",
    "tooltip": "고객의 리뷰 내용을 기반으로 적절한 댓글 초안을 자동으로 작성합니다.\n작성된 댓글은 그대로 저장하거나 적절하게 수정한 후 저장할 수 있습니다.\n고객에게 도움이 되고 운영진의 진심이 담긴 댓글을 쉽게 작성해보세요."
  },
  "comment_enrichment": {
    "button_text": "입력한 문장 개선",
    "tooltip": "입력한 문장의 부족한 부분을 추가하고 개선하여 댓글을 완성해 줍니다.",
    "tooltip_when_disabled": "작성창에 댓글을 입력한 후 사용 가능합니다."
  },
  "comment_auto_correct": {
    "button_text": "맞춤법 및 문법 개선",
    "tooltip": "입력한 문장의 맞춤법 및 문법을 개선합니다.",
    "tooltip_when_disabled": "작성창에 댓글을 입력한 후 사용 가능합니다."
  },
  "add_random_message_button": "일괄 댓글 입력",
  "created_comment": "댓글을 작성했습니다.",
  "brand_user_sentiment": {
    "positive": "평소 긍정 리뷰 작성고객",
    "neutral": "평소 중립 리뷰 작성고객",
    "negative": "평소 부정 리뷰 작성고객"
  },
  "show_brand_user_reviews": "작성자의 다른 리뷰 보기"
}
</i18n>
<i18n locale="en">
{
  "title_edit": "Edit comment",
  "title_new": "Write comment",
  "sub_title_edit": "{0}'s comment",
  "sub_title_new": "{0}'s review",
  "message_placeholder": "Please write comment.",
  "add_random_message_button": "Add batch comment message",
  "created_comment": "Comment has been written."
}
</i18n>

<style lang="scss" scoped>
.ReviewReviewEditCommentDialog__help-button {
  float: right;
}

.ReviewReviewEditCommentDialog__review-info {
  margin-bottom: 28px;
}

.ReviewReviewEditCommentDialog__review-info-user-info {
  display: flex;
  justify-content: space-between;
}
</style>
