var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.installationIds
      ? _vm.$t('sub_title', {
          count: _vm.installationIds.length
        })
      : '',"form-props":Object.assign({}, _vm.formProps)},scopedSlots:_vm._u([{key:"group-sub-item",fn:function(ref){
      var group = ref.group;
      var value = ref.value;
      var checked = ref.checked;
      var errors = ref.errors;
return [(group.id === 'duplicate_type')?[(value === _vm.SmartInstallationDuplicateType.DIFFERENT_SKIN_ID)?[_c('div',{staticClass:"AppForm__description"},[_vm._v(_vm._s(_vm.$t('skin.label')))]),_c('div',{staticClass:"AppForm__description"},[_vm._v(_vm._s(_vm.$t('skin.description')))]),_c('div',{staticClass:"AppForm__group-field"},[_c('AppTextInput',{staticClass:"ReviewInstallSimulationDuplicateInstallationsFormDialog__skin-id",attrs:{"id":"skin_id","name":"skin_id","placeholder":_vm.$t('skin.placeholder'),"disabled":!checked,"invalid":!!errors.skin_id,"type":"text"},on:{"change":function($event){return _vm.validateField('skin_id')},"blur":function($event){return _vm.validateField('skin_id')}},model:{value:(_vm.formObject.skin_id),callback:function ($$v) {_vm.$set(_vm.formObject, "skin_id", $$v)},expression:"formObject.skin_id"}}),_c('AppFormError',{attrs:{"error":errors.skin_id}})],1)]:_vm._e()]:_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }