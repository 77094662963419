<template>
  <a
    :class="['AppLink', `AppLink--style-${linkStyle}`]"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot>{{ label }}</slot>
  </a>
</template>

<script>
export default {
  name: 'AppLink',
  props: {
    label: { type: String, default: '' },
    linkStyle: {
      type: String,
      default: 'default',
      validator: v => ['default', 'blue', 'grey'].includes(v)
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_links.scss';

.AppLink {
  -webkit-user-drag: none;
  user-drag: none;
}

.AppLink--style-blue {
  @include link-blue;
}

.AppLink--style-grey {
  color: $color-grey-70;

  &:hover {
    text-decoration: underline;
  }
}
</style>
