<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <ReviewChartPercentageBar
        class="ReviewChartPercentageBar"
        :label="$t('label')"
        :numerator="1"
        :denominator="3"
      />
      <ReviewChartPercentageBar
        class="ReviewChartPercentageBar"
        :label="$t('label')"
        :numerator="0"
        :denominator="3"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Lavender</div>
      <ReviewChartPercentageBar
        class="ReviewChartPercentageBar"
        :label="$t('label')"
        :numerator="1"
        :denominator="1"
        :chart-color="'lavender'"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Flamingo</div>
      <ReviewChartPercentageBar
        class="ReviewChartPercentageBar"
        :label="$t('label')"
        :numerator="1"
        :denominator="10"
        :chart-color="'flamingo'"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Divide by Zero</div>
      <ReviewChartPercentageBar
        class="ReviewChartPercentageBar"
        :label="$t('label')"
        :numerator="10"
        :denominator="0"
        :chart-color="'lavender'"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Round to the First Decimal Place</div>
      <ReviewChartPercentageBar
        class="ReviewChartPercentageBar"
        label="16.66666 (1/6)"
        :numerator="1"
        :denominator="6"
        :chart-color="'lavender'"
      />
      <ReviewChartPercentageBar
        class="ReviewChartPercentageBar"
        label="33.33333 (1/3)"
        :numerator="1"
        :denominator="3"
        :chart-color="'lavender'"
      />
    </div>
  </div>
</template>

<script>
import ReviewChartPercentageBar from '../review/components/ReviewChartPercentageBar';

export default {
  name: 'UiReviewChartPercentageBar',
  components: { ReviewChartPercentageBar }
};
</script>

<i18n locale="ko">
{
  "label": "크리마 몰"
}
</i18n>
