<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="
      installationIds
        ? $t('sub_title', {
            count: installationIds.length
          })
        : ''
    "
    :form-props="{ ...formProps }"
    v-on="formEvents"
  >
    <template #group-sub-item="{ group, value, checked, errors }">
      <template v-if="group.id === 'duplicate_type'">
        <template
          v-if="value === SmartInstallationDuplicateType.DIFFERENT_SKIN_ID"
        >
          <div class="AppForm__description">
            {{ $t('smart_installations.skin.label') }}
          </div>
          <div class="AppForm__description">
            {{ $t('smart_installations.skin.description') }}
          </div>
          <div class="AppForm__group-field">
            <AppTextInput
              id="skin_id"
              v-model="formObject.skin_id"
              name="skin_id"
              :placeholder="$t('smart_installations.skin.placeholder')"
              :disabled="!checked"
              :invalid="!!errors.skin_id"
              type="text"
              class="ReviewSmartInstallationDuplicateInstallationsFormDialog__skin-id"
              @change="validateField('skin_id')"
              @blur="validateField('skin_id')"
            />
            <AppFormError :error="errors.skin_id" />
          </div>
        </template>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import { mapState, mapMutations } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import SmartInstallationDuplicateType from '@/enums/SmartInstallationDuplicateType';

export default {
  name: 'ReviewSmartInstallationDuplicateInstallationsFormDialog',
  mixins: [DialogFormView],
  props: {
    installationIds: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    SmartInstallationDuplicateType: () => SmartInstallationDuplicateType,
    formSections() {
      return [
        {
          groups: [
            {
              id: 'duplicate_type',
              groupDescription: this.$t('duplicate_type.description'),
              label: this.$t('duplicate_type.label'),
              type: 'select_radio',
              required: true,
              options: [
                {
                  label: this.$t('duplicate_type.same_skin_id'),
                  value: SmartInstallationDuplicateType.SAME_SKIN_ID
                },
                {
                  label: this.$t('duplicate_type.different_skin_id'),
                  value: SmartInstallationDuplicateType.DIFFERENT_SKIN_ID
                }
              ],
              fields:
                this.formObject.duplicate_type ===
                SmartInstallationDuplicateType.DIFFERENT_SKIN_ID
                  ? [
                      {
                        id: 'skin_id',
                        validate:
                          this.currentBrand.shop_builder === 'cafe24'
                            ? [
                                {
                                  rule: 'check_skin_id',
                                  errorMessage: this.$t(
                                    'smart_installations.skin.error'
                                  )
                                }
                              ]
                            : []
                      }
                    ]
                  : []
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapMutations('reviewWidgets', [
      'CREATE_REVIEW_WIDGET_SMART_INSTALLATION'
    ]),
    submit() {
      this.isSubmitting = true;
      const formData = new FormData(this.$el.getElementsByTagName('form')[0]);
      this.installationIds.forEach(id => formData.append('ids[]', id));
      api
        .post('/smart_installations/duplicate_installations', formData)
        .then(({ data }) => {
          data.smart_installations.forEach(installation => {
            this.CREATE_REVIEW_WIDGET_SMART_INSTALLATION({
              widgetId: installation.widget_id,
              installation: installation
            });
          });
          this.emit('submit');
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "설치 내역 복제",
  "sub_title": "선택 설치 내역 {count}개",
  "duplicate_type": {
    "label": "적용 스킨 설정",
    "same_skin_id": "그대로 복제",
    "different_skin_id": "다른 스킨 정보로 복제",
    "description": "복제 대상인 설치 내역의 적용 스킨 정보를 그대로 복제하나 다른 적용 스킨 정보로 복제할 수 있습니다."
  }
}
</i18n>
