<template>
  <div class="SlotPreview">
    <div class="SlotPreview__header">
      <div class="SlotPreview__title" v-text="$t('preview')" />
      <AppSelectButton
        v-model="previewDevice"
        class="SlotPreview__device"
        :options="WidgetDeviceType.options(previewDeviceOptions)"
      />
      <AppBubble v-if="isBubbleVisible" side="right" @click="hideBubble">{{
        $t('message')
      }}</AppBubble>
    </div>
    <div class="SlotPreview__preview">
      <Component
        :is="
          previewDevice === WidgetDeviceType.MOBILE
            ? 'AppMobilePhonePreview'
            : 'div'
        "
        v-if="previewUrls.length > 0"
        :class="{
          'SlotPreview__iframes--pc': previewDevice === WidgetDeviceType.PC
        }"
        :scrollable="true"
      >
        <AppIframeViewer
          v-for="(url, index) in previewUrls"
          :key="index"
          class="SlotPreview__iframe"
          :src="url"
          :style="{ height: getIframeHeight(url) }"
        />
      </Component>
      <div v-else class="SlotPreview__iframe-placeholder">
        <div class="SlotPreview__no-widget-container">
          <div class="SlotPreview__no-widget-title">
            {{ $t('no_widget_title') }}
          </div>
          <div class="SlotPreview__no-widget-message">
            <div>{{ $t('no_widget_message1') }}</div>
            <div>{{ $t('no_widget_message2') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import qs from 'qs';
import { mapState, mapGetters, mapMutations } from 'vuex';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import IframesHeightSetter from '@/mixins/IframesHeightSetter';

export default {
  name: 'SlotPreview',
  mixins: [IframesHeightSetter],
  data() {
    return {
      previewDevice: null,
      previewDeviceOptions: null
    };
  },
  computed: {
    ...mapState('installRequest', ['installDeviceTypes', 'bubbleVisibility']),
    ...mapGetters('installRequest', ['currentInstallationsBySlotId']),
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    isBubbleVisible() {
      return this.bubbleVisibility.preview;
    },
    previewUrls() {
      const urls = _.chain(this.currentInstallationsBySlotId)
        .filter(
          i =>
            i.widget_device_type === this.previewDevice && i.widget_shortcut_url
        )
        .orderBy(['slot_type', 'order'], ['desc', 'asc'])
        .map(i => {
          const [path, query] = i.widget_shortcut_url.split('?');
          const params = {
            ...qs.parse(query),
            iframe: 1,
            parent_url: location.href
          };
          return `${path}?${qs.stringify(params)}`;
        })
        .value();

      return urls;
    }
  },
  mounted() {
    this.previewDevice = this.installDeviceTypes[0];
    if (this.installDeviceTypes && this.installDeviceTypes.length > 0)
      this.previewDeviceOptions = this.installDeviceTypes;
  },
  beforeDestroy() {
    if (this.bubbleVisibility.preview) this.SET_BUBBLE_INVISIBLE('preview');
  },
  methods: {
    ...mapMutations('installRequest', ['SET_BUBBLE_INVISIBLE']),
    hideBubble() {
      this.SET_BUBBLE_INVISIBLE('preview');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.SlotPreview {
  height: 100%;
  width: 100%;
  padding: 16px 22px 0 22px;
}

.SlotPreview__header {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
}

.SlotPreview__title {
  @include text-sub-title;
}

.SlotPreview__device {
  margin-left: 15px;
}

$header-height: 50px;
$preview-margin: 32px;
.SlotPreview__preview {
  margin: $preview-margin 0;
  background-color: $color-background;
  border-radius: 8px;
  height: calc(100% - $header-height - ($preview-margin * 2));
}

.SlotPreview__iframes {
  &--pc {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
}

.SlotPreview__iframe-placeholder {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SlotPreview__no-widget-container {
  width: 304px;
  height: 112px;
  text-align: center;
}

.SlotPreview__no-widget-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.SlotPreview__no-widget-message {
  height: 60px;
  font-size: 13px;
  font-weight: 400;
  color: $color-grey-60;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>

<i18n locale="ko">
{
  "preview": "위젯 미리보기",
  "message": "선택한 위젯이 어떻게 보여질지 미리 확인해보세요.",
  "no_widget_title": "선택된 위젯이 없습니다.",
  "no_widget_message1": "왼쪽 '위젯 선택'에서 설치를 원하는 위젯을 선택해보세요.",
  "no_widget_message2": "PC, 모바일 별로 설치 위젯을 선택할 수 있습니다."
}
</i18n>
