<template>
  <AppDropdownMenuButton
    :label="$t('app.manage')"
    :disabled="message.sending"
    :tooltip="message.sending ? $t('message_sending') : null"
    :menu-items="menuItems"
  />
</template>

<script>
import api from '@/lib/api';
import { mapActions } from 'vuex';

export default {
  name: 'ReviewMessagesCampaignMessageManageCell',
  props: {
    message: { type: Object, required: true },
    campaignType: { type: Number, default: null }
  },
  computed: {
    menuItems() {
      return [
        {
          label: this.$t('app.edit'),
          clickHandler: ({ close }) => {
            this.clickEditMessage();
            close();
          }
        },
        {
          label: this.$t('dispatch_now.label'),
          clickHandler: ({ close }) => {
            this.clickDispatchMessage();
            close();
          },
          superAdmin: true
        },
        {
          label: this.$t('app.delete'),
          style: 'danger',
          clickHandler: ({ close }) => {
            this.clickDeleteMessage();
            close();
          }
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['confirm', 'openDialog']),
    clickEditMessage() {
      this.openDialog([
        'ReviewMessagesMessageFormDialog',
        {
          campaignType: this.campaignType,
          campaignMessageId: this.message.id
        }
      ]).then(eventBus => {
        eventBus.$on('submit', () => this.$emit('refresh'));
      });
    },
    clickDeleteMessage() {
      this.confirm({
        type: 'delete_confirm',
        title: this.$t('delete_confirm_title'),
        message: this.$t('delete_confirm_message')
      }).then(result => {
        if (result) this.deleteMessage(this.message.id);
      });
    },
    deleteMessage() {
      api
        .delete(`/review/campaign/messages/${this.message.id}`, {
          successMessage: this.$t('app.deleted')
        })
        .then(() => this.$emit('deleted'));
    },
    clickDispatchMessage() {
      api
        .post(
          `/review/campaign/messages/${this.message.id}/dispatch_now`,
          {},
          {
            successMessage: this.$t('dispatch_now.success_message')
          }
        )
        .catch(() => alert(this.$t('dispatch_now.error_message')));
    }
  }
};
</script>

<i18n locale="ko">
{
  "delete_confirm_title": "발송 메시지를 삭제하시겠습니까?",
  "delete_confirm_message": "삭제된 발송 메시지는 복원이 어렵습니다.",
  "message_sending": "현재 발송중입니다. 발송 완료 후 다시 시도해주세요.",
  "dispatch_now": {
    "label": "즉시 발송",
    "success_message": "즉시 발송 job이 등록되었습니다.",
    "error_message": "즉시 발송 job 등록 실패하였습니다."
  }
}
</i18n>
