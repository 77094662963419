<template>
  <div class="TheHelpMenu">
    <AppDropdownMenu :menu-items="menuItems" @close="close">
      <template #item="{ id }">
        <template v-if="id === 'search'">
          <div class="TheHelpMenu__search">
            <form @submit.prevent="search">
              <AppSearchInput
                v-model="query"
                :placeholder="$t('search_placeholder')"
              />
            </form>
          </div>
        </template>
      </template>
    </AppDropdownMenu>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import BrandReviewStatus from '@/enums/BrandReviewStatus';
import SearchCategory from '@/enums/SearchCategory';
import InquiryHelper from '@/mixins/InquiryHelper';

export default {
  name: 'TheHelpMenu',
  mixins: [InquiryHelper],
  data() {
    return { query: '' };
  },
  computed: {
    ...mapState('session', ['adminSettings', 'currentBrand']),
    ...mapGetters('menu', ['selectedMainMenuItemId', 'helpChatLabel']),
    ...mapGetters('session', [
      'isShopifyBrand',
      'isKoreanBrand',
      'isTutorialEnabled'
    ]),
    isMenuReview() {
      return this.selectedMainMenuItemId === 'review';
    },
    isMenuFit() {
      return this.selectedMainMenuItemId === 'fit';
    },
    isMenuTarget() {
      return this.selectedMainMenuItemId === 'target';
    },
    isMenuDisplay() {
      return this.selectedMainMenuItemId === 'display';
    },
    isMenuAnalytics() {
      return this.selectedMainMenuItemId === 'analytics';
    },
    isReviewPreparing() {
      return (
        this.currentBrand.review_status === BrandReviewStatus.NONE ||
        this.currentBrand.review_status === BrandReviewStatus.REQUESTED
      );
    },
    versionHistoryUrl() {
      return this.isMenuReview
        ? this.adminSettings.version_history_url_review
        : this.isMenuFit
        ? this.adminSettings.version_history_url_fit
        : '';
    },
    menuItems() {
      if (this.isShopifyBrand) {
        return [
          [{ id: 'search' }],
          [
            {
              label: this.helpChatLabel,
              clickHandler: this.clickChannel
            }
          ]
        ];
      } else if (this.isMenuReview && this.isReviewPreparing) {
        return [
          [{ id: 'search' }],
          [
            {
              label: this.helpChatLabel,
              type: 'external_link',
              to: 'https://www.cre.ma/contact/'
            }
          ]
        ];
      } else if (this.isMenuAnalytics) {
        return [
          [
            {
              label: this.helpChatLabel,
              type: 'external_link',
              to:
                'https://help-review.cre.ma/hc/ko/requests/new?ticket_form_id=13177171921305'
            },
            {
              label: this.$t('insight_manual'),
              type: 'external_link',
              to:
                'https://cremafactory.notion.site/e23816bb0da5443493dc4c7824ee57ec'
            }
          ]
        ];
      } else
        return [
          [{ id: 'search' }],
          [
            {
              label: this.helpChatLabel,
              clickHandler: this.clickChannel
            }
          ],
          ...(this.isMenuReview && this.isTutorialEnabled && this.isKoreanBrand
            ? [
                [
                  {
                    label: this.$t('review.tutorial'),
                    clickHandler: this.clickTutorial
                  }
                ]
              ]
            : []),
          ...(this.versionHistoryUrl
            ? [
                [
                  {
                    label: this.$t(
                      this.isMenuReview ? 'history_review' : 'history_fit'
                    ),
                    type: 'external_link',
                    to: this.versionHistoryUrl
                  }
                ]
              ]
            : []),
          ...(this.isMenuReview
            ? [
                [
                  {
                    label: this.$t('review.manuals_dialog'),
                    clickHandler: ({ close }) => {
                      this.openDialog([
                        'TheManualsDialog',
                        { initialTab: 'manuals_dialog_review' }
                      ]);
                      close();
                    }
                  },
                  {
                    label: this.$t('review.help_center'),
                    type: 'external_link',
                    to: 'https://help-review.cre.ma/hc/ko'
                  }
                ]
              ]
            : []),
          ...(this.isMenuTarget
            ? [
                [
                  {
                    label: this.$t('target.manuals_dialog'),
                    clickHandler: ({ close }) => {
                      this.openDialog([
                        'TheManualsDialog',
                        { initialTab: 'manuals_dialog_target' }
                      ]);
                      close();
                    }
                  },
                  {
                    label: this.$t('target.help_center'),
                    type: 'external_link',
                    to: 'https://help-target.cre.ma/hc/ko'
                  }
                ]
              ]
            : []),
          ...(this.isMenuFit
            ? [
                [
                  {
                    label: this.$t('fit.manuals_dialog'),
                    clickHandler: ({ close }) => {
                      this.openDialog([
                        'TheManualsDialog',
                        { initialTab: 'manuals_dialog_fit' }
                      ]);
                      close();
                    }
                  },
                  {
                    label: this.$t('fit.help_center'),
                    type: 'external_link',
                    to: 'https://help-fit.cre.ma/hc/ko'
                  }
                ]
              ]
            : []),
          ...(this.isMenuDisplay
            ? [
                [
                  {
                    label: this.$t('display.manuals_dialog'),
                    clickHandler: ({ close }) => {
                      this.openDialog([
                        'TheManualsDialog',
                        { initialTab: 'manuals_dialog_display' }
                      ]);
                      close();
                    }
                  },
                  {
                    label: this.$t('display.help_center'),
                    type: 'external_link',
                    to: 'https://help-display.cre.ma/hc/ko'
                  }
                ]
              ]
            : [])
        ];
    }
  },
  methods: {
    ...mapMutations('tutorial', ['SET_IS_REVIEW_SETTINGS_TUTORIAL_VISIBLE']),
    ...mapActions('dialog', ['openDialog']),
    search() {
      if (!this.query) return;

      if (this.adminSettings.enable_zendesk) {
        const subdomain = this.isShopifyBrand
          ? 'help-global'
          : `help-${this.selectedMainMenuItemId}`;
        const url = `https://${subdomain}.cre.ma/hc/ko/search?utf8=%E2%9C%93&query=${this.query}`;
        window.open(url);
      } else {
        this.openDialog([
          'TheSearchDialog',
          {
            initialQuery: this.query,
            initialSearchCategory: SearchCategory.MANUALS
          }
        ]);
      }
      this.close();
    },
    clickChannel() {
      this.openInquiry();
      this.close();
    },
    clickTutorial() {
      this.SET_IS_REVIEW_SETTINGS_TUTORIAL_VISIBLE(true);
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_dialog.scss';

.TheHelpMenu {
  @include dialog;
  width: min-content;

  margin-bottom: 4px;
}

.TheHelpMenu__search {
  padding: 3px 5px;
}
</style>

<i18n locale="ko">
{
  "search_placeholder": "도움말 검색",
  "history_review": "리뷰 업데이트 안내",
  "history_fit": "핏 업데이트 안내",
  "insight_manual": "서비스 매뉴얼",
  "review": {
    "tutorial": "리뷰 기본 설정 가이드",
    "help_center": "크리마 리뷰 헬프센터",
    "manuals_dialog": "리뷰 주요기능 및 FAQ"
  },
  "target": {
    "help_center": "크리마 타겟 헬프센터",
    "manuals_dialog": "타겟 주요기능 및 FAQ"
  },
  "fit": {
    "help_center": "크리마 핏 헬프센터",
    "manuals_dialog": "핏 주요기능 및 FAQ"
  },
  "display": {
    "help_center": "크리마 진열 헬프센터",
    "manuals_dialog": "진열 주요기능 및 FAQ"
  }
}
</i18n>
