<template>
  <div class="ReviewNewReviewsDashboard">
    <div class="ReviewNewReviewsDashboard-section-wrapper">
      <ReviewNewReviewsStatSection class="ReviewNewReviewsDashboard-section" />
      <ReviewNewReviewsBestAi
        class="ReviewNewReviewsDashboard-section"
        :best-ai-reviews-count="4"
        :average-best-ai-reviews-count="2"
        :average-total-reviews-count="100"
        :total-reviews-count="10"
      />
      <ReviewNewReviewsFirstWrittenReview
        class="ReviewNewReviewsDashboard-section"
        v-bind="{ startDate, endDate }"
      />
      <ReviewNewReviewsMonthlyTrend class="ReviewNewReviewsDashboard-section" />
    </div>
    <div class="ReviewNewReviewsDashboard-caption">
      {{ $t('dashboard_caption') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewNewReviewsDashboard',
  props: {
    startDate: { type: String, required: true },
    endDate: { type: String, required: true }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_breakpoints.scss';

.ReviewNewReviewsDashboard {
  background-color: $color-grey-05;
  border-radius: 12px;
  min-width: 1110px;
  max-width: 1330px;
  padding: 20px 24px 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  @include media-breakpoint-each(mobile) {
    min-width: auto;
  }
}

.ReviewNewReviewsDashboard-section-wrapper {
  display: flex;
  flex-direction: row;
  gap: 14px;
}

.ReviewNewReviewsDashboard-section {
  flex: 1 0 255px;
}

.ReviewNewReviewsDashboard-caption {
  @include text-caption-small;

  margin-left: 8px;
}
</style>

<i18n locale="ko">
{
  "dashboard_caption": "* 스탭/연동/복사 리뷰를 제외한 데이터입니다."
}
</i18n>
