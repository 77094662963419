<template>
  <AppContainer class="ReviewMessagesMemberReviewCampaignReport__container">
    <AppDropdownMenuButton
      v-model="reportType"
      :menu-items="menuItems"
      button-style="label"
      :label="$t(`label.${reportTypeKey}`)"
    />
    <div class="ReviewMessagesMemberReviewCampaignReport__description">
      {{ $t(`description.${reportTypeKey}`) }}
    </div>
    <AppAjaxContent :is-loading="isLoading">
      <AppColorReportTable
        class="ReviewMessagesMemberReviewCampaignReport__table"
        :title="$t(`report.title.${reportTypeKey}`)"
        :columns="columns"
        :rows="rows"
      />
    </AppAjaxContent>
  </AppContainer>
</template>

<script>
import moment from 'moment';
import api from '@/lib/api';
import ReviewCampaignType from '@/enums/ReviewCampaignType';
import ReviewCampaignReportType from '@/enums/ReviewCampaignReportType';

export default {
  name: 'ReviewMessagesMemberReviewCampaignReport',
  data: () => ({
    isLoading: true,
    reportType: ReviewCampaignReportType.ORDER_REVIEWED_AFTER,
    rawData: []
  }),
  computed: {
    reportTypeKey() {
      return ReviewCampaignReportType.key(this.reportType);
    },
    menuItems() {
      return ReviewCampaignReportType.values.map(value => ({
        label: this.$t(`label.${ReviewCampaignReportType.key(value)}`),
        clickHandler: ({ close }) => this.updateReportType(close, value)
      }));
    },
    columnOptions() {
      return [
        { value: '0', min: 0, max: 0 },
        { value: '1', min: 1, max: 1 },
        { value: '2', min: 2, max: 2 },
        { value: '3', min: 3, max: 3 },
        { value: '4', min: 4, max: 4 },
        { value: '5', min: 5, max: 5 },
        { value: '6', min: 6, max: 6 },
        { value: '7', min: 7, max: 7 },
        { value: '8-10', min: 8, max: 10 },
        { value: '11-14', min: 11, max: 14 },
        { value: '15-21', min: 15, max: 21 },
        { value: '22-30', min: 22, max: 30 },
        { value: '30+', min: 31, max: null }
      ];
    },
    columns() {
      return this.columnOptions.map(({ value }) =>
        this.$t('unit.days', [value])
      );
    },
    report() {
      const { formatDate } = this;
      return this.rawData.map(row => ({
        ...row,
        startDate: formatDate(row.start_date),
        endDate: formatDate(row.end_date),
        sum: row.data.length,
        data: this.columnOptions.map(
          ({ min, max }) =>
            row.data.filter(
              value =>
                value >= min && (max === null || (max !== null && value <= max))
            ).length
        )
      }));
    },
    rows() {
      const { reportTypeKey } = this;

      return this.report.map(row => ({
        label: this.rowLabel(row.weeks_ago),
        summary: this.$t('unit.cases', [row.total_count]),
        summaryTooltip: {
          message: this.$t(`report.summary_tooltip.${reportTypeKey}`, [
            row.startDate,
            row.endDate
          ]),
          info: this.isReportTypeOrder
            ? this.$t('report.summary_tooltip.info', [
                row.total_count
                  ? Math.round((row.sum / row.total_count) * 1000) / 10
                  : 0
              ])
            : null,
          textAlign: 'left'
        },
        data: row.data.map((_, columnIndex) => this.cellData(row, columnIndex))
      }));
    },
    isReportTypeOrder() {
      return this.reportType === ReviewCampaignReportType.ORDER_REVIEWED_AFTER;
    }
  },
  mounted() {
    this.fetchReport();
  },
  methods: {
    fetchReport() {
      this.isLoading = true;
      api
        .get('review/campaigns/report', {
          params: {
            campaign_type: ReviewCampaignType.MEMBER_REVIEW,
            report_type: this.reportType
          }
        })
        .then(({ data }) => (this.rawData = data.report))
        .finally(() => (this.isLoading = false));
    },
    cellData(row, columnIndex) {
      const value = row.data[columnIndex];
      const cellValue = row.sum ? (value / row.sum) * 100 : 0;
      const cell = {
        value: cellValue,
        level: parseInt(cellValue / 3),
        type: 'percentage',
        tooltipMessage: this.$t(`report.tooltip.${this.reportTypeKey}`, [
          row.startDate,
          row.endDate,
          this.columns[columnIndex]
        ])
      };

      if (this.isReportTypeOrder) {
        const now = moment();
        const cellStartDate = moment(row.startDate, 'YYYY-MM-DD').add(
          this.columnOptions[columnIndex].min,
          'day'
        );
        const cellEndDate =
          this.columnOptions[columnIndex].max === null
            ? undefined
            : moment(row.endDate, 'YYYY-MM-DD').add(
                this.columnOptions[columnIndex].max,
                'day'
              );

        if (now.isBetween(cellStartDate, cellEndDate, 'day', '[]'))
          return {
            ...cell,
            mark: true,
            tooltipInfo: this.$t('report.tooltip.collecting')
          };
        else if (now.isSameOrBefore(cellStartDate, 'day')) return {};
      }

      if (cell.value) return cell;
      else return { value: '-', level: 0, type: 'string' };
    },
    updateReportType(close, reportType) {
      this.reportType = reportType;
      this.fetchReport();
      close();
    },
    rowLabel(weeksAgo) {
      if (weeksAgo === 0) return this.$t('unit.this_week');
      else return this.$t('unit.weeks_ago', [weeksAgo]);
    }
  }
};
</script>

<style scoped>
.ReviewMessagesMemberReviewCampaignReport__container {
  padding: 24px 32px;
}

.ReviewMessagesMemberReviewCampaignReport__description {
  margin-top: 8px;
}

.ReviewMessagesMemberReviewCampaignReport__table {
  margin-top: 24px;
}
</style>

<i18n locale="ko">
{
  "label": {
    "ORDER_REVIEWED_AFTER": "주문 완료 후 며칠만에 리뷰가 달릴까?",
    "REVIEW_PURCHASED_FROM": "이번 주에 작성된 리뷰는 주문 후 며칠만에 작성된 리뷰일까?"
  },
  "description": {
    "ORDER_REVIEWED_AFTER": "매주 월요일부터 일요일까지 주문 완료된 건에 대하여 리뷰가 달린 경우 며칠만에 리뷰가 작성되었는지 보여줍니다.",
    "REVIEW_PURCHASED_FROM": "매주 월요일부터 일요일까지 작성된 리뷰가 주문 완료 후 며칠만에 작성되었는지 보여줍니다."
  },
  "report": {
    "title": {
      "ORDER_REVIEWED_AFTER": "주간 주문 완료건 수",
      "REVIEW_PURCHASED_FROM": "주간 리뷰 작성건 수"
    },
    "summary_tooltip": {
      "ORDER_REVIEWED_AFTER": "{0} - {1} 기간 동안 발생한 주문 완료 수",
      "REVIEW_PURCHASED_FROM": "{0} - {1} 기간 동안 작성된 리뷰 수",
      "info": "리뷰 작성률: {0}%"
    },
    "tooltip": {
      "ORDER_REVIEWED_AFTER": "{0} - {1} 사이에 완료된 주문 중 주문 완료 {2} 후 리뷰 작성된 비율입니다.",
      "REVIEW_PURCHASED_FROM": "{0} - {1} 사이에 작성된 리뷰 중 주문 완료 {2} 후 리뷰 작성된 비율입니다.",
      "collecting": "데이터 수집 중입니다. 이후에 수치가 변경될 수 있습니다."
    }
  }
}
</i18n>
