<template>
  <AppAjaxContent :is-loading="isLoading">
    <CustomContentHeader
      :resource="campaign"
      resource-type="review_campaign"
      :is-name-editable="false"
    >
      <template #actions>
        <ReviewMessagesCampaignsManageButton
          :campaign="campaign"
          show-status-label
          @change-status="changeStatus"
        />
      </template>
    </CustomContentHeader>
    <AppInfoBox class="ReviewMessagesCampaignShow__info-box">
      {{ $t(`${campaignTypeLocaleKey}.info_box`) }}
      <AppButton
        v-if="isNthStepReviewCampaign"
        class="ReviewMessagesCampaignShow__info-box-button"
        button-style="underline"
        :label="$t('nth_step_review.info_box_button')"
        @click="openNthStepReviewPreviewDialog"
      />
    </AppInfoBox>
    <ReviewMessagesMemberReviewCampaignReport v-if="isMemberReviewCampaign" />
    <template v-else-if="isNthStepReviewCampaign">
      <ReviewMessagesNthStepReviewCampaignStatCards
        class="ReviewMessagesCampaignShow__stats-cards"
      />
      <div class="ReviewMessagesCampaignShow__settings-wrapper">
        <ReviewMessagesNthStepReviewCampaignCategorySettings
          class="ReviewMessagesCampaignShow__settings-item"
          :campaign-id="campaignId"
        />
        <ReviewMessagesNthStepReviewCampaignMileageSettings
          class="ReviewMessagesCampaignShow__settings-item"
          :campaign-id="campaignId"
        />
      </div>
    </template>
    <AppButton
      :label="$t('button.add_campaign_message')"
      button-style="red"
      :disabled="isAddButtonDisabled"
      :tooltip="isAddButtonDisabled ? $t('button.disabled_tooltip') : null"
      @click="openCreateCampaignMessageDialog"
    />
    <ReviewMessagesCampaignMessagesTable
      class="ReviewMessagesCampaignShow__table"
      :campaign-id="campaignId"
      :campaign-type="campaign.campaign_type"
      :event-bus="tableEventBus"
      @update-messages-count="updateCampaignMessagesCount"
    />
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import Vue from 'vue';
import { mapActions } from 'vuex';
import ReviewCampaignType from '@/enums/ReviewCampaignType';
import ReviewCampaignStatus from '@/enums/ReviewCampaignStatus';
import ReviewMessagesMemberReviewCampaignReport from './components/ReviewMessagesMemberReviewCampaignReport';
import ReviewMessagesNthStepReviewCampaignStatCards from './components/ReviewMessagesNthStepReviewCampaignStatCards';
import ReviewMessagesCampaignMessagesTable from './components/ReviewMessagesCampaignMessagesTable.vue';
import ReviewMessagesCampaignsManageButton from './components/ReviewMessagesCampaignsManageButton.vue';
import ReviewMessagesNthStepReviewCampaignCategorySettings from './components/ReviewMessagesNthStepReviewCampaignCategorySettings';
import ReviewMessagesNthStepReviewCampaignMileageSettings from './components/ReviewMessagesNthStepReviewCampaignMileageSettings';

const MAX_NTH_REVIEW_MESSAGE_COUNT = 4;

export default {
  name: 'ReviewMessagesCampaignShow',
  components: {
    ReviewMessagesMemberReviewCampaignReport,
    ReviewMessagesNthStepReviewCampaignStatCards,
    ReviewMessagesCampaignMessagesTable,
    ReviewMessagesCampaignsManageButton,
    ReviewMessagesNthStepReviewCampaignCategorySettings,
    ReviewMessagesNthStepReviewCampaignMileageSettings
  },
  data: () => ({
    isLoading: true,
    campaign: {},
    tableEventBus: new Vue()
  }),
  computed: {
    campaignId() {
      return Number(this.$route.params.id);
    },
    campaignTypeLocaleKey() {
      if (!this.campaign.campaign_type) return '';

      return ReviewCampaignType.toKey(
        this.campaign.campaign_type
      ).toLowerCase();
    },
    isNthStepReviewCampaign() {
      return this.campaign.campaign_type === ReviewCampaignType.NTH_STEP_REVIEW;
    },
    isMemberReviewCampaign() {
      return this.campaign.campaign_type === ReviewCampaignType.MEMBER_REVIEW;
    },
    isAddButtonDisabled() {
      return (
        this.isNthStepReviewCampaign &&
        this.campaign.messages_count >= MAX_NTH_REVIEW_MESSAGE_COUNT
      );
    }
  },
  mounted() {
    api
      .get(`/review/campaigns/${this.campaignId}`)
      .then(({ data }) => {
        this.campaign = {
          ...data.campaign,
          name: ReviewCampaignType.t(data.campaign.campaign_type)
        };
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    updateCampaignMessagesCount(messagesCount) {
      this.campaign = { ...this.campaign, messages_count: messagesCount };
      if (
        this.campaign.status === ReviewCampaignStatus.ENABLED &&
        messagesCount === 0
      )
        this.changeStatus(ReviewCampaignStatus.DISABLED);
    },
    changeStatus(status) {
      this.campaign = { ...this.campaign, status };
    },
    openCreateCampaignMessageDialog() {
      this.openDialog([
        'ReviewMessagesMessageFormDialog',
        { campaignType: this.campaign.campaign_type }
      ]).then(eventBus => {
        eventBus.$on('submit', () => this.tableEventBus.$emit('refresh'));
      });
    },
    openNthStepReviewPreviewDialog() {
      this.openDialog(['ReviewMessagesNthReviewPreviewDialog']);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewMessagesCampaignShow__info-box {
  @include text-label;

  margin-top: 32px;
  padding: 20px 0;
}

.ReviewMessagesCampaignShow__info-box-button {
  vertical-align: top;
  margin-left: 4px;
}

.ReviewMessagesCampaignShow__stats-cards {
  margin-top: 32px;
}

.ReviewMessagesCampaignShow__settings-wrapper {
  display: flex;
  gap: 20px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.ReviewMessagesCampaignShow__settings-item {
  flex: 1;
  margin-top: 0 !important;
}

.ReviewMessagesCampaignShow__table {
  margin-top: 24px;
}
</style>

<i18n locale="ko">
{
  "member_review": {
    "info_box": "💡 리뷰를 작성하지 않은 쇼핑몰 회원에게 반복적으로 메시지를 보내 리뷰 작성을 유도할 수 있습니다."
  },
  "nth_step_review": {
    "info_box": "💡 리뷰를 작성한 회원에게 추가 리뷰 작성을 유도하여 더 생생한 사용 경험을 다른 고객에게 전달할 수 있습니다.",
    "info_box_button": "n차 리뷰 미리보기"
  },
  "button": {
    "add_campaign_message": "발송 메시지 추가",
    "disabled_tooltip": "설정할 수 있는 모든 발송 메시지를 추가하였습니다."
  }
}
</i18n>
