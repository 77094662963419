<template>
  <AppModalForm
    :title="$t(editing ? 'title_edit' : 'title_new')"
    :form-props="{ ...formProps, objectId: 'smart_installation' }"
    v-on="formEvents"
    @blur-group="formGroupBlurred"
  >
    <input
      type="hidden"
      name="smart_installation[installation_type]"
      :value="installation_type"
    />
    <input type="hidden" name="smart_installation[id]" :value="hideWidget.id" />
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import WidgetPageType from '@/enums/WidgetPageType';
import SmartInstallationType from '@/enums/SmartInstallationType';
import SmartInstallationFormView from '@/mixins/SmartInstallationFormView';

export default {
  name: 'ReviewInstallSimulationHideWidgetFormDialog',
  mixins: [SmartInstallationFormView],
  props: {
    hideWidget: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    groupOptions() {
      return {
        ...this.baseGroupOptions,
        element_selector: {
          label: this.$t('element_selector.label'),
          type: 'text',
          placeholder: this.$t('element_selector.placeholder'),
          validate: [
            'required',
            {
              rule: 'css_selector_format',
              errorMessage: this.$t('validations.selector_format')
            }
          ]
        }
      };
    },
    groupIds() {
      return this.formObject.page_type === WidgetPageType.SPECIFIC
        ? [
            'device_type',
            'page_type',
            'page_path',
            'element_selector',
            'skin_id'
          ]
        : ['device_type', 'page_type', 'element_selector', 'skin_id'];
    },
    editing() {
      return this.formObject.id;
    },
    pageTypeOptions() {
      return WidgetPageType.options();
    }
  },
  created() {
    this.installation_type = SmartInstallationType.HIDE_ELEMENT;
  },
  mounted() {
    this.orgFormObject = this.hideWidget;
  },
  methods: {
    submit(formData) {
      if (this.editing) {
        api
          .patch(
            '/install_simulation/simulations/update_installation',
            formData,
            {
              successMessage: this.$t('app.saved')
            }
          )
          .then(({ data }) => {
            this.eventBus.$emit('update', data.smart_installation);
            this.close(true);
          });
      } else {
        api
          .post('/install_simulation/simulations', formData, {
            successMessage: this.$t('app.created')
          })
          .then(({ data }) => {
            this.eventBus.$emit('save', data.smart_installation);
            this.close(true);
          });
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "title_new": "쇼핑몰 기존 위젯 숨김 추가",
  "title_edit": "쇼핑몰 기존 위젯 숨김 설정",
  "element_selector": {
    "label": "숨김 위젯 입력",
    "placeholder": "숨김처리할 위젯의 CSS selector를 입력해주세요."
  }
}
</i18n>
