<template>
  <div class="TheReviewSettingsProgressStep">
    <div
      class="TheReviewSettingsProgressStep__item"
      @mouseenter="isHovering = true"
      @mouseleave="isHovering = false"
    >
      <AppSvgIcon
        class="TheReviewSettingsProgressStep__bullet-point"
        :width="16"
        :height="16"
        :name="isFulfilled ? 'icon-bullet-filled-point' : 'icon-bullet-point'"
      />
      <div
        :class="[
          'TheReviewSettingsProgressStep__step',
          { 'TheReviewSettingsProgressStep__step--fulfilled': isFulfilled }
        ]"
      >
        {{ step.title }}
      </div>
      <div class="TheReviewSettingsProgressStep__arrow">
        <AppSvgIcon :width="7" :height="18" name="nav-chevron-right" />
      </div>
    </div>
    <TutorialSummaryCard
      v-if="isHovering"
      class="TheReviewSettingsProgressStep__card"
      :title="step.title"
      :description="step.description"
      image-url="https://assets.cre.ma/m/tutorial/biz_message.png"
    />
  </div>
</template>

<script>
import TutorialSummaryCard from './TutorialSummaryCard.vue';

export default {
  name: 'TheReviewSettingsProgressStep',
  components: {
    TutorialSummaryCard
  },
  props: {
    step: { type: Object, required: true },
    isFulfilled: { type: Boolean, default: false }
  },
  data() {
    return {
      isHovering: false
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/vars/_colors.scss';

.TheReviewSettingsProgressStep {
  position: relative;
}

.TheReviewSettingsProgressStep__item {
  display: flex;
  align-items: center;

  &:hover {
    outline: 6px solid $color-nav-selected;
    border-radius: 4px;
    background-color: $color-nav-selected;
  }
}

.TheReviewSettingsProgressStep__bullet-point {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.TheReviewSettingsProgressStep__step {
  @include text-caption-bold-dark;

  &--fulfilled {
    text-decoration: line-through;
    color: $color-grey-40;
  }
}

.TheReviewSettingsProgressStep__arrow {
  display: flex;
  align-items: center;
  margin-left: auto;
  color: $color-grey-35;
}

.TheReviewSettingsProgressStep__card {
  position: absolute;
  right: calc(100% + 30px);
  top: 50%;
  transform: translateY(-50%);
}
</style>
