<template>
  <div class="InstallRequestOnboardingDirectionDialogEtc">
    InstallRequestOnboardingDirectionDialogEtc
  </div>
</template>

<script>
export default {
  name: 'InstallRequestOnboardingDirectionDialogEtc'
};
</script>
