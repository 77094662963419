<template>
  <div class="ReviewMessagesMessageSettings">
    <AppAjaxContent :is-loading="isLoading">
      <AppForm
        v-bind="{
          ...formProps,
          objectId: 'message_settings',
          submitButton: true,
          formStyle: 'hor'
        }"
        v-on="formEvents"
      >
        <template #group="{ id }">
          <template v-if="id == 'sending_limit_per_day'">
            <div class="AppForm__group-field">
              {{ $t('common_settings.sending_limit_per_day.description') }}
              <div class="AppForm__group-field">
                <AppSelectRadio
                  v-model="formObject.sending_limit_per_day_enabled"
                  name="message_settings[sending_limit_per_day_enabled]"
                  :options="SENDING_LIMIT_PER_DAY_OPTIONS"
                >
                  <template #label="{value, checked}">
                    <i18n
                      v-if="value"
                      path="common_settings.sending_limit_per_day.options.limited"
                    >
                      <AppNumberInput
                        v-model="formObject.sending_limit_per_day"
                        name="message_settings[sending_limit_per_day]"
                        :digits="3"
                        :disabled="!checked"
                        @blur="validateField('sending_limit_per_day')"
                        @change="validateField('sending_limit_per_day')"
                      />
                      <span
                        v-tooltip="{
                          message: $t(
                            'common_settings.sending_limit_per_day.tooltip.message'
                          ),
                          textAlign: 'left',
                          horizontal: true
                        }"
                        class="ReviewMessagesMessageSettings__sending-limit-per-day-tooltip"
                        >{{
                          $t(
                            'common_settings.sending_limit_per_day.tooltip.label'
                          )
                        }}</span
                      >
                    </i18n>
                  </template>
                </AppSelectRadio>
              </div>
            </div>
          </template>
          <template v-else-if="id === 'sending_delay_and_time'">
            <div class="AppForm__group-field">
              <i18n path="common_settings.sending_delay_and_time.description">
                <AppNumberInput
                  v-model="formObject.sending_delay_after_delivery_in_days"
                  :digits="2"
                  name="message_settings[sending_delay_after_delivery_in_days]"
                />
                <AppSelectHour
                  v-model="formObject.sending_start_hour"
                  name="message_settings[sending_start_hour]"
                  interval="half"
                  :selectable-hours="SENDING_START_HOUR_OPTIONS"
                />
              </i18n>
            </div>
            <AppFormHint
              :message="
                $t('common_settings.sending_delay_and_time.hint', [
                  formattedSendingStartHour
                ])
              "
            />
          </template>
          <template v-else-if="id === 'email_preview'">
            <div class="AppForm__group-field">
              <AppButton
                :label="
                  $t(
                    'dispatch_method_settings.email_preview.open_email_preview_button_label'
                  )
                "
                @click="openEmailPreviewDialog"
              />
              <AppButton
                :label="
                  $t(
                    'dispatch_method_settings.email_preview.send_sample_email_button_label'
                  )
                "
                @click="sendSampleEmail"
              />
            </div>
          </template>
        </template>
        <template #group-foot="{ id }">
          <template v-if="id === 'message_sending_enabled'">
            <div class="ReviewMessagesMessageSettings__dispatch-method">
              <AppFormHint :message="messageSendingHint" />
              <label
                :class="[
                  'AppForm__group-title',
                  formObject.message_sending_enabled
                    ? ''
                    : 'AppForm__group-title--disabled'
                ]"
                >{{ $t('dispatch_method_settings.dispatch_method') }}</label
              >
              <AppSelectRadio
                v-model="formObject.dispatch_method"
                class="AppForm__group-field"
                name="message_settings[dispatch_method]"
                :options="DISPATCH_METHOD_OPTIONS"
                :disabled="!formObject.message_sending_enabled"
              />
            </div>
          </template>
        </template>
      </AppForm>
    </AppAjaxContent>
  </div>
</template>

<script>
import api from '@/lib/api';
import { isEmailFormatValid } from '@/lib/emailFormatValidator';
import { mapActions, mapGetters, mapState } from 'vuex';
import FormView from '@/mixins/FormView';
import ReviewMessageDispatchMethod from '@/enums/ReviewMessageDispatchMethod';

export default {
  name: 'ReviewMessagesMessageSettings',
  mixins: [FormView],
  data() {
    const SENDING_LIMIT_PER_DAY_OPTIONS = [
      {
        value: false,
        label: this.$t(
          'common_settings.sending_limit_per_day.options.unlimited'
        )
      },
      { value: true }
    ];
    const SENDING_START_HOUR_OPTIONS = [...Array(19).keys()].map(
      v => v / 2 + 8
    );
    const DISPATCH_METHOD_OPTIONS = ReviewMessageDispatchMethod.options().map(
      o =>
        o.value === ReviewMessageDispatchMethod.SMS
          ? { ...o, superAdmin: true }
          : o
    );

    return {
      isLoading: true,
      SENDING_LIMIT_PER_DAY_OPTIONS,
      SENDING_START_HOUR_OPTIONS,
      DISPATCH_METHOD_OPTIONS
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('session', ['isKoreanBrand']),
    formSections() {
      return [
        {
          id: 'common_settings',
          label: this.$t('common_settings.label'),
          groups: [
            {
              id: 'sending_limit_per_day',
              label: this.$t('common_settings.sending_limit_per_day.label'),
              hint: this.$t('common_settings.sending_limit_per_day.hint'),
              validate: [
                ...(this.formObject.sending_limit_per_day_enabled
                  ? [
                      {
                        rule: () =>
                          this.formObject.sending_limit_per_day !== null,
                        errorMessage: this.$t('validations.required')
                      }
                    ]
                  : [])
              ]
            },
            {
              id: 'sending_delay_and_time',
              label: this.$t('common_settings.sending_delay_and_time.label')
            },
            {
              id: 'sending_target_groups',
              label: this.$t('common_settings.sending_target_groups.label'),
              type: 'hash_select_checkbox',
              groupDescription: this.$t(
                'common_settings.sending_target_groups.description'
              ),
              options: [
                {
                  id: 'offline_message_enabled',
                  label: this.$t(
                    'common_settings.sending_target_groups.options.offline'
                  )
                },
                {
                  id: 'nonmember_message_enabled',
                  label: this.$t(
                    'common_settings.sending_target_groups.options.nonmember'
                  )
                }
              ],
              superAdmin: true
            }
          ]
        },
        {
          id: 'dispatch_method_settings',
          label: this.$t('dispatch_method_settings.label'),
          groups: [
            {
              id: 'message_sending_enabled',
              label: this.$t(
                'dispatch_method_settings.message_sending_enabled.label'
              ),
              type: 'checkbox',
              superAdmin: !this.isKoreanBrand
            },
            {
              id: 'email_sending_enabled',
              label: this.$t('dispatch_method_settings.email_sending_enabled'),
              type: 'checkbox'
            }
          ],
          advancedGroups: [
            {
              id: 'advanced_tip_box',
              label: null,
              type: 'tip_box',
              value: this.$t(
                'dispatch_method_settings.advanced_settings_tip_box_text'
              )
            },
            {
              id: 'email_subject',
              label: this.$t('dispatch_method_settings.email_subject.label'),
              type: 'text',
              placeholder: this.$t(
                'dispatch_method_settings.email_subject.placeholder'
              ),
              hint: this.$t('dispatch_method_settings.email_subject.hint', {
                brand: '%{brand}',
                user: '%{user}'
              })
            },
            {
              id: 'reward_notice_short',
              type: 'textarea',
              superAdmin: true,
              label: this.$t(
                'dispatch_method_settings.reward_notice_short.label'
              )
            },
            {
              id: 'email_preview',
              label: this.$t('dispatch_method_settings.email_preview.label')
            }
          ]
        }
      ];
    },
    formattedSendingStartHour() {
      const hour_in_float = this.formObject.sending_start_hour;
      const hour = Math.floor(hour_in_float);
      const minute = (hour_in_float % 1) * 60;

      const hour_string_with_leading_zero = String(hour).padStart(2, '0');
      const minute_string_with_leading_zero = String(minute).padStart(2, '0');

      return `${hour_string_with_leading_zero}:${minute_string_with_leading_zero}`;
    },
    messageSendingHint() {
      return this.$t('dispatch_method_settings.message_sending_enabled.hint', {
        dispatch_method: ReviewMessageDispatchMethod.t(
          this.formObject.dispatch_method
        )
      });
    },
    registerBrandPhoneRequired() {
      if (this.currentBrand.phone) return false;

      const messageSendingEnabledChangedtoTrue =
        !this.orgFormObject.message_sending_enabled &&
        this.formObject.message_sending_enabled;
      const dispatchMethodChangedToSms =
        this.orgFormObject.dispatch_method ===
          ReviewMessageDispatchMethod.BIZ_MESSAGE &&
        this.formObject.dispatch_method === ReviewMessageDispatchMethod.SMS;

      return (
        (messageSendingEnabledChangedtoTrue &&
          this.formObject.dispatch_method ===
            ReviewMessageDispatchMethod.SMS) ||
        dispatchMethodChangedToSms
      );
    }
  },
  mounted() {
    api
      .get('/review/message_settings')
      .then(({ data }) => (this.orgFormObject = data.message_settings))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['confirm', 'openDialog']),
    ...mapActions('session', ['updateReviewSettings']),
    submit(formData) {
      if (this.registerBrandPhoneRequired) {
        this.confirm({
          title: this.$t(
            'dispatch_method_settings.message_sending_enabled.dialog.title'
          ),
          message: this.$t(
            'dispatch_method_settings.message_sending_enabled.dialog.message'
          ),
          closeButtonLabel: this.$t(
            'dispatch_method_settings.message_sending_enabled.dialog.close_button'
          ),
          cancelButtonLabel: this.$t('app.close')
        }).then(result => {
          if (!result) return;

          const initialTab = 'settings_dialog_brand';
          const elementId = 'brand--phone';
          this.openDialog(['TheSettingsDialog', { initialTab, elementId }]);
        });

        return;
      }

      this.isLoading = true;
      api
        .patch('/review/message_settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.orgFormObject = this.formObject;
          this.updateReviewSettings(this.formObject);
        })
        .finally(() => (this.isLoading = false));
    },
    openEmailPreviewDialog() {
      this.openDialog('ReviewSettingsEmailPreviewDialog');
    },
    sendSampleEmail() {
      const to = prompt(
        this.$t(
          'dispatch_method_settings.email_preview.send_sample_email_prompt'
        )
      );
      if (!to) return;

      if (!isEmailFormatValid(to)) {
        alert(
          this.$t(
            'dispatch_method_settings.email_preview.email_format_invalid',
            { to }
          )
        );
        return;
      }

      api.post(
        '/review/sample_email',
        { to },
        {
          successMessage: this.$t(
            'dispatch_method_settings.email_preview.sample_email_sent',
            { to }
          )
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewMessagesMessageSettings__sending-limit-per-day-tooltip {
  color: $color-grey-50;
  text-decoration: underline;
  font-size: 12px;
}

.ReviewMessagesMessageSettings__dispatch-method {
  margin-left: 22px;
}
</style>

<i18n locale="ko">
{
  "common_settings": {
    "label": "공통 설정",
    "sending_limit_per_day": {
      "label": "메시지 발송 한도",
      "description": "설정한 발송 한도에 도달하면 자동으로 메시지 발송이 중단됩니다.",
      "hint": "하루에 발송할 총 메시지 수를 제한하여, 한 달 동안 균등하게 메시지 발송 건수를 소모할 수 있습니다.",
      "options": {
        "unlimited": "발송 한도 없음",
        "limited": "1일 최대 {0} 건까지만 메시지 발송 {1}"
      },
      "tooltip": {
        "label": "메시지 발송 기준",
        "message": "발송 건수 제한으로 미발송된 메시지가 우선적으로 발송됩니다.\n이후, 배송 완료 순서대로 메시지가 발송됩니다."
      }
    },
    "sending_delay_and_time": {
      "label": "메시지 발송 시간",
      "description": "배송 완료 {0} 일 이후 {1} 부터 20:00까지",
      "hint": "호스팅사 기준으로 배송 완료 처리된 주문 건을 {0}~20:00에 30분마다 발송합니다.\n- 현재 시간을 기준으로 이전으로 설정할 경우, 다음날부터 적용됩니다.\n- 현재 시간을 기준으로 이후로 설정할 경우, 바로 적용됩니다."
    },
    "sending_target_groups": {
      "label": "메시지 발송 대상",
      "description": "기본은 회원에게만 발송합니다.",
      "options": {
        "offline": "매장 구매 고객",
        "nonmember": "비회원"
      }
    }
  },
  "dispatch_method_settings": {
    "label": "발송 방법",
    "message_sending_enabled": {
      "label": "배송 완료 고객에게 메시지 발송",
      "hint": "배송 완료 고객에게 {dispatch_method}을 발송합니다.",
      "dialog": {
        "title": "쇼핑몰 대표 전화번호 등록이 필요합니다.",
        "message": "현재 쇼핑몰 대표 전화번호가 등록되어있지 않아 문자를 발송할 수 없습니다.\n먼저 쇼핑몰 대표 전화번호를 등록하신 후, 다시 설정 저장을 시도해주세요.",
        "close_button": "쇼핑몰 대표 전화번호 등록"
      }
    },
    "dispatch_method": "메시지 발송 방법",
    "email_sending_enabled": "배송 완료 고객에게 이메일 발송",
    "advanced_settings_tip_box_text": "고급 설정은 세밀한 사용을 위한 설정모음 입니다. 해당 설정을 잘 모르신다면 기본 값 사용을 추천합니다.",
    "email_subject": {
      "label": "이메일 제목",
      "placeholder": "제목을 입력해주세요.",
      "hint": "{brand}를 입력하면 쇼핑몰 이름을, {user}를 입력하면 고객 이름을 이메일 제목에 표시합니다."
    },
    "email_preview": {
      "label": "발송될 이메일 미리보기",
      "open_email_preview_button_label": "이메일 미리보기",
      "send_sample_email_button_label": "테스트 이메일 발송",
      "send_sample_email_prompt": "테스트 이메일을 받을 주소를 입력해주세요.",
      "email_format_invalid": "`{to}`는 정상적인 이메일 주소가 아닙니다.",
      "sample_email_sent": "테스트 이메일을 `{to}`로 발송했습니다."
    },
    "reward_notice_short": {
      "label": "이메일 내 안내문구"
    }
  }
}
</i18n>
