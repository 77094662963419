<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar @submit="searchResource">
      <template #right>
        <li>
          <AppSelect
            v-model="resourceParams.log_type"
            :options="logTypeOptions"
            :placeholder="$t('app.all')"
            @change="searchResource"
          />
        </li>
        <li>
          <AppSearchWithType
            v-model="searchWithType"
            :search-types="searchTypes"
          />
        </li>
        <li>
          <AppDateRangePicker
            v-model="resourceParams"
            :min-date="minDate"
            @update="searchResource"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppInfoBox>{{ $t('info') }}</AppInfoBox>
    <AppResourceTable
      table-id="logs-table"
      :columns="columns"
      :resources="logs"
      :rows="logs.items"
      @paginate="paginate"
    >
      <template #cell="{ row, column }">
        <template v-if="column === 'actor'">
          <div>{{ row.actor_email }}</div>
          {{ row.actor_ip }}
        </template>
        <template v-else-if="column === 'log_type'">
          <AppBadge
            :label="LogType.t(row.log_type)"
            :badge-style="badgeStyle(row.log_type)"
          />
        </template>
        <template v-else-if="column === 'description'">
          <span class="TheLogsDialogBizMessage__description">
            {{ row.description }}
          </span>
          <AppInlineButton
            v-if="row.log_type === LogType.BIZ_MESSAGE_TEMPLATE_CREATED"
            :label="$t('biz_message_template_link')"
            button-style="blue"
            @click="openBizMessageTemplateShowDialog(row)"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import LogType from '@/enums/LogType';
import BizMessageTemplateType from '@/enums/BizMessageTemplateType';

const TYPE = 'biz_message';

export default {
  name: 'TheLogsDialogBizMessage',
  mixins: [ResourceViewNoRoute],
  data: () => ({ logs: nullResources, isLoading: true }),
  computed: {
    LogType: () => LogType,
    columns() {
      return [
        { id: 'created_at', type: 'datetime', width: '155px' },
        { id: 'actor', width: '170px' },
        { id: 'log_type', width: '100px' },
        { id: 'description', align: 'left' }
      ].map(column => ({ ...column, label: this.$t(`columns.${column.id}`) }));
    },
    defaultResourceParams: () => ({
      per: '20',
      page: '1',
      search_type: 'actor_email'
    }),
    minDate() {
      return moment()
        .subtract(3, 'month')
        .add(1, 'day')
        .toDate()
        .toISOString();
    },
    searchTypes() {
      return ['actor_email', 'actor_ip'].map(value => ({
        value,
        label: this.$t(`search_type.${value}`)
      }));
    },
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    logTypeOptions() {
      return LogType.options([
        LogType.BIZ_MESSAGE_TEMPLATE_CREATED,
        LogType.BIZ_MESSAGE_TEMPLATE_DELETED,
        LogType.BIZ_MESSAGE_CAMPAIGN_UPDATED,
        LogType.BIZ_MESSAGE_PROFILE_CREATED,
        LogType.BIZ_MESSAGE_PROFILE_DELETED
      ]);
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog', 'alert']),
    badgeStyle(logType) {
      switch (logType) {
        case LogType.BIZ_MESSAGE_TEMPLATE_CREATED:
          return 'blue';
        case LogType.BIZ_MESSAGE_TEMPLATE_DELETED:
          return 'red';
        case LogType.BIZ_MESSAGE_CAMPAIGN_UPDATED:
          return 'blue-outline';
        case LogType.BIZ_MESSAGE_PROFILE_CREATED:
          return 'blue';
        case LogType.BIZ_MESSAGE_PROFILE_DELETED:
          return 'red';
      }
    },
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/logs', { params: { ...params, type: TYPE } })
        .then(({ data }) => (this.logs = data.logs))
        .finally(() => (this.isLoading = false));
    },
    openBizMessageTemplateShowDialog(log) {
      if (log.biz_message_template_deleted) {
        this.alert({
          title: this.$t('template_deleted'),
          message: '',
          closeButtonLabel: this.$t('app.confirmed')
        });
        return;
      }

      this.openDialog([
        'BizMessageTemplateShowDialog',
        {
          templateId: log.biz_message_template_id,
          templateType: BizMessageTemplateType.CUSTOM,
          hideEditButton: true
        }
      ]);
    }
  }
};
</script>

<style lang="scss">
.TheLogsDialogBizMessage__description {
  white-space: pre-line;
}
</style>

<i18n locale="ko">
{
  "info": "알림톡 이력은 최근 3개월 간의 내역만 제공합니다.",
  "columns": {
    "created_at": "날짜",
    "actor": "관리자",
    "log_type": "동작",
    "description": "상세 이력"
  },
  "search_type": {
    "actor_email": "관리자 ID",
    "actor_ip": "관리자 IP"
  },
  "biz_message_template_link": "템플릿 확인하러 가기",
  "template_deleted": "삭제된 템플릿입니다."
}
</i18n>
