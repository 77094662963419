<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <ReviewSemiDonutChart
        class="ui-section__chart"
        :data="basicChartData"
        :tooltip="basicChartTooltip"
        @tooltip="onBasicChartTooltip"
      />
      <div>
        <div>main value: {{ basicChartData[0].value }}</div>
        <div>main total: {{ basicChartData[0].total }}</div>
        <div>indicator value: {{ basicChartData[1].value }}</div>
        <div>indicator total: {{ basicChartData[1].total }}</div>
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Divide by Zero</div>
      <ReviewSemiDonutChart
        class="ui-section__chart"
        :data="divideByZeroChartData"
        :tooltip="divideByZeroChartTooltip"
        @tooltip="onDivideByZeroChartTooltip"
      />
      <div>
        <div>main value: {{ divideByZeroChartData[0].value }}</div>
        <div>main total: {{ divideByZeroChartData[0].total }}</div>
        <div>indicator value: {{ divideByZeroChartData[1].value }}</div>
        <div>indicator total: {{ divideByZeroChartData[1].total }}</div>
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <ReviewSemiDonutChart
        class="ui-section__chart"
        :data="longNameChartData"
        :tooltip="longNameChartTooltip"
        @tooltip="onLongNameChartTooltip"
      />
      <div>
        <div>main value: {{ longNameChartData[0].value }}</div>
        <div>main total: {{ longNameChartData[0].total }}</div>
        <div>indicator value: {{ longNameChartData[1].value }}</div>
        <div>indicator total: {{ longNameChartData[1].total }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ReviewSemiDonutChart from '../review/components/ReviewSemiDonutChart';

export default {
  name: 'UiReviewSemiDonutChart',
  components: { ReviewSemiDonutChart },
  data() {
    return {
      basicChartData: [
        {
          label: '크리마 몰',
          type: 'main',
          value: 1,
          total: 4
        },
        {
          label: '업계 평균',
          type: 'indicator',
          value: 1,
          total: 3
        }
      ],
      divideByZeroChartData: [
        {
          label: '크리마 몰',
          type: 'main',
          value: 0,
          total: 0
        },
        {
          label: '업계 평균',
          type: 'indicator',
          value: 0,
          total: 0
        }
      ],
      longNameChartData: [
        {
          label: '이름이 긴 쇼핑몰 테스트입니다.',
          type: 'main',
          value: 1,
          total: 4
        },
        {
          label: '업계 평균',
          type: 'indicator',
          value: 1,
          total: 3
        }
      ],
      basicChartTooltip: null,
      divideByZeroChartTooltip: null,
      longNameChartTooltip: null
    };
  },
  methods: {
    onBasicChartTooltip({ mainValue, indicatorValue }) {
      this.basicChartTooltip = {
        title: '2025-02-27',
        items: [
          { symbol: 'square', name: '크리마 몰', value: mainValue },
          { symbol: 'line', name: '업계 평균', value: indicatorValue }
        ]
      };
    },
    onDivideByZeroChartTooltip({ mainValue, indicatorValue }) {
      this.divideByZeroChartTooltip = {
        title: '2025-02-27',
        items: [
          { symbol: 'square', name: '크리마 몰', value: mainValue },
          { symbol: 'line', name: '업계 평균', value: indicatorValue }
        ]
      };
    },
    onLongNameChartTooltip({ mainValue, indicatorValue }) {
      this.basicChartTooltip = {
        title: '2025-02-27',
        items: [
          {
            symbol: 'square',
            name: '이름이 긴 쇼핑몰 테스트입니다.',
            value: mainValue
          },
          { symbol: 'line', name: '업계 평균', value: indicatorValue }
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.ui-section__chart {
  width: 300px;
}
</style>
