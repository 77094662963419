<template>
  <div class="ReviewNewReviewsStatSection">
    <ReviewStatCard icon="icon-new" :label="$t('new.label')" :value="10">
      <ReviewRateChange :value="5" status="positive" />
    </ReviewStatCard>
    <ReviewStatCard
      icon="icon-mileage"
      :label="$t('mileage.label')"
      :value="11"
    />
    <ReviewStatCard
      icon="icon-comment"
      :label="$t('comment.label')"
      :value="12"
    />
  </div>
</template>

<script>
export default {
  name: 'ReviewNewReviewsStatSection'
};
</script>

<style lang="scss" scoped>
.ReviewNewReviewsStatSection {
  height: 236px;
  min-width: 255px;
  max-width: 310px;
}
</style>

<i18n locale="ko">
{
  "new": {
    "label": "신규 리뷰"
  },
  "mileage": {
    "label": "적립금 지급 필요 리뷰"
  },
  "comment": {
    "label": "댓글 작성 필요 리뷰"
  }  
}
</i18n>
