<template>
  <div
    :class="[
      'AppRadioLabel',
      `AppRadioLabel--${position}`,
      {
        'AppRadioLabel--disabled': disabled,
        'AppRadioLabel--checked': checked
      },
      inputStyle ? `AppRadioLabel--${inputStyle}` : null
    ]"
  >
    <label class="AppRadioLabel__main-item" @click="selectSubItem">
      <div v-if="position === 'left'" class="AppRadioLabel__input">
        <slot />
      </div>
      <div
        v-if="label || $scopedSlots.label"
        ref="label"
        class="AppRadioLabel__label"
      >
        <slot name="label" :value="value" :checked="checked" :label="label">{{
          label
        }}</slot>
      </div>
      <div v-if="tooltip" v-tooltip="tooltip" class="AppRadioLabel__icon">
        <AppSvgIcon name="icon-info-tooltip" />
      </div>
      <template v-if="detailButtonClickHandler">
        <AppInlineButton
          class="AppRadioLabel__detail-button"
          :label="$t('app.detail')"
          button-style="caption"
          @click.prevent="detailButtonClickHandler"
        />
      </template>
      <div v-if="position === 'right'" class="AppRadioLabel__input">
        <slot />
      </div>
    </label>

    <div
      v-if="$scopedSlots['sub-item']"
      :class="[
        'AppRadioLabel__sub-item',
        { 'AppRadioLabel__sub-item--disabled': !checked }
      ]"
    >
      <slot name="sub-item" :value="value" :checked="checked" />
    </div>
  </div>
</template>

<script>
import focusChild from '@/lib/focusChild';

export default {
  name: 'AppRadioLabel',
  props: {
    detailButtonClickHandler: { type: Function, default: null },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: null },
    inputStyle: {
      type: String,
      default: null,
      validator: v => ['standalone', 'inline', 'block'].includes(v)
    },
    checked: { type: Boolean, required: true },
    value: { type: [String, Number, Boolean], default: null },
    tooltip: { type: [String, Object], default: null },
    isBlock: { type: Boolean, default: false },
    inputPosition: {
      type: String,
      default: null,
      validator: v => ['left', 'right'].includes(v)
    }
  },
  data() {
    return {
      position: this.inputPosition || 'left'
    };
  },
  methods: {
    selectSubItem() {
      if (!this.$el.contains(document.activeElement)) focusChild(this.$el);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.AppRadioLabel {
  display: inline-block;

  &--disabled {
    pointer-events: none;
    color: $color-disable-text;
  }

  &--block {
    display: block;
  }
}

.AppRadioLabel__main-item {
  display: inline-flex;
  align-items: baseline;

  .AppRadioLabel--standalone & {
    position: relative;
    line-height: 0;
    padding: 8px;
  }

  .AppRadioLabel--inline & {
    display: inline-block;

    & + & {
      margin-left: 4px;
    }
  }
}

.AppRadioLabel__input {
  position: relative;
  top: 2px;
  flex: 0 0 auto;

  .AppRadioLabel--standalone & {
    top: 0;
  }

  .AppRadioLabel--inline & {
    display: inline;
  }
}

.AppRadioLabel__label {
  .AppRadioLabel--inline & {
    display: inline;
  }
}

.AppRadioLabel--left {
  .AppRadioLabel__label {
    margin-left: 8px;
  }
  &.AppRadioLabel--inline .AppRadioLabel__label {
    margin-left: 4px;
  }
}

.AppRadioLabel--right {
  .AppRadioLabel__label {
    margin-right: 8px;
  }

  &.AppRadioLabel--inline .AppRadioLabel__label {
    margin-right: 4px;
  }
}

.AppRadioLabel__icon {
  pointer-events: auto;
  position: relative;
  top: 2px;
  margin-left: 4px;
}

.AppRadioLabel__detail-button {
  margin-left: 4px;
}

.AppRadioLabel__sub-item {
  &:empty {
    display: none;
  }

  margin-top: 4px;
  margin-left: 22px;

  &--disabled {
    color: $color-disable-text;
  }
}
</style>

<style>
/* HACK - `.AppTable`은 `vertical-align: middle`이 적용되어 있어서 top을 조정하면 중앙에서 벗어난다. */
/* BEM 규약에 어긋나지만 더 좋은 해결 방법을 찾지 못함 */
.AppTable .AppRadioLabel--standalone {
  top: 0;
}
</style>
