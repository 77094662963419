<template>
  <AppModalForm
    class="ReviewDashboardGodoReviewCsvUploadDialog"
    :title="$t('title')"
    :form-props="{ ...formProps, detectFormDataChange: false }"
    v-on="formEvents"
    @close="close"
  >
    <template #group="{id}">
      <template v-if="id === 'info'">
        <AppInfoBox
          class="ReviewDashboardGodoReviewCsvUploadDialog__info-header"
          >{{ $t('info_header') }}
        </AppInfoBox>
        <AppContainer>
          <AppImage
            src="//assets.cre.ma/m/admin/v2/godo_review_csv_upload_guidance.png"
            :resolutions="[2]"
            class="ReviewDashboardGodoReviewCsvUploadDialog__image"
          />
          <AppDataLabel :label="$t('guidance_label')" />
          <ul class="ReviewDashboardGodoReviewCsvUploadDialog__guide_list">
            <li>{{ $t('guidance_1') }}</li>
            <li>{{ $t('guidance_2') }}</li>
            <!-- eslint-disable vue/no-v-html -->
            <li v-html="$t('guidance_3_html')" />
            <!-- eslint-enable vue/no-v-html -->
            <li>{{ $t('guidance_4') }}</li>
          </ul>
          <AppTipBox
            class="ReviewDashboardGodoReviewCsvUploadDialog__tip_box"
            :text="$t('tip_box')"
            :margin-bottom="0"
          />
          <AppDataLabel
            class="ReviewDashboardGodoReviewCsvUploadDialog__inqury"
            :label="$t('inqury.label')"
          />
          <div>{{ $t('inqury.content') }}</div>
        </AppContainer>
      </template>
      <template v-if="id === 'csv_file'">
        <AppFileInput
          :id="id"
          :placeholder="$t('csv_file.placeholder')"
          name="file"
          accept=".csv"
          :event-bus="fileEventBus"
          @change="changeReviewCsvFile"
        />
        <AppCheckbox
          v-model="isNoneReviewChecked"
          :label="$t('none_review_checked.label')"
          @change="validateField('csv_file')"
        />
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import Vue from 'vue';
import api from '@/lib/api';
import { mapActions } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ReviewDashboardGodoReviewCsvUploadDialog',
  mixins: [DialogFormView],
  data() {
    return {
      selectedReviewCsvFile: null,
      isReviewCsvSelected: false,
      isNoneReviewChecked: false,
      selectedReviewsCsvFile: null,
      fileEventBus: new Vue()
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'info',
              label: ''
            },
            {
              id: 'csv_file',
              label: this.$t('csv_file.label'),
              required: true,
              validate: [
                {
                  rule: () =>
                    this.isNoneReviewChecked || this.isReviewCsvSelected,
                  errorMessage: this.$t('error.required')
                },
                {
                  rule: () => this.isNoneReviewChecked || !this.containsHangul,
                  errorMessage: this.$t('error.contains_hangul')
                }
              ]
            }
          ]
        }
      ];
    },
    containsHangul() {
      const hangulRegex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;
      const fileName = this.selectedReviewsCsvFile.name.normalize('NFC');
      return hangulRegex.test(fileName);
    }
  },
  methods: {
    ...mapActions('easyJoin', ['updateOnboardingProgress']),
    changeReviewCsvFile(file) {
      this.isReviewCsvSelected = true;
      this.isNoneReviewChecked = false;
      this.selectedReviewsCsvFile = file;
      this.validateField('csv_file');
    },
    submit(formData) {
      if (this.isNoneReviewChecked) {
        this.isSubmitting = true;

        this.updateOnboardingProgress({ no_reviews_to_sync: true })
          .then(() => this.close())
          .finally(() => {
            this.isSubmitting = false;
          });
      } else {
        this.isSubmitting = true;

        api
          .post('/easy_join/godo_review_csvs', formData, {
            successMessage: this.$t('app.saved')
          })
          .then(() => {
            this.fileEventBus.$emit('reset');
            this.close();
          })
          .finally(() => (this.isSubmitting = false));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewDashboardGodoReviewCsvUploadDialog__info-header {
  font-size: 12px;
  margin-bottom: 20px;
}

.ReviewDashboardGodoReviewCsvUploadDialog__image {
  margin: 16px 0;
}

.ReviewDashboardGodoReviewCsvUploadDialog__guide_list {
  list-style-type: disc;
  font-size: 12px;
  padding-left: 15px;
  margin-bottom: 10px;
}

.ReviewDashboardGodoReviewCsvUploadDialog__tip_box {
  font-size: 11px;
}

.ReviewDashboardGodoReviewCsvUploadDialog__inqury {
  margin-top: 10px;
  margin-bottom: 3px;
}
</style>

<i18n locale="ko">
  {
    "title": "리뷰 CSV 등록",
    "info_header": "리뷰 동기화를 위해 고도 관리자 페이지에서 CSV파일을 다운받아 크리마에 업로드 해주세요.",
    "guidance_label": "리뷰 CSV 다운로드 및 등록 방법",
    "guidance_1": "위 이미지와 같이 [다운로드] 버튼 클릭 후, 사유 선택 단계에서 회원관리를 선택하고 확인 버튼을 누르세요.",
    "guidance_2": "파일 다운로드를 위한 비밀번호 설정 후, 파일을 다운로드 받은 후 압축을 해제하세요.",
    "guidance_3_html": "압축 해제한 파일 중에서 리뷰 CSV 파일을 선택하여 아래 <b>'CSV 파일 업로드'에 등록</b>해주세요.",
    "guidance_4": "업로드 할 CSV 파일은 한글 파일명으로 등록이 불가능하니, 파일명을 영문 또는 숫자 조합으로 변경한 후 저장해주세요.",
    "tip_box": "플러스리뷰를 사용 중이라면 '리뷰 파일 다운로드 범위 설정'에서 <b>다운로드 범위를 '플러스 리뷰'로 선택</b>해주세요. 다운로드 범위를 '전체'를 선택할 경우, 중복 리뷰가 발생할 수 있습니다.<br /><img src='https://assets.cre.ma/m/admin/v2/godo_file_download_settings.png' width='305'/>",
    "inqury": {
      "label": "문제가 있으신가요?",
      "content": "파일 다운로드에 문제가 있을 경우 고도몰에 문의해 주세요."
    },
    "csv_file": {
      "label": "CSV 파일 업로드",
      "placeholder": "업로드할 CSV 파일을 등록해주세요."
    },
    "none_review_checked": {
      "label": "등록할 리뷰가 없으실 경우 체크 박스를 클릭해주세요."
    },
    "error": {
      "required": "필수값입니다. 등록할 CSV가 없다면 아래 체크박스를 클릭해주세요. ",
      "contains_hangul": "등록할 수 없는 csv 파일입니다. 제목이 한글로 된 csv파일은 등록이 불가합니다."
    }
  }
</i18n>
