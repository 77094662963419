<template>
  <AppButton :label="$t('label')" @click="onClick" />
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';

export default {
  name: 'ReviewNewReviewsCommentButton',
  props: {
    review: { type: Object, required: true }
  },
  methods: {
    ...mapActions('dialog', ['openDialog', 'confirm']),
    onClick() {
      if (this.review.admin_commented_at) {
        this.confirm({
          title: this.$t('confirm.title'),
          message: this.$t('confirm.message'),
          closeButtonLabel: this.$t('confirm.button')
        }).then(result => {
          if (!result) return;

          this.openCommentDialog();
        });
      } else {
        this.openCommentDialog();
      }
    },
    openCommentDialog() {
      api.get(`/review/reviews/${this.review.id}`).then(({ data }) => {
        const { review_messages_sentiment_analysis_reports } = data;
        this.doOpenCommentDialog(review_messages_sentiment_analysis_reports);
      });
    },
    doOpenCommentDialog(sentimentAnalysisReport) {
      this.openDialog([
        'ReviewReviewEditCommentDialog',
        {
          review: this.review,
          sentimentAnalysisReport
        }
      ]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "label": "댓글",
  "confirm": {
    "title": "댓글을 추가로 작성하시겠습니까?",
    "message": "이미 댓글을 작성한 리뷰입니다.\n기존 댓글을 관리하려면 '리뷰 상세 내용' 항목의 본문을 클릭해 주세요.",
    "button": "작성"
  }
}
</i18n>
