import WidgetDeviceType from '@/enums/WidgetDeviceType';

export default {
  data() {
    return {
      pcCustomImageFileName: null,
      pcCustomImageFileType: null,
      mobileCustomImageFileName: null,
      mobileCustomImageFileType: null
    };
  },
  methods: {
    customImageUrlKey(deviceType) {
      return `${WidgetDeviceType.key(
        deviceType
      ).toLowerCase()}_custom_image_url`;
    },
    customCommentKey(deviceType) {
      return `${WidgetDeviceType.key(deviceType).toLowerCase()}_custom_comment`;
    },
    customImageFileName(deviceType) {
      return deviceType === WidgetDeviceType.PC
        ? this.pcCustomImageFileName
        : this.mobileCustomImageFileName;
    },
    customImageFileType(deviceType) {
      return deviceType === WidgetDeviceType.PC
        ? this.pcCustomImageFileType
        : this.mobileCustomImageFileType;
    },
    formGroupChanged(group) {
      if (group.id === 'pc_custom_image_url') {
        this.pcCustomImageFileName = group.value.fileName;
        this.pcCustomImageFileType = group.value.fileType;
      } else if (group.id === 'mobile_custom_image_url') {
        this.mobileCustomImageFileName = group.value.fileName;
        this.mobileCustomImageFileType = group.value.fileType;
      }
    },
    setCustomImage(direction) {
      if (direction.device_type === WidgetDeviceType.PC) {
        this.pcCustomImageFileName = direction.custom_image_file_name;
        this.pcCustomImageFileType = direction.custom_image_file_type;
      } else if (direction.device_type === WidgetDeviceType.MOBILE) {
        this.mobileCustomImageFileName = direction.custom_image_file_name;
        this.mobileCustomImageFileType = direction.custom_image_file_type;
      }
    }
  }
};
