import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import TutorialGuideDialog from '@/layouts/components/TheTutorial/TutorialGuideDialog.vue';
import TutorialStatus from '@/enums/TutorialStatus';
import SmartstoreStatus from '@/enums/SmartstoreStatus';
import KakaoStyleReviewImportStatus from '@/enums/KakaoStyleReviewImportStatus';
import TUTORIAL_CONTENTS from '@/constants/TUTORIAL_CONTENTS';

const SMARTSTORE_URL = '/v2/review/import/smartstore';
const KAKAO_STYLE_URL = '/v2/review/import/kakao-style';

export default {
  data() {
    return {
      dialogs: []
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapState('kakaoStyle', {
      kakaoStyleReviewImportStatus: 'reviewImportStatus'
    }),
    ...mapState('smartstore', ['smartstoreStatus']),
    ...mapState('tutorial', {
      tutorialReviewImportStatus: 'reviewImportStatus'
    }),
    isSmartstoreTutorialAvailable() {
      return this.smartstoreStatus === SmartstoreStatus.NONE;
    },
    isKakaoStyleTutorialAvailable() {
      return (
        this.kakaoStyleReviewImportStatus ===
        KakaoStyleReviewImportStatus.NOT_REQUESTED
      );
    },
    currentPageSteps() {
      return TUTORIAL_CONTENTS.REVIEW_IMPORT.steps.filter(
        step => step.url === this.currentUrl && this.isAvailableStep(step)
      );
    },
    allAvailableSteps() {
      return TUTORIAL_CONTENTS.REVIEW_IMPORT.steps.filter(step =>
        this.isAvailableStep(step)
      );
    }
  },
  methods: {
    ...mapActions('tutorial', ['showTutorialDialog']),
    isDuplicatedDialog(targetElementId) {
      return this.dialogs.some(e => e.targetElementId === targetElementId);
    },
    createDialog(props) {
      const DialogConstructor = Vue.extend(TutorialGuideDialog);
      const dialogInstance = new DialogConstructor({
        propsData: {
          ...props,
          eventBus: new Vue()
        },
        parent: this
      });
      dialogInstance.targetElementId = props.targetElementId;
      dialogInstance.position = props.position;

      dialogInstance.eventBus.$on('continue', () => {
        this.destroyDialog(dialogInstance);

        if (this.dialogs.length > 0) {
          this.showTutorialDialog(this.dialogs[0]);
        } else if (!props.isLast) {
          this.$router.push({
            path: 'kakao-style',
            query: { brand_id: this.currentBrand.id }
          });
        }
      });

      return dialogInstance;
    },
    destroyDialog(dialog) {
      if (!dialog) return;

      dialog.eventBus.$off('continue');
      dialog.$destroy();
      if (dialog.$el && dialog.$el.parentNode) {
        dialog.$el.parentNode.removeChild(dialog.$el);
      }
      this.dialogs.shift();
    },
    isAvailableStep(step) {
      if (step.default) return true;
      if (step.url === SMARTSTORE_URL && this.isSmartstoreTutorialAvailable)
        return true;
      if (step.url === KAKAO_STYLE_URL && this.isKakaoStyleTutorialAvailable)
        return true;

      return false;
    }
  },
  mounted() {
    this.currentUrl = window.location.pathname;
    if (this.tutorialReviewImportStatus !== TutorialStatus.IN_PROGRESS) return;

    this.currentPageSteps.forEach(step => {
      if (this.isDuplicatedDialog(step.id)) return;

      const dialog = this.createDialog({
        title: step.title,
        description: step.description,
        targetElementId: step.id,
        position: step.position,
        isLast:
          this.allAvailableSteps[this.allAvailableSteps.length - 1].id ===
          step.id
      });

      this.dialogs.push(dialog);
    });

    if (this.dialogs.length > 0) {
      this.showTutorialDialog(this.dialogs[0]);
    }
  },
  beforeDestroy() {
    this.dialogs.forEach(dialog => {
      this.destroyDialog(dialog);
    });
  }
};
