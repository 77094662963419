<template>
  <div>
    <AppButton
      :label="$t('biz_messages.add_template')"
      @click="clickNewTemplate"
    />
    <AppResourceTable
      class="ReviewMessagesBizMessageTemplateSelectTable__table"
      :columns="columns"
      :rows="rows"
      :enable-total-count="false"
    >
      <template #cell="{ column, row }">
        <template v-if="column === 'select'">
          <div v-tooltip="row.isSelectable ? null : $t('not_selectable')">
            <AppRadio
              v-model="selectedValue"
              input-style="standalone"
              :value="row.id"
              :disabled="!row.isSelectable"
            />
          </div>
        </template>
        <template v-else-if="column === 'name'">
          <template
            v-if="row.bizMessageTemplateType === BizMessageTemplateType.GROUP"
          >
            <AppSelect
              v-model="selectedGroupTemplateId"
              :options="groupTemplateOptions"
              :disabled="!isGroupTemplateSelected"
              @change="$emit('change', $event)"
            />
          </template>
        </template>
        <template v-else-if="column === 'status'">
          <AppBadge v-bind="row.status" />
        </template>
        <template v-else-if="column === 'manage'">
          <ReviewMessageBizMessageTemplateManageCell :template="row" />
        </template>
      </template>
    </AppResourceTable>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BizMessageTemplateStatus from '@/enums/BizMessageTemplateStatus';
import BizMessageTemplateInspectionStatus from '@/enums/BizMessageTemplateInspectionStatus';
import BizMessageTemplateType from '@/enums/BizMessageTemplateType';

const GROUP_TEMPLATE_SELECTED_VALUE = 0;

export default {
  name: 'ReviewMessagesBizMessageTemplateSelectTable',
  props: {
    template: { type: Object, default: () => {} },
    campaignType: { type: Number, required: true }
  },
  data: () => ({ selectedGroupTemplateId: null, selectedValue: null }),
  computed: {
    ...mapState('bizMessageTemplate', ['customTemplates', 'groupTemplates']),
    BizMessageTemplateType: () => BizMessageTemplateType,
    isGroupTemplateSelected() {
      return this.selectedValue === 0;
    },
    selectedTemplate() {
      if (this.selectedValue)
        return {
          biz_message_template_type: BizMessageTemplateType.CUSTOM,
          biz_message_template_id: this.selectedValue
        };
      else
        return {
          biz_message_template_type: BizMessageTemplateType.GROUP,
          biz_message_template_id: this.selectedGroupTemplateId
        };
    },
    columns() {
      return [
        { id: 'select', label: this.$t('app.select'), width: '40px' },
        { id: 'name', label: this.$t('columns.name'), align: 'left' },
        { id: 'status', label: this.$t('app.status'), width: '100px' },
        { id: 'manage', label: this.$t('app.manage'), width: '82px' }
      ];
    },
    rows() {
      return this.groupTemplateRow.concat(this.templateRows);
    },
    groupTemplateRow() {
      return [
        {
          id: GROUP_TEMPLATE_SELECTED_VALUE,
          status: {
            badgeStyle: 'grey-outline',
            label: this.$t('group_template.status')
          },
          isSelectable: true,
          bizMessageTemplateType: BizMessageTemplateType.GROUP
        }
      ];
    },
    templateRows() {
      return this.customTemplates
        .filter(
          template =>
            ![
              BizMessageTemplateInspectionStatus.NONE,
              BizMessageTemplateInspectionStatus.REGISTERED
            ].includes(template.inspection_status)
        )
        .map(template => ({
          ...template,
          status:
            template.inspection_status ===
            BizMessageTemplateInspectionStatus.APPROVED
              ? {
                  badgeStyle: BizMessageTemplateStatus.badgeStyle(
                    template.status
                  ),
                  label: BizMessageTemplateStatus.t(template.status)
                }
              : {
                  badgeStyle: BizMessageTemplateInspectionStatus.badgeStyle(
                    template.inspection_status
                  ),
                  label: BizMessageTemplateInspectionStatus.t(
                    template.inspection_status
                  ),
                  tooltip: template.rejected_message
                },
          isSelectable:
            template.status !== BizMessageTemplateStatus.STOPPED &&
            template.inspection_status ===
              BizMessageTemplateInspectionStatus.APPROVED,
          bizMessageTemplateType: BizMessageTemplateType.CUSTOM
        }));
    },
    groupTemplateOptions() {
      return this.groupTemplates
        .filter(({ campaign_type }) => campaign_type === this.campaignType)
        .map(t => ({
          label: t.name,
          value: t.id
        }));
    }
  },
  watch: {
    selectedTemplate() {
      this.$emit('change', this.selectedTemplate);
    }
  },
  created() {
    const {
      biz_message_template_id,
      biz_message_template_type
    } = this.template;
    const isGroupTemplateType = BizMessageTemplateType.isGroupTemplateType(
      biz_message_template_type
    );
    this.selectedGroupTemplateId = isGroupTemplateType
      ? biz_message_template_id
      : this.groupTemplateOptions[0].value;
    this.selectedValue = isGroupTemplateType
      ? GROUP_TEMPLATE_SELECTED_VALUE
      : biz_message_template_id || GROUP_TEMPLATE_SELECTED_VALUE;
  },
  methods: mapActions('bizMessageTemplate', ['clickNewTemplate'])
};
</script>

<style scoped>
.ReviewMessagesBizMessageTemplateSelectTable__table {
  margin-top: 4px;
}
</style>

<i18n locale="ko">
{
  "columns": {
    "name": "메시지 템플릿 이름"
  },
  "group_template": {
    "status": "기본 템플릿"
  },
  "not_selectable": "상태가 '사용가능'인 템플릿만 선택이 가능합니다."
}
</i18n>
