<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar @submit="searchResource">
      <template #right>
        <li>
          <AppSearchWithType
            v-model="resourceParams"
            :search-types="SEARCH_TYPES"
          />
        </li>
        <li>
          <AppDateRangePicker
            v-model="resourceParams"
            @update="searchResource"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppResourceTable table-id="logs-table" :columns="columns" :rows="rows">
      <template #cell="{row, column}">
        <template v-if="column === 'description'">
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="row.description" />
          <!-- eslint-enable vue/no-v-html -->
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import escapeHtml from '@/lib/vendor/escapeHtml';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

const SORTED_KEYS = [
  'popup_install_position',
  'enable_widget_observe_install',
  'use_score_widget_v2',
  'enable_remove_hard_coded_crema_class',
  'remove_hard_coded_crema_class_skins',
  'page_url_patterns'
];

export default {
  name: 'TheLogsDialogSmartInstallationSettings',
  mixins: [ResourceViewNoRoute],
  data() {
    return {
      isLoading: true,
      logs: nullResources,
      SEARCH_TYPES: ['actor_email', 'actor_ip', 'setting_name'].map(value => ({
        value,
        label: this.$t(`search_type.${value}`)
      }))
    };
  },
  computed: {
    defaultResourceParams: () => ({
      search_type: 'actor_email'
    }),
    columns() {
      return [
        { id: 'date', label: this.$t('column.date') },
        { id: 'actor', type: 'html', label: this.$t('column.actor') },
        {
          id: 'description',
          label: this.$t('column.description'),
          align: 'left'
        }
      ];
    },
    rows() {
      return this.logs.items.map(log => {
        return {
          date: this.formatDateTime(log.created_at),
          actor: this.$t('actor_content_html', {
            actor_email: log.email,
            actor_ip: log.ip
          }),
          description: this.description(log)
        };
      });
    }
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      const type = 'smart_installation_settings';
      api
        .get('/logs', { params: { ...params, type } })
        .then(({ data }) => (this.logs = data.logs))
        .finally(() => (this.isLoading = false));
    },
    description(row) {
      let descriptions = [];
      const oldSettings = row.old_setting_name_value_map;
      const newSettings = row.new_setting_name_value_map;
      for (const key of this.sortedTranslatedkeysMap()) {
        if (!newSettings[key] && !oldSettings[key]) {
          continue;
        }

        const oldValue = escapeHtml(oldSettings[key]);
        const newValue = escapeHtml(newSettings[key]);

        const comparisonResult = this.formatComparisonResult(
          key,
          oldValue,
          newValue
        );

        descriptions.push(`<b>${key}:</b> ${comparisonResult}`);
      }

      return descriptions.join('<br>');
    },
    sortedTranslatedkeysMap() {
      return SORTED_KEYS.map(keys => this.$t(`${keys}`));
    },
    formatComparisonResult(key, oldValue, newValue) {
      switch (key) {
        case this.$t('page_url_patterns'):
          return this.formatPageUrlPatternsComparisonResult(oldValue, newValue);
        case this.$t('remove_hard_coded_crema_class_skins'):
          return this.formatHardCodedCreamaClassSkinsComparisonResult(
            oldValue,
            newValue
          );
        default:
          return `${oldValue} -> ${newValue}`;
      }
    },
    formatHardCodedCreamaClassSkinsComparisonResult(oldValue, newValue) {
      return [oldValue, newValue]
        .map(v => (v === '' ? '모든 스킨' : v))
        .filter(v => v)
        .join(' -> ');
    },
    formatPageUrlPatternsComparisonResult(oldValue, newValue) {
      const oldUrls = oldValue ? oldValue.split('\n') : [];
      const newUrls = newValue ? newValue.split('\n') : [];
      const maxLength = Math.max(oldUrls.length, newUrls.length);
      const comparisons = [''];

      for (let i = 0; i < maxLength; i++) {
        const oldUrl = oldUrls[i] || '';
        const newUrl = newUrls[i] || '';
        if (oldUrl || newUrl) {
          comparisons.push(`${oldUrl} -> ${newUrl}`);
        }
      }
      return comparisons.join('<br>');
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .logs-table__date {
    flex: 0 0 155px;
  }

  .logs-table__actor {
    flex: 0 0 170px;
  }
}
</style>

<i18n locale="ko">
{
  "popup_install_position": "작성 팝업 설치",
  "enable_widget_observe_install": "간편 설치 위젯 동적 감지 기능 사용",
  "use_score_widget_v2": "별점 아이콘 정렬 개선",
  "enable_remove_hard_coded_crema_class": "수동 설치된 크리마 요소 동적 제거",
  "remove_hard_coded_crema_class_skins": "수동 설치된 크리마 요소 동적 제거(적용 스킨)",
  "actor_content_html": "%{actor_email}<br>%{actor_ip}",
  "page_url_patterns": "페이지 URL 패턴 관리",
  "search_type": {
    "actor_email": "관리자 ID",
    "actor_ip": "관리자 IP",
    "setting_name": "설정명"
  },
  "column": {
    "date": "날짜",
    "actor": "관리자",
    "description": "상세 이력"
  }
}
</i18n>
