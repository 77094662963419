import _ from 'lodash';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import WidgetPageType from '@/enums/WidgetPageType';
import InstallSlotType from '@/enums/InstallSlotType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';

export default {
  computed: {
    ...mapState('installRequest', ['widgetsBySlotId']),
    ...mapGetters('installRequest', ['etcDirections']),
    etcDropdownMenuItems() {
      return [
        [
          {
            label: InstallSlotType.t(InstallSlotType.EVENT),
            clickHandler: () =>
              this.openInstallRequestRenewalSlotEtcAddDialog(
                WidgetPageType.EVENT
              )
          },
          {
            label: InstallSlotType.t(InstallSlotType.CATEGORY),
            clickHandler: () =>
              this.openInstallRequestRenewalSlotEtcAddDialog(
                WidgetPageType.PRODUCT_LIST
              )
          }
        ]
      ];
    },
    etcColumns() {
      return [
        {
          id: 'page_type_name',
          label: this.$t('install_request.renewal.etc.page_type'),
          width: '20%'
        },
        {
          id: 'custom_url',
          label: this.$t('install_request.renewal.etc.custom_url'),
          width: '30%'
        },
        {
          id: 'widget_to_install',
          type: 'html',
          label: this.$t('install_request.renewal.etc.widget_to_install'),
          width: '30%'
        },
        {
          id: 'actions',
          label: this.$t('app.actions'),
          width: '20%'
        }
      ];
    },
    etcRows() {
      const slotIdToDirections = _.groupBy(this.etcDirections, 'slot_id');
      return Object.entries(slotIdToDirections).map(([_key, directions]) => {
        const direction = directions[0];
        return {
          slot_id: direction.slot_id,
          page_type: direction.page_type,
          slot_type: direction.slot_type,
          custom_url: direction.custom_url,
          page_type_name: WidgetPageType.t(direction.page_type),
          widget_to_install: this.installationInfo(direction.slot_id)
        };
      });
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog', 'confirm']),
    ...mapMutations('installRequest', [
      'REMOVE_WIDGETS_BY_SLOT_ID',
      'REMOVE_DIRECTIONS_BY_SLOT_ID'
    ]),
    openInstallRequestRenewalSlotEtcAddDialog(pageType) {
      const slotType = this.getEtcInstallSlotType(pageType);
      this.openDialog([
        'InstallRequestRenewalSlotEtcAddDialog',
        { pageType, slotType }
      ]);
    },
    openInstallRequestRenewalSlotEtcEditDialog(pageType, slotType, slotId) {
      this.openDialog([
        'InstallRequestRenewalSlotEtcEditDialog',
        { pageType, slotType, slotId }
      ]);
    },
    getEtcInstallSlotType(pageType) {
      return pageType === WidgetPageType.EVENT
        ? InstallSlotType.EVENT
        : InstallSlotType.CATEGORY;
    },
    clickDeleteEtc(row) {
      return this.confirm({
        type: 'delete_confirm',
        title: this.$t('install_request.renewal.etc.delete_confirm.title'),
        message: this.$t('install_request.renewal.etc.delete_confirm.message', [
          row.page_type_name,
          row.custom_url
        ])
      }).then(result => {
        if (!result) return;

        this.REMOVE_WIDGETS_BY_SLOT_ID(row.slot_id);
        this.REMOVE_DIRECTIONS_BY_SLOT_ID(row.slot_id);
      });
    },
    slotWidgets(slotId) {
      return this.widgetsBySlotId[slotId] || [];
    },
    pcSlotInstallations(slotId) {
      return this.slotWidgets(slotId).filter(
        i => WidgetDeviceType.PC === i.widget_device_type && i.selected
      );
    },
    mobileSlotInstallations(slotId) {
      return this.slotWidgets(slotId).filter(
        i => WidgetDeviceType.MOBILE === i.widget_device_type && i.selected
      );
    },
    installationInfo(slotId) {
      const info = [];
      const pcInstallationStyles = this.pcSlotInstallations(slotId)
        .map(i => i.widget_style_name)
        .join(', ');
      const mobileInstallationStyles = this.mobileSlotInstallations(slotId)
        .map(i => i.widget_style_name)
        .join(', ');
      if (pcInstallationStyles !== '')
        info.push(`(${this.$t('app.pc')}) ${pcInstallationStyles}`);
      if (mobileInstallationStyles !== '')
        info.push(`(${this.$t('app.mobile')}) ${mobileInstallationStyles}`);

      return info.join(' / ');
    }
  }
};
