import { constructEnum } from '@/lib/enum';

const ReviewPointType = constructEnum('ReviewPointType', {
  NONE: -1,
  MILEAGE: 0,
  COUPON: 10,
  POINT: 20,
  DISCOUNT_CODE: 30
});

ReviewPointType.isCurrencyType = function(reviewPointType) {
  return (
    reviewPointType === ReviewPointType.MILEAGE ||
    reviewPointType === ReviewPointType.DISCOUNT_CODE
  );
};

export default ReviewPointType;
