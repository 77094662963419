<template>
  <AppModal :margin-vertical="16">
    <template #head>
      <AppImage
        v-if="!!imageUrl"
        class="TutorialGuideDialog__image"
        :src="imageUrl"
        :border-radius="8"
        type="rounded"
      />
      <div class="TutorialGuideDialog__title">{{ title }}</div>
    </template>
    <template #body>
      <div class="TutorialGuideDialog__body">
        <AppText
          class="TutorialGuideDialog__body-description"
          :text="description"
        />
        <AppExternalLinkWithIcon
          v-if="showDetailLink"
          class="TutorialGuideDialog__body-link"
          :label="$t('detail_link')"
          :to="detailLink"
        />
      </div>
    </template>
    <template #foot>
      <div class="TutorialGuideDialog__foot">
        <AppButton
          class="TutorialGuideDialog__foot-continue-button"
          :label="isLast ? $t('button.done') : $t('button.continue')"
          size="narrow"
          @click="clickContinueButton"
        />
        <AppButton
          v-if="showSkipButton"
          class="TutorialGuideDialog__foot-skip-button"
          :label="$t('button.skip')"
          button-style="label"
          size="narrow"
          @click="clickSkipButton"
        />
      </div>
    </template>
  </AppModal>
</template>

<script>
import DialogView from '@/mixins/DialogView';

export default {
  name: 'TutorialGuideDialog',
  mixins: [DialogView],
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    detailLink: {
      type: String,
      default: ''
    },
    showDetailLink: {
      type: Boolean,
      default: false
    },
    imageUrl: {
      type: String,
      default: ''
    },
    showSkipButton: {
      type: Boolean,
      default: false
    },
    eventBus: {
      type: Object,
      required: true
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickContinueButton() {
      this.eventBus.$emit('continue');
    },
    clickSkipButton() {
      alert('skip');
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/vars/_colors.scss';

::v-deep .AppModal__backdrop {
  position: fixed;
  background-color: transparent;
}

::v-deep .AppModal__wrapper {
  width: 359px;
  border-radius: 12px;
  padding: 16px 20px;
  background-color: $color-grey-80;
}

.TutorialGuideDialog__image {
  display: block;
  padding: 4px 0 16px 0;
}

.TutorialGuideDialog__title {
  @include text-content;
  font-weight: bold;
  color: $color-grey-05;
  padding: 0 4px 16px 4px;
}

.TutorialGuideDialog__body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 4px 4px 4px;
}

.TutorialGuideDialog__body-description {
  @include text-content;
  color: $color-grey-15;
}

.TutorialGuideDialog__body-link {
  @include text-caption;
  width: fit-content;
  color: $color-review-white;
  opacity: 80%;

  &:hover {
    opacity: 100%;
  }
}

.TutorialGuideDialog__foot {
  display: flex;
  gap: 4px;
  padding: 16px 4px 0 4px;
}

.TutorialGuideDialog__foot-continue-button {
  ::v-deep .AppButton__button {
    border: 0;
    background-color: rgba(255, 255, 255, 0.2);
    color: $color-review-white;

    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }
}

.TutorialGuideDialog__foot-skip-button {
  ::v-deep .AppButton__button {
    color: rgba(255, 255, 255, 0.8);
    font-weight: normal;

    &:hover {
      color: $color-review-white;
    }
  }
}

::v-deep .AppButton + .AppButton {
  margin-left: 0;
}
</style>

<i18n locale="ko">
{
  "detail_link": "상세 안내 문서",
  "button": {
    "done": "완료",
    "continue": "계속",
    "skip": "다음에 진행"
  }
}
</i18n>
