import { render, staticRenderFns } from "./ChatbotMessageProfileDialog.vue?vue&type=template&id=0b38ad0d&scoped=true&"
import script from "./ChatbotMessageProfileDialog.vue?vue&type=script&lang=js&"
export * from "./ChatbotMessageProfileDialog.vue?vue&type=script&lang=js&"
import style0 from "./ChatbotMessageProfileDialog.vue?vue&type=style&index=0&id=0b38ad0d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b38ad0d",
  null
  
)

/* custom blocks */
import block0 from "./ChatbotMessageProfileDialog.vue?vue&type=custom&index=0&blockType=i18n&locale=ko"
if (typeof block0 === 'function') block0(component)

export default component.exports