<template>
  <div class="ReviewImportSmartstore">
    <AppAjaxContent :is-loading="isLoading">
      <template #default>
        <template v-if="isOnboarding">
          <SmartstoreProgressSteps />
          <Component :is="currentOnboardingComponent" />
        </template>
        <template v-else>
          <AppSummaryBox
            v-if="displaySummaryBox"
            class="ReviewImportSmartstore__summary"
          >
            <template #title
              ><AppBadge
                :badge-style="currentSummaryBoxInfo['badgeStyle']"
                :label="currentSummaryBoxInfo['badgeLabel']"/>
              <!-- eslint-disable vue/no-v-html -->
              <span
                class="ReviewImportSmartstore__summary_title"
                v-html="currentSummaryBoxInfo['title']"
            /></template>
            <AppMarked
              v-if="currentSummaryBoxInfo['message']"
              :text="currentSummaryBoxInfo['message']"
            />
            <template v-if="currentSummaryBoxInfo['buttonShowable']" #buttons>
              <a
                class="ReviewImportSmartstore__summary_button"
                @click="openSmartstoreNameDialog"
              >
                {{ $t('smartstore_name') }}
              </a>
            </template>
          </AppSummaryBox>
          <AppTabContent menu-item-id="review_import_smartstore" />
        </template>
      </template>
    </AppAjaxContent>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import SmartstoreStatus from '@/enums/SmartstoreStatus';
import SmartstoreProgressSteps from './components/SmartstoreProgressSteps';
import SmartstoreAgreement from './components/SmartstoreAgreement';
import SmartstoreProductMatch from './components/SmartstoreProductMatch';
import SmartstorePayment from './components/SmartstorePayment';
import SmartstorePaymentExpired from './components/SmartstorePaymentExpired';
import SmartstoreEstimating from './components/SmartstoreEstimating';
import SmartstoreImporting from './components/SmartstoreImporting';
import TutorialReviewImportHelper from '@/mixins/tutorial/TutorialReviewImportHelper';

export default {
  name: 'ReviewImportSmartstore',
  components: {
    SmartstoreProgressSteps,
    SmartstoreAgreement,
    SmartstoreProductMatch,
    SmartstorePayment,
    SmartstorePaymentExpired,
    SmartstoreEstimating,
    SmartstoreImporting
  },
  mixins: [TutorialReviewImportHelper],
  data() {
    return { isLoading: true };
  },
  computed: {
    ...mapState('smartstore', [
      'smartstoreStatus',
      'smartstoreName',
      'smartstoreExpiresAt',
      'enableSmartstoreReviewSync'
    ]),
    isOnboarding() {
      return this.smartstoreStatus < SmartstoreStatus.OPERATING;
    },
    currentOnboardingComponent() {
      switch (this.smartstoreStatus) {
        case SmartstoreStatus.NONE:
          return SmartstoreAgreement;
        case SmartstoreStatus.PRODUCT_MATCH_PENDING:
        case SmartstoreStatus.PRODUCT_MATCH_STARTED:
        case SmartstoreStatus.PRODUCT_MATCH_FAILED:
        case SmartstoreStatus.PRODUCT_MATCH_FINISHED:
          return SmartstoreProductMatch;
        case SmartstoreStatus.ESTIMATION_QUEUED:
        case SmartstoreStatus.ESTIMATION_STARTED:
        case SmartstoreStatus.ESTIMATION_FAILED:
          return SmartstoreEstimating;
        case SmartstoreStatus.PAYMENT_PENDING:
          return SmartstorePayment;
        case SmartstoreStatus.PAYMENT_EXPIRED:
          return SmartstorePaymentExpired;
        default:
          // SmartstoreStatus.STARTER_IMPORT_QUEUED
          // SmartstoreStatus.STARTER_IMPORT_STARTED
          // SmartstoreStatus.STARTER_IMPORT_FAILED
          return SmartstoreImporting;
      }
    },
    displaySummaryBox() {
      return [SmartstoreStatus.OPERATING, SmartstoreStatus.EXPIRED].includes(
        this.smartstoreStatus
      );
    },
    currentSummaryBoxInfo() {
      if (
        this.enableSmartstoreReviewSync &&
        moment(this.smartstoreExpiresAt) > moment()
      )
        return {
          badgeStyle: 'mint-green',
          badgeLabel: this.$t('operating.badge_label'),
          title: this.$t('operating.title', [this.smartstoreName]),
          message: this.$t('operating.message'),
          buttonShowable: true
        };
      else if (moment(this.smartstoreExpiresAt) < moment())
        return {
          badgeStyle: 'mango',
          badgeLabel: this.$t('expired.badge_label'),
          title: this.$t('expired.title', [this.smartstoreName]),
          message: this.$t('expired.message'),
          buttonShowable: true
        };
      else
        return {
          badgeStyle: 'default',
          badgeLabel: this.$t('not_used.badge_label'),
          title: this.$t('not_used.title', [this.smartstoreName]),
          buttonShowable: false
        };
    }
  },
  mounted() {
    this.fetchSmartstoreStatus().finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('smartstore', ['fetchSmartstoreStatus']),
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('smartstore', ['SET_SMARTSTORE_NAME']),
    openSmartstoreNameDialog() {
      this.openDialog([
        'TheSmartstoreNameDialog',
        { smartstoreName: this.smartstoreName }
      ]).then(eventBus => {
        eventBus.$on('save', smartstore_name => {
          this.SET_SMARTSTORE_NAME(smartstore_name);
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.ReviewImportSmartstore__summary {
  margin-bottom: 28px;
}

.ReviewImportSmartstore__summary_title {
  vertical-align: middle;
}

.ReviewImportSmartstore__summary_button {
  color: $color-grey-40;
  display: block;
  text-decoration: underline;
}
</style>

<i18n locale="ko">
{
  "operating": {
    "badge_label": "매일연동 사용중",
    "title": " 현재 연동중인 스마트스토어는 <span style='color: #3B9E78'>{0}</span>입니다.",
    "message": "매일 리뷰 연동 사용 중에는 대행사 권한이 제거되면 연동이 진행되지 않습니다.<br />연동하신 스마트스토어의 이름이 변경된 경우 크리마의 저장된 정보를 변경하고 대행사 권한을 새로 추가해주세요."
  },
  "expired": {
    "badge_label": "매일연동 만료됨",
    "title": " 최근 연동한 스마트스토어는 <span style='color: #3B9E78'>{0}</span>입니다.",
    "message": "매일 리뷰 연동 사용기한이 만료되었으며, 다시 사용하시려면 재결제 하셔야합니다.<br />매일 리뷰 연동 사용 중에는 대행사 권한이 제거되면 연동이 진행되지 않습니다.<br />연동하신 스마트스토어의 이름이 변경된 경우 크리마의 저장된 정보를 변경하고 대행사 권한을 새로 추가해주세요."
  },
  "not_used": {
    "badge_label": "매일연동 미사용",
    "title": " 과거 리뷰를 연동한 스마트스토어는 <span style='color: #3B9E78'>{0}</span>입니다."
  },
  "smartstore_name": "스마트스토어 이름 변경하기"
}
</i18n>
