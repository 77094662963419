<template>
  <div :class="isSeparateButtons ? 'AppButtonToggleGroup--separated' : ''">
    <AppInputGroupItem v-for="button in buttons" :key="button.id">
      <AppButtonToggle v-bind="button" @change="$emit('select', button.id)" />
    </AppInputGroupItem>
  </div>
</template>

<script>
export default {
  name: 'AppButtonToggleGroup',
  props: {
    buttons: { type: Array, required: true },
    isSeparateButtons: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss" scoped>
.AppButtonToggleGroup {
  &--separated {
    display: flex;
    gap: 8px;

    ::v-deep {
      .AppButton__button {
        border-radius: 3px !important;
      }
    }
  }
}
</style>
