<template>
  <AppAjaxContent :is-loading="isLoading">
    <div class="ReviewNewReviews">
      <ReviewNewReviewsTitleSection
        v-model="resourceParams.recent_days"
        @change="searchResource"
      />
      <AppScrollBox>
        <ReviewNewReviewsDashboard v-bind="{ startDate, endDate }" />
      </AppScrollBox>
      <AppTabContent
        menu-item-id="review_new_reviews"
        class="ReviewNewReviews__tab-content"
      >
        <AppSearchbar @submit="searchResource">
          <template #right>
            <AppSearchWithType
              v-model="searchWithType"
              :search-types="SEARCH_TYPES"
            />
          </template>
        </AppSearchbar>
        <AppResourceTable
          table-id="new-reviews-table"
          enable-refresh
          enable-column-settings
          :columns="columns"
          :rows="rows"
          :resources="reviews"
          :custom-buttons="[sortButton]"
          :batch-buttons="batchButtons"
          @refresh="refreshResource"
          @paginate="paginate"
          @select-rows="selectedReviews = $event"
        >
          <template #cell="{row, column}">
            <ReviewReviewsDateCell v-if="column === 'date'" :review="row" />
            <ReviewReviewsCustomerCell
              v-else-if="column === 'customer'"
              :review="row"
            />
            <ReviewReviewsProductCell
              v-else-if="column === 'product'"
              :review="row"
            />
            <ReviewReviewsReviewCell
              v-else-if="column === 'review'"
              :review="row"
            />
            <ReviewReviewsStatusCell
              v-else-if="column === 'status'"
              :review="row"
            />
            <ReviewReviewsTagsCell
              v-else-if="column === 'tags'"
              :review="row"
            />
            <ReviewReviewsAiRecommendationScoreCell
              v-else-if="column === 'ai_recommendation_score'"
              :review="row"
            />
            <ReviewNewReviewsActionsCell
              v-else-if="column === 'actions'"
              :review="row"
              :resource-params="orgResourceParams"
            />
          </template>
        </AppResourceTable>
      </AppTabContent>
    </div>
  </AppAjaxContent>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import ResourceView from '@/mixins/ResourceView';
import ReviewPointTypeHelper from '@/mixins/ReviewPointTypeHelper';
import ReviewBatchHelper from '@/mixins/ReviewBatchHelper';

export default {
  name: 'ReviewNewReviews',
  mixins: [ResourceView, ReviewPointTypeHelper, ReviewBatchHelper],
  data() {
    return {
      SEARCH_TYPES: [
        'user_id',
        'user_name',
        'phone_number',
        'product_name',
        'review_id',
        'order_code',
        'review_message',
        'product_code',
        'product_id'
      ].map(value => ({
        value,
        label: this.$t(`review_search_type.${value}`)
      }))
    };
  },
  computed: {
    ...mapState('session', ['brandSettings', 'reviewSettings']),
    ...mapState('review', ['reviews']),
    ...mapGetters('review', ['isFetchingReviews']),
    ...mapGetters('session', ['canSaveMileage', 'canManageComment']),
    defaultResourceParams() {
      return {
        recent_days: 7,
        per: '20',
        page: '1',
        date_type: 'review_created_at',
        search_type: 'product_name'
      };
    },
    startDate() {
      return moment()
        .subtract(this.resourceParams.recent_days, 'days')
        .format('YYYY. MM. DD.');
    },
    endDate() {
      return moment().format('YYYY. MM. DD.');
    },
    isLoading() {
      return this.reviews.isNull || this.isFetchingReviews;
    },
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    columns() {
      return [
        { id: 'row_select', type: 'row_select', required: true },
        { id: 'id', label: this.$t('table_header.id'), hideByDefault: true },
        {
          id: 'position',
          label: this.$t('table_header.position'),
          hideByDefault: true
        },
        {
          id: 'date',
          label: this.$t('table_header.date'),
          required: true
        },
        {
          id: 'customer',
          label: this.$t('table_header.customer'),
          required: true
        },
        {
          id: 'product',
          label: this.$t('app.product'),
          required: true
        },
        {
          id: 'product_code',
          label: this.$t('products.code'),
          hideByDefault: true
        },
        this.brandSettings.show_sub_brand
          ? {
              id: 'sub_brand_name',
              label: this.$t('table_header.sub_brand_name'),
              hideByDefault: true
            }
          : null,
        { id: 'review', label: this.$t('table_header.review'), required: true },
        { id: 'status', label: this.$t('app.status') },
        { id: 'tags', label: this.$t('table_header.tags') },
        {
          id: 'ai_recommendation_score',
          label: this.$t('table_header.ai_recommendation_score'),
          tooltip: this.$t('table_header.ai_recommendation_score_tooltip'),
          hideByDefault: this.resourceParams.sort !== 'ai_recommendation'
        },
        {
          id: 'actions',
          label: this.$t('table_header.actions'),
          required: true
        }
      ].filter(c => c);
    },
    rows() {
      const positionStart =
        this.reviews.total_count - (this.reviews.page - 1) * this.reviews.per;
      return this.reviews.items.map((review, index) => {
        const { review_index, photo_review_index } = review;
        const nthReview =
          review_index !== null &&
          review_index < this.reviewSettings.give_mileage_nth_reviews.length
            ? review_index + 1
            : null;
        const nthPhotoReview =
          photo_review_index !== null &&
          photo_review_index <
            this.reviewSettings.give_mileage_nth_photo_reviews.length
            ? photo_review_index + 1
            : null;
        return {
          ...review,
          position: positionStart - index,
          product_code: review.product ? review.product.code : null,
          nthReview,
          nthPhotoReview
        };
      });
    },
    sortButton() {
      const betaBadge = { label: this.$t('app.beta'), badgeStyle: 'red' };
      const options = [
        'created_at_desc',
        'created_at_asc',
        'plus_likes_count_desc',
        'score_asc',
        'delivered_at_desc',
        'ai_recommendation'
      ];
      const defaultValue =
        this.resourceParams.tab === 'ai_best_review'
          ? 'ai_recommendation'
          : 'created_at_desc';

      return {
        icon: 'icon-sort',
        controlType: 'select',
        options: options.map(value => ({
          label: this.$t(`button.sort.${value}`),
          value,
          badge: value === 'ai_recommendation' ? betaBadge : null
        })),
        value: options.includes(this.$route.query.sort)
          ? this.$route.query.sort
          : defaultValue,
        changeHandler: this.sortButtonChanged
      };
    },
    batchButtons() {
      return [
        ...(this.canSaveMileage
          ? [
              {
                label: this.$t('review_batch.give_mileage'),
                tooltip: this.$t('review_batch.give_mileage_tooltip'),
                clickHandler: this.batchGiveMileage
              },
              {
                label: this.$t('review_batch.deduct_mileage'),
                tooltip: this.$t('review_batch.deduct_mileage_tooltip'),
                clickHandler: this.batchCancelMileage
              }
            ]
          : []),
        ...(this.canManageComment
          ? [
              {
                label: this.$t('review_batch.comment'),
                tooltip: this.$t('review_batch.comment_tooltip'),
                clickHandler: this.batchComment
              }
            ]
          : []),
        {
          label: this.$t('review_batch.show'),
          tooltip: this.$t('review_batch.show_tooltip'),
          clickHandler: this.batchShow
        },
        {
          label: this.$t('review_batch.hide'),
          tooltip: this.$t('review_batch.hide_tooltip'),
          clickHandler: this.batchHide
        },
        {
          label: this.$t('review_batch.tag'),
          tooltip: this.$t('review_batch.tag_tooltip'),
          clickHandler: this.batchTag
        },
        {
          label: this.$t('app.delete'),
          tooltip: this.$t('review_batch.delete_tooltip'),
          clickHandler: this.batchDelete
        }
      ];
    }
  },
  beforeDestroy() {
    this.resetReviews();
  },
  methods: {
    ...mapActions('review', ['fetchNewReviews', 'resetReviews']),
    fetchResource(params) {
      this.fetchNewReviews(params);
    },
    sortButtonChanged(newValue) {
      this.resourceParams = {
        ...this.resourceParams,
        sort: newValue
      };
      this.searchResource();
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewNewReviews__tab-content {
  margin-top: 30px;
  min-width: 1110px;
  max-width: 1330px;
}

::v-deep {
  .AppExternalLinkIcon {
    margin-left: 2px;
  }

  .new-reviews-table__id {
    flex: 1 0 80px;
  }

  .new-reviews-table__position {
    flex: 1 0 80px;
  }

  .new-reviews-table__date {
    flex: 13 0 132px;
  }

  .new-reviews-table__customer {
    flex: 13 0 136px;
  }

  .new-reviews-table__product {
    flex: 16 0 180px;
  }

  .new-reviews-table__product-code {
    flex: 0 0 92px;
  }

  .new-reviews-table__sub-brand-name {
    flex: 10 0 120px;
  }

  .new-reviews-table__review {
    flex: 36 0 236px;
  }

  .new-reviews-table__status {
    flex: 10 0 100px;

    &--body {
      min-height: 0;
    }
  }

  .new-reviews-table__tags {
    flex: 12 0 120px;

    &--body {
      min-height: 0;
    }
  }

  .new-reviews-table__ai-recommendation-score {
    flex: 1 0 102px;
  }

  .new-reviews-table__actions {
    text-align: right;
    flex: 1 0 272px;
  }

  .new-reviews-table--coupon .new-reviews-table__actions {
    flex: 1 0 359px;
  }
}
</style>

<i18n locale="ko">
{
  "review_search_type": {
    "user_id": "고객 ID",
    "user_name": "고객 이름",
    "phone_number": "고객 전화번호",
    "product_name": "상품명",
    "review_id": "리뷰 ID",
    "order_code": "주문번호",
    "review_message": "리뷰내용",
    "product_code": "상품 번호",
    "product_id": "상품 ID"
  },
  "table_header": {
    "id": "ID",
    "position": "번호",
    "date": "작성일",
    "customer": "고객정보",
    "review": "리뷰 상세 내용",
    "tags": "태그",
    "actions": "{point_type} 지급/차감 및 리뷰관리",
    "sub_brand_name": "서브 브랜드",
    "ai_recommendation_score": "AI 추천 점수",
    "ai_recommendation_score_tooltip": "크리마 AI가 분석한 리뷰의 추천 점수입니다.\n100점에 가까울수록 우수한 리뷰입니다."
  },
  "button": {
    "sort": {
      "created_at_desc": "최신순",
      "created_at_asc": "작성일순",
      "plus_likes_count_desc": "도움이 많이된 순",
      "score_asc": "별점 낮은순",
      "delivered_at_desc": "최근 배송완료된 순",
      "ai_recommendation": "AI 추천순"
    }
  }
}
</i18n>
