<template>
  <div class="ReviewStatCard">
    <div class="ReviewStatCard__icon">
      <AppSvgIcon :name="icon" />
    </div>
    <div class="ReviewStatCard__container">
      <div class="ReviewStatCard__label">
        {{ label }}
        <span v-if="valueInfo" class="ReviewStatCard__value-info"
          >{{ valueInfo }}
        </span>
      </div>
      <div class="ReviewStatCard__content">
        <div class="ReviewStatCard__value">
          <span class="ReviewStatCard__current-value">{{ currentValue }}</span
          ><span v-if="totalValue" class="ReviewStatCard__total-value"
            >/{{ totalValue }}
          </span>
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewStatCard',
  props: {
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: [Number, Object],
      required: true
    }
  },
  computed: {
    currentValue() {
      return this.value?.currentValue || this.value;
    },
    totalValue() {
      return this.value?.totalValue;
    },
    valueInfo() {
      return this.value?.valueInfo;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_breakpoints.scss';

.ReviewStatCard {
  display: flex;
  height: 72px;
  min-width: 255px;
  max-width: 310px;
  border-radius: 10px;
  border: 1px solid $color-grey-25;
  background-color: $color-review-white;

  & + & {
    margin-top: 10px;
  }

  @include media-breakpoint-each(mobile) {
    min-width: auto;
    max-width: none;
  }
}

.ReviewStatCard__icon {
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReviewStatCard__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.ReviewStatCard__label {
  @include text-caption-bold-dark;
}

.ReviewStatCard__value-info {
  @include text-caption-dark;
}

.ReviewStatCard__content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ReviewStatCard__current-value {
  @include text-modal-title;
}

.ReviewStatCard__total-value {
  @include text-modal-title;
  font-weight: normal;
}

::v-deep {
  .AppSvgIcon {
    stroke: none;
  }
}
</style>

<i18n locale="ko">
{
  "label": "신규 리뷰",
  "content": "10"
}
</i18n>
