<template>
  <div class="ReviewReviewsTagsCell">
    <div
      v-for="tag in review.tags"
      :key="tag.id"
      class="table-line table-line--mt4"
    >
      <AppBadge badge-style="grey-outline" :label="tag.name" />
    </div>
    <div class="table-line table-line--mt8">
      <a class="ReviewReviewsTagsCell__new-tag-link" @click="editTags(review)">
        {{ $t('new_tag') }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ReviewReviewsTagsCell',
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    editTags(review) {
      this.openDialog(['ReviewReviewsTagsDialog', { review }]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.ReviewReviewsTagsCell__new-tag-link {
  color: $color-blue;
  cursor: pointer;
}
</style>

<i18n locale="ko">
{
  "new_tag": "+태그 추가"
}
</i18n>

<i18n locale="en">
{
  "new_tag": "+Add Tag"
}
</i18n>
