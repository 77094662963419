<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title', { count: reviews.length })"
    :form-props="{
      ...formProps,
      submitLabel: $t('submit_label')
    }"
    v-on="formEvents"
  >
    <template #group="{ id, inputId, type, digits, error }">
      <div v-if="id === 'mileage_amount'" class="AppForm__group-field">
        <AppSelectRadio
          v-model="batchMileage.use_calculated"
          :options="[
            { label: $t('use_calculated'), value: true },
            { value: false }
          ]"
          @change="validateField(id)"
        >
          <template #label="{ value, checked }">
            <AppCurrencyInput
              v-if="value === false && type === 'currency'"
              v-model="batchMileage.mileage_amount"
              :invalid="!!error"
              :disabled="!checked"
              class="app-input-inline"
              :digits="digits"
              @change="validateField(id)"
            />
            <AppNumberInput
              v-else-if="value === false && type === 'number'"
              v-model="batchMileage.mileage_amount"
              :invalid="!!error"
              :disabled="!checked"
              class="app-input-inline"
              :digits="digits"
              @change="validateField(id)"
            />
          </template>
        </AppSelectRadio>
      </div>
      <template v-else-if="id === 'mileage_message'">
        <div class="AppForm__group-field AppForm__group-field--right">
          <AppCheckbox
            v-model="batchMileage.save_mileage_message"
            :label="$t('save_mileage_message')"
          />
        </div>
        <div class="AppForm__group-field">
          <AppTextInput
            :id="inputId"
            v-model="batchMileage.mileage_message"
            v-focus
            :invalid="!!error"
            @change="validateField(id)"
          />
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import DialogSize from '@/enums/DialogSize';
import ReviewPointType from '@/enums/ReviewPointType';
import DialogFormView from '@/mixins/DialogFormView';
import ReviewPointTypeHelper from '@/mixins/ReviewPointTypeHelper';
import ReviewMileageMessageValidationHelper from '@/mixins/ReviewMileageMessageValidationHelper';
import ManualMileageMaxAmountConfirmShowable from '@/mixins/ManualMileageMaxAmountConfirmShowable';

export default {
  name: 'ReviewReviewsBatchMileageDialog',
  mixins: [
    DialogFormView,
    ReviewPointTypeHelper,
    ReviewMileageMessageValidationHelper,
    ManualMileageMaxAmountConfirmShowable
  ],
  props: {
    reviews: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      orgFormObject: {
        use_calculated: true,
        mileage_amount: null,
        save_mileage_message: false,
        mileage_message: null
      },
      detectFormDataChange: false
    };
  },
  computed: {
    ...mapState('session', ['reviewSettings']),
    ...mapGetters('session', ['showMileageConfirm', 'currencyKey']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'mileage_amount',
              label: this.$t('mileage_amount'),
              type:
                this.reviewSettings.review_point_type ===
                ReviewPointType.MILEAGE
                  ? 'currency'
                  : 'number',
              digits: 6,
              validate: this.batchMileage.use_calculated ? [] : ['required']
            },
            {
              id: 'mileage_message',
              type: 'text',
              label: this.$t('mileage_message'),
              description: this.$t('mileage_message_description', {
                product_name: '%{product_name}',
                product_options: '%{product_options}'
              }),
              hint: this.$t('mileage_message_hint', {
                product_name: '%{product_name}',
                product_options: '%{product_options}'
              }),
              validate: [
                'required',
                this.mileageMessageBytesValidation,
                this.mileageMessageTemplateValidation
              ].filter(v => v)
            }
          ]
        }
      ];
    },
    batchMileage() {
      return this.formObject;
    }
  },
  mounted() {
    this.batchMileage.mileage_message = this.reviewSettings.default_batch_mileage_message;
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    submit() {
      if (this.showMileageConfirm && !confirm(this.$t('mileage_confirm')))
        return;

      if (
        this.isManualMileageMaxAmountConfirmNeeded(
          this.batchMileage.mileage_amount
        )
      ) {
        return this.showManualMileageMaxAmountConfirm();
      }

      this.emit('submit', { ...this.batchMileage });
      this.close();
    },
    showManualMileageMaxAmountConfirm() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'check_confirm',
          title: this.$t('check_confirm.title'),
          markdownText: this.$t('check_confirm.markdown_text'),
          checkboxLabel: this.$t('check_confirm.checkbox_label', [
            this.formatCurrency(this.batchMileage.mileage_amount)
          ]),
          closeButtonLabel: this.$t('check_confirm.close_button_label'),
          cancelButtonLabel: this.$t('check_confirm.cancel_button_label'),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => {
          this.emit('submit', { ...this.batchMileage });
          this.close();
        });
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "{point_type} 지급",
  "sub_title": "선택 리뷰 {count}개",
  "mileage_amount": "{point_type} 금액",
  "mileage_confirm": "{point_type} 지급 시뮬레이션 설정이 OFF 상태입니다.\n실제로 {point_type}((이)) 지급됩니다. 진행하시겠습니까?",
  "use_calculated": "선택한 리뷰 각각의 예상{point_type}대로 지급",
  "mileage_message": "{point_type} 지급 사유",
  "mileage_message_description": "원활한 적립금 지급을 위해 지급 사유에 예약어 {product_name}과 {product_options}이 반드시 포함되어야 합니다.",
  "mileage_message_hint": "{product_name}은 상품명, {product_options}는 옵션명으로 대체됩니다.",
  "save_mileage_message": "지급 사유 입력값 저장",
  "submit_label": "{point_type} 지급",
  "check_confirm": {
    "title": "평소보다 큰 금액이 입력되었습니다.",
    "markdown_text": "지급하려는 금액이 맞는지 확인해주세요.<br>아래의 체크박스를 누르면 입력된 금액을 지급할 수 있습니다.<br>만약 잘못 입력하셨다면 닫기 버튼을 눌러주세요.",
    "checkbox_label": "{0}을 지급하겠습니다.",
    "close_button_label": "적립금 지급",
    "cancel_button_label": "닫기"
  }
}
</i18n>
