import { constructEnum } from '@/lib/enum';

const ReviewCampaignType = constructEnum('ReviewCampaignType', {
  MEMBER_REVIEW: 10,
  NTH_STEP_REVIEW: 20,

  LEGACY_MEMBER: -3,
  LEGACY_NONMEMBER: -2,
  LEGACY_OFFLINE: -1
});

ReviewCampaignType.LEGACY_CAMPAIGN_TYPES = [
  ReviewCampaignType.LEGACY_MEMBER,
  ReviewCampaignType.LEGACY_NONMEMBER,
  ReviewCampaignType.LEGACY_OFFLINE
];

ReviewCampaignType.campaignTypeOptions = ReviewCampaignType.options().filter(
  ({ value }) => !ReviewCampaignType.LEGACY_CAMPAIGN_TYPES.includes(value)
);

export default ReviewCampaignType;
