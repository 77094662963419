<template>
  <div class="ReviewCautionReviewsVipNegativeReviews">
    <div class="ReviewCautionReviewsVipNegativeReviews__title">
      <span>{{ $t('title') }}</span>
    </div>
    <div class="ReviewCautionReviewsVipNegativeReviews__contents">
      <div class="ReviewCautionReviewsVipNegativeReviews__sub-title">
        {{ $t('sub_title.first') }}
      </div>
      <div
        class="ReviewCautionReviewsVipNegativeReviews__negative-reviews-count"
      >
        {{ negativeReviewsCount }}
      </div>
      <div class="ReviewCautionReviewsVipNegativeReviews__divider" />
      <div class="ReviewCautionReviewsVipNegativeReviews__sub-title">
        {{ $t('sub_title.first') }}
      </div>
      <div class="ReviewCautionReviewsVipNegativeReviews__vip-criteria">
        {{
          $t('vip_criteria', {
            amount: vipCriteria.amount,
            percentage: vipCriteria.percentage
          })
        }}
      </div>
      <div
        class="ReviewCautionReviewsVipNegativeReviews__see-all-button-container"
      >
        <AppButton
          class="ReviewCautionReviewsVipNegativeReviews__see-all-button"
          :label="$t('see_all_button')"
          size="small"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewCautionReviewsVipNegativeReviews',
  props: {
    negativeReviewsCount: {
      type: Number,
      required: true
    },
    vipCriteria: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewCautionReviewsVipNegativeReviews {
  min-width: 255px;
  max-width: 310px;
  padding: 18px 22px;
  border-radius: 10px;
  border: 1px solid $color-grey-25;
  background-color: $color-review-white;
}

.ReviewCautionReviewsVipNegativeReviews__title {
  @include text-caption-bold-dark;
}

.ReviewCautionReviewsVipNegativeReviews__contents {
  margin-top: 27px;
}

.ReviewCautionReviewsVipNegativeReviews__sub-title {
  @include text-caption-tiny-dark;
}

.ReviewCautionReviewsVipNegativeReviews__negative-reviews-count {
  @include text-modal-title;
}

.ReviewCautionReviewsVipNegativeReviews__divider {
  background-color: $color-grey-32;
  margin: 7px 0;
  height: 1px;
  width: 100%;
}

.ReviewCautionReviewsVipNegativeReviews__see-all-button-container {
  display: flex;
  margin-top: 28px;
  width: 100%;
  justify-content: center;
}

.ReviewCautionReviewsVipNegativeReviews__see-all-button {
  @include text-caption-dark;
}
</style>

<i18n locale="ko">
{
  "title": "매출액 상위 고객의 부정 리뷰",
  "sub_title": {
    "first": "매출액 상위 고객의 부정 리뷰",
    "second": "매출액 상위 고객 기준"
  },
  "vip_criteria": "매출액 {amount}원 이상 (상위 {percentage}%)",
  "see_all_button": "모아보기"
}
</i18n>
