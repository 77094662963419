var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppRadioLabel',_vm._b({class:['AppRadio', _vm.inputStyle ? ("AppRadio--" + _vm.inputStyle) : null],attrs:{"checked":_vm.isChecked},scopedSlots:_vm._u([(_vm.$scopedSlots.label)?{key:"label",fn:function(labelProps){return [_vm._t("label",null,null,labelProps)]}}:null,(_vm.$scopedSlots['sub-item'])?{key:"sub-item",fn:function(subItemProps){return [_vm._t("sub-item",null,null,subItemProps)]}}:null],null,true)},'AppRadioLabel',{
    detailButtonClickHandler: _vm.detailButtonClickHandler,
    disabled: _vm.disabled,
    label: _vm.label,
    inputStyle: _vm.inputStyle,
    inputPosition: _vm.inputPosition,
    tooltip: _vm.tooltip,
    value: _vm.value
  },false),[_c('input',{class:['AppRadio__input', { 'AppRadio__input--invalid': _vm.invalid }],attrs:{"name":_vm.name,"disabled":_vm.disabled,"type":"radio"},domProps:{"checked":_vm.isChecked,"value":_vm.value},on:{"input":function($event){return _vm.$emit('change', _vm.value)}}})])}
var staticRenderFns = []

export { render, staticRenderFns }