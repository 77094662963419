import $ from 'jquery';

import { getVerticallyScrollableParent } from '@/lib/scroll';

function getScrollInfo(targetElement) {
  if (!targetElement) return { scrollableParent: null, scrollTop: 0 };

  const scrollableParent = getVerticallyScrollableParent(targetElement);
  const scrollableParentY = scrollableParent.getBoundingClientRect().top;
  const targetElementY = targetElement.getBoundingClientRect().top;
  const scrollTop =
    targetElementY + scrollableParent.scrollTop - scrollableParentY;
  return { scrollableParent, scrollTop };
}

export default {
  methods: {
    scrollToTop(targetElement) {
      const { scrollableParent, scrollTop } = getScrollInfo(targetElement);
      if (
        targetElement.getBoundingClientRect().top <
        scrollableParent.getBoundingClientRect().top
      ) {
        this.scrollToPosition(scrollableParent, { scrollTop });
      }
    },
    scrollToPosition(scrollableParent, position, duration) {
      return new Promise(resolve =>
        $(scrollableParent).animate(position, duration, 'swing', resolve)
      );
    },
    scrollToElement(element, args) {
      const scrollInfo = getScrollInfo(element);
      const scrollTop =
        scrollInfo.scrollTop + (args ? args.scrollOffsetTop : 0);
      return this.scrollToPosition(scrollInfo.scrollableParent, { scrollTop });
    },
    scrollIntoView(element) {
      const scrollInfo = getScrollInfo(element);
      const scrollable = scrollInfo.scrollableParent;

      const scrollableRect = scrollable.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();

      let scrollTop = null;
      if (elementRect.top < scrollableRect.top)
        scrollTop = scrollInfo.scrollTop;
      else if (scrollableRect.bottom < elementRect.bottom)
        scrollTop =
          scrollInfo.scrollTop - scrollableRect.height + elementRect.height;

      if (scrollTop != null)
        this.scrollToPosition(scrollable, { scrollTop }, 100);
    }
  }
};
