var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.subMenuItem.disabled ? _vm.disableTooltip(_vm.subMenuItem.id) : null),expression:"subMenuItem.disabled ? disableTooltip(subMenuItem.id) : null"}],class:[
    'SubMenuItem',
    {
      'SubMenuItem--active': _vm.subMenuItem.id === _vm.selectedSubMenuItemId,
      'SubMenuItem--2depth': _vm.is2Depth,
      'SubMenuItem--disabled': _vm.subMenuItem.disabled,
      'super-admin__item': _vm.subMenuItem.superAdmin
    }
  ],attrs:{"id":_vm.subMenuItem.id},on:{"click":_vm.click}},[_c(_vm.linkComponent,{tag:"Component",staticClass:"SubMenuItem__link",attrs:{"to":_vm.subMenuItem.path}},[_vm._v(_vm._s(_vm.subMenuItem.label)+" "),(_vm.hasChild)?_c('AppSvgIcon',{staticClass:"SubMenuItem__arrow_icon",attrs:{"name":_vm.subMenuItem.isChildSelected ? 'nav-chevron-down' : 'nav-chevron-right'}}):_vm._e()],1),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }