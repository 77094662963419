<template>
  <div class="ReviewReviewCouponButton">
    <AppButton
      :tooltip="$t('coupon.give_coupon_tooltip')"
      :label="$t('coupon.give_coupon')"
      @click="openCouponPage"
    />
    <form
      ref="couponForm"
      :action="review.brand_user.coupon_url"
      target="coupon"
      method="post"
    >
      <input
        v-for="(formInput, i) in couponFormInputs"
        :key="i"
        type="hidden"
        :name="formInput.name"
        :value="formInput.value"
      />
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ReviewReviewCouponButton',
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    couponFormInputs() {
      const { shop_builder } = this.currentBrand;
      if (shop_builder === 'makeshop') {
        return [{ name: 'loginid', value: this.review.user_username }];
      } else if (shop_builder === 'cafe24') {
        return [
          {
            name: 'member_id',
            value: this.review.user_username
          },
          {
            name: 'member_name',
            value: this.review.user_name
          }
        ];
      }
      return [];
    }
  },
  methods: {
    openCouponPage() {
      const doOpenCouponPage = () => {
        window.open(
          'about:blank',
          'coupon',
          'scrollbars=1,width=1000,height=920'
        );
        this.$refs.couponForm.submit();
      };

      const { mileage_message } = this.review.mileage;
      if (mileage_message) {
        this.$copyText(mileage_message).finally(doOpenCouponPage);
      } else {
        doOpenCouponPage();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewReviewCouponButton {
  display: inline-block;
}
</style>

<i18n locale="ko">
{
  "coupon": {
    "give_coupon": "쿠폰 지급",
    "give_coupon_tooltip": "버튼을 클릭하면 쿠폰 지급 사유가 클립보드에\n복사되고 쇼핑몰 CRM 페이지가 열립니다.\n\nCRM 페이지 지급사유 입력란을 선택하고\nCtrl+V 키를 눌러 편리하게 입력하세요."
  }
}
</i18n>
<i18n locale="en">
{
  "coupon": {
    "give_coupon": "Give coupon"
  }
}
</i18n>
