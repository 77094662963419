import { constructEnum } from '@/lib/enum';
import WidgetStyle from '@/enums/WidgetStyle';

const PaginationMethod = constructEnum('PaginationMethod', {
  NONE: -1,
  SEE_MORE: 10,
  SEE_ALL: 15,
  INFINITE_SCROLL: 20,
  PAGINATE: 0
});

PaginationMethod.available_options = ({
  widget_style,
  mobile,
  renewed,
  use_vuejs // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
}) => {
  const without_see_all = [
    PaginationMethod.NONE,
    PaginationMethod.SEE_MORE,
    PaginationMethod.INFINITE_SCROLL,
    PaginationMethod.PAGINATE
  ];
  switch (widget_style) {
    case WidgetStyle.EXTERNAL:
      return use_vuejs // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
        ? PaginationMethod.options(without_see_all)
        : PaginationMethod.options([
            PaginationMethod.NONE,
            PaginationMethod.PAGINATE
          ]);
    case WidgetStyle.LIST:
      return mobile && renewed
        ? PaginationMethod.options()
        : PaginationMethod.options(without_see_all);
    case WidgetStyle.LIST_V3:
      return mobile
        ? PaginationMethod.options()
        : PaginationMethod.options(without_see_all);
    default:
      return PaginationMethod.options(without_see_all);
  }
};

export default PaginationMethod;
