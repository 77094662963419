var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ReviewCautionReviews"},[_c('div',{staticClass:"ReviewCautionReviews__title"},[_c('AppSelect',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
        _vm.$t('tooltip', {
          start_date: _vm.startDate,
          end_date: _vm.endDate,
          recent_days: _vm.resourceParams.recent_days
        })
      ),expression:"\n        $t('tooltip', {\n          start_date: startDate,\n          end_date: endDate,\n          recent_days: resourceParams.recent_days\n        })\n      "}],staticClass:"ReviewCautionReviews__title-select",attrs:{"options":_vm.recentDaysOptions},model:{value:(_vm.resourceParams.recent_days),callback:function ($$v) {_vm.$set(_vm.resourceParams, "recent_days", $$v)},expression:"resourceParams.recent_days"}}),_c('span',[_vm._v(_vm._s(_vm.$t('menu.review_caution_reviews')))])],1),_c('AppScrollBox',[_c('div',{staticClass:"ReviewCautionReviews__dashboard"},[_c('div',{staticClass:"ReviewCautionReviews__dashboard-section"},[_c('ReviewStatCard',{attrs:{"icon":"icon-negative-review","label":_vm.$t('negative_review.label'),"value":{
            currentValue: 21,
            totalValue: 30,
            valueInfo: _vm.$t('negative_review.value_info')
          }}}),_c('ReviewStatCard',{attrs:{"icon":"icon-reported-review","label":_vm.$t('reported_review.label'),"value":{
            currentValue: 21,
            totalValue: 21,
            valueInfo: _vm.$t('reported_review.value_info')
          }}}),_c('ReviewStatCard',{attrs:{"icon":"icon-keyword-review","label":_vm.$t('keyword_review.label'),"value":{
            currentValue: 21,
            totalValue: 21,
            valueInfo: _vm.$t('keyword_review.value_info')
          }}})],1),_c('div',{staticClass:"ReviewCautionReviews__dashboard-section"},[_c('ReviewCautionReviewsVipNegativeReviews',{attrs:{"negative-reviews-count":12,"vip-criteria":{
            amount: 1200000,
            percentage: 20
          }}})],1),_c('div',{staticClass:"ReviewCautionReviews__dashboard-section"},[_c('ReviewCautionReviewsNegativeReviewsTrend')],1),_c('div',{staticClass:"ReviewCautionReviews__dashboard-section"},[_c('ReviewCautionReviewsReportedReviews')],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }