export default {
  REVIEW_IMPORT: {
    steps: [
      {
        url: '/v2/review/import/smartstore',
        default: true,
        title: '쇼핑몰 외부 리뷰 연동',
        id: 'breadcrumb-review_import',
        description:
          '외부 리뷰 연동 기능을 사용해 다양한 외부 채널에서\n수집한 리뷰를 자사몰에 노출하세요',
        position: 'bottom'
      },
      {
        url: '/v2/review/import/smartstore',
        default: false,
        title: '스마트스토어 연동',
        id: 'smartstore-review-import-guide',
        description:
          '스마트스토어 리뷰 연동은 단계별 안내에 따라 진행해 주세요',
        position: 'top'
      },
      {
        url: '/v2/review/import/smartstore',
        default: false,
        title: '스마트스토어 연동',
        id: 'smartstore-review-import-detail',
        description:
          '각 버튼을 클릭해 스마트스토어 기능에 대한 안내 문서와 연동 비용을 확인할 수 있습니다',
        position: 'top'
      },
      {
        url: '/v2/review/import/kakao-style',
        default: false,
        title: '카카오스타일(지그재그) 연동',
        id: 'kakao-style-review-import-guide',
        description:
          '카카오스타일 API 정보를 입력하고 ‘연동 신청’ 버튼을 클릭하면 연동이 시작됩니다\n상세한 방법은 안내 문서의 링크를 클릭해서 확인하세요\n\n카카오스타일(지그재그) 연동은 뷰티 카테고리만 사용 가능합니다',
        position: 'bottom'
      }
    ]
  }
};
