<template>
  <div class="ReviewNewReviewsTitleSection">
    <div class="ReviewNewReviewsTitleSection__title">
      <AppSelect
        v-tooltip="
          $t('tooltip', {
            start_date: startDate,
            end_date: endDate,
            recent_days: recentDays
          })
        "
        :value="recentDays"
        class="ReviewNewReviewsTitleSection__select"
        :options="recentDaysOptions"
        @change="$emit('change', $event)"
      />
      <span>{{ $t('menu.review_new_reviews') }}</span>
    </div>
    <i18n class="ReviewNewReviewsTitleSection__info" path="info">
      <AppExternalLinkWithIcon :to="reviewReviewsUrl">
        <span class="ReviewNewReviewsTitleSection__link">
          {{ $t('review_reviews') }}
        </span>
      </AppExternalLinkWithIcon>
    </i18n>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'ReviewNewReviewsTitleSection',
  model: {
    prop: 'recentDays',
    event: 'change'
  },
  props: {
    recentDays: { type: [String, Number], default: null }
  },
  computed: {
    ...mapState(['env']),
    ...mapGetters(['pathWithBrandParams']),
    startDate() {
      return moment()
        .subtract(this.recentDays, 'days')
        .format('YYYY. MM. DD.');
    },
    endDate() {
      return moment().format('YYYY. MM. DD.');
    },
    recentDaysOptions() {
      return [1, 3, 7].map(value => ({
        label: this.$t('recent_days', [value]),
        value
      }));
    },
    reviewReviewsUrl() {
      const env = this.env === 'production' ? '' : location.host[0];
      const url = `${window.location.protocol}//${env}admin.cre.ma/v2/review/reviews`;
      return this.pathWithBrandParams(url);
    }
  },
  mounted() {
    this.SET_SHOW_CUSTOM_TITLE(true);
  },
  beforeDestroy() {
    this.SET_SHOW_CUSTOM_TITLE(false);
  },
  methods: {
    ...mapMutations('menu', ['SET_SHOW_CUSTOM_TITLE'])
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_breakpoints.scss';

.ReviewNewReviewsTitleSection {
  padding: 10px 0 24px;
}

.ReviewNewReviewsTitleSection__title {
  @include text-title;

  @include media-breakpoint-each(tablet, mobile) {
    position: sticky;
    left: $padding-horizontal-tablet;
    width: calc(100vw - #{$padding-horizontal-tablet * 2});
  }
}

.ReviewNewReviewsTitleSection__select {
  margin-right: 8px;
}

.ReviewNewReviewsTitleSection__info {
  @include text-caption-dark;

  white-space: pre-line;
  padding-top: 24px;
  display: block;
}

.ReviewNewReviewsTitleSection__link {
  text-decoration: underline;
  font-weight: bold;
}
</style>

<i18n locale="ko">
{
  "recent_days": "최근 {0}일",
  "info": "신규 리뷰에 대한 적립금 지급과 댓글 작성, 우수리뷰 상단고정 등을 손쉽게 관리하세요.\n더 자세한 관리는 {0} 를 이용하세요",
  "review_reviews": "전체 리뷰 관리",
  "tooltip": "{start_date} ~ {end_date}\n({recent_days}일 전 오전 0시부터 현재까지)"
}
</i18n>
