<template>
  <div class="ReviewDataRequest">
    <AppSummaryBox class="ReviewDataRequest__summary-box">
      <template #title>{{ $t('summary_box.title') }}</template>
      {{ $t('summary_box.content') }}
    </AppSummaryBox>
    <AppForm
      :sections="sections"
      :is-submitting="isSubmitting"
      :submit-button="{
        submitLabel: $t('email.submit'),
        submittingLabel: $t('email.submitting')
      }"
      v-bind="formProps"
      v-on="formEvents"
    >
      <template #group="{ id }">
        <div v-if="id === 'date_range'" class="AppForm__group-field">
          <AppDateRangePicker
            v-model="formObject"
            :required="true"
            :max-days="366"
          />
          <input
            type="hidden"
            name="start_date"
            :value="formObject.start_date"
          />
          <input type="hidden" name="end_date" :value="formObject.end_date" />
        </div>
      </template>
    </AppForm>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/lib/api';
import moment from 'moment';
import FormView from '@/mixins/FormView';
import { isPasswordValid } from '@/lib/passwordValidator';

export default {
  name: 'ReviewDataRequest',
  mixins: [FormView],
  data() {
    return {
      isSubmitting: false,
      detectFormDataChange: false,
      sections: [
        {
          groups: [
            {
              id: 'data_type',
              label: this.$t('data_type.label'),
              type: 'select_radio',
              options: [
                {
                  label: this.$t('data_type.reviews'),
                  value: 'reviews',
                  detailButtonClickHandler: () => {
                    this.alert({
                      title: this.$t('data_type.reviews'),
                      message: this.$t('data_type.reviews_detail')
                    });
                  }
                },
                {
                  label: this.$t('data_type.products'),
                  value: 'products',
                  detailButtonClickHandler: () => {
                    this.alert({
                      title: this.$t('data_type.products'),
                      message: this.$t('data_type.products_detail')
                    });
                  }
                }
              ]
            },
            {
              id: 'date_range',
              label: this.$t('date_range.label'),
              groupDescription: this.$t('date_range.description')
            },
            {
              id: 'password',
              type: 'new_password',
              label: this.$t('password.label'),
              required: true,
              groupDescription: this.$t('password.description'),
              fields: [
                {
                  id: 'password[password]',
                  validate: [
                    'required',
                    {
                      rule: v => isPasswordValid(v),
                      errorMessage: this.$t('password.validation')
                    }
                  ]
                },
                {
                  id: 'password[password_confirmation]',
                  validate: [
                    {
                      rule: () =>
                        this.formObject.password.password ===
                        this.formObject.password.password_confirmation,
                      errorMessage: this.$t('validations.password_confirmation')
                    }
                  ]
                }
              ]
            },
            {
              id: 'data_email',
              type: 'email',
              label: this.$t('data_email.label'),
              placeholder: this.$t('data_email.placeholder'),
              validate: ['required', 'email_format']
            }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapState('session', ['currentUser'])
  },
  created() {
    this.orgFormObject = {
      data_type: 'reviews',
      start_date: moment()
        .subtract(1, 'month')
        .toVal(),
      end_date: moment()
        .subtract(1, 'day')
        .toVal(),
      data_email: this.currentUser.email
    };
  },
  methods: {
    ...mapActions('dialog', ['alert']),
    ...mapActions('toast', ['createToast']),
    submit(formData) {
      api.post('/review/data_requests', formData).then(() => {
        this.createToast(
          this.$t('job_started', {
            type:
              this.formObject.data_type === 'reviews'
                ? this.$t('data_type.reviews')
                : this.$t('data_type.products'),
            email: this.formObject.data_email
          })
        );
        this.isSubmitting = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewDataRequest__summary-box {
  margin-bottom: 32px;
}
</style>

<i18n locale="ko">
{
  "summary_box": {
    "title": "리뷰/상품 데이터를 CSV파일로 받을 수 있습니다.",
    "content": "데이터 크기에 따라 최대 1일 내로 입력한 이메일 주소로 발송됩니다. 파일에 설정한 비밀번호는 따로 저장하고 있지 않으므로 별도 관리해주세요."
  },
  "email": {
    "submit": "이메일 발송",
    "submitting": "이메일 발송 중"
  },
  "data_type": {
    "label": "데이터 종류",
    "products": "상품 데이터",
    "reviews": "리뷰 데이터",
    "reviews_detail": "- 리뷰id : 크리마에서 등록한 id 값\n- 리뷰code : 쇼핑몰에서 등록한 id 값\n- 주문번호\n- 리뷰작성일\n- 상품구매일\n- 배송완료일\n- 리뷰본문\n- 회원id\n- 회원명\n- 회원등급\n- 추가정보\n- 상품번호\n- 상품명\n- 상품가격\n- 상품옵션\n- 적립금\n- 적립금지급일\n- 리뷰작성경로\n- 리뷰별점\n- 태그\n- 포토개수\n- 동영상개수\n- 포토url\n- 동영상url\n- 댓글개수\n- 댓글내용",
    "products_detail": "- 상품번호\n- 상품명\n- 세트상품\n- 스탭리뷰개수\n- 추천리뷰개수\n- 상품url\n- 리뷰수\n- 포토/동영상리뷰수\n- 리뷰작성률\n- 평점"
  },
  "date_range": {
    "label": "데이터 기간",
    "description": "한번에 받을 수 있는 데이터의 기간은 최대 1년입니다."
  },
  "password": {
    "label": "CSV 압축파일 비밀번호",
    "description": "영문 대소문자/ 숫자/ 특수문자(!@$%^&*) 중 두가지 이상의 조합으로 10~16자리를 입력해야 합니다.",
    "validation": "영문 대소문자/ 숫자/ 특수문자(!@$%^&*) 중 두가지 이상의 조합으로 10~16자리를 입력해주세요."
  },
  "data_email": {
    "label": "데이터를 받을 이메일 주소",
    "placeholder": "이메일 주소를 입력해주세요."
  },
  "job_started": "{type} csv 생성 작업을 시작합니다.<br />입력하신 이메일({email})로 CSV파일이 전송되며, 최대 1일까지 소요될 수 있습니다."
}
</i18n>
