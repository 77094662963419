<template>
  <div
    :class="['SlotRequestCard', isSmallType ? 'SlotRequestCard--small' : null]"
  >
    <div class="SlotRequestCard__image-box">
      <AppImage class="SlotRequestCard__image" :src="imageUrl" />
    </div>
    <div class="SlotRequestCard__content">
      <div>
        <div>
          <span class="SlotRequestCard__title" v-text="title" />
          <AppBadge
            class="SlotRequestCard__badge"
            :label="badgeLabel"
            :badge-style="badgeStyle"
          />
        </div>
        <div
          v-if="!isSmallType && description"
          class="SlotRequestCard__description"
        >
          {{ description }}
        </div>
        <div
          :class="[
            'SlotRequestCard__info-box',
            slotInstallationInfo ? null : 'SlotRequestCard__info-box--no-data'
          ]"
        >
          <span
            v-if="slotInstallationInfo"
            class="SlotRequestCard__info-box-title"
            v-text="slotInstallationInfoLabel"
          /><span>{{ slotInstallationInfo || $t('no_data') }}</span>
        </div>
      </div>
      <div v-if="slotInstallationInfo">
        <AppButton
          class="SlotRequestCard__button"
          button-style="grey-outline"
          :label="$t('modify')"
          @click="$emit('click')"
        />
        <AppButton
          v-if="!isRequired"
          class="SlotRequestCard__button"
          button-style="red-outline"
          :label="$t('delete')"
          @click="openDeleteConfirmDialog"
        />
      </div>
      <AppButton
        v-else
        class="SlotRequestCard__button"
        button-style="blue-outline"
        :label="buttonLabel || $t('widget_select')"
        :bubble-message="bubbleMessage"
        bubble-side="bottom-center"
        @click="$emit('click')"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapMutations } from 'vuex';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import InstallSlotType from '@/enums/InstallSlotType';
import WidgetPageType from '@/enums/WidgetPageType';
import CountAndScorePosition from '@/enums/CountAndScorePosition';
import CountAndScoreFormat from '@/enums/CountAndScoreFormat';
import CountAndScoreAlignment from '@/enums/CountAndScoreAlignment';
import ReviewWidgetFontWeight from '@/enums/ReviewWidgetFontWeight';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'SlotRequestCard',
  props: {
    isSmallType: { type: Boolean, default: false },
    imageUrl: { type: String, required: true },
    title: { type: String, required: true },
    description: { type: String, default: null },
    isRequired: { type: Boolean, default: false },
    bubbleMessage: { type: String, default: '' },
    slotType: { type: Number, default: undefined, required: false },
    pageType: { type: Number, default: undefined, required: false },
    buttonLabel: { type: String, default: '', required: false }
  },
  computed: {
    ...mapState('installRequest', ['widgetsBySlotId', 'directions']),
    badgeLabel() {
      return this.isRequired ? this.$t('required') : this.$t('optional');
    },
    badgeStyle() {
      return this.isRequired ? 'lavender' : 'default';
    },
    getSlotId() {
      const slotDirection = this.directions.find(
        d => d.slot_type === this.slotType
      );

      let slotId = null;
      if (slotDirection) slotId = slotDirection.slot_id;

      return slotId;
    },
    slotInstallations() {
      const slotId = this.getSlotId;
      if (!slotId) return [];
      return this.widgetsBySlotId[slotId].filter(i => i.selected);
    },
    countAndScoreDirections() {
      return this.directions.filter(
        d => d.slot_type === InstallSlotType.COUNT_AND_SCORE
      );
    },
    slotInstallationInfo() {
      return this.slotType === InstallSlotType.COUNT_AND_SCORE
        ? this.countAndScoreInfo + this.countAndScoreFontStyleInfo
        : this.slotType === InstallSlotType.COUNT_AND_SCORE_CUSTOM
        ? this.countAndScoreCustomInfo
        : this.installationInfo;
    },
    slotInstallationInfoLabel() {
      return [
        InstallSlotType.COUNT_AND_SCORE,
        InstallSlotType.COUNT_AND_SCORE_CUSTOM
      ].includes(this.slotType)
        ? this.$t('requested_direction')
        : this.$t('requested_widget');
    },
    installationInfo() {
      return this.slotInstallations.map(i => i.widget_style_name).join(', ');
    },
    countAndScoreInfo() {
      if (this.countAndScoreDirections.length === 0) return '';
      const options = this.countAndScoreDirections[0].count_and_score_options;
      const pageTypes = this.countAndScorePageTypes;
      const position = CountAndScorePosition.t(options.position);
      const format = CountAndScoreFormat.t(options.format);
      const alignment = CountAndScoreAlignment.t(options.alignment);

      return `${pageTypes} / ${position} / ${format}, ${alignment}`;
    },
    countAndScorePageTypes() {
      const pageTypes = _.uniq(
        this.countAndScoreDirections.map(direction => direction.page_type)
      );

      return pageTypes
        .map(p =>
          WidgetPageType.t(p)
            .split(' ')
            .slice(0, -1)
            .join(' ')
        )
        .join(', ');
    },
    countAndScoreFontStyleInfo() {
      if (this.countAndScoreDirections.length === 0) return '';

      const options = this.countAndScoreDirections[0].count_and_score_options;
      const styles = [];
      if (options.font_size) {
        styles.push(this.$t('font_style.size', [options.font_size]));
      }
      if (options.font_weight) {
        styles.push(
          this.$t('font_style.weight', [
            ReviewWidgetFontWeight.t(options.font_weight)
          ])
        );
      }
      if (options.font_color) {
        styles.push(this.$t('font_style.color', [options.font_color]));
      }

      if (styles.length === 0) return '';
      return ` / ${this.$t('font_style.label')} (${styles.join(', ')})`;
    },
    countAndScoreCustomInfo() {
      const devicesInfo = this.directions
        .filter(d => d.slot_type === this.slotType)
        .map(direction => WidgetDeviceType.t(direction.device_type))
        .join(', ');

      return devicesInfo
        ? `${devicesInfo} ${this.$t('count_and_score_summary')}`
        : '';
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('installRequest', [
      'REMOVE_COUNT_AND_SCORE_CUSTOM_DEVICE_TYPES',
      'REMOVE_WIDGETS_BY_SLOT_ID',
      'REMOVE_DIRECTIONS_BY_SLOT_ID'
    ]),
    openDeleteConfirmDialog() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: this.$t('delete_confirm.title'),
          markdownText: this.$t('delete_confirm.message'), // TODO : 설치 요청 특정하도록 수정
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => {
          this.removeBySlotType();
        });
      });
    },
    removeBySlotType() {
      switch (this.slotType) {
        case InstallSlotType.COUNT_AND_SCORE_CUSTOM:
          this.REMOVE_COUNT_AND_SCORE_CUSTOM_DEVICE_TYPES();
          this.REMOVE_DIRECTIONS_BY_SLOT_ID(this.getSlotId);
          return;
        case InstallSlotType.COUNT_AND_SCORE:
          this.REMOVE_DIRECTIONS_BY_SLOT_ID(this.getSlotId);
          return;
        default:
          this.REMOVE_WIDGETS_BY_SLOT_ID(this.getSlotId);
          this.REMOVE_DIRECTIONS_BY_SLOT_ID(this.getSlotId);
          return;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.SlotRequestCard {
  display: flex;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
  min-height: 220px;
  border-radius: 6px;

  &--small {
    min-height: 160px;
    box-shadow: none;
  }
}

.SlotRequestCard__image-box {
  width: 364px;
  flex-shrink: 0;
  background-color: $color-background-card;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 0 0 6px;

  .SlotRequestCard--small & {
    width: 260px;
  }
}

.SlotRequestCard__content {
  padding: 20px;
  flex-grow: 1;
  background-color: $color-background;
  border-radius: 0px 6px 6px 0px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  .SlotRequestCard--small & {
    border: 1px solid #e8e8ea;
  }
}

.SlotRequestCard__title {
  @include text-sub-title;

  .SlotRequestCard--small & {
    @include text-content-dark;
  }
}

.SlotRequestCard__badge {
  vertical-align: text-bottom;
  margin-left: 4px;
}

.SlotRequestCard__description {
  @include text-content;
  margin-top: 4px;
}

.SlotRequestCard__info-box {
  @include text-content-dark;
  margin-top: 8px;
  width: 100%;
  padding: 12px 16px 12px 16px;
  border-radius: 3px;
  border: 1px solid $color-grey-15;
  background-color: $color-grey-05;

  &--no-data {
    @include text-content-light;
  }

  display: flex;
  .SlotRequestCard__info-box-title {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  .SlotRequestCard--small & {
    margin-top: 4px;
  }
}

.SlotRequestCard__info-box-title {
  font-weight: bold;
  margin-right: 8px;
}

.SlotRequestCard__button {
  max-width: fit-content;
}
</style>

<i18n locale="ko">
{
  "required": "필수",
  "optional": "선택",
  "widget_select": "위젯 선택",
  "modify": "수정",
  "delete": "삭제",
  "no_data": "요청 내용이 없습니다.",
  "requested_widget": "요청 위젯",
  "requested_direction": "요청 내용",
  "font_style": {
    "label": "글자 스타일",
    "size": "크기 {0}px",
    "weight": "굵기 {0}",
    "color": "색상 {0}"
  },
  "delete_confirm": {
    "title": "요청을 삭제하시겠습니까?",
    "message": "설치 요청을 삭제하시겠습니까?"
  },
  "count_and_score_summary": "리뷰 수 위치 입력 완료"
}
</i18n>
