<template>
  <SlotModalForm
    class="InstallRequestOnboardingSlotProductDetailDefaultDialog"
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    is-maximized-by-default
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #head>
      <SlotInfoCard :image-url="cardImageUrl" :name="cardName" />
    </template>
    <template #section-label="{ id, label }">
      <legend
        class="InstallRequestOnboardingSlotProductDetailDefaultDialog__label AppForm__section-title"
      >
        <span v-text="label" />
        <AppBadge
          class="InstallRequestOnboardingSlotProductDetailDefaultDialog__badge"
          :label="badgeLabel(id)"
          :badge-style="badgeStyle(id)"
        />
      </legend>
    </template>
    <template #group="{ id }">
      <template v-if="id === 'product_detail_default'">
        <SlotTableProductDetailDefault />
      </template>
      <template v-else-if="id === 'product_detail_default_additional'">
        <SlotTable
          :slot-type="InstallSlotType.PRODUCT_DETAIL_DEFAULT_ADDITIONAL"
        />
      </template>
    </template>
  </SlotModalForm>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import ShopBuilder from '@/enums/ShopBuilder';
import WidgetPageType from '@/enums/WidgetPageType';
import InstallSlotType from '@/enums/InstallSlotType';
import DialogFormView from '@/mixins/DialogFormView';
import SlotInfoCard from '../components/SlotInfoCard';
import SlotModalForm from './components/SlotModalForm';
import SlotTable from './components/SlotTable';
import SlotTableProductDetailDefault from './components/SlotTableProductDetailDefault';

export default {
  name: 'InstallRequestOnboardingSlotProductDetailDefaultDialog',
  components: {
    SlotModalForm,
    SlotInfoCard,
    SlotTable,
    SlotTableProductDetailDefault
  },
  mixins: [DialogFormView],
  data() {
    return {
      pageType: WidgetPageType.PRODUCT_DETAIL,
      slotType: InstallSlotType.PRODUCT_DETAIL_DEFAULT, // slotId 생성 기준
      cardImageUrl:
        'https://assets.cre.ma/m/admin/v2/install_request/product_detail_default_slot.png',
      oldInstallations: [],
      oldWidgets: []
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapState('installRequest', ['currentSlotId']),
    ...mapGetters('installRequest', [
      'currentWidgetsBySlotId',
      'currentInstallationsBySlotId'
    ]),
    InstallSlotType() {
      return InstallSlotType;
    },
    cardName() {
      return this.$t('card_name', [
        ShopBuilder.tk(this.currentBrand.shop_builder)
      ]);
    },
    formSections() {
      return [
        {
          id: 'default_slot',
          label: this.$t('default_slot'),
          groups: [
            {
              id: 'tip',
              label: null,
              type: 'tip_box',
              value: this.$t('default_slot_tip', [
                ShopBuilder.tk(this.currentBrand.shop_builder)
              ])
            },
            {
              id: 'product_detail_default',
              label: null
            }
          ]
        },
        {
          id: 'additional_slot',
          label: this.$t('additional_slot'),
          groups: [
            {
              id: 'tip',
              label: null,
              type: 'tip_box',
              value: this.$t('additional_slot_tip')
            },
            {
              id: 'product_detail_default_additional',
              label: null
            }
          ]
        }
      ];
    },
    isFormDataChanged() {
      const orgSortedInstallations = _.sortBy(this.oldInstallations, v => [
        v.id,
        v.slot_type
      ]);
      const sortedInstallations = _.sortBy(
        this.currentInstallationsBySlotId,
        v => [v.id, v.slot_type]
      );
      const result = !_.isEqual(orgSortedInstallations, sortedInstallations);
      return result;
    },
    defaultAdditionalSlotInstallation() {
      return this.currentInstallationsBySlotId.find(
        i => i.slot_type === InstallSlotType.PRODUCT_DETAIL_DEFAULT_ADDITIONAL
      );
    }
  },
  created() {
    this.SET_CURRENT_PAGE_TYPE(this.pageType);
    this.ensureSlotId(this.slotType);
    this.oldWidgets = this.currentWidgetsBySlotId;
    this.oldInstallations = this.currentInstallationsBySlotId;
  },
  methods: {
    ...mapActions('installRequest', ['ensureSlotId']),
    ...mapMutations('installRequest', [
      'SET_CURRENT_PAGE_TYPE',
      'REMOVE_DIRECTIONS_BY_SLOT_ID',
      'APPEND_DIRECTION_WITH_SLOT_ID',
      'REMOVE_WIDGETS_BY_SLOT_ID',
      'ADD_WIDGETS_BY_SLOT_ID'
    ]),
    badgeLabel(sectionId) {
      return sectionId === 'default_slot'
        ? this.$t('required')
        : this.$t('optional');
    },
    badgeStyle(sectionId) {
      return sectionId === 'default_slot' ? 'lavender' : 'default';
    },
    beforeCloseDialog() {
      this.REMOVE_WIDGETS_BY_SLOT_ID(this.currentSlotId);
      if (this.oldWidgets.length > 0) {
        this.ADD_WIDGETS_BY_SLOT_ID(this.oldWidgets);
      }
    },
    submit() {
      this.REMOVE_DIRECTIONS_BY_SLOT_ID(this.currentSlotId);
      if (this.currentInstallationsBySlotId.length > 0) {
        this.APPEND_DIRECTION_WITH_SLOT_ID({
          page_type: this.pageType,
          slot_type: InstallSlotType.PRODUCT_DETAIL_DEFAULT
        });

        if (this.defaultAdditionalSlotInstallation)
          this.APPEND_DIRECTION_WITH_SLOT_ID({
            page_type: this.pageType,
            slot_type: InstallSlotType.PRODUCT_DETAIL_DEFAULT_ADDITIONAL
          });
      }
      this.close(true);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppForm__group:last-child {
    margin-top: 32px;
  }
}

.InstallRequestOnboardingSlotProductDetailDefaultDialog__label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.InstallRequestOnboardingSlotProductDetailDefaultDialog__badge {
  margin-left: 4px;
}
</style>

<i18n locale="ko">
{
  "title": "설치 위젯 선택",
  "sub_title": "상품 상세 페이지",
  "card_name": "{0} 기본 리뷰 영역",
  "default_slot": "기본 위젯 선택",
  "additional_slot": "기본 위젯 상단에 설치할 위젯 선택",
  "default_slot_tip": "{0} 기본 리뷰 영역을 대체할 위젯을 선택해주세요.",
  "additional_slot_tip": "기본 위젯 상단에 위젯을 추가하고 싶을 경우 선택해주세요. 여러개 위젯을 선택한 경우 설치 순서도 확인해 주세요.",
  "required": "필수",
  "optional": "선택"
}
</i18n>
