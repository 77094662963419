var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ReviewBulkReviewCsvsRegister"},[_c('AppSummaryBox',{staticClass:"ReviewBulkReviewCsvsRegister__summary",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('summary.title')))]},proxy:true}])},[_c('AppText',{staticClass:"ReviewBulkReviewCsvsRegister__summary-content",attrs:{"text":_vm.$t('summary.content')}}),_c('AppExternalLink',{staticClass:"ReviewBulkReviewCsvsRegister__summary-link",attrs:{"label":_vm.$t('summary.link'),"to":_vm.smartStoreLink}})],1),_c('AppForm',_vm._g(_vm._b({attrs:{"object-id":"bulk_review_csv","is-submitting":_vm.isSubmitting,"submit-button":{
      submitLabel: _vm.$t('app.upload'),
      submittingLabel: _vm.$t('app.uploading')
    }},on:{"change-group":_vm.formGroupChanged},scopedSlots:_vm._u([{key:"group",fn:function(ref){
    var id = ref.id;
return [(id === 'csv_file_guide')?[_c('AppContainer',[_c('div',{staticClass:"ReviewBulkReviewCsvsRegister__csv-file-guide-wrapper"},[_c('i18n',{attrs:{"path":"csv_file_guide.content1"}},[_c('AppExternalLink',{attrs:{"to":_vm.csvStandardFileLink,"link-style":"blue"}},[_vm._v(" "+_vm._s(_vm.$t('csv_file_guide.link'))+" ")])],1),_c('AppImage',{staticClass:"ReviewBulkReviewCsvsRegister__csv-file-guide-image",attrs:{"src":_vm.isAdminLocaleEn
                  ? 'https://assets.cre.ma/m/admin/v2/csv_file_guide1_en.png'
                  : 'https://assets.cre.ma/m/admin/v2/csv_file_guide1@3x.png'}})],1),_c('div',{staticClass:"ReviewBulkReviewCsvsRegister__csv-file-guide-wrapper"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('csv_file_guide.content2'))}}),_c('AppImage',{staticClass:"ReviewBulkReviewCsvsRegister__csv-file-guide-image--middle",attrs:{"src":_vm.isAdminLocaleEn
                  ? 'https://assets.cre.ma/m/admin/v2/csv_file_guide2_en.png'
                  : 'https://assets.cre.ma/m/admin/v2/csv_file_guide2@3x.png'}})],1),_c('div',{staticClass:"ReviewBulkReviewCsvsRegister__csv-file-guide-wrapper"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('csv_file_guide.content3'))}}),_c('AppImage',{staticClass:"ReviewBulkReviewCsvsRegister__csv-file-guide-image",attrs:{"src":_vm.isAdminLocaleEn
                  ? 'https://assets.cre.ma/m/admin/v2/csv_file_guide3_en.png'
                  : 'https://assets.cre.ma/m/admin/v2/csv_file_guide3@3x.png'}})],1)])]:_vm._e()]}}])},'AppForm',_vm.formProps,false),_vm.formEvents),[_c('input',{attrs:{"type":"hidden","name":"bulk_review_csv[locale]"},domProps:{"value":_vm.isAdminLocaleEn ? 'en-US' : 'ko-KR'}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }