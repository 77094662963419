<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Positive</div>
      <ReviewRateChange :value="10" status="positive" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Negative</div>
      <ReviewRateChange :value="12" status="negative" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Neutral</div>
      <ReviewRateChange :value="0" status="neutral" />
    </div>
  </div>
</template>

<script>
import ReviewRateChange from '../review/components/ReviewRateChange';

export default {
  name: 'UiReviewRateChange',
  components: { ReviewRateChange }
};
</script>
