import { constructEnum } from '@/lib/enum';

const WidgetPageType = constructEnum('WidgetPageType', {
  MAIN: 0,
  PRODUCT_LIST: 1,
  REVIEW_BOARD: 2,
  MY_REVIEWS: 3,
  MY_ORDERS: 4,
  PRODUCT_DETAIL: 5,
  PRODUCT_SEARCH: 6,
  MYSHOP_MAIN: 7,
  CART: 8,
  EVENT: 9,
  SPECIFIC: 9999
});

WidgetPageType.usingPageUrlPatterns = [
  WidgetPageType.PRODUCT_DETAIL,
  WidgetPageType.REVIEW_BOARD,
  WidgetPageType.MAIN,
  WidgetPageType.PRODUCT_LIST,
  WidgetPageType.PRODUCT_SEARCH,
  WidgetPageType.MYSHOP_MAIN,
  WidgetPageType.MY_ORDERS,
  WidgetPageType.MY_REVIEWS,
  WidgetPageType.CART,
  WidgetPageType.EVENT
];

WidgetPageType.displayOrder = [
  WidgetPageType.PRODUCT_DETAIL,
  WidgetPageType.REVIEW_BOARD,
  WidgetPageType.MAIN,
  WidgetPageType.PRODUCT_LIST,
  WidgetPageType.PRODUCT_SEARCH,
  WidgetPageType.MY_ORDERS,
  WidgetPageType.MY_REVIEWS,
  WidgetPageType.MYSHOP_MAIN,
  WidgetPageType.EVENT,
  WidgetPageType.SPECIFIC
];

WidgetPageType.loginRequiredPageTypes = [
  WidgetPageType.MY_REVIEWS,
  WidgetPageType.MY_ORDERS,
  WidgetPageType.MYSHOP_MAIN
];

export default WidgetPageType;
