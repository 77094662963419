import _ from 'lodash';
import api from '@/lib/api';
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {
      selectedReviews: []
    };
  },
  computed: {
    ...mapGetters('session', ['accountConfiguredForMileage'])
  },
  methods: {
    ...mapMutations('session', ['SET_REVIEW_SETTINGS']),
    ...mapActions('dialog', ['confirm', 'openDialog']),
    ...mapActions('review', [
      'giveMileageReviews',
      'cancelMileageReviews',
      'createCommentReviews',
      'updateDisplayReviews',
      'updateTagReviews',
      'deleteReviews'
    ]),
    batchGiveMileage() {
      const reviews = _.chain(this.selectedReviews)
        .keyBy('id')
        .values()
        .value();

      if (!this.accountConfiguredForMileage) {
        if (confirm(this.$t('reviews.set_brand_manager_confirm'))) {
          this.openDialog([
            'TheSettingsDialog',
            { initialTab: 'settings_dialog_user' }
          ]);
        }
        return;
      }

      this.openDialog(['ReviewReviewsBatchMileageDialog', { reviews }]).then(
        eventBus =>
          eventBus.$on('submit', batchMileage => {
            if (batchMileage.save_mileage_message) {
              const default_batch_mileage_message =
                batchMileage.mileage_message;
              api
                .patch(
                  '/review/settings',
                  { review_settings: { default_batch_mileage_message } },
                  { silent: true }
                )
                .then(() =>
                  this.SET_REVIEW_SETTINGS({
                    ...this.reviewSettings,
                    default_batch_mileage_message
                  })
                );
            }

            this.giveMileageReviews({
              title: this.$t('review_batch.give_mileage_title'),
              reviews,
              batchMileage
            });
          })
      );
    },
    batchCancelMileage() {
      const reviews = _.chain(this.selectedReviews)
        .keyBy('id')
        .values()
        .value();

      if (
        !confirm(
          this.$t('review_batch.deduct_mileage_confirm', {
            count: reviews.length
          })
        )
      )
        return;

      this.cancelMileageReviews({
        title: this.$t('review_batch.deduct_mileage_title'),
        reviews
      });
    },
    batchComment() {
      const reviews = this.selectedReviews;

      this.openDialog(['ReviewReviewsBatchCommentDialog', { reviews }]).then(
        eventBus =>
          eventBus.$on('apply', batchComment => {
            this.createCommentReviews({
              title: this.$t('review_batch.comment_title'),
              reviews,
              batchComment
            });
          })
      );
    },
    batchShow() {
      const reviews = this.selectedReviews;

      if (
        !confirm(
          this.$t('review_batch.show_confirm', { count: reviews.length })
        )
      ) {
        return;
      }

      this.updateDisplayReviews({
        title: this.$t('review_batch.show_title'),
        reviews,
        display: true
      });
    },
    batchHide() {
      const reviews = this.selectedReviews;

      if (
        !confirm(
          this.$t('review_batch.hide_confirm', { count: reviews.length })
        )
      ) {
        return;
      }

      this.updateDisplayReviews({
        title: this.$t('review_batch.hide_title'),
        reviews,
        display: false
      });
    },
    batchTag() {
      const reviews = this.selectedReviews;

      this.openDialog(['ReviewReviewsBatchTagsDialog', { reviews }]).then(
        eventBus =>
          eventBus.$on('apply', reviewIdTagIdsMap => {
            this.updateTagReviews({
              title: this.$t('review_batch.tag_title'),
              reviews,
              reviewIdTagIdsMap
            });
          })
      );
    },
    batchDelete() {
      const reviews = this.selectedReviews;

      if (
        !confirm(
          this.$t('review_batch.delete_confirm', { count: reviews.length })
        )
      ) {
        return;
      }

      this.deleteReviews({
        title: this.$t('review_batch.delete_title'),
        reviews,
        resourceParams: this.resourceParams
      });
    }
  }
};
