import _ from 'lodash';
import Vue from 'vue';
import api from '@/lib/api';
import InstallSlotType from '@/enums/InstallSlotType';
import { objectToFormData } from '@/lib/utils';

const state = {
  installRequestType: null,
  installRequestStepCompleted: false,
  installRequestCurrentStep: 0,
  currentPageType: null,
  lastSlotId: 0,
  currentSlotId: null,
  installDeviceTypes: [],
  countAndScoreCustomDeviceTypes: [],
  bubbleVisibility: { cardButton: true, preview: true },
  directions: [],
  widgetsBySlotId: {}
};

const mutations = {
  SET_INSTALL_REQUEST_TYPE(state, type) {
    state.installRequestType = type;
  },
  SET_INSTALL_REQUEST_STEP_COMPLETED(state, status) {
    state.installRequestStepCompleted = status;
  },
  SET_INSTALL_REQUEST_STEP(state, installRequestCurrentStep) {
    state.installRequestCurrentStep = installRequestCurrentStep;
  },
  SET_CURRENT_PAGE_TYPE(state, pageType) {
    state.currentPageType = pageType;
  },
  SET_LAST_SLOT_ID(state, slotId) {
    state.lastSlotId = slotId;
  },
  SET_CURRENT_SLOT_ID(state, slotId) {
    state.currentSlotId = slotId;
  },
  SET_INSTALL_DEVICE_TYPES(state, deviceTypes) {
    state.installDeviceTypes = deviceTypes;
  },
  SET_COUNT_AND_SCORE_CUSTOM_DEVICE_TYPES(state, deviceTypes) {
    state.countAndScoreCustomDeviceTypes = deviceTypes;
  },
  SET_BUBBLE_INVISIBLE(state, bubbleId) {
    Vue.set(state.bubbleVisibility, bubbleId, false);
  },
  SET_ALL_BUBBLE_VISIBLE(state) {
    state.bubbleVisibility = { cardButton: true, preview: true };
  },
  SET_WIDGETS_BY_SLOT_ID(state, widgets) {
    Vue.set(state.widgetsBySlotId, state.currentSlotId, widgets);
  },
  ADD_WIDGETS_BY_SLOT_ID(state, widgets) {
    const oldWidgets = state.widgetsBySlotId[state.currentSlotId] || [];
    Vue.set(state.widgetsBySlotId, state.currentSlotId, [
      ...oldWidgets,
      ...widgets
    ]);
  },
  REMOVE_WIDGETS_BY_SLOT_ID(state, slotId) {
    state.widgetsBySlotId = _.omit(state.widgetsBySlotId, slotId);
  },
  CLEAR_WIDGETS_BY_SLOT_ID(state) {
    state.widgetsBySlotId = {};
  },
  CLEAR_DIRECTIONS(state) {
    state.directions = [];
  },
  APPEND_DIRECTION_WITH_SLOT_ID(state, direction) {
    direction.slot_id = state.currentSlotId;
    state.directions = [...state.directions, direction];
  },
  REMOVE_DIRECTIONS_BY_SLOT_ID(state, slotId) {
    state.directions = state.directions.filter(d => d.slot_id !== slotId);
  },
  REMOVE_COUNT_AND_SCORE_CUSTOM_DEVICE_TYPES(state) {
    state.countAndScoreCustomDeviceTypes = [];
  }
};

const getters = {
  currentWidgetsBySlotId(state) {
    return state.widgetsBySlotId[state.currentSlotId] || [];
  },
  currentInstallationsBySlotId(_state, getters) {
    return getters.currentWidgetsBySlotId.filter(w => w.selected);
  },
  currentDirectionsBySlotId(state) {
    return state.directions.filter(d => d.slot_id === state.currentSlotId);
  },
  etcDirections(state) {
    return state.directions.filter(d =>
      InstallSlotType.ETC_SLOT_TYPES.includes(d.slot_type)
    );
  },
  installationsToSave(state) {
    return _.flatMap(state.widgetsBySlotId, (widgets, slotId) => {
      const slotIdInt = parseInt(slotId);
      return widgets
        .filter(w => w.selected)
        .map(w => ({ ...w, slot_id: slotIdInt }));
    });
  },
  hasInstallationsSelected: (_state, getters) => deviceType => {
    return getters.currentInstallationsBySlotId.some(
      installation =>
        installation.widget_device_type === deviceType && installation.selected
    );
  }
};

export const buildInstallRequestRequestFormData = function(
  formData,
  installRequestRequest
) {
  const requiredKeys = [
    'user_name',
    'phone',
    'email',
    'renewal_pc_skin',
    'renewal_mobile_skin',
    'renewal_already_live',
    'renewal_design_complete_date',
    'install_request_date',
    'one_day_renewal_available'
  ];
  objectToFormData(
    _.pick(installRequestRequest, requiredKeys),
    formData,
    'install_request_request'
  );
};

function base64ToBlob(base64String, mimeType) {
  const base64Data = base64String.split(',')[1];
  const binaryString = atob(base64Data);

  const buffer = new ArrayBuffer(binaryString.length);
  const uint8Array = new Uint8Array(buffer);

  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i) & 0xff;
  }

  return new Blob([buffer], { type: mimeType });
}

export const buildDirectionsFormData = function(formData, directions) {
  const requiredKeys = [
    'slot_id',
    'device_type',
    'page_type',
    'custom_url',
    'slot_type',
    'custom_comment',
    'review_board_install_option_type',
    'count_and_score_options'
  ];
  directions.forEach(direction => {
    objectToFormData(
      _.pick(direction, requiredKeys),
      formData,
      'install_request_directions[]'
    );

    if (direction.custom_image) {
      const blob = base64ToBlob(
        direction.custom_image,
        direction.custom_image_file_type
      );
      formData.append(
        'install_request_directions[][custom_image]',
        blob,
        direction.custom_image_file_name
      );
    }
  });
};

export const buildInstallationsFormData = function(formData, installations) {
  const requiredKeys = [
    'page_type',
    'slot_type',
    'widget_device_type',
    'widget_id',
    'widget_type',
    'widget_style',
    'widget_sub_style',
    'order',
    'slot_id'
  ];

  installations.forEach(installation => {
    objectToFormData(
      _.pick(installation, requiredKeys),
      formData,
      'install_request_installations[]'
    );
  });
};

const actions = {
  createSlotId: ({ state, commit }) => {
    const slotId = state.lastSlotId + 1;
    commit('SET_LAST_SLOT_ID', slotId);
    commit('SET_CURRENT_SLOT_ID', slotId);
  },
  ensureSlotId: ({ state, commit }, slotType) => {
    const slotDirection = state.directions.find(d => d.slot_type === slotType);

    let slotId = null;
    if (slotDirection) slotId = slotDirection.slot_id;
    else {
      slotId = state.lastSlotId + 1;
      commit('SET_LAST_SLOT_ID', slotId);
    }

    commit('SET_CURRENT_SLOT_ID', slotId);
  },
  removeDeviceSlotWidgetsBySlotId: (
    { commit, getters },
    { deviceType, slotType }
  ) => {
    const deviceTypeWidgets = _.reject(
      getters.currentWidgetsBySlotId,
      i => i.widget_device_type === deviceType && i.slot_type === slotType
    );
    commit('SET_WIDGETS_BY_SLOT_ID', deviceTypeWidgets);
  },
  requestRenewalWidgetInstall({ state, getters }, install_request_request) {
    const formData = new FormData();
    buildInstallRequestRequestFormData(formData, install_request_request);
    buildDirectionsFormData(formData, state.directions);
    buildInstallationsFormData(formData, getters.installationsToSave);
    return api.post('/install_request/renewal/install_requests', formData);
  },

  clearAllInstallRequest({ commit }) {
    commit('SET_INSTALL_REQUEST_TYPE', null);
    commit('SET_INSTALL_REQUEST_STEP_COMPLETED', false);
    commit('SET_INSTALL_REQUEST_STEP', 0);
    commit('SET_CURRENT_PAGE_TYPE', null);
    commit('SET_LAST_SLOT_ID', 0);
    commit('SET_CURRENT_SLOT_ID', null);
    commit('SET_INSTALL_DEVICE_TYPES', []);
    commit('REMOVE_COUNT_AND_SCORE_CUSTOM_DEVICE_TYPES');
    commit('CLEAR_DIRECTIONS');
    commit('CLEAR_WIDGETS_BY_SLOT_ID');
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
