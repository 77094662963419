<template>
  <div class="SummaryBoxEasyJoinWithBrand">
    <AppSummaryBox>
      <template #title>
        {{ $t('title') }}
      </template>
      {{ $t('content_1', [shopBuilder]) }}
      <AppExternalLink
        class="SummaryBoxEasyJoinWithBrand__info-box-link"
        :to="appStoreLink"
        target="_blank"
      >
        {{ $t('link') }}
      </AppExternalLink>
      <div>
        {{ $t('content_2') }}
      </div>
      <template #buttons>
        <div class="SummaryBoxEasyJoinWithBrand__button-wrapper">
          <AppButton
            class="SummaryBoxEasyJoinWithBrand__button"
            :button-style="buttonStyle"
            :label="$t('button')"
            :disabled="!onboardable"
            @click="startEasyJoinWithBrand"
          />
          <!-- eslint-disable vue/no-v-html -->
          <AppBubble
            v-if="showBubble"
            class="SummaryBoxEasyJoinWithBrand__bubble"
            side="right"
            @click="hideBubble"
            v-html="$t('bubble_message')"
          />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-if="!onboardable"
            class="SummaryBoxEasyJoinWithBrand__error-message"
          >
            {{ $t('error_message') }}
          </div>
        </div>
      </template>
    </AppSummaryBox>
  </div>
</template>

<script>
import ShopBuilder from '@/enums/ShopBuilder';
import { mapState, mapActions } from 'vuex';
import api from '@/lib/api';

export default {
  name: 'SummaryBoxEasyJoinWithBrand',
  data() {
    return {
      onboardable: true,
      showBubble: true
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    shopBuilder() {
      return ShopBuilder.tk(this.currentBrand.shop_builder);
    },
    appStoreLink() {
      switch (this.currentBrand.shop_builder) {
        case 'cafe24':
          return 'https://store.cafe24.com/kr/apps/1406';
        case 'shopby':
          return 'https://apps.nhn-commerce.com/apps/755';
        default:
          return '';
      }
    },
    buttonStyle() {
      return this.onboardable ? 'blue' : 'red';
    }
  },
  methods: {
    ...mapActions('session', ['redirectToNextPage']),
    startEasyJoinWithBrand() {
      this.hideBubble();

      api
        .post(
          '/easy_join_with_brand/preparation/check_onboardable',
          {},
          { silent: true }
        )
        .then(() => this.redirectToNextPage('/easy-join-with-brand'))
        .catch(() => (this.onboardable = false));
    },
    hideBubble() {
      this.showBubble = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.SummaryBoxEasyJoinWithBrand__info-box-link {
  color: $color-grey-50;
  text-decoration: underline;
}

.SummaryBoxEasyJoinWithBrand__button-wrapper {
  position: relative;
}

.SummaryBoxEasyJoinWithBrand__error-message {
  margin-top: 5px;
  white-space: pre-line;
  color: $color-red;
}

.SummaryBoxEasyJoinWithBrand__bubble {
  top: 16px;
  left: 108px;
}
</style>

<i18n locale="ko">
{
  "title" : "크리마 리뷰 지금 시작하세요!",
  "content_1": "{0} 앱 스토어에서 크리마 리뷰 앱을 설치하셨나요?",
  "content_2": "크리마 리뷰 앱 설치 후 로그인 하셨다면 [사용 준비 시작] 버튼을 눌러 간편하게 크리마 리뷰 사용 준비를 시작해 보세요.",
  "link": "앱 스토어 바로가기",
  "button": "사용 준비 시작",
  "error_message": "사용 준비를 시작할 수 없습니다. 앱 스토어에서 크리마 리뷰 앱 설치 후 다시 로그인한 뒤 사용 준비를 시작해 주세요.\n 앱이 설치되어 있어도 문제가 발생하는 경우 앱 삭제 및 재설치 후 다시 시도해 주세요.",
  "bubble_message": "이 버튼을 클릭하고<br />크리마 리뷰 온보딩을 시작해 보세요✨"
}
</i18n>
