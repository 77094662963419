<template>
  <div class="ReviewCautionReviewsNegativeReviewsTrend">
    <ReviewMiniLineChart
      class="ReviewCautionReviewsNegativeReviewsTrend__chart"
      v-bind="NEGATIVE_REVIEW_TREND"
    />
  </div>
</template>

<script>
export default {
  name: 'ReviewCautionReviewsNegativeReviewsTrend',
  data() {
    return {
      NEGATIVE_REVIEW_TREND: {
        series: [
          {
            name: this.$t('chart.negative_reviews_rate', ['크리마몰']),
            data: [15, 20, 12, 0]
          },
          {
            name: this.$t('chart.industry_average_rate'),
            data: [10, 10, 10, 10]
          }
        ],
        categories: ['12월3주', '12월4주', '12월5주', '1월1주'],
        title: this.$t('chart.title'),
        unit: 'percentage',
        height: 143
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewCautionReviewsNegativeReviewsTrend {
  min-width: 240px;
  max-width: 298px;
  padding: 18px 10px;
  border-radius: 10px;
  border: 1px solid $color-grey-25;
  background-color: $color-review-white;
}

.ReviewCautionReviewsNegativeReviewsTrend__chart {
  width: 100%;
}
</style>

<i18n locale="ko">
{
  "chart": {
    "title": "부정 리뷰 추이",
    "negative_reviews_rate": "{0} 부정 리뷰 비율",
    "industry_average_rate": "업계 평균 부정 리뷰 비율"
  }
}
</i18n>
