const IFRAME_MARGIN = 100;

export default {
  data() {
    return {
      iframeHeights: {}
    };
  },
  created() {
    this.resetIframeHeights();
  },
  mounted() {
    window.addEventListener('message', this.handleMessage);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage);
  },
  methods: {
    allIframes() {
      return Array.from(
        document.querySelectorAll('iframe.AppIframeViewer__iframe')
      );
    },
    findSourceIframe(source) {
      const allIframes = this.allIframes();
      return allIframes.find(iframe => iframe.contentWindow === source);
    },
    addMarginToHeight(height) {
      return `${height + IFRAME_MARGIN}px`;
    },
    handleMessage(e) {
      if (!this.requiresCalculation) return;

      const hit = typeof e.data === 'string' && e.data.match(/height=(\d+)/);
      if (hit && e.source) {
        const sourceIframe = this.findSourceIframe(e.source);

        if (sourceIframe) {
          const height = this.addMarginToHeight(parseInt(hit[1]));
          this.$set(this.iframeHeights, sourceIframe.src, height);
        }
      }
    },
    resetIframeHeights() {
      this.iframeHeights = {};
    },
    getIframeHeight(url) {
      return this.iframeHeights[url];
    }
  },
  computed: {
    requiresCalculation() {
      return true;
    }
  }
};
