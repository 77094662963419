<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <ReviewMiniLineChart v-bind="MONTHLY_REVIEW_TREND" />
      <ReviewMiniLineChart v-bind="NEGATIVE_REVIEW_TREND" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Flexible Width</div>
      <ReviewMiniLineChart style="width: 300px" v-bind="MONTHLY_REVIEW_TREND" />
      <ReviewMiniLineChart
        style="width: 400px"
        v-bind="NEGATIVE_REVIEW_TREND"
      />
    </div>
  </div>
</template>

<script>
import ReviewMiniLineChart from '../review/components/ReviewMiniLineChart';

export default {
  name: 'UiReviewMiniLineChart',
  components: { ReviewMiniLineChart },
  data() {
    return {
      MONTHLY_REVIEW_TREND: {
        series: [
          {
            name: '신규 리뷰',
            data: [20, 30, 20, 15, 14]
          },
          {
            name: 'AI 분석 우수 리뷰',
            data: [8, 12, 8, 5, 5]
          },
          {
            name: '처음 작성한 리뷰',
            data: [10, 5, 5, 1, 1]
          }
        ],
        categories: ['10월', '11월', '12월', '1월', '2월'],
        title: '월간 리뷰 추이'
      },
      NEGATIVE_REVIEW_TREND: {
        series: [
          {
            name: '크리마몰 부정 리뷰 비율',
            data: [15, 20, 12, 0]
          },
          {
            name: '업계 평균 부정 리뷰 비율',
            data: [10, 10, 10, 10]
          }
        ],
        categories: ['12월3주', '12월4주', '12월5주', '1월1주'],
        title: '부정 리뷰 추이',
        unit: 'percentage'
      }
    };
  }
};
</script>
