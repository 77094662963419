<template>
  <div class="InstallRequestRenewalDirectionDialogReviewBoard">
    <SlotRequestCard
      v-bind="slotRequestCardProps()"
      class="InstallRequestRenewalDirectionDialogReviewBoard__slot"
      @click="openInstallRequestRenewalSlotDialog()"
    />
    <Banner
      class="InstallRequestRenewalDirectionDialogReviewBoard__banner"
      url="https://sample.cre.ma/v2/review/recommendation/review_board/150"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import InstallSlotType from '@/enums/InstallSlotType';
import WidgetPageType from '@/enums/WidgetPageType';
import SlotRequestCard from './components/SlotRequestCard';
import Banner from '../components/Banner';

export default {
  name: 'InstallRequestRenewalDirectionDialogReviewBoard',
  components: { SlotRequestCard, Banner },
  computed: {
    slotTypes() {
      return [InstallSlotType.REVIEW_BOARD];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    slotRequestCardProps() {
      return {
        imageUrl:
          'https://assets.cre.ma/m/admin/v2/install_request/review_board_slot@3x.png',
        title: this.$t('review_board.title'),
        description: this.$t('review_board.description'),
        isRequired: false,
        pageType: WidgetPageType.REVIEW_BOARD,
        slotType: InstallSlotType.REVIEW_BOARD
      };
    },
    openInstallRequestRenewalSlotDialog() {
      this.openDialog('InstallRequestRenewalSlotReviewBoardDialog');
    }
  }
};
</script>

<style lang="scss" scoped>
.InstallRequestRenewalDirectionDialogReviewBoard {
  margin-top: 24px;
}
.InstallRequestRenewalDirectionDialogReviewBoard__slot {
  & + & {
    margin-top: 12px;
  }
}
.InstallRequestRenewalDirectionDialogReviewBoard__banner {
  margin-top: 32px;
}
</style>

<i18n locale="ko">
{
  "review_board": {
    "title": "전체 리뷰 페이지",
    "description": "전체 리뷰 페이지에 고객의 리뷰를 효과적으로 노출해보세요."
  }
}
</i18n>
