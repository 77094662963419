<template>
  <AppButton v-bind="buttonProps" @click="onClick" />
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import api from '@/lib/api';

export default {
  name: 'ReviewNewReviewsPinButton',
  props: {
    review: { type: Object, required: true },
    resourceParams: { type: Object, default: () => ({}) }
  },
  computed: {
    buttonProps() {
      return {
        label: this.label,
        tooltip: this.tooltip,
        disabled: !this.isReviewVisible
      };
    },
    label() {
      return this.$t(this.review.pinned ? 'remove_pin' : 'pin_to_top');
    },
    tooltip() {
      return this.$t(
        this.review.pinned ? 'tooltip.remove_pin' : 'tooltip.pin_to_top'
      );
    },
    isReviewVisible() {
      return (
        this.review.force_display ||
        (this.review.display && this.review.product_visible)
      );
    }
  },
  methods: {
    ...mapMutations('review', ['UPDATE_REVIEW']),
    ...mapActions('review', ['unpinReview']),
    ...mapActions('dialog', ['openDialog']),
    onClick() {
      this.review.pinned ? this.unpin() : this.pin();
    },
    pin() {
      api
        .patch(
          `/review/reviews/${this.review.id}/pin`,
          { sort: this.resourceParams.sort },
          { successMessage: this.$t('pin_success') }
        )
        .then(({ data }) => {
          this.UPDATE_REVIEW(data.review);
          if (data.pinned_reviews.length > 1) {
            this.openDialog([
              'ReviewReviewsPinDialog',
              {
                productName: data.review.product.name,
                orgReviews: data.pinned_reviews
              }
            ]);
          }
        });
    },
    unpin() {
      this.unpinReview(this.review.id);
    }
  }
};
</script>

<i18n locale="ko">
{
  "pin_to_top": "추천 리뷰 지정",
  "pin_success": "추천 리뷰 지정을 완료했습니다.",
  "remove_pin": "추천 리뷰 해제",
  "tooltip": {
    "pin_to_top": "버튼을 클릭하면 이 리뷰를 추천 리뷰로 지정합니다. 추천 리뷰는 위젯에서 추천순 정렬 시, [쇼핑몰 추천 리뷰] 표시와 함께 해당 리뷰를 최상단에 노출합니다",
    "remove_pin": "버튼을 클릭하면 이 리뷰를 추천 리뷰에서 해제합니다."
  }
}
</i18n>
