import ReviewSort from '@/enums/ReviewSort';
import WidgetStyle from '@/enums/WidgetStyle';
import store from '@/store';

export default {
  formSections({
    widgetStyle,
    isDeviceMobile,
    isDevicePc,
    isShopifyBrand,
    useSubBrandFilter
  }) {
    const display_reviews = [
      'display_review_info',
      isShopifyBrand ? null : 'exclude_category_ids',
      useSubBrandFilter ? 'sub_brand_ids' : null,
      'tag_id',
      'exclude_tag_id'
    ];
    const common = ['no_item_action'];
    const sort = ['display_order'];
    const display_widgets = ['show_widget_on_all_categories'];
    const feature = ['show_header_section'];
    const isDevicePcOnly = isDevicePc && !isDeviceMobile;
    switch (widgetStyle) {
      case WidgetStyle.LIST:
        return {
          common,
          display_widgets,
          display_reviews: [...display_reviews, 'filter_photo_only'],
          sort,
          feature,
          design: [
            'posts_per_page',
            'message_initial_rows',
            isDevicePc ? 'pagination_method' : null,
            isDeviceMobile ? 'pagination_method_for_mobile' : null
          ]
        };
      case WidgetStyle.GALLERY:
        return {
          common,
          display_widgets,
          display_reviews,
          sort,
          feature,
          design: [
            isDevicePcOnly ? 'reviews_custom_layout' : null,
            isDevicePcOnly ? null : 'posts_per_page',
            isDeviceMobile ? 'mobile_posts_per_line' : null,
            'show_thumbnail_as_square',
            'product_thumbnail_shape',
            isDeviceMobile ? 'review_popup_display_type_for_mobile' : null,
            'message_initial_rows',
            isDevicePc ? 'pagination_method' : null,
            isDeviceMobile ? 'pagination_method_for_mobile' : null
          ]
        };
      case WidgetStyle.BEST_GALLERY:
        return {
          common,
          display_widgets,
          display_reviews,
          sort,
          feature,
          design: [
            isDevicePcOnly ? null : 'posts_per_page',
            isDevicePcOnly ? 'reviews_custom_layout' : null,
            isDeviceMobile ? 'mobile_posts_per_line' : null,
            'show_thumbnail_as_square',
            'product_thumbnail_shape',
            'message_initial_rows'
          ]
        };
      case WidgetStyle.SQUARE_GALLERY:
        return {
          common,
          display_widgets,
          display_reviews,
          sort,
          feature,
          design: [
            isDevicePcOnly ? null : 'posts_per_page',
            isDevicePcOnly ? 'reviews_custom_layout' : null,
            isDeviceMobile ? 'mobile_posts_per_line' : null,
            isDevicePc ? 'pagination_method' : null,
            isDeviceMobile ? 'pagination_method_for_mobile' : null
          ]
        };
      case WidgetStyle.BOARD:
        return {
          common,
          display_widgets,
          display_reviews: [...display_reviews, 'filter_photo_only'],
          sort,
          feature,
          design: ['posts_per_page', 'pagination_method_for_mobile']
        };
      case WidgetStyle.HORIZONTAL_SCROLL:
        return {
          common: ['posts_per_page', ...common],
          display_widgets,
          display_reviews,
          sort,
          feature: [...feature, 'horizontal_scroll_enable_autoscroll'],
          design: [
            'show_thumbnail_as_square',
            'product_thumbnail_shape',
            'message_initial_rows',
            'horizontal_scroll_contents_to_show',
            isDevicePc
              ? 'horizontal_scroll_initial_posts_on_page_for_pc'
              : null,
            isDeviceMobile ? 'horizontal_scroll_initial_posts_on_page' : null
          ]
        };
      default:
        return {
          common,
          display_widgets,
          display_reviews,
          sort,
          feature
        };
    }
  },
  widgetStyles({ isDevicePc }) {
    let widgetStyles = [
      WidgetStyle.LIST,
      WidgetStyle.GALLERY,
      WidgetStyle.BEST_GALLERY,
      WidgetStyle.SQUARE_GALLERY,
      WidgetStyle.HORIZONTAL_SCROLL
    ];
    if (!isDevicePc) widgetStyles.splice(4, 0, WidgetStyle.BOARD);

    return widgetStyles;
  },
  displayOrderOptions() {
    const reviewSortOptions = store.getters['reviewWidgets/reviewSortOptions'];
    return reviewSortOptions([
      ReviewSort.RECENTLY_CREATED,
      ReviewSort.MOST_SCORES,
      ReviewSort.MOST_LIKES
    ]);
  },
  showHeaderSectionSubSettings({ widgetStyle }) {
    return [
      'show_title',
      'description_text',
      'show_notices',
      'show_score_filter',
      ...([
        WidgetStyle.GALLERY,
        WidgetStyle.BEST_GALLERY,
        WidgetStyle.SQUARE_GALLERY,
        WidgetStyle.BOARD
      ].includes(widgetStyle)
        ? ['show_search_section']
        : [])
    ];
  },
  useBackgroundColor() {
    return true;
  }
};
