<template>
  <AppModalDefault :title="$t('title')" width="auto" @close="close">
    <template #body>
      <AppInfoBox>
        <div class="ReviewMessagesNthReviewPreviewDialog__info">
          {{ $t('info') }}
        </div>
      </AppInfoBox>
      <pre class="ReviewMessagesNthReviewPreviewDialog__description">{{
        $t('description')
      }}</pre>
      <AppImage
        class="ReviewMessagesNthReviewPreviewDialog__image"
        src="https://assets.cre.ma/m/admin/v2/nth_review_preview.png"
      />
    </template>
    <template #foot>
      <AppButton
        button-style="blue"
        :label="$t('app.confirmed')"
        @click="close"
      />
    </template>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';

export default {
  name: 'ReviewMessagesNthReviewPreviewDialog',
  mixins: [DialogView]
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewMessagesNthReviewPreviewDialog__image {
  margin-top: 16px;
}

.ReviewMessagesNthReviewPreviewDialog__info {
  @include text-label;
}

.ReviewMessagesNthReviewPreviewDialog__description {
  margin-top: 16px;
}
</style>

<i18n locale="ko">
{
  "title": "n차 리뷰 미리보기",
  "info": "💡 리스트 위젯 v3 사용 시 표시됩니다.",
  "description": "n차 리뷰는 다음과 같이 표시됩니다.\n1. n차 리뷰 배지가 표시됩니다.\n2. 이전에 작성된 리뷰를 연결해 보여줍니다.\n3. n차 리뷰 작성 기간 동안 누적된 도움돼요, 댓글을 모두 보여줍니다."
}
</i18n>
