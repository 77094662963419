import { mapState } from 'vuex';
import getByteSize from '@/lib/vendor/getByteSize';

export default {
  computed: {
    ...mapState('session', ['currentBrand']),
    mileageMessageBytesValidation() {
      const { max_mileage_message_bytes } = this.currentBrand;
      return max_mileage_message_bytes
        ? {
            rule: v => this.messageBytes(v) <= max_mileage_message_bytes,
            errorMessage: this.$t('validations.max_mileage_message_bytes', {
              max_bytes: max_mileage_message_bytes
            })
          }
        : null;
    },
    mileageMessageTemplateValidation() {
      return {
        rule: v =>
          v.includes('%{product_name}') && v.includes('%{product_options}'),
        errorMessage: this.$t('validations.mileage_message_template', {
          product_name: '%{product_name}',
          product_options: '%{product_options}'
        })
      };
    }
  },
  methods: {
    messageBytes(message) {
      const min_bytes_str = '1234..';
      const min_order_code = '123456..';
      return getByteSize(
        message
          .replace(/(%{product_name}|%{product_options})/, min_bytes_str)
          .replace(/(%{order_code})/, min_order_code)
      );
    }
  }
};
