<template>
  <div class="TheReviewSettingsTutorial">
    <TheReviewSettingsTutorialHead
      :show-bubble="isMinimized"
      @toggle-minimize="toggleMinimize"
      @close="close"
    />
    <div v-if="!isMinimized">
      <div class="TheReviewSettingsTutorial__body">
        <div class="TheReviewSettingsProgress__confirm-wrapper">
          <AppImage
            class="TheReviewSettingsProgress__confirm-star"
            src="https://assets.cre.ma/m/admin/v2/star_light.png"
          />
          <span class="TheReviewSettingsProgress__confirm-text">{{
            $t('confirm_text')
          }}</span>
        </div>
        <TheReviewSettingsProgress :total-count="4" :progress-count="1" />
        <div class="TheReviewSettingsTutorial__steps-wrapper">
          <TheReviewSettingsProgressStep
            v-for="(step, index) in steps"
            :key="index"
            :step="step"
          />
        </div>
      </div>
      <div
        v-if="currentBrand.free_trial_days_left > 0"
        class="TheReviewSettingsTutorial__foot"
      >
        <div class="TheReviewSettingsProgress__foot">
          <i18n path="free_trial_days_left_content">
            <span class="TheReviewSettingsTutorial__days-left">{{
              $t('free_trial_days', [currentBrand.free_trial_days_left])
            }}</span>
          </i18n>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import TheReviewSettingsProgress from './TheReviewSettingsProgress';
import TheReviewSettingsProgressStep from './TheReviewSettingsProgressStep.vue';
import TheReviewSettingsTutorialHead from './TheReviewSettingsTutorialHead.vue';

export default {
  name: 'TheReviewSettingsTutorial',
  components: {
    TheReviewSettingsProgress,
    TheReviewSettingsProgressStep,
    TheReviewSettingsTutorialHead
  },
  data() {
    return {
      steps: [
        'biz_message',
        'review_settings_popup_text',
        'review_mileage',
        'review_import'
      ].map(step => ({
        title: this.$t(`${step}.title`),
        description: this.$t(`${step}.description`)
      })),
      isMinimized: false
    };
  },
  computed: {
    ...mapState('session', ['currentBrand'])
  },
  methods: {
    ...mapMutations('tutorial', ['SET_IS_REVIEW_SETTINGS_TUTORIAL_VISIBLE']),
    close() {
      this.SET_IS_REVIEW_SETTINGS_TUTORIAL_VISIBLE(false);
    },
    toggleMinimize() {
      this.isMinimized = !this.isMinimized;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_dialog.scss';

.TheReviewSettingsTutorial {
  @include dialog;

  width: 250px;
  border-radius: 8px;
}

.TheReviewSettingsTutorial__body {
  @include text-caption-bold-dark;

  padding: 0 23px;
}

.TheReviewSettingsTutorial__foot {
  position: relative;
  padding: 0 23px;
}

.TheReviewSettingsProgress__confirm-wrapper {
  display: flex;
}

.TheReviewSettingsProgress__confirm-star {
  width: 21px;
  height: 18px;
  margin-right: 6px;
}

.TheReviewSettingsTutorial__steps-wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 28px 0 39px;
}

.TheReviewSettingsProgress__foot {
  @include text-content-tag;

  text-align: center;
  padding-bottom: 21px;
}

.TheReviewSettingsTutorial__days-left {
  color: $color-grey-75;
}
</style>

<i18n locale="ko">
{
  "confirm_text": "주요 기본 설정을 확인해보세요",
  "biz_message": {
    "title": "알림톡 발송",
    "description": "구매 고객에게 알림톡을 보내 쉽고 빠르게\n리뷰를 받아보세요"
  },
  "review_settings_popup_text": {
    "title": "리뷰 작성 팝업 문구",
    "description": "쇼핑몰 컨셉에 맞는 문구를 설정해 통일된\n브랜드 느낌을 전달하세요"
  },
  "review_mileage": {
    "title": "리뷰 작성 적립금 금액",
    "description": "적립금 조건과 금액을 설정해보세요\n적절한 적립금은 많은 리뷰를 이끌어냅니다"
  },
  "review_import": {
    "title": "쇼핑몰 외부 리뷰 연동",
    "description": "쇼핑몰 외 다양한 판매 채널의 리뷰를 연동해\n쇼핑몰에 노출하세요"
  },
  "free_trial_days_left_content": "무료 사용기간 {0} 남음",
  "free_trial_days": "{0}일"
}
</i18n>
