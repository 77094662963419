<template>
  <div class="InstallRequestOnboardingDirectionDialogReviewBoard">
    InstallRequestOnboardingDirectionDialogReviewBoard
  </div>
</template>

<script>
export default {
  name: 'InstallRequestOnboardingDirectionDialogReviewBoard'
};
</script>
