<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label>리뷰 기본 설정 가이드</label>
      </div>
      <TheReviewSettingsTutorial />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label>TheWelcomeTutorialDialog</label>
      </div>
      <AppButton label="TheWelcomeTutorial" @click="openTheWelcomeTutorial" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label>TutorialGuideDialog</label>
      </div>
      <AppButton label="TutorialGuideDialog" @click="openTutorialGuideDialog" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label>TutorialSummaryCard</label>
      </div>
      <TutorialSummaryCard
        :title="$t('tutorial_summary_card.title')"
        :description="$t('tutorial_summary_card.description')"
        detail-link="/"
        :image-url="$t('image_url')"
        show-tag
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TheReviewSettingsTutorial from '@/layouts/components/TheTutorial/TheReviewSettingsTutorial';
import TutorialSummaryCard from '@/layouts/components/TheTutorial/TutorialSummaryCard';

export default {
  name: 'UiTutorialComponents',
  components: { TheReviewSettingsTutorial, TutorialSummaryCard },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openTheWelcomeTutorial() {
      this.openDialog('TheWelcomeTutorialDialog');
    },
    openTutorialGuideDialog() {
      this.openDialog([
        'TutorialGuideDialog',
        {
          title: this.$t('tutorial_guide_dialog.title'),
          description: this.$t('tutorial_guide_dialog.description'),
          showDetailLink: true,
          imageUrl: this.$t('image_url')
        }
      ]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "tutorial_summary_card": {
    "title": "알림톡 설정",
    "description": "구매 고객이 편리하게 더 많은 리뷰를\n 작성하도록 알림톡 발송 설정해 보세요"
  },
  "tutorial_guide_dialog": {
    "title": "리뷰 작성 유도 알림톡 발송 설정",
    "description": "알림톡 발송을 위해 메시지 발송 설정이 켜져 있어야 합니다\n발송을 원하지 않으면 설정을 꺼주시면 됩니다"
  },
  "image_url": "https://assets.cre.ma/m/tutorial/biz_message.png"
}
</i18n>
