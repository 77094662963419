<template>
  <MessageTemplateSelectTable
    :templates="templates"
    :selected-value="selectedValue"
    @invalid="$emit('invalid', $event)"
    @change="$emit('change', $event)"
    @new-template="clickNewTemplate"
    @show-template="onShowTemplate"
    @edit-template="doEditTemplate"
    @delete-template="onDeleteTemplate"
  />
</template>

<script>
import { mapActions } from 'vuex';
import CremaServiceType from '@/enums/CremaServiceType';
import BizMessageTemplateType from '@/enums/BizMessageTemplateType';
import MessageTemplateSelectTable from '../../components/MessageTemplateSelectTable.vue';

export default {
  name: 'BizMessageTemplateSelectTable',
  components: { MessageTemplateSelectTable },
  model: { prop: 'selectedValue', event: 'change' },
  props: {
    templates: { required: true, type: Array },
    selectedValue: { type: Number, default: null }
  },
  computed: {
    templateType() {
      return BizMessageTemplateType.CUSTOM;
    }
  },
  methods: {
    ...mapActions('dialog', ['confirm', 'openDialog']),
    ...mapActions('bizMessageTemplate', ['clickNewTemplate', 'deleteTemplate']),
    onShowTemplate(template) {
      this.openDialog([
        'BizMessageTemplateShowDialog',
        { templateId: template.id, templateType: this.templateType }
      ]);
    },
    doEditTemplate(template) {
      this.openDialog([
        'BizMessageTemplateFormDialog',
        {
          serviceType: CremaServiceType.REVIEW,
          templateId: template.id,
          templateType: this.templateType,
          inspectDirectly: true
        }
      ]);
    },
    onDeleteTemplate(template) {
      this.confirm({
        type: 'delete_confirm',
        title: this.$t('delete_confirm_title'),
        message: this.$t('delete_confirm_message')
      }).then(result => {
        if (result)
          this.deleteTemplate({
            id: template.id,
            templateType: this.templateType
          });
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "delete_confirm_title": "이 템플릿을 삭제하시겠습니까?",
  "delete_confirm_message": "삭제된 템플릿은 복구가 어렵습니다."
}
</i18n>
