<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppTable
      class="SlotTableProductDetailDefault AppForm__group-field AppForm__group-field--mt8"
      :columns="columns"
      :rows="rows"
    >
      <template #cell="{ column, row }">
        <template v-if="column.id === 'widget_style'">
          <span>{{ row.widget_style_name }}</span>
          <AppBadge
            v-if="row.widget_style === WidgetStyle.LIST"
            class="SlotTableProductDetailDefault__badge"
            badge-style="lavender-outline"
            badget-size="small"
            :label="$t('best')"
          />
        </template>
        <template v-else-if="column.id === 'select'">
          <AppRadio
            v-model="installationId"
            name="product_detail_default_installation"
            :value="row.id"
          />
        </template>
      </template>
    </AppTable>
  </AppAjaxContent>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';
import api from '@/lib/api';
import WidgetType from '@/enums/WidgetType';
import WidgetStyle from '@/enums/WidgetStyle';
import InstallSlotType from '@/enums/InstallSlotType';

export default {
  name: 'SlotTableProductDetailDefault',
  data() {
    return {
      installationId: null,
      slotType: InstallSlotType.PRODUCT_DETAIL_DEFAULT,
      widgets: [],
      isLoading: false
    };
  },
  computed: {
    ...mapGetters('installRequest', ['currentWidgetsBySlotId']),
    WidgetStyle() {
      return WidgetStyle;
    },
    columns() {
      return [
        {
          id: 'widget_style',
          label: this.$t('form.widget_style'),
          width: '50%'
        },
        {
          id: 'select',
          label: this.$t('app.select'),
          width: '50%'
        }
      ];
    },
    productDetailDefaultWidgets() {
      return this.currentWidgetsBySlotId.filter(
        i => i.slot_type === this.slotType
      );
    },
    rows() {
      return this.widgets.map(widget => ({
        ...widget,
        selected: widget.id === this.installationId
      }));
    }
  },
  watch: {
    rows() {
      this.updateWidgetsBySlotType();
    }
  },
  created() {
    if (this.productDetailDefaultWidgets.length === 0) {
      this.isLoading = true;
      api
        .get('/install_request/onboarding/widgets', {
          params: {
            slot_type: this.slotType
          }
        })
        .then(({ data }) => {
          this.widgets = data.widgets.map(w => ({
            ...w,
            id: this.widgetKey(w),
            widget_style_name: this.widgetStyleName(w),
            slot_type: this.slotType
          }));

          this.setDefaultWidget();
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.installationId = this.productDetailDefaultWidgets.find(
        i => i.selected
      ).id;
      this.widgets = this.productDetailDefaultWidgets;
    }
  },
  methods: {
    ...mapMutations('installRequest', [
      'SET_WIDGETS_BY_SLOT_ID',
      'ADD_WIDGETS_BY_SLOT_ID'
    ]),
    widgetKey(widget) {
      return `${WidgetType.key(
        widget.widget_type
      ).toLowerCase()}-${WidgetStyle.key(
        widget.widget_sub_style || widget.widget_style
      ).toLowerCase()}`;
    },
    widgetStyleName(widget) {
      return widget.widget_type === WidgetType.PRODUCTS
        ? WidgetType.t(widget.widget_type)
        : `${WidgetStyle.t(
            widget.widget_sub_style || widget.widget_style
          )} ${this.$t('install_request.widget')}`;
    },
    setDefaultWidget() {
      const defaultWidget = this.widgets.find(
        w => w.widget_style === WidgetStyle.LIST
      );
      this.installationId = defaultWidget.id;
    },
    updateWidgetsBySlotType() {
      const slotTypeWidgets = _.reject(
        this.currentWidgetsBySlotId,
        i => i.slot_type === this.slotType
      );
      this.SET_WIDGETS_BY_SLOT_ID(slotTypeWidgets);
      this.ADD_WIDGETS_BY_SLOT_ID(this.rows);
    }
  }
};
</script>

<style lang="scss" scoped>
.SlotTableProductDetailDefault__badge {
  margin-left: 4px;
}
</style>

<i18n locale="ko">
{
  "form": {
    "widget_style": "위젯",
    "widget_info": "위젯 정보"
  },
  "best": "Best"
}
</i18n>
