<template>
  <AppModalForm
    :title="$t(editing ? 'title_edit' : 'title_new')"
    :form-props="{ ...formProps, objectId: 'smart_installation' }"
    v-on="formEvents"
  >
    <input
      type="hidden"
      name="smart_installation[installation_type]"
      :value="installation_type"
    />
    <input
      type="hidden"
      name="smart_installation[id]"
      :value="newReviewLink.id"
    />
  </AppModalForm>
</template>

<script>
import SmartInstallationFormView from '@/mixins/SmartInstallationFormView';
import WidgetPageType from '@/enums/WidgetPageType';
import SmartInstallationType from '@/enums/SmartInstallationType';
import api from '@/lib/api';
import _ from 'lodash';

export default {
  name: 'ReviewInstallSimulationNewReviewLinkFormDialog',
  mixins: [SmartInstallationFormView],
  props: {
    newReviewLink: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    groupIds() {
      return this.formObject.page_type === WidgetPageType.SPECIFIC
        ? ['device_type', 'page_type', 'page_path', 'element_selector']
        : ['device_type', 'page_type', 'element_selector'];
    },
    editing() {
      return this.formObject.id;
    },
    pageTypeOptions() {
      const widgetPageTypes = [
        WidgetPageType.MY_ORDERS,
        WidgetPageType.MY_REVIEWS,
        WidgetPageType.SPECIFIC
      ];
      return WidgetPageType.options(widgetPageTypes);
    }
  },
  created() {
    this.installation_type = SmartInstallationType.NEW_REVIEW_LINK;
  },
  mounted() {
    this.orgFormObject = _.cloneDeep(this.newReviewLink);
  },
  methods: {
    submit(formData) {
      if (this.editing) {
        api
          .patch(
            '/install_simulation/simulations/update_installation',
            formData,
            {
              successMessage: this.$t('app.saved')
            }
          )
          .then(({ data }) => {
            this.eventBus.$emit('update', data.smart_installation);
            this.close(true);
          });
      } else {
        api
          .post('/install_simulation/simulations', formData, {
            successMessage: this.$t('app.created')
          })
          .then(({ data }) => {
            this.eventBus.$emit('save', data.smart_installation);
            this.close(true);
          });
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "title_new": "작성 팝업 설치 추가",
  "title_edit": "작성 팝업 설치 설정"
}
</i18n>
