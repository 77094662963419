<template>
  <div class="ReviewNewReviewsBestAi">
    <span v-tooltip="$t('tooltip')">
      <span class="ReviewNewReviewsBestAi__title">{{ $t('title') }}</span>
      <AppSvgIcon
        class="ReviewNewReviewsBestAi__title-icon"
        name="icon-info-tooltip"
      />
    </span>
    <div class="ReviewNewReviewsBestAi__count">
      <AppNumber :value="bestAiReviewsCount" />
    </div>
    <div class="ReviewNewReviewsBestAi__chart">
      <ReviewChartPercentageBar
        :label="currentBrand.name"
        :numerator="bestAiReviewsCount"
        :denominator="totalReviewsCount"
      />
      <ReviewChartPercentageBar
        :label="$t('average_percentage')"
        :numerator="averageBestAiReviewsCount"
        :denominator="averageTotalReviewsCount"
        chart-color="flamingo"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ReviewNewReviewsBestAi',
  props: {
    bestAiReviewsCount: {
      type: Number,
      required: true
    },
    totalReviewsCount: {
      type: Number,
      required: true
    },
    averageBestAiReviewsCount: {
      type: Number,
      required: true
    },
    averageTotalReviewsCount: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState('session', ['currentBrand'])
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewNewReviewsBestAi {
  height: 236px;
  min-width: 255px;
  max-width: 310px;
  padding: 18px 22px;
  border-radius: 10px;
  border: 1px solid $color-grey-25;
  background-color: $color-review-white;
}

.ReviewNewReviewsBestAi__title {
  @include text-caption-bold-dark;
}

.ReviewNewReviewsBestAi__title-icon {
  vertical-align: middle;
  margin-left: 4px;
  color: $color-grey-60;
}

.ReviewNewReviewsBestAi__count {
  @include text-caption-title;

  margin-top: 4px;
  font-weight: bold;
}

.ReviewNewReviewsBestAi__chart {
  margin-top: 20px;
}
</style>

<i18n locale="ko">
{
  "title": "AI 우수 리뷰",
  "tooltip": "AI가 리뷰 본문의 길이, 내용, 첨부된 사진과 동영상 등 다양한 요소를 종합적으로 평가해 높은 점수를 부여한 리뷰를 의미합니다",
  "average_percentage": "업계 평균"
}
</i18n>
