<template>
  <div class="InstallRequestOnboardingDirectionDialogMain">
    InstallRequestOnboardingDirectionDialogMain
  </div>
</template>

<script>
export default {
  name: 'InstallRequestOnboardingDirectionDialogMain'
};
</script>
