<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{ formStyle: 'wide', ...formProps }"
    v-on="formEvents"
  >
    <template #group="{id, options, value, errors}">
      <template v-if="id === 'use_including_all_product_categories'">
        <div class="AppForm__group-field">
          <AppSelect
            v-model="formObject.use_including_all_product_categories"
            class="AppForm__group-field"
            name="campaign_settings[use_including_all_product_categories]"
            :options="options"
          />
        </div>
        <template v-if="!value">
          <div class="AppForm__group-field">
            <AppMultipleSelectProductCategory
              v-model="formObject.including_product_category_ids"
              name="campaign_settings[including_product_category_ids][]"
              :placeholder="$t('including_product_category_ids.placeholder')"
              :invalid="!!errors.including_product_category_ids"
              @blur="validateField('including_product_category_ids')"
              @change="validateField('including_product_category_ids')"
            />
            <AppFormError :error="errors.including_product_category_ids" />
          </div>
        </template>
        <div class="AppForm__group-field">
          <AppCheckbox
            v-model="formObject.use_excluding_product_category_ids"
            name="campaign_settings[use_excluding_product_category_ids]"
            :label="$t('use_excluding_product_category_ids.label')"
          >
            <template #sub-item="{ checked }">
              <AppMultipleSelectProductCategory
                v-model="formObject.excluding_product_category_ids"
                name="campaign_settings[excluding_product_category_ids][]"
                :placeholder="$t('excluding_product_category_ids.placeholder')"
                :disabled="!checked"
                :invalid="!!errors.excluding_product_category_ids"
                :exclude-category-values="
                  formObject.including_product_category_ids
                "
                @blur="validateField('excluding_product_category_ids')"
                @change="validateField('excluding_product_category_ids')"
              />
              <AppFormHint
                :message="$t('excluding_product_category_ids.hint')"
                :disabled="!checked"
              />
              <AppFormError :error="errors.excluding_product_category_ids" />
            </template>
          </AppCheckbox>
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ReviewMessagesNthStepReviewCampaignCategoryDialog',
  mixins: [DialogFormView],
  props: {
    campaignId: { type: Number, required: true },
    campaignSettings: { type: Object, default: () => {} }
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'use_including_all_product_categories',
              label: this.$t('use_including_all_product_categories.label'),
              options: [
                {
                  label: this.$t(
                    'use_including_all_product_categories.option.true'
                  ),
                  value: true
                },
                {
                  label: this.$t(
                    'use_including_all_product_categories.option.false'
                  ),
                  value: false
                }
              ],
              validate:
                !this.formObject.use_including_all_product_categories ||
                this.formObject.use_excluding_product_category_ids
                  ? ['required']
                  : [],
              fields: [
                ...(!this.formObject.use_including_all_product_categories
                  ? [
                      {
                        id: 'including_product_category_ids',
                        validate: ['required']
                      }
                    ]
                  : []),
                ...(this.formObject.use_excluding_product_category_ids
                  ? [
                      {
                        id: 'excluding_product_category_ids',
                        validate: ['required']
                      }
                    ]
                  : [])
              ]
            }
          ]
        }
      ];
    }
  },
  created() {
    this.orgFormObject = { ...this.campaignSettings };
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(
          `/review/campaigns/${this.campaignId}/settings/update_category_settings`,
          formData,
          {
            successMessage: this.$t('app.saved')
          }
        )
        .then(({ data }) => {
          this.orgFormObject = data.settings;
          this.close(true);

          this.eventBus.$emit('submit', data.settings);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "발송 대상 상품 카테고리 설정",
  "use_including_all_product_categories": {
    "label": "발송 대상 상품 카테고리",
    "option": {
      "true": "모든 상품 카테고리 포함",
      "false": "선택한 상품 카테고리만 포함"
    }
  },
  "use_excluding_product_category_ids": {
    "label": "다음 상품 카테고리에 포함되는 상품 제외"
  },
  "including_product_category_ids": {
    "placeholder": "상품 카테고리를 선택해주세요."
  },
  "excluding_product_category_ids": {
    "placeholder": "제외할 상품 카테고리를 선택해주세요.",
    "hint": "예를들어 아우터 카테고리에 포함된 상품 중 SALE에도 포함된 상품을 제외 하려면<br/>위에서 SALE카테고리를 선택하세요."
  }
}
</i18n>
