function deparam(dataString) {
  // 예시: 'iframe_id=crema-fullscreen-popup-1&act=set_parent_div_margin&style=' 문자열을 hash 값으로 변경
  // {iframe_id: 'crema-fullscreen-popup-1', act: 'set_parent_div_margin', style: ''}
  return typeof dataString === 'string'
    ? dataString.split('&').reduce(function(result, param) {
        var [key, value] = param.split('=');
        result[key] = value;
        return result;
      }, {})
    : {};
}

function getBaseUrl(url) {
  return /https?:\/\//.test(url)
    ? url.split('?')[0]
    : window.location.protocol + '//' + url.split('?')[0];
}

function isIframe() {
  return window.self !== window.top;
}

function setStyle(el, spec) {
  for (var n in spec) {
    el.style[n] = spec[n];
  }
}

// https://stackoverflow.com/questions/16839698/jquery-getscript-alternative-in-native-javascript
function getScript(source, callback) {
  let script = document.createElement('script');
  let prior = document.getElementsByTagName('script')[0];
  script.async = 1;
  script.onload = script.onreadystatechange = function(_, isAbort) {
    if (
      isAbort ||
      !script.readyState ||
      /loaded|complete/.test(script.readyState)
    ) {
      script.onload = script.onreadystatechange = null;
      script = undefined;
      if (!isAbort && callback) setTimeout(callback, 0);
    }
  };
  script.src = source;
  prior.parentNode.insertBefore(script, prior);
}

function objectToFormData(obj, formData, previousKey) {
  if (obj === null || obj === undefined) {
    return formData;
  } else if (Array.isArray(obj)) {
    if (obj.length) {
      obj.forEach((value, _index) => {
        let key = previousKey + '[]';

        objectToFormData(value, formData, key);
      });
    }
  } else if (obj === Object(obj)) {
    Object.keys(obj).forEach(prop => {
      const value = obj[prop];

      if (Array.isArray(obj)) {
        while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
          prop = prop.substring(0, prop.length - 2);
        }
      }

      const key = previousKey ? previousKey + '[' + prop + ']' : prop;
      objectToFormData(value, formData, key);
    });
  } else {
    formData.append(previousKey, obj);
  }

  return formData;
}

export { deparam, getBaseUrl, getScript, isIframe, setStyle, objectToFormData };
