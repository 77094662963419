<template>
  <div
    :class="[
      'AppChartTooltip',
      { 'AppChartTooltip--in-dialog': isInDialog },
      className
    ]"
    :style="positionStyle"
  >
    <div v-if="title" class="AppChartTooltip__title">
      {{ title }}
    </div>
    <div class="AppChartTooltip__body">
      <table class="AppChartTooltip__table">
        <tr
          v-for="(item, i) in items"
          :key="i"
          :class="['AppChartTooltip__item', item.className]"
        >
          <td class="AppChartTooltip__name">
            <svg
              v-if="item.symbol"
              :class="[
                'AppChartTooltip__icon',
                `highcharts-color-${
                  item.hasOwnProperty('colorIndex') ? item.colorIndex : i
                }`
              ]"
              viewBox="0 0 8 8"
            >
              <rect v-if="item.symbol === 'square'" width="8" height="8" />
              <line
                v-else-if="item.symbol === 'line'"
                x1="0"
                y1="4"
                x2="8"
                y2="4"
                stroke-width="2"
              />
              <circle v-else cx="4" cy="4" r="3" stroke="none" />
            </svg>
            <!-- eslint-disable vue/no-v-html -->
            <span v-html="item.name" />
          </td>
          <td class="AppChartTooltip__value">
            <div v-if="typeof item.value === 'string'" v-html="item.value" />
            <!-- eslint-enable vue/no-v-html -->
            <AppNumber v-else v-bind="item" />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppChartTooltip',
  props: {
    position: { type: Object, required: true },
    title: { type: String, default: '' },
    items: { type: Array, required: true },
    className: { type: String, default: null }
  },
  data() {
    return { positionStyle: {}, isInDialog: false };
  },
  watch: {
    position() {
      this.recalcPosition();
    }
  },
  mounted() {
    this.isInDialog = !!this.$el.closest('.AppModal');
    this.recalcPosition();
  },
  methods: {
    recalcPosition() {
      const MARGIN_X = 8;
      const width = this.$el.offsetWidth;
      const { x, y } = this.position;
      let left = x + MARGIN_X;
      if (left + width > window.innerWidth) left = x - width - MARGIN_X;
      this.positionStyle = { top: `${y}px`, left: `${left}px` };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/vars/_z-indexes.scss';

.AppChartTooltip {
  position: fixed;
  display: inline-block;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px $color-layout-section-dark;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 4px 8px;
  z-index: $z-index-tooltip;
  white-space: nowrap;
  pointer-events: none;
  transform: translateY(-50%);

  &--in-dialog {
    z-index: $z-index-tooltip-modal;
  }
}

.AppChartTooltip__title {
  @include text-caption-tiny-dark;
}

.AppChartTooltip__body {
  padding: 8px 0;
}

.AppChartTooltip__table {
  min-width: 222px;

  td {
    vertical-align: middle;
  }

  td + td {
    padding-left: 4px;
  }

  tr + tr {
    td {
      padding-top: 4px;
    }
  }
}

.AppChartTooltip__icon-cell {
  line-height: 0;
}

.AppChartTooltip__icon {
  margin-right: 4px;
  width: 8px;
}

.AppChartTooltip__name {
  @include text-caption-dark;
}

.AppChartTooltip__value {
  @include text-caption-darker;
  font-weight: bold;
  text-align: right;
}
</style>
