<template>
  <AppModalForm
    :title="$t(editing ? 'title_edit' : 'title_new')"
    :form-props="{ ...formProps, objectId: 'smart_installation' }"
    :is-loading="isLoading"
    v-on="formEvents"
    @blur-group="formGroupBlurred"
  >
    <input
      type="hidden"
      name="smart_installation[installation_type]"
      :value="installation_type"
    />
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import { mapState } from 'vuex';
import WidgetPageType from '@/enums/WidgetPageType';
import SmartInstallationType from '@/enums/SmartInstallationType';
import SmartInstallationFormView from '@/mixins/SmartInstallationFormView';

export default {
  name: 'ReviewSmartInstallationReviewsCountReplacementFormDialog',
  mixins: [SmartInstallationFormView],
  props: {
    replacement: {
      type: Object,
      default: () => ({
        reviews_count_display_format: '%{reviews_count}'
      })
    }
  },
  data() {
    return {
      installation_type: SmartInstallationType.REPLACE_REVIEWS_COUNT
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    groupIds() {
      return this.formObject.page_type === WidgetPageType.SPECIFIC
        ? [
            'device_type',
            'page_type',
            'page_path',
            'element_selector',
            'skin_id',
            'reviews_count_display_format'
          ]
        : this.formObject.page_type === WidgetPageType.PRODUCT_DETAIL
        ? [
            'device_type',
            'page_type',
            'skip_product_no_from_url',
            'element_selector',
            'skin_id',
            'reviews_count_display_format'
          ]
        : [
            'device_type',
            'page_type',
            'element_selector',
            'skin_id',
            'reviews_count_display_format'
          ];
    },
    editing() {
      return this.formObject.id;
    },
    pageTypeOptions() {
      const widgetPageTypes = [
        WidgetPageType.PRODUCT_DETAIL,
        WidgetPageType.MAIN,
        WidgetPageType.PRODUCT_LIST,
        WidgetPageType.PRODUCT_SEARCH,
        WidgetPageType.EVENT,
        WidgetPageType.SPECIFIC
      ];
      return WidgetPageType.options(widgetPageTypes);
    }
  },
  mounted() {
    this.orgFormObject = this.replacement;
    this.isLoading = false;
  },
  methods: {
    submit(formData) {
      if (this.editing) {
        api
          .patch(`/smart_installations/${this.replacement.id}`, formData, {
            successMessage: this.$t('app.saved')
          })
          .then(({ data }) => {
            this.eventBus.$emit('update', data.smart_installation);
            this.close(true);
          });
      } else {
        api
          .post('/smart_installations', formData, {
            successMessage: this.$t('app.created')
          })
          .then(({ data }) => {
            this.eventBus.$emit('save', data.smart_installation);
            this.close(true);
          });
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "title_new": "상품 리뷰 수 교체 설치 추가",
  "title_edit": "상품 리뷰 수 교체 설치 설정"
}
</i18n>
