<template>
  <div class="ReviewCautionReviews">
    <div class="ReviewCautionReviews__title">
      <AppSelect
        v-model="resourceParams.recent_days"
        v-tooltip="
          $t('tooltip', {
            start_date: startDate,
            end_date: endDate,
            recent_days: resourceParams.recent_days
          })
        "
        class="ReviewCautionReviews__title-select"
        :options="recentDaysOptions"
      />
      <span>{{ $t('menu.review_caution_reviews') }}</span>
    </div>
    <AppScrollBox>
      <div class="ReviewCautionReviews__dashboard">
        <div class="ReviewCautionReviews__dashboard-section">
          <ReviewStatCard
            icon="icon-negative-review"
            :label="$t('negative_review.label')"
            :value="{
              currentValue: 21,
              totalValue: 30,
              valueInfo: $t('negative_review.value_info')
            }"
          />
          <ReviewStatCard
            icon="icon-reported-review"
            :label="$t('reported_review.label')"
            :value="{
              currentValue: 21,
              totalValue: 21,
              valueInfo: $t('reported_review.value_info')
            }"
          />
          <ReviewStatCard
            icon="icon-keyword-review"
            :label="$t('keyword_review.label')"
            :value="{
              currentValue: 21,
              totalValue: 21,
              valueInfo: $t('keyword_review.value_info')
            }"
          />
        </div>
        <div class="ReviewCautionReviews__dashboard-section">
          <ReviewCautionReviewsVipNegativeReviews
            :negative-reviews-count="12"
            :vip-criteria="{
              amount: 1200000,
              percentage: 20
            }"
          />
        </div>
        <div class="ReviewCautionReviews__dashboard-section">
          <ReviewCautionReviewsNegativeReviewsTrend />
        </div>
        <div class="ReviewCautionReviews__dashboard-section">
          <ReviewCautionReviewsReportedReviews />
        </div>
      </div>
    </AppScrollBox>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import moment from 'moment';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

export default {
  name: 'ReviewCautionReviews',
  mixins: [ResourceViewNoRoute],
  computed: {
    defaultResourceParams() {
      return { recent_days: 7 };
    },
    startDate() {
      return moment()
        .subtract(this.resourceParams.recent_days, 'days')
        .format('YYYY. MM. DD.');
    },
    endDate() {
      return moment().format('YYYY. MM. DD.');
    },
    recentDaysOptions() {
      return [1, 3, 7, 30, 90, 365].map(value => ({
        label: this.$t('recent_days', [value]),
        value
      }));
    }
  },
  mounted() {
    this.SET_SHOW_CUSTOM_TITLE(true);
  },
  beforeDestroy() {
    this.SET_SHOW_CUSTOM_TITLE(false);
  },
  methods: {
    ...mapMutations('menu', ['SET_SHOW_CUSTOM_TITLE']),
    fetchResource() {
      // do nothing
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_breakpoints.scss';

.ReviewCautionReviews__title {
  @include text-title;
  padding-top: 10px;
  padding-bottom: 32px;

  @include media-breakpoint-each(tablet, mobile) {
    position: sticky;
    left: $padding-horizontal-tablet;
    width: calc(100vw - #{$padding-horizontal-tablet * 2});
  }
}

.ReviewCautionReviews__title-select {
  margin-right: 8px;
}

.ReviewCautionReviews__dashboard {
  background-color: $color-grey-05;
  border-radius: 12px;
  min-width: 1110px;
  max-width: 1330px;
  padding: 20px 24px;
  display: flex;
  flex-direction: row;
  gap: 14px;

  @include media-breakpoint-each(mobile) {
    min-width: auto;
  }
}
</style>

<i18n locale="ko">
{
  "recent_days": "최근 {0}일",
  "tooltip": "{start_date} ~ {end_date}\n({recent_days}일 전 오전 0시부터 현재까지)",
  "negative_review": {
    "label": "AI 부정 리뷰",
    "value_info": "(관리전/전체)"
  },
  "reported_review": {
    "label": "신고 리뷰",
    "value_info": "(관리전/전체)"
  },
  "keyword_review": {
    "label": "특정 검색어 리뷰",
    "value_info": "(관리전/전체)"
  }
}
</i18n>
