<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppInfoBox>
      <AppMarkdown
        :text="
          $t('info_box.description', { link: $t('info_box.document_link') })
        "
      />
    </AppInfoBox>
    <AppResourceTable
      table-id="bulk-review-csv-histories-table"
      :table-name="$t('csv_table.title')"
      :resources="bulk_review_csv_histories"
      :columns="COLUMNS"
      :rows="rows"
      @paginate="paginate"
    >
      <template #additional-table-searchbar>
        <AppSelect
          v-model="resourceParams.status"
          :options="CSV_STATUS_SEARCH_OPTIONS"
          @change="searchResource"
        />
      </template>
      <template #cell="{ column, value }">
        <template v-if="column === 'brandCsvStatus'">
          <AppBadge
            :badge-style="value.badge.style"
            :label="value.badge.label"
            :tooltip="value.tooltipMessage"
          />
        </template>
        <template v-else-if="column === 'brandCsvFile'">
          <AppButton>
            <a :href="value">
              {{ $t('download_csv_file') }}
            </a>
          </AppButton>
        </template>
        <template v-else-if="column === 'manageReviews' && !!value">
          <AppButton
            class="ReviewBulkReviewCsvsHistory__manage-reviews-button"
            :label="$t('show_reviews')"
            :to="`/review/reviews?bulk_review_csv_history_id=${value.id}`"
            type="link"
            :disabled="value.searchButtonDisabled"
            :tooltip="
              value.searchButtonDisabled
                ? $t('search_button_tooltip_message')
                : null
            "
          />
          <AppButton
            class="ReviewBulkReviewCsvsHistory__manage-reviews-button"
            :label="$t('delete_reviews')"
            button-style="red-outline"
            :disabled="value.deleteButtonDisabled"
            :tooltip="value.deleteButtonTooltipMessage"
            @click="deleteReviews(value.id)"
          />
        </template>
        <template v-else-if="column === 'error' && !!value">
          <AppButton
            :disabled="!value.errorCsvDownloadUrl"
            :tooltip="value.errorCsvDownloadTooltip"
          >
            <a :href="value.errorCsvDownloadUrl">
              {{ $t('download_error_csv_file') }}
            </a>
          </AppButton>
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import BrandCsvType from '@/enums/BrandCsvType';
import BulkReviewCsvHistoryStatus from '@/enums/BulkReviewCsvHistoryStatus';
import BulkReviewCsvHistoryReviewsDeleteStatus from '@/enums/BulkReviewCsvHistoryReviewsDeleteStatus';
import ResourceView from '@/mixins/ResourceView';

export default {
  name: 'ReviewBulkReviewCsvsHistory',
  mixins: [ResourceView],
  data() {
    return {
      COLUMNS: [
        {
          id: 'bulkReviewCsvId',
          label: this.$t('csv_table.index'),
          minWidth: '90px'
        },
        {
          id: 'brandCsvType',
          label: this.$t('csv_table.csv_type'),
          minWidth: '90px'
        },
        {
          id: 'date',
          label: this.$t('csv_table.date'),
          type: 'pre',
          headerAlign: 'center',
          align: 'left',
          minWidth: '200px'
        },
        {
          id: 'brandCsvStatus',
          label: this.$t('csv_table.csv_status'),
          minWidth: '90px'
        },
        {
          id: 'resultSummary',
          label: this.$t('csv_table.result_summary'),
          type: 'pre',
          headerAlign: 'center',
          align: 'left',
          minWidth: '150px'
        },
        {
          id: 'brandCsvFile',
          label: this.$t('csv_table.brand_csv_file'),
          headerAlign: 'center',
          align: 'center',
          minWidth: '150px'
        },
        {
          id: 'manageReviews',
          label: this.$t('csv_table.manage_reviews'),
          headerAlign: 'center',
          align: 'center',
          minWidth: '150px'
        },
        {
          id: 'error',
          label: this.$t('csv_table.error'),
          headerAlign: 'center',
          align: 'center',
          minWidth: '180px'
        }
      ],
      ERROR_SHOWABLE_STATUSES: [
        BulkReviewCsvHistoryStatus.FAILED,
        BulkReviewCsvHistoryStatus.PARTIALLY_FAILED
      ],
      CSV_STATUS_SEARCH_OPTIONS: [
        { label: this.$t('csv_status.all') },
        {
          label: this.$t('csv_status.import_pending'),
          value: BulkReviewCsvHistoryStatus.PENDING
        },
        {
          label: this.$t('csv_status.importing'),
          value: BulkReviewCsvHistoryStatus.PROCESSING
        },
        {
          label: this.$t('csv_status.imported'),
          value: BulkReviewCsvHistoryStatus.COMPLETE
        },
        {
          label: this.$t('csv_status.import_failed'),
          value: BulkReviewCsvHistoryStatus.FAILED
        },
        {
          label: this.$t('csv_status.imported_partial'),
          value: BulkReviewCsvHistoryStatus.PARTIALLY_FAILED
        }
      ]
    };
  },
  computed: {
    ...mapState(['env']),
    ...mapState('reviewBulkReviewCsv', ['bulk_review_csv_histories']),
    ...mapGetters('session', ['adminLocale']),
    ...mapGetters('reviewBulkReviewCsv', [
      'isFetchingBulkReviewCsvs',
      'originalFileDownloadUrl',
      'errorFileDownloadUrl'
    ]),
    isLoading() {
      return (
        this.bulk_review_csv_histories.isNull || this.isFetchingBulkReviewCsvs
      );
    },
    rows() {
      const { formatDateTime } = this.$options.filters;
      const { total_count, page, per } = this.bulk_review_csv_histories;
      const idStart = total_count - (page - 1) * per;

      return this.bulk_review_csv_histories.items.map((history, index) => {
        const {
          id,
          brand_csv_type,
          created_at,
          finished_at,
          processing,
          status,
          error_message,
          success_count,
          failure_count,
          reviews_delete_status
        } = history;
        return {
          bulkReviewCsvId: idStart - index,
          brandCsvType: BrandCsvType.t(brand_csv_type),
          date: this.$t('csv_date', {
            created_at: formatDateTime(created_at),
            imported_at: processing ? '-' : formatDateTime(finished_at)
          }),
          brandCsvStatus: {
            badge: this.statusBadgeInfo(status),
            tooltipMessage:
              status === BulkReviewCsvHistoryStatus.FAILED
                ? this.errorTooltipMessage(error_message)
                : null
          },
          brandCsvFile: this.originalFileDownloadUrl(id),
          resultSummary: this.resultSummary({
            status,
            success_count,
            failure_count,
            error_message
          }),
          manageReviews:
            success_count > 0 &&
            [
              BulkReviewCsvHistoryStatus.COMPLETE,
              BulkReviewCsvHistoryStatus.PARTIALLY_FAILED
            ].includes(status)
              ? {
                  id,
                  deleteButtonDisabled:
                    reviews_delete_status !==
                    BulkReviewCsvHistoryReviewsDeleteStatus.NONE,
                  deleteButtonTooltipMessage: this.deleteReviewsTooltipMessage(
                    reviews_delete_status
                  ),
                  searchButtonDisabled:
                    reviews_delete_status ===
                    BulkReviewCsvHistoryReviewsDeleteStatus.COMPLETE
                }
              : null,
          error: this.ERROR_SHOWABLE_STATUSES.includes(status)
            ? {
                errorCsvDownloadUrl:
                  status === BulkReviewCsvHistoryStatus.PARTIALLY_FAILED
                    ? this.errorFileDownloadUrl(id)
                    : null,
                errorCsvDownloadTooltip:
                  status === BulkReviewCsvHistoryStatus.FAILED
                    ? this.$t('download_error_csv_tooltip')
                    : null
              }
            : null
        };
      });
    }
  },
  watch: {
    bulk_review_csv_histories() {
      const value = this.bulk_review_csv_histories.items
        .filter(h => h.processing)
        .map(h => h.id);
      this.SET_POLL_PARAMS({
        key: 'bulk_review_csv_history_ids',
        value: value
      });
    }
  },
  beforeDestroy() {
    this.UNSET_POLL_PARAMS('bulk_review_csv_history_ids');
  },
  methods: {
    ...mapActions('reviewBulkReviewCsv', [
      'fetchBulkReviewCsvs',
      'deleteBulkReviews'
    ]),
    ...mapActions('dialog', ['confirm']),
    ...mapMutations('poll', ['SET_POLL_PARAMS', 'UNSET_POLL_PARAMS']),
    fetchResource(params) {
      this.fetchBulkReviewCsvs(params);
    },
    resultSummary({ status, success_count, failure_count, error_message }) {
      switch (status) {
        case BulkReviewCsvHistoryStatus.FAILED:
          if (this.adminLocale === 'ko') {
            return error_message?.replace(/\((detail).*\)$/, '')?.trim();
          }
          return this.$t('csv_result_summary.failed');
        case BulkReviewCsvHistoryStatus.COMPLETE:
        case BulkReviewCsvHistoryStatus.PARTIALLY_FAILED:
          return this.$t('csv_result_summary.default', {
            success_count,
            failure_count
          });
        default:
          return '-';
      }
    },
    errorTooltipMessage(error_message) {
      return error_message?.match(/\((detail).*\)$/g)
        ? this.$t('import_failed_with_system_error')
        : this.$t('import_failed_with_header_missing');
    },
    statusBadgeInfo(status) {
      switch (status) {
        case BulkReviewCsvHistoryStatus.PENDING:
          return {
            style: 'default',
            label: this.$t('csv_status.import_pending')
          };
        case BulkReviewCsvHistoryStatus.PROCESSING:
          return {
            style: 'mint-green-outline',
            label: this.$t('csv_status.importing')
          };
        case BulkReviewCsvHistoryStatus.COMPLETE:
          return { style: 'mint-green', label: this.$t('csv_status.imported') };
        case BulkReviewCsvHistoryStatus.FAILED:
          return { style: 'red', label: this.$t('csv_status.import_failed') };
        case BulkReviewCsvHistoryStatus.PARTIALLY_FAILED:
          return {
            style: 'mango',
            label: this.$t('csv_status.imported_partial')
          };
      }
    },
    deleteReviewsTooltipMessage(reviews_delete_status) {
      switch (reviews_delete_status) {
        case BulkReviewCsvHistoryReviewsDeleteStatus.PENDING:
        case BulkReviewCsvHistoryReviewsDeleteStatus.PROCESSING:
          return this.$t('delete_reviews_processing');
        case BulkReviewCsvHistoryReviewsDeleteStatus.COMPLETE:
          return this.$t('delete_reviews_complete');
        default:
          return null;
      }
    },
    deleteReviews(bulkReviewCsvHistoryId) {
      this.confirm({
        title: this.$t('delete_reviews_confirm.title'),
        message: this.$t('delete_reviews_confirm.message')
      }).then(result => {
        if (!result) return;

        this.deleteBulkReviews(bulkReviewCsvHistoryId);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewBulkReviewCsvsHistory__manage-reviews-button {
  & + & {
    margin-top: 8px;
    margin-left: 0px;
  }
}

::v-deep {
  .AppResourceTable__body-row {
    height: 90px;
  }
}
</style>

<i18n locale="ko">
{
  "csv_table": {
    "title": "리뷰 일괄 등록 내역",
    "index": "ID",
    "csv_type": "리뷰 출처",
    "date": "날짜",
    "csv_status": "상태",
    "result_summary": "결과",
    "brand_csv_file": "업로드한 파일",
    "manage_reviews": "리뷰 관리",
    "error": "등록 오류"
  },
  "csv_date": "등록일: {created_at}\n완료일: {imported_at}",
  "csv_result_summary": {
    "default": "등록: {success_count}\n실패: {failure_count}"
  },
  "csv_status": {
    "all": "모든 상태",
    "import_pending": "대기 중",
    "importing": "등록 중",
    "imported": "등록 완료",
    "imported_partial": "부분 실패",
    "import_failed": "전체 실패"
  },
  "download_csv_file": "파일 다운로드",
  "download_error_csv_file": "오류 내역 받아보기",
  "download_error_csv_tooltip": "모든 항목 등록이 실패했습니다. '파일 다운로드' 버튼을 이용해주세요.",
  "import_failed_with_system_error": "시스템 오류가 발생했습니다. 재시도 후 오류가 반복되면 크리마로 문의해주세요.",
  "import_failed_with_header_missing": "CSV 파일에서 필수 항목이 누락되지 않았는지 확인해주세요.",
  "info_box": {
    "description": "기능 사용 중 궁금하신 점은 [안내 문서]({link})를 참고해주시고, 추가 문의사항은 물음표 버튼 > 채팅 상담하기로 전달해주세요.",
    "document_link": "https://help-review.cre.ma/hc/ko/articles/42557283252505--%EB%A6%AC%EB%B7%B0-%EB%A6%AC%EB%B7%B0-%EC%9D%BC%EA%B4%84-%EB%93%B1%EB%A1%9D-%ED%9B%84-%EB%93%B1%EB%A1%9D%ED%95%9C-%EB%A6%AC%EB%B7%B0%EA%B0%80-%EB%B3%B4%EC%9D%B4%EC%A7%80-%EC%95%8A%EC%95%84%EC%9A%94"
  },
  "delete_reviews": "리뷰 일괄삭제",
  "delete_reviews_processing": "리뷰를 일괄 삭제 중입니다.",
  "delete_reviews_complete": "삭제할 리뷰가 없습니다.",
  "delete_reviews_confirm": {
    "title": "등록한 리뷰를 일괄 삭제합니다.",
    "message": "해당 CSV를 이용해 등록한 리뷰를 일괄 삭제합니다.\n진행하시겠습니까?"
  },
  "show_reviews": "리뷰 모아보기",
  "search_button_tooltip_message": "등록된 리뷰가 없습니다."
}
</i18n>

<i18n locale="en">
{
  "csv_result_summary": {
    "failed": "System error"
  }
}
</i18n>
