<template>
  <AppDropdownMenuButton v-bind="{ label, buttonStyle, menuItems }" />
</template>

<script>
import api from '@/lib/api';
import { mapActions } from 'vuex';
import DialogSize from '@/enums/DialogSize';
import HttpStatus from '@/enums/HttpStatus';
import ChannelFeedStatus from '@/enums/ChannelFeedStatus';

export default {
  name: 'ChannelFeedManageButton',
  props: {
    feed: { type: Object, required: true },
    showStatusLabel: { type: Boolean, default: false }
  },
  computed: {
    label() {
      if (this.showStatusLabel) return ChannelFeedStatus.t(this.feed.status);
      else return this.$t('app.manage');
    },
    buttonStyle() {
      if (!this.showStatusLabel) return;
      return this.feed.status == ChannelFeedStatus.ENABLED
        ? 'mint-green'
        : 'red-outline';
    },
    menuItems() {
      return [this.feedStatusMenuItem, this.feedMenuItems, this.deleteMenuItem];
    },
    feedStatusMenuItem() {
      return [ChannelFeedStatus.ENABLED, ChannelFeedStatus.DISABLED].map(
        status => {
          const selected = this.feed.status === status;
          return {
            label: ChannelFeedStatus.t(status),
            selected,
            clickHandler: ({ close }) => {
              this.updateFeedStatus(status);
              close();
            }
          };
        }
      );
    },
    feedMenuItems() {
      return [
        {
          label: this.$t('menu_item.copy_feed_url'),
          tooltip: this.$t('menu_tooltip.copy_feed_url'),
          clickHandler: this.onClickCopyFeedUrl,
          superAdmin: true
        },
        {
          label: this.$t('menu_item.copy_feed_csv_url'),
          tooltip: this.$t('menu_tooltip.copy_feed_csv_url'),
          clickHandler: this.onClickCopyFeedCsvUrl,
          superAdmin: true
        },
        {
          label: this.$t('app.clone'),
          tooltip: this.$t('menu_tooltip.clone_feed'),
          clickHandler: ({ close }) => this.onClickCloneFeed(close)
        }
      ];
    },
    deleteMenuItem() {
      return [
        {
          label: this.$t('menu_item.delete_feed'),
          style: 'danger',
          clickHandler: this.onClickDeleteFeed
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    updateFeedStatus(status) {
      if (status === this.feed.status) return;

      api
        .patch(`/channel/feeds/${this.feed.id}/status`, { feed: { status } })
        .then(() => this.$emit('change-status', status));
    },
    onClickDeleteFeed() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: this.$t('delete_dialog.title'),
          markdownText: this.$t('delete_dialog.message'),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('close', this.deleteFeed);
      });
    },
    deleteFeed() {
      api.delete(`/channel/feeds/${this.feed.id}`).then(() => {
        this.$emit('deleted');
        this.createToast(this.$t('deleted'));
      });
    },
    onClickCloneFeed(close) {
      this.cloneFeed();
      close();
    },
    cloneFeed() {
      api
        .post(
          '/channel/feeds/clone',
          { id: this.feed.id },
          {
            successMessage: this.$t('clone_feed.success', {
              name: this.feed.name
            }),
            silent: true
          }
        )
        .then(() => this.$emit('cloned'))
        .catch(error => {
          if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY)
            this.createToast(
              this.$t('clone_feed.failure', { name: this.feed.name })
            );
          else error.errorHandler();
        });
    },
    onClickCopyFeedUrl() {
      this.$copyText(this.feed.ad_feed_url);
      this.createToast(this.$t('copied.copy_feed_url'));
    },
    onClickCopyFeedCsvUrl() {
      this.$copyText(this.feed.ad_feed_csv_url);
      this.createToast(this.$t('copied.copy_feed_csv_url'));
    }
  }
};
</script>

<i18n locale="ko">
{
  "menu_item": {
    "delete_feed": "광고 피드 삭제",
    "copy_feed_url": "광고 피드 URL 복사(XML)",
    "copy_feed_csv_url": "광고 피드 URL 복사(CSV)"
  },
  "menu_tooltip": {
    "copy_feed_url": "(메타, 구글 지원) 클릭해서 클립보드에 복사하기",
    "copy_feed_csv_url": "(당근, 구글 지원) 클릭해서 클립보드에 복사하기",
    "clone_feed": "동일한 조건의 피드를 복제하여 생성합니다."
  },
  "clone_feed": {
    "success": "{name}을 복제 완료하였습니다.",
    "failure": "{name}의 이름이 너무 길어 복제할 수 없습니다."
  },
  "delete_dialog": {
    "title": "광고 피드를 삭제하시겠습니까?",
    "message": "삭제된 광고 피드는 복원이 어렵습니다."
  },
  "deleted": "삭제하였습니다.",
  "copied": {
    "copy_feed_url": "광고 피드 URL(XML)을 클립보드에 복사했습니다.",
    "copy_feed_csv_url": "광고 피드 URL(CSV)을 클립보드에 복사했습니다."
  }
}
</i18n>
