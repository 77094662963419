<template>
  <Component :is="currentViewComponent" />
</template>

<script>
import KakaoStyleReviewImportStatus from '@/enums/KakaoStyleReviewImportStatus';
import KakaoStyleAgreement from './components/KakaoStyleAgreement';
import KakaoStyleSettings from './components/KakaoStyleSettings';
import { mapState } from 'vuex';

export default {
  name: 'ReviewImportKakaoStyle',
  components: { KakaoStyleAgreement, KakaoStyleSettings },
  computed: {
    ...mapState('kakaoStyle', ['reviewImportStatus']),
    currentViewComponent() {
      return this.reviewImportStatus ===
        KakaoStyleReviewImportStatus.NOT_REQUESTED
        ? KakaoStyleAgreement
        : KakaoStyleSettings;
    }
  }
};
</script>
