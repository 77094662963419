import Vue from 'vue';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';

const FETCH_BULK_REVIEW_CSVS_REQUEST_ID = 'FETCH_BULK_REVIEW_CSVS';

const state = {
  bulk_review_csv_histories: nullResources
};

const mutations = {
  SET_BULK_REVIEW_CSV_HISTORIES(state, bulk_review_csv_histories) {
    state.bulk_review_csv_histories = bulk_review_csv_histories;
  },
  UPDATE_BULK_REVIEW_CSV_HISTORY(state, bulk_review_csv_history) {
    const index = state.bulk_review_csv_histories.items.findIndex(
      h => h.id === bulk_review_csv_history.id
    );
    if (index !== -1)
      Vue.set(
        state.bulk_review_csv_histories.items,
        index,
        bulk_review_csv_history
      );
  }
};

const actions = {
  fetchBulkReviewCsvs({ commit }, params) {
    api.cancel(FETCH_BULK_REVIEW_CSVS_REQUEST_ID);
    api
      .get('/review/bulk_review_csvs', {
        requestId: FETCH_BULK_REVIEW_CSVS_REQUEST_ID,
        params
      })
      .then(({ data }) => {
        commit('SET_BULK_REVIEW_CSV_HISTORIES', data.bulk_review_csv_histories);
      });
  },
  createBulkReviewCsv(_, { formData }) {
    return api.post('/review/bulk_review_csvs', formData, { silent: true });
  },
  deleteBulkReviews({ commit }, bulkReviewCsvHistoryId) {
    api
      .delete(
        `/review/bulk_review_csvs/${bulkReviewCsvHistoryId}/delete_reviews`
      )
      .then(({ data }) => {
        commit('UPDATE_BULK_REVIEW_CSV_HISTORY', data.bulk_review_csv_history);
      });
  }
};

const getters = {
  isFetchingBulkReviewCsvs(_state, _getters, _rootState, rootGetters) {
    return rootGetters['request/isRequestPending'](
      FETCH_BULK_REVIEW_CSVS_REQUEST_ID
    );
  },
  originalFileDownloadUrl: (
    _state,
    _getters,
    _rootState,
    rootGetters
  ) => bulk_review_csv_history_id => {
    return rootGetters['pathWithBrandParams'](
      `/api/review/bulk_review_csvs/${bulk_review_csv_history_id}/download_original_file`
    );
  },
  errorFileDownloadUrl: (
    _state,
    _getters,
    _rootState,
    rootGetters
  ) => bulk_review_csv_history_id => {
    return rootGetters['pathWithBrandParams'](
      `/api/review/bulk_review_csvs/${bulk_review_csv_history_id}/download_error_file`
    );
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
