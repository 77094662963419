export default [
  {
    id: 'review_reports_reviews',
    path: '/review/reports',
    parentId: 'review_reports'
  },
  {
    id: 'review_reports_review_rate',
    path: '/review/reports/review-rate',
    parentId: 'review_reports'
  },
  {
    id: 'review_reports_mileage',
    path: '/review/reports/mileage',
    parentId: 'review_reports',
    isAccessible: getters => !getters.isShopifyBrand
  },
  {
    id: 'review_messages_chatbot_messages_settings',
    path: '/review/messages/chatbot_messages',
    parentId: 'review_messages_chatbot_messages',
    isAccessible: getters =>
      getters.isReviewMessagesAccessible && getters.isKoreanBrand
  },
  {
    id: 'review_messages_chatbot_messages_history',
    path: '/review/messages/chatbot_messages/history',
    parentId: 'review_messages_chatbot_messages',
    isAccessible: getters =>
      getters.isReviewMessagesAccessible && getters.isKoreanBrand
  },
  {
    id: 'review_messages_biz_messages_settings',
    path: '/review/messages/biz_messages/settings',
    parentId: 'review_messages_biz_messages',
    isAccessible: getters => getters.isKoreanBrand,
    isLaunched: getters => getters.isBizMessageSettingsMenuAccessible
  },
  {
    id: 'review_messages_biz_messages_history',
    path: '/review/messages/biz_messages',
    parentId: 'review_messages_biz_messages',
    isAccessible: getters => getters.isKoreanBrand
  },
  {
    id: 'review_messages_admin_biz_message',
    path: '/review/messages/admin/biz_message',
    parentId: 'review_messages_admin',
    superAdmin: true
  },
  {
    id: 'review_settings_manage',
    path: '/review/settings/basic',
    parentId: 'review_settings_basic',
    installationAgency: true
  },
  {
    id: 'review_settings_popup',
    path: '/review/settings/popup',
    parentId: 'review_settings_basic',
    installationAgency: true,
    isAccessible: getters => !getters.isReviewCampaignEnabled
  },
  {
    id: 'review_settings_engagement',
    path: '/review/settings/engagement',
    parentId: 'review_settings_basic',
    installationAgency: true,
    isAccessible: getters => getters.isReviewCampaignEnabled
  },
  {
    id: 'review_settings_mileage',
    path: '/review/settings/mileage',
    parentId: 'review_settings_basic',
    installationAgency: true
  },
  {
    id: 'review_settings_widget',
    path: '/review/settings/widget',
    parentId: 'review_settings_basic',
    installationAgency: true
  },
  {
    id: 'review_settings_design',
    path: '/review/settings/design',
    parentId: 'review_settings_basic',
    installationAgency: true
  },
  {
    id: 'review_settings_sync',
    path: '/review/settings/sync',
    parentId: 'review_settings_basic',
    installationAgency: true
  },
  {
    id: 'review_settings_widgets_product_reviews',
    path: '/review/settings/widgets',
    parentId: 'review_settings_widgets',
    installationAgency: true
  },
  {
    id: 'review_settings_widgets_reviews',
    path: '/review/settings/widgets/reviews',
    parentId: 'review_settings_widgets',
    installationAgency: true
  },
  {
    id: 'review_settings_widgets_category_reviews',
    path: '/review/settings/widgets/category_reviews',
    parentId: 'review_settings_widgets',
    installationAgency: true,
    isAccessible: getters => getters.isSuperAdminAccessible,
    superAdmin: true
  },
  {
    id: 'review_settings_widgets_switcher',
    path: '/review/settings/widgets/switcher',
    parentId: 'review_settings_widgets',
    installationAgency: true,
    isAccessible: getters => !getters.isShopifyBrand && !getters.isImwebBrand
  },
  {
    id: 'review_settings_widgets_products',
    path: '/review/settings/widgets/products',
    parentId: 'review_settings_widgets',
    installationAgency: true
  },
  {
    id: 'review_settings_widgets_etc',
    path: '/review/settings/widgets/etc',
    parentId: 'review_settings_widgets',
    superAdmin: getters => getters.isShopifyBrand,
    installationAgency: true
  },
  {
    id: 'review_settings_review_option_category_inclusion',
    path: '/review/settings/review_option/category_inclusion',
    parentId: 'review_settings_review_option',
    isAccessible: getters => getters.isReviewOptionV2Enabled,
    installationAgency: true
  },
  {
    id: 'review_settings_review_option_category_exclusion',
    path: '/review/settings/review_option/category_exclusion',
    parentId: 'review_settings_review_option',
    isAccessible: getters => getters.isReviewOptionV2Enabled,
    installationAgency: true
  },
  {
    id: 'review_settings_review_option_type_sets',
    path: '/review/settings/review_option/type_sets',
    parentId: 'review_settings_review_option',
    isAccessible: getters => getters.isReviewOptionV2Enabled,
    installationAgency: true
  },
  {
    id: 'review_settings_super_admin_manage',
    path: '/review/settings/super-admin',
    parentId: 'review_settings_super_admin',
    superAdmin: true
  },
  {
    id: 'review_settings_super_admin_sms',
    path: '/review/settings/super-admin/sms',
    parentId: 'review_settings_super_admin',
    superAdmin: true
  },
  {
    id: 'review_settings_super_admin_biz_message',
    path: '/review/settings/super-admin/biz-message',
    parentId: 'review_settings_super_admin',
    superAdmin: true
  },
  {
    id: 'review_settings_super_admin_service',
    path: '/review/settings/super-admin/service',
    parentId: 'review_settings_super_admin',
    superAdmin: true
  },
  {
    id: 'review_settings_super_admin_vue_widget',
    path: '/review/settings/super-admin/vue-widget',
    parentId: 'review_settings_super_admin',
    superAdmin: true
  },
  {
    id: 'review_data_request',
    path: '/review/data/request',
    parentId: 'review_data',
    skipReviewAccessible: true,
    isAccessible: getters =>
      getters.isPrivacyAccessible &&
      getters.isDatabaseCreated &&
      (getters.isReviewAccessible || getters.isReviewTerminated)
  },
  {
    id: 'review_data_request_history',
    path: '/review/data/request_history',
    parentId: 'review_data',
    skipReviewAccessible: true,
    isAccessible: getters =>
      getters.isPrivacyAccessible &&
      getters.isDatabaseCreated &&
      (getters.isReviewAccessible || getters.isReviewTerminated)
  },
  {
    id: 'review_smart_installation_widget_management_all',
    path: '/review/smart_installation/widget_management',
    parentId: 'review_smart_installation_widget_management',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_smart_installation_widget_management_product_detail',
    path: '/review/smart_installation/widget_management?tab=product_detail',
    parentId: 'review_smart_installation_widget_management',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_smart_installation_widget_management_review_board',
    path: '/review/smart_installation/widget_management?tab=review_board',
    parentId: 'review_smart_installation_widget_management',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_smart_installation_widget_management_main',
    path: '/review/smart_installation/widget_management?tab=main',
    parentId: 'review_smart_installation_widget_management',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_smart_installation_widget_management_product_list',
    path: '/review/smart_installation/widget_management?tab=product_list',
    parentId: 'review_smart_installation_widget_management',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_smart_installation_widget_management_product_search',
    path: '/review/smart_installation/widget_management?tab=product_search',
    parentId: 'review_smart_installation_widget_management',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_smart_installation_widget_management_my_orders',
    path: '/review/smart_installation/widget_management?tab=my_orders',
    parentId: 'review_smart_installation_widget_management',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_smart_installation_widget_management_my_reviews',
    path: '/review/smart_installation/widget_management?tab=my_reviews',
    parentId: 'review_smart_installation_widget_management',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_smart_installation_widget_management_myshop_main',
    path: '/review/smart_installation/widget_management?tab=myshop_main',
    parentId: 'review_smart_installation_widget_management',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_smart_installation_widget_management_event',
    path: '/review/smart_installation/widget_management?tab=event',
    parentId: 'review_smart_installation_widget_management',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_smart_installation_widget_management_specific',
    path: '/review/smart_installation/widget_management?tab=specific',
    parentId: 'review_smart_installation_widget_management',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_install_simulation_attach_widget_all',
    path: '/review/install_simulation/attach_widget',
    parentId: 'review_install_simulation_attach_widget',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_install_simulation_attach_widget_product_detail',
    path: '/review/install_simulation/attach_widget?tab=product_detail',
    parentId: 'review_install_simulation_attach_widget',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_install_simulation_attach_widget_review_board',
    path: '/review/install_simulation/attach_widget?tab=review_board',
    parentId: 'review_install_simulation_attach_widget',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_install_simulation_attach_widget_main',
    path: '/review/install_simulation/attach_widget?tab=main',
    parentId: 'review_install_simulation_attach_widget',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_install_simulation_attach_widget_product_list',
    path: '/review/install_simulation/attach_widget?tab=product_list',
    parentId: 'review_install_simulation_attach_widget',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_install_simulation_attach_widget_product_search',
    path: '/review/install_simulation/attach_widget?tab=product_search',
    parentId: 'review_install_simulation_attach_widget',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_install_simulation_attach_widget_my_orders',
    path: '/review/install_simulation/attach_widget?tab=my_orders',
    parentId: 'review_install_simulation_attach_widget',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_install_simulation_attach_widget_my_reviews',
    path: '/review/install_simulation/attach_widget?tab=my_reviews',
    parentId: 'review_install_simulation_attach_widget',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_install_simulation_attach_widget_myshop_main',
    path: '/review/install_simulation/attach_widget?tab=myshop_main',
    parentId: 'review_install_simulation_attach_widget',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_install_simulation_attach_widget_event',
    path: '/review/install_simulation/attach_widget?tab=event',
    parentId: 'review_install_simulation_attach_widget',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_install_simulation_attach_widget_specific',
    path: '/review/install_simulation/attach_widget?tab=specific',
    parentId: 'review_install_simulation_attach_widget',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'review_home_admin_onboarding',
    path: '/review/home/admin/onboarding',
    parentId: 'review_home_admin',
    superAdmin: true,
    isAccessible: getters => getters.isReviewOnboarding
  },
  {
    id: 'review_move_shop_builder_basic',
    path: '/review/move_shop_builder/basic',
    parentId: 'review_move_shop_builder'
  },
  {
    id: 'review_move_shop_builder_script',
    path: '/review/move_shop_builder/script',
    parentId: 'review_move_shop_builder'
  },
  {
    id: 'review_move_shop_builder_code_conversion',
    path: '/review/move_shop_builder/code_conversion',
    parentId: 'review_move_shop_builder'
  },
  {
    id: 'review_move_shop_builder_sync',
    path: '/review/move_shop_builder/sync',
    parentId: 'review_move_shop_builder'
  },
  {
    id: 'review_move_shop_builder_review',
    path: '/review/move_shop_builder/review',
    parentId: 'review_move_shop_builder'
  },
  {
    id: 'review_move_shop_builder_additional_service',
    path: '/review/move_shop_builder/additional_service',
    parentId: 'review_move_shop_builder'
  },
  {
    id: 'review_import_smartstore_products',
    path: '/review/import/smartstore',
    parentId: 'review_import_smartstore'
  },
  {
    id: 'review_import_smartstore_review_csv',
    path: '/review/import/smartstore/review_csv',
    parentId: 'review_import_smartstore'
  },
  {
    id: 'review_import_smartstore_product_csv',
    path: '/review/import/smartstore/product_csv',
    parentId: 'review_import_smartstore'
  },
  {
    id: 'review_import_admin_smartstore',
    path: '/review/import/admin',
    parentId: 'review_import_admin',
    superAdmin: true
  },
  {
    id: 'review_import_admin_kakao_style',
    path: '/review/import/admin/kakao-style',
    parentId: 'review_import_admin',
    superAdmin: true
  },
  {
    id: 'review_bulk_review_csvs_register',
    path: '/review/bulk_review_csvs/register',
    parentId: 'review_bulk_review_csvs',
    superAdmin: getters =>
      getters.isShopifyBrand && !getters.isBulkReviewCsvImportForShopifyEnabled
  },
  {
    id: 'review_bulk_review_csvs_history',
    path: '/review/bulk_review_csvs/history',
    parentId: 'review_bulk_review_csvs',
    superAdmin: getters =>
      getters.isShopifyBrand && !getters.isBulkReviewCsvImportForShopifyEnabled
  }
].map(item => ({ ...item, type: 'tab' }));
