<template>
  <div>
    <div
      v-if="description"
      class="ThePaymentDialogReviewPackagesTable__description"
    >
      {{ description }}
    </div>
    <AppTable
      table-id="ThePaymentDialogReviewPackagesTable"
      :columns="COLUMNS"
      :rows="rows"
    >
      <template #cell="{ row, column }">
        <template v-if="column.id === 'select'">
          <AppRadio
            :value="row.id"
            input-style="standalone"
            :name="name"
            :selected-value="ids[0]"
            :disabled="row.disabled"
            @change="$emit('change', row.id ? [row.id] : [])"
          />
        </template>
        <template v-else-if="column.id === 'name'">
          <div
            :class="[
              'ThePaymentDialogReviewPackagesTable__name',
              row.disabled
                ? 'ThePaymentDialogReviewPackagesTable__name--disabled'
                : ''
            ]"
          >
            {{ row.name }}
          </div>
          <AppButton
            v-if="row.disabled"
            :label="$t('payment_cancel_button')"
            button-style="underline"
            @click="openCancelPaymentConfirm(row.payment)"
          />
        </template>
        <template v-else-if="column.id === 'promotion'">
          <template v-if="row.id">
            <template
              v-if="
                ['special_offer', 'long_term_discount'].includes(row.promotion)
              "
            >
              <div>
                <span
                  class="ThePaymentDialogReviewPackagesTable__promotion-percentage"
                >
                  {{ getPromotionPercentageFormat(row) }}
                </span>
                <AppNumber
                  class="ThePaymentDialogReviewPackagesTable__promotion-org-price"
                  :value="row.price"
                  unit="currency.krw"
                />
              </div>
              <div>
                <AppNumber :value="row.final_price" unit="currency.krw" />
                (<i18n path="promotion.monthly_price"
                  ><AppNumber
                    :value="Math.floor(row.final_price / row.months)"
                    unit="currency.krw"/></i18n
                >)
              </div>
            </template>
            <template v-else-if="row.promotion === 'bonus_credits'">
              <div
                class="ThePaymentDialogReviewPackagesTable__promotion-default"
              >
                {{ $t('promotion.bonus_credits', [row.free_credit]) }}
              </div>
            </template>
            <div v-else-if="row.promotion === 'monthly_bonus_point'">
              {{
                $t('promotion.monthly_bonus_point', [row.monthly_bonus_point])
              }}
              <div
                class="ThePaymentDialogReviewPackagesTable__promotion-monthly-free-dispatchable-sms-count"
              >
                {{
                  $t('promotion.monthly_free_dispatchable_sms_count', [
                    Math.floor(row.monthly_bonus_point / row.sms_price)
                  ])
                }}
              </div>
            </div>
            <template v-else-if="row.promotion === 'discount'">
              <div
                class="ThePaymentDialogReviewPackagesTable__promotion-default"
              >
                {{ $t('promotion.discount', [discountPercentage(row)]) }}
              </div>
            </template>
            <template v-else>
              -
            </template>
          </template>
        </template>
        <template v-else-if="column.id === 'price'">
          <template v-if="row.id">
            <div class="ThePaymentDialogReviewPackagesTable__price">
              <AppNumber
                :value="row.final_price_with_vat"
                unit="currency.krw"
              />
            </div>
            <div class="ThePaymentDialogReviewPackagesTable__price-calculation">
              <AppNumber :value="row.final_price" unit="currency.krw" />
              <span>+{{ $t('vat') }} </span>
              <AppNumber
                :value="row.final_price_with_vat - row.final_price"
                unit="currency.krw"
              />
            </div>
          </template>
        </template>
      </template>
      <template #no-data>
        <div class="ThePaymentDialogReviewPackagesTable__no-data">
          <AppText :text="$t('payment_block.guide')" />
          <AppButton
            class="ThePaymentDialogReviewPackagesTable__review-intro-button"
            :label="$t('payment_block.review_intro_button')"
            @click="$emit('go-to-review-intro')"
          />
        </div>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'ThePaymentDialogReviewPackagesTable',
  model: { prop: 'ids', event: 'change' },
  props: {
    ids: { type: Array, required: true },
    name: { type: String, required: true },
    packages: { type: Array, required: true },
    multiple: { type: Boolean, default: false },
    description: { type: String, default: null }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'select', label: this.$t('column.select') },
        { id: 'name', label: this.$t('column.name') },
        { id: 'promotion', label: this.$t('column.promotion'), align: 'right' },
        { id: 'price', label: this.$t('column.price'), align: 'right' }
      ]
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('session', [
      'isIdleEasyJoinAvailable',
      'isPaymentBlockForIdleEasyJoinEnabled'
    ]),
    ...mapGetters('payment', ['packageShortName']),
    rows() {
      if (
        this.isIdleEasyJoinAvailable &&
        this.isPaymentBlockForIdleEasyJoinEnabled
      ) {
        return [];
      }

      const packagesToShow = this.packages.some(
        p => p.promotion === 'special_offer'
      )
        ? this.packages.filter(p => p.promotion === 'special_offer')
        : this.packages;

      return [
        ...(this.multiple ||
        (!this.packages.length && this.$scopedSlots['no-data'])
          ? []
          : [{ name: this.$t('no_selection') }]),
        ...packagesToShow.map(p => ({
          ...p,
          name: this.packageShortName(p),
          disabled: this.isReviewPackagePendingRequest(p)
        }))
      ];
    },
    brand_name() {
      return this.currentBrand.name;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    getPromotionPercentageFormat(row) {
      if (row.promotion === 'special_offer') {
        return this.$t('promotion.special_offer', [
          this.brand_name,
          this.discountPercentage(row)
        ]);
      } else {
        return this.$t('promotion.long_term_discount', [
          this.discountPercentage(row)
        ]);
      }
    },
    openCancelPaymentConfirm(payment) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: this.$t('dialog.title'),
          markdownText: this.$t('dialog.context', [
            this.formatDateTime(payment.created_at),
            payment.danal_itemname
          ]),
          width: DialogSize.SMALL,
          closeButtonLabel: this.$t('dialog.close_button'),
          cancelButtonStyle: 'grey-outline'
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => this.cancelPayment(payment.id));
      });
    },
    discountPercentage({ price, final_price }) {
      return Math.floor(((price - final_price) / price) * 100);
    },
    cancelPayment(id) {
      api
        .post(`/payment/payments/${id}/cancel`)
        .finally(() => this.$emit('refresh'));
    },
    isReviewPackagePendingRequest(p) {
      return p.is_special_offer && p.is_pending_requested;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
.ThePaymentDialogReviewPackagesTable__description {
  margin-bottom: 16px;
}

.ThePaymentDialogReviewPackagesTable__promotion-default {
  color: $color-blue;
}

.ThePaymentDialogReviewPackagesTable__promotion-percentage {
  font-size: 12px;
  color: $color-blue;
}

.ThePaymentDialogReviewPackagesTable__promotion-org-price {
  margin-left: 4px;
  font-size: 12px;
  text-decoration: line-through;
}

.ThePaymentDialogReviewPackagesTable__promotion-monthly-free-dispatchable-sms-count {
  font-size: 12px;
  color: $color-grey-50;
}

.ThePaymentDialogReviewPackagesTable__name--disabled {
  color: $color-grey-40;
}

.ThePaymentDialogReviewPackagesTable__price {
  @include text-label;
}

.ThePaymentDialogReviewPackagesTable__price-calculation {
  @include text-caption;
}

.ThePaymentDialogReviewPackagesTable__no-data {
  @include text-content;

  display: inline-block;
  margin: 46px auto;
  text-align: center;
}

.ThePaymentDialogReviewPackagesTable__review-intro-button {
  margin-top: 8px;
}

::v-deep {
  .ThePaymentDialogReviewPackagesTable__select {
    width: 76px;
  }

  .ThePaymentDialogReviewPackagesTable__name {
    width: 260px;
  }

  .ThePaymentDialogReviewPackagesTable__price {
    width: 216px;
  }
}
</style>

<i18n locale="ko">
{
  "column": {
    "select": "상품 선택",
    "name": "상품 종류",
    "promotion": "특별 혜택",
    "price": "결제 가격"
  },
  "promotion": {
    "special_offer": "{0} 특별결제 {1}% ↓",
    "long_term_discount": "장기 계약 {0}% ↓",
    "monthly_price": "월 {0}",
    "bonus_credits": "보너스 {0}크레딧",
    "monthly_bonus_point": "매달 {0}포인트 제공",
    "monthly_free_dispatchable_sms_count": "SMS 기준 {0} 건 발송 가능",
    "discount": "정상가 대비 {0}% 할인"
  },
  "dialog": {
    "title": "입금 신청을 취소하시겠습니까?",
    "context": "<pre>{0}에 신청한</pre>{1}<br>입금 신청을 취소합니다.",
    "close_button": "입금 신청 취소"
  },
  "no_selection": "결제할 상품을 선택해주세요.",
  "payment_cancel_button": "입금 신청 취소",
  "vat": "부가세",
  "payment_block": {
    "guide": "크리마 리뷰에 관심이 있으신가요?",
    "review_intro_button": "크리마 리뷰 시작하기"
  }
}
</i18n>
