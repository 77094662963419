import { constructEnum } from '@/lib/enum';

const InstallSlotType = constructEnum('InstallSlotType', {
  PRODUCT_DETAIL_DEFAULT: 10,
  PRODUCT_DETAIL_DEFAULT_ADDITIONAL: 11,
  PRODUCT_DETAIL_PRODUCT_THUMBNAIL_AFTER: 12,
  PRODUCT_DETAIL_DESC_SECTION_TOP: 13,
  MAIN_BOTTOM: 20,
  MAIN_CUSTOM: 21,
  REVIEW_BOARD: 30,
  EVENT: 40,
  CATEGORY: 50,
  COUNT_AND_SCORE: 60,
  COUNT_AND_SCORE_CUSTOM: 61
});

InstallSlotType.PRODUCT_DETAIL_SLOT_TYPES = [
  InstallSlotType.PRODUCT_DETAIL_DEFAULT,
  InstallSlotType.PRODUCT_DETAIL_PRODUCT_THUMBNAIL_AFTER,
  InstallSlotType.PRODUCT_DETAIL_DESC_SECTION_TOP
];

InstallSlotType.MAIN_SLOT_TYPES = [
  InstallSlotType.MAIN_BOTTOM,
  InstallSlotType.MAIN_CUSTOM
];

InstallSlotType.ETC_SLOT_TYPES = [
  InstallSlotType.EVENT,
  InstallSlotType.CATEGORY
];

InstallSlotType.installableTypes = function(slotType) {
  if (!slotType) return [];

  if (slotType === InstallSlotType.PRODUCT_DETAIL_DEFAULT)
    return [
      InstallSlotType.PRODUCT_DETAIL_DEFAULT,
      InstallSlotType.PRODUCT_DETAIL_DEFAULT_ADDITIONAL
    ];
  else return [slotType];
};

export default InstallSlotType;
