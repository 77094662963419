<template>
  <div class="TutorialSummaryCard">
    <AppImage
      class="TutorialSummaryCard__img"
      :src="imageUrl"
      type="rounded"
      :border-radius="12"
    />
    <div class="TutorialSummaryCard__main">
      <div class="TutorialSummaryCard__main-head">
        <div class="TutorialSummaryCard__main-title">{{ title }}</div>
        <div v-if="showTag" class="TutorialSummaryCard__main-tag">
          {{ $t('tag') }}
        </div>
      </div>
      <div class="TutorialSummaryCard__main-body">
        <AppText
          class="TutorialSummaryCard__main-body-description"
          :text="description"
        />
        <AppExternalLinkWithIcon
          v-if="!!detailLink"
          class="TutorialSummaryCard__main-body-link"
          :label="$t('link')"
          :to="detailLink"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TutorialSummaryCard',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    detailLink: {
      type: String,
      default: null
    },
    imageUrl: {
      type: String,
      required: true
    },
    showTag: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/vars/_colors.scss';

.TutorialSummaryCard {
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.3);
  display: flex;
  width: 400px;
  border-radius: 12px;
}

.TutorialSummaryCard__img {
  width: 146px;

  ::v-deep .AppImage__img {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.TutorialSummaryCard__main {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 12px 16px;
}

.TutorialSummaryCard__main-title {
  @include text-sub-title;
}

.TutorialSummaryCard__main-tag {
  @include text-content-tag;
  color: $color-green;
}

.TutorialSummaryCard__main-body {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.TutorialSummaryCard__main-body-description {
  @include text-caption-dark;
}

.TutorialSummaryCard__main-body-link {
  @include text-caption;
}
</style>

<i18n locale="ko">
{
  "tag": "튜토리얼 진행중",
  "link": "상세 안내 문서"
}
</i18n>
