<template>
  <div class="TheFloater">
    <div v-if="isReviewSettingsTutorialVisible" class="TheFloater__tutorial">
      <TheReviewSettingsTutorial />
      <TutorialGuideDialog
        v-if="introStatus === TutorialStatus.IN_PROGRESS"
        class="TheFloater__tutorial-dialog"
        :title="$t('tutorial.title')"
        :description="$t('tutorial.description')"
        :event-bus="eventBus"
      />
    </div>
    <TheHelp v-if="isTheHelpVisible" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Vue from 'vue';

import TheReviewSettingsTutorial from '@/layouts/components/TheTutorial/TheReviewSettingsTutorial';
import TutorialGuideDialog from '@/layouts/components/TheTutorial/TutorialGuideDialog';
import BrandInsightStatus from '@/enums/BrandInsightStatus';
import TutorialStatus from '@/enums/TutorialStatus';
import TheHelp from '@/layouts/TheHelp';

export default {
  name: 'TheFloater',
  components: { TheHelp, TheReviewSettingsTutorial, TutorialGuideDialog },
  data() {
    return {
      eventBus: new Vue(),
      TutorialStatus
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapState('tutorial', ['isReviewSettingsTutorialVisible', 'introStatus']),
    ...mapGetters('menu', ['selectedMainMenuItemId']),
    isTheHelpVisible() {
      return (
        ['review', 'fit', 'target', 'display'].includes(
          this.selectedMainMenuItemId
        ) ||
        ('analytics' === this.selectedMainMenuItemId &&
          BrandInsightStatus.isMenuAccessible(this.currentBrand.insight_status))
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_z-indexes.scss';

.TheFloater {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 32px;
  bottom: 16px;
  z-index: $z-index-help;
}

.TheFloater__tutorial {
  position: relative;
}

.TheFloater__tutorial-dialog {
  position: absolute;
  left: auto;
  right: calc(100% + 16px);
}

.TheReviewSettingsTutorial {
  margin-bottom: 4px;
}
</style>

<i18n locale="ko">
{
  "tutorial": {
    "title": "리뷰 기본 설정 가이드 시작",
    "description": "먼저 알림톡 발송 설정 가이드를 시작합니다\n이후 이 메뉴에서 원하는 항목을 클릭하면 가이드를 시작합니다"
  }
}
</i18n>
