var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"ui-section"},[_c('div',{staticClass:"ui-section__title"},[_vm._v("Basic Usage")]),_c('ReviewStatCard',{attrs:{"icon":"icon-new","label":_vm.$t('new.label'),"value":10}}),_c('ReviewStatCard',{attrs:{"icon":"icon-mileage","label":_vm.$t('mileage.label'),"value":21}}),_c('ReviewStatCard',{attrs:{"icon":"icon-comment","label":_vm.$t('comment.label'),"value":15}})],1),_c('div',{staticClass:"ui-section"},[_c('div',{staticClass:"ui-section__title"},[_vm._v("With Total Value Usage")]),_c('ReviewStatCard',{attrs:{"icon":"icon-negative-review","label":_vm.$t('negative_review.label'),"value":{
        currentValue: 21,
        totalValue: 30,
        valueInfo: '(관리전/전체)'
      }}}),_c('ReviewStatCard',{attrs:{"icon":"icon-reported-review","label":_vm.$t('reported_review.label'),"value":{
        currentValue: 21,
        totalValue: 21,
        valueInfo: '(관리전/전체)'
      }}}),_c('ReviewStatCard',{attrs:{"icon":"icon-keyword-review","label":_vm.$t('keyword_review.label'),"value":{
        currentValue: 21,
        totalValue: 21,
        valueInfo: '(관리전/전체)'
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }