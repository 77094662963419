<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title', [formatUser(review)])"
    :form-props="{
      ...formProps,
      focusGroupId: 'mileage_amount_cents',
      submitLabel: $t('submit_label'),
      submittingLabel: $t('submitting_label')
    }"
    v-on="formEvents"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DialogSize from '@/enums/DialogSize';
import HttpStatus from '@/enums/HttpStatus';
import ReviewPointType from '@/enums/ReviewPointType';
import DialogFormView from '@/mixins/DialogFormView';
import ReviewPointTypeHelper from '@/mixins/ReviewPointTypeHelper';
import ReviewMileageMessageValidationHelper from '@/mixins/ReviewMileageMessageValidationHelper';
import ManualMileageMaxAmountConfirmShowable from '@/mixins/ManualMileageMaxAmountConfirmShowable';

export default {
  name: 'ReviewReviewsMileageGiveDialog',
  mixins: [
    DialogFormView,
    ReviewPointTypeHelper,
    ReviewMileageMessageValidationHelper,
    ManualMileageMaxAmountConfirmShowable
  ],
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  data() {
    const review = this.review;
    return {
      orgFormObject: {
        mileage_amount_cents: this.mileageAmount(),
        mileage_message:
          review.mileage.gave_mileage_message ||
          this.$t('review.reward_message', {
            product_name: '%{product_name}',
            product_options: '%{product_options}'
          }),
        updated_at: review.updated_at
      },
      detectFormDataChange: false
    };
  },
  computed: {
    ...mapGetters('session', ['showMileageConfirm']),
    mileage() {
      return this.formObject;
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'mileage_amount_cents',
              type: ReviewPointType.isCurrencyType(
                this.reviewSettings.review_point_type
              )
                ? 'currency'
                : 'number',
              digits: 6,
              label: this.$t('mileage_amount_cents'),
              validate: ['required']
            },
            {
              id: 'mileage_message',
              type: 'text',
              label: this.$t('mileage_message'),
              description: this.review.mileage.gave_mileage_at
                ? ''
                : this.$t('mileage_message_description', {
                    product_name: '%{product_name}',
                    product_options: '%{product_options}'
                  }),
              validate: [
                'required',
                this.mileageMessageBytesValidation,
                this.review.mileage.gave_mileage_at
                  ? null
                  : this.mileageMessageTemplateValidation
              ].filter(v => v)
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('review', ['giveMileageReview']),
    ...mapActions('toast', ['createToast']),
    submit() {
      if (this.showMileageConfirm && !confirm(this.$t('mileage_confirm')))
        return;

      if (
        this.isManualMileageMaxAmountConfirmNeeded(
          this.mileage.mileage_amount_cents
        )
      ) {
        return this.showManualMileageMaxAmountConfirm();
      }

      return this.submitGiveMileageReview();
    },
    submitGiveMileageReview() {
      const review = this.review;
      const mileage = this.mileage;
      this.isSubmitting = true;
      this.giveMileageReview({
        reviewId: review.id,
        mileage
      })
        .then(response => {
          const { mileage } = response.data.review;
          this.createToast(
            this.$t('gave_mileage_success', {
              user: this.formatUser(review),
              amount: this.formatMileage(mileage.gave_mileage_amount),
              message: mileage.gave_mileage_message
            })
          );
          this.close();
        })
        .catch(error => {
          if (error.response.status === HttpStatus.CONFLICT) {
            alert(
              error.response.data.review.mileage.gave_mileage_at
                ? this.$t('gave_mileage_error_mileage_already_given')
                : this.$t('gave_mileage_error_review_updated')
            );
            this.close();
          } else {
            error.errorHandler();
          }
        })
        .finally(() => (this.isSubmitting = false));
    },
    showManualMileageMaxAmountConfirm() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'check_confirm',
          title: this.$t('check_confirm.title'),
          markdownText: this.$t('check_confirm.markdown_text'),
          checkboxLabel: this.$t('check_confirm.checkbox_label', [
            this.formatCurrency(this.mileage.mileage_amount_cents)
          ]),
          closeButtonLabel: this.$t('check_confirm.close_button_label'),
          cancelButtonLabel: this.$t('check_confirm.cancel_button_label'),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => {
          this.submitGiveMileageReview();
        });
      });
    },
    mileageAmount() {
      const {
        gave_mileage_amount,
        admin_mileage_amount,
        calculated_mileage_amount
      } = this.review.mileage;

      if (gave_mileage_amount != null) return gave_mileage_amount;
      if (admin_mileage_amount != null) return admin_mileage_amount;
      return calculated_mileage_amount;
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "{point_type} 지급",
  "sub_title": "{0}님의 리뷰",
  "mileage_amount_cents": "{point_type} 금액",
  "mileage_confirm": "{point_type} 지급 시뮬레이션 설정이 OFF 상태입니다.\n실제로 {point_type}((이)) 지급됩니다. 진행하시겠습니까?",
  "mileage_message": "{point_type} 지급 사유",
  "mileage_message_description": "원활한 적립금 지급을 위해 지급 사유에 예약어 {product_name}과 {product_options}이 반드시 포함되어야 합니다.",
  "gave_mileage_success": "{user}에게 {amount}의 {point_type}((을)) 지급했습니다.\n사유: {message}",
  "gave_mileage_error_mileage_already_given": "이미 {point_type}((을)) 지급한 리뷰입니다. {point_type}((을)) 다시 지급하려면 지급 내역 확인 후 '{point_type} 다시 주기' 기능을 사용하세요.",
  "gave_mileage_error_review_updated": "리뷰가 변경됐습니다. {point_type} 조건을 확인 후 다시 지급해주세요.",
  "submit_label": "{point_type} 지급",
  "submitting_label": "{point_type} 지급 중..",
  "check_confirm": {
    "title": "평소보다 큰 금액이 입력되었습니다.",
    "markdown_text": "지급하려는 금액이 맞는지 확인해주세요.<br>아래의 체크박스를 누르면 입력된 금액을 지급할 수 있습니다.<br>만약 잘못 입력하셨다면 닫기 버튼을 눌러주세요.",
    "checkbox_label": "{0}을 지급하겠습니다.",
    "close_button_label": "적립금 지급",
    "cancel_button_label": "닫기"
  }
}
</i18n>
