<template>
  <SlotModalForm
    class="InstallRequestRenewalSlotMainCustomDialog"
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    is-maximized-by-default
    :form-props="formProps"
    v-on="formEvents"
    @change-group="formGroupChanged"
  >
    <template #head>
      <SlotInfoCard :image-url="cardImageUrl" :name="cardName" />
    </template>
    <template #group="{ id }">
      <template v-if="id === 'pc_main_custom'">
        <SlotTable :device-type="WidgetDeviceType.PC" :slot-type="slotType" />
      </template>
      <template v-else-if="id === 'mobile_main_custom'">
        <SlotTable
          :device-type="WidgetDeviceType.MOBILE"
          :slot-type="slotType"
        />
      </template>
    </template>
    <template #description="{ id }">
      <div
        v-if="id === 'pc_custom_comment' || id === 'mobile_custom_comment'"
        class="AppForm__description"
      >
        <AppInlineButton
          button-style="caption"
          :label="$t('attachment_guide')"
          @click="openAlertDialog"
        />
      </div>
    </template>
  </SlotModalForm>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import InstallRequestRenewalCustomImageHelper from '@/mixins/InstallRequestRenewalCustomImageHelper';
import SlotModalForm from './components/SlotModalForm';
import SlotInfoCard from '../components/SlotInfoCard';
import DialogSize from '@/enums/DialogSize';
import WidgetPageType from '@/enums/WidgetPageType';
import InstallSlotType from '@/enums/InstallSlotType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';

export default {
  name: 'InstallRequestRenewalSlotMainCustomDialog',
  components: { SlotModalForm, SlotInfoCard },
  mixins: [DialogFormView, InstallRequestRenewalCustomImageHelper],
  data() {
    return {
      pageType: WidgetPageType.MAIN,
      slotType: InstallSlotType.MAIN_CUSTOM,
      cardImageUrl:
        'https://assets.cre.ma/m/admin/v2/install_request/main_custom_slot@3x.png',
      oldInstallations: [],
      oldWidgets: []
    };
  },
  computed: {
    ...mapState('installRequest', ['installDeviceTypes', 'currentSlotId']),
    ...mapGetters('installRequest', [
      'currentDirectionsBySlotId',
      'currentWidgetsBySlotId',
      'currentInstallationsBySlotId',
      'hasInstallationsSelected'
    ]),
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    InstallSlotType() {
      return InstallSlotType;
    },
    cardName() {
      return this.$t('card_name');
    },
    formSections() {
      return [
        ...(this.installDeviceTypes?.includes(WidgetDeviceType.PC)
          ? [
              {
                id: 'pc_direction',
                groups: [
                  {
                    id: 'tip',
                    label: this.$t('direction.pc'),
                    type: 'tip_box',
                    value: this.$t('direction.tip'),
                    validate: [{ rule: 'required', value: () => true }]
                  },
                  {
                    id: 'pc_custom_image_url',
                    label: null,
                    type: 'image',
                    notRemovable: true,
                    placeholder:
                      this.pcCustomImageFileName ||
                      this.$t('direction.placeholder.pc_image_url'),
                    validate: ['required']
                  },
                  {
                    id: 'pc_custom_comment',
                    label: null,
                    type: 'text',
                    placeholder: this.$t('direction.placeholder.comment'),
                    validate: ['required']
                  }
                ]
              }
            ]
          : []),
        ...(this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)
          ? [
              {
                id: 'mobile_direction',
                groups: [
                  {
                    id: 'tip',
                    label: this.$t('direction.mobile'),
                    type: 'tip_box',
                    value: this.$t('direction.tip'),
                    validate: [{ rule: 'required', value: () => true }]
                  },
                  {
                    id: 'mobile_custom_image_url',
                    label: null,
                    type: 'image',
                    notRemovable: true,
                    placeholder:
                      this.mobileCustomImageFileName ||
                      this.$t('direction.placeholder.mobile_image_url'),
                    validate: ['required']
                  },
                  {
                    id: 'mobile_custom_comment',
                    label: null,
                    type: 'text',
                    placeholder: this.$t('direction.placeholder.comment'),
                    validate: ['required']
                  }
                ]
              }
            ]
          : []),
        {
          id: 'custom_slot',
          label: this.$t('select_widget'),
          groups: [
            {
              id: 'tip',
              label: null,
              type: 'tip_box',
              value: this.$t('select_widget_tip')
            },
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.PC)
              ? [
                  {
                    id: 'pc_main_custom',
                    label: this.$t('app.pc'),
                    validate: [
                      { rule: 'required', value: () => true },
                      {
                        rule: () =>
                          this.hasInstallationsSelected(WidgetDeviceType.PC),
                        errorMessage: this.$t('validations.required')
                      }
                    ]
                  }
                ]
              : []),
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)
              ? [
                  {
                    id: 'mobile_main_custom',
                    label: this.$t('app.mobile'),
                    validate: [
                      { rule: 'required', value: () => true },
                      {
                        rule: () =>
                          this.hasInstallationsSelected(
                            WidgetDeviceType.MOBILE
                          ),
                        errorMessage: this.$t('validations.required')
                      }
                    ]
                  }
                ]
              : [])
          ]
        }
      ];
    },
    isDirectionChanged() {
      return this.currentDirectionsBySlotId.some(direction => {
        return (
          this.formObject[this.customImageUrlKey(direction.device_type)] !==
            direction.custom_image ||
          this.formObject[this.customCommentKey(direction.device_type)] !==
            direction.custom_comment
        );
      });
    },
    isInstallationChanged() {
      const orgSortedInstallations = _.sortBy(this.oldInstallations, v => [
        v.id,
        v.widget_device_type
      ]);
      const sortedInstallations = _.sortBy(
        this.currentInstallationsBySlotId,
        v => [v.id, v.widget_device_type]
      );
      const result = !_.isEqual(orgSortedInstallations, sortedInstallations);
      return result;
    },
    isFormDataChanged() {
      return this.isInstallationChanged || this.isDirectionChanged;
    }
  },
  watch: {
    currentInstallationsBySlotId: {
      handler(installations) {
        if (!installations?.length) return;

        if (this.installDeviceTypes?.includes(WidgetDeviceType.PC)) {
          this.validateField('pc_main_custom');
        }
        if (this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)) {
          this.validateField('mobile_main_custom');
        }
      }
    }
  },
  created() {
    this.SET_CURRENT_PAGE_TYPE(this.pageType);
    this.ensureSlotId(this.slotType);
    this.oldWidgets = this.currentWidgetsBySlotId;
    this.oldInstallations = this.currentInstallationsBySlotId;
    this.setDirection();
  },
  methods: {
    ...mapActions('dialog', ['alert']),
    ...mapActions('installRequest', ['ensureSlotId']),
    ...mapMutations('installRequest', [
      'SET_CURRENT_PAGE_TYPE',
      'ADD_WIDGETS_BY_SLOT_ID',
      'REMOVE_WIDGETS_BY_SLOT_ID',
      'APPEND_DIRECTION_WITH_SLOT_ID',
      'REMOVE_DIRECTIONS_BY_SLOT_ID'
    ]),
    openAlertDialog() {
      this.alert({
        title: this.$t('attachment_guide'),
        message: this.$t('alert_message'),
        width: DialogSize.MIDDLE,
        closeButtonStyle: 'default',
        closeButtonLabel: this.$t('app.close')
      });
    },
    beforeCloseDialog() {
      this.REMOVE_WIDGETS_BY_SLOT_ID(this.currentSlotId);
      if (this.oldWidgets.length > 0) {
        this.ADD_WIDGETS_BY_SLOT_ID(this.oldWidgets);
      }
    },
    setDirection() {
      this.currentDirectionsBySlotId.forEach(direction => {
        this.$set(
          this.formObject,
          this.customImageUrlKey(direction.device_type),
          direction.custom_image
        );
        this.$set(
          this.formObject,
          this.customCommentKey(direction.device_type),
          direction.custom_comment
        );
        this.setCustomImage(direction);
      });
    },
    appendDirections() {
      this.installDeviceTypes.forEach(deviceType => {
        this.APPEND_DIRECTION_WITH_SLOT_ID({
          device_type: deviceType,
          page_type: this.pageType,
          slot_type: this.slotType,
          custom_image: this.formObject[this.customImageUrlKey(deviceType)],
          custom_comment: this.formObject[this.customCommentKey(deviceType)],
          custom_image_file_name: this.customImageFileName(deviceType),
          custom_image_file_type: this.customImageFileType(deviceType)
        });
      });
    },
    submit() {
      this.REMOVE_DIRECTIONS_BY_SLOT_ID(this.currentSlotId);
      this.appendDirections();
      this.close(true);
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "설치 위젯 선택",
  "sub_title": "메인 페이지",
  "card_name": "직접 입력",
  "select_widget": "위젯 선택",
  "select_widget_tip": "설치할 위젯을 선택해 주세요. 여러개 위젯을 선택한 경우 설치 순서도 확인해주세요.",
  "attachment_guide": "이미지 첨부 가이드",
  "alert_message": "아래 이미지와 같이 위젯을 설치하고 싶은 위치가 표시된 이미지를 첨부해주세요.<br><br>![](https://assets.cre.ma/m/admin/v2/install_request/attachment_guide_sample@3x.png)",
  "direction" : {
    "pc": "PC 설치 위치",
    "mobile": "모바일 설치 위치",
    "tip": "설치와 무관한 내용은 반영되지 않습니다. 설치가 어려운 위치는 설치가 진행되지 않을 수 있습니다.",
    "placeholder": {
      "pc_image_url": "PC 설치 위치 이미지 첨부",
      "mobile_image_url": "모바일 설치 위치 이미지 첨부",
      "comment": "크리마 팀이 참고할 코멘트가 있다면 남겨주세요."
    }
  }
}
</i18n>
