<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <ReviewColumnChart
        class="ReviewColumnChart__column-chart"
        v-bind="REPORTED_REVIEWS_DATA"
      />
    </div>
  </div>
</template>

<script>
import ReviewColumnChart from '../review/components/ReviewColumnChart';

export default {
  name: 'UiReviewColumnChart',
  components: { ReviewColumnChart },
  data() {
    return {
      REPORTED_REVIEWS_DATA: {
        data: [
          { label: '크리마몰', value: 0.51, color: '#e17055' },
          { label: '전체평균', value: 1.47, color: '#6c5ce7' }
        ],
        title: '리뷰 10,000건당 신고 건 수',
        xAxisLabel: '최근 1년 기준'
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.ReviewColumnChart__column-chart {
  width: 220px;
}
</style>
