<template>
  <div class="ReviewMessagesNthStepReviewDispatchRecipient">
    <input type="hidden" name="message[nth_step]" :value="message.nth_step" />
    <i18n path="description">
      <label>{{ message.nth_step - 1 }}</label>
      <AppNumberInput
        v-model="message.dispatch_days"
        name="message[dispatch_days]"
        inline
        :digits="3"
        :invalid="!!error"
        @blur="$emit('validate')"
        @change="$emit('validate')"
      />
    </i18n>
    <AppFormHint :message="$t('hint', [message.mileage_expiry_days])" />
    <AppFormError :error="error" />
  </div>
</template>

<script>
export default {
  name: 'ReviewMessagesNthStepReviewDispatchRecipient',
  props: {
    message: { type: Object, default: () => {} },
    error: { type: Object, default: null }
  }
};
</script>

<i18n locale="ko">
{
  "description": "{0}차 리뷰 작성 완료 후 {1} 일 지난 회원",
  "hint": "Tip. n차 리뷰 작성 유도 메시지 발송 완료 후, {0}일이내 작성된 리뷰에 대해서 적립금을 지급합니다."
}
</i18n>
