<template>
  <div class="ReviewChartPercentageBar">
    <div class="ReviewChartPercentageBar__header">
      <div class="ReviewChartPercentageBar__label">{{ label }}</div>
      <AppNumber
        class="ReviewChartPercentageBar__percentage"
        :value="percentage"
        unit="percentage"
        placeholder="-%"
      />
    </div>
    <div class="ReviewChartPercentageBar__bar">
      <div
        :class="[
          'ReviewChartPercentageBar__fill',
          `ReviewChartPercentageBar__fill--${chartColor}`
        ]"
        :style="{ width: `${percentage || 0}%` }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewChartPercentageBar',
  props: {
    label: { type: String, default: null },
    numerator: { type: Number, required: true },
    denominator: { type: Number, required: true },
    chartColor: {
      type: String,
      default: 'lavender',
      validator: v => ['lavender', 'flamingo'].includes(v)
    }
  },
  computed: {
    percentage() {
      return this.denominator
        ? Math.round((this.numerator / this.denominator) * 100)
        : null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewChartPercentageBar {
  & + & {
    margin-top: 20px;
  }
}

.ReviewChartPercentageBar__bar {
  height: 24px;
  border-radius: 4px;
  background-color: $color-grey-25;
}

.ReviewChartPercentageBar__fill {
  height: 100%;
  border-radius: 4px;

  &--lavender {
    background-color: $color-lavender;
  }

  &--flamingo {
    background-color: $color-flamingo;
  }
}

.ReviewChartPercentageBar__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.ReviewChartPercentageBar__label {
  @include text-caption-small-dark;
}

.ReviewChartPercentageBar__percentage {
  @include text-caption-small-darker;

  font-weight: bold;
}
</style>
