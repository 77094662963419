<template>
  <div class="InstallRequestRenewalDirectionDialogConfirm">
    <div class="InstallRequestRenewalDirectionDialogConfirm__request">
      <div class="InstallRequestRenewalDirectionDialogConfirm__title">
        {{ $t('install_request.product_detail') }}
      </div>
      <SlotRequestCard
        v-for="(slot, i) in productDetailSlotTypes"
        :key="i"
        v-bind="slotRequestCardProps(slot)"
        class="InstallRequestRenewalDirectionDialogConfirm__slot"
        is-small-type
        @click="openInstallRequestRenewalSlotDialog(slot)"
      />
    </div>
    <div class="InstallRequestRenewalDirectionDialogConfirm__request">
      <div class="InstallRequestRenewalDirectionDialogConfirm__title">
        {{ $t('install_request.main') }}
      </div>
      <SlotRequestCard
        v-for="(slot, i) in mainSlotTypes"
        :key="i"
        v-bind="slotRequestCardProps(slot)"
        class="InstallRequestRenewalDirectionDialogConfirm__slot"
        is-small-type
        @click="openInstallRequestRenewalSlotDialog(slot)"
      />
    </div>
    <div class="InstallRequestRenewalDirectionDialogConfirm__request">
      <div class="InstallRequestRenewalDirectionDialogConfirm__title">
        {{ $t('install_request.review_board') }}
      </div>
      <SlotRequestCard
        v-bind="slotRequestCardProps(InstallSlotType.REVIEW_BOARD)"
        class="InstallRequestRenewalDirectionDialogConfirm__slot"
        is-small-type
        @click="
          openInstallRequestRenewalSlotDialog(InstallSlotType.REVIEW_BOARD)
        "
      />
    </div>
    <div class="InstallRequestRenewalDirectionDialogConfirm__request">
      <div class="InstallRequestRenewalDirectionDialogConfirm__title">
        {{ $t('install_request.count_and_score') }}
      </div>
      <SlotRequestCard
        v-bind="slotRequestCardProps(InstallSlotType.COUNT_AND_SCORE)"
        class="InstallRequestRenewalDirectionDialogConfirm__slot"
        is-small-type
        @click="
          openInstallRequestRenewalSlotDialog(InstallSlotType.COUNT_AND_SCORE)
        "
      />
      <SlotRequestCard
        v-bind="slotRequestCardProps(InstallSlotType.COUNT_AND_SCORE_CUSTOM)"
        class="InstallRequestRenewalDirectionDialogConfirm__slot"
        is-small-type
        @click="
          openInstallRequestRenewalSlotDialog(
            InstallSlotType.COUNT_AND_SCORE_CUSTOM
          )
        "
      />
    </div>
    <div
      class="InstallRequestRenewalDirectionDialogConfirm__request InstallRequestRenewalDirectionDialogConfirm__request--outline"
    >
      <div class="InstallRequestRenewalDirectionDialogConfirm__title-wrapper">
        <div class="InstallRequestRenewalDirectionDialogConfirm__title">
          {{ $t('install_request.etc') }}
        </div>
        <AppDropdownMenuButton
          class="InstallRequestRenewalDirectionDialogEtc_add-page-button"
          button-style="grey-outline"
          :label="$t('install_request.renewal.etc.add_page')"
          :menu-items="etcDropdownMenuItems"
        />
      </div>
      <AppTable
        class="InstallRequestRenewalDirectionDialogConfirm__etc-table"
        :columns="etcColumns"
        :rows="etcRows"
        :no-data="$t('install_request.renewal.etc.no_data')"
      >
        <template #cell="{ column, row }">
          <template v-if="column.id === 'actions'">
            <AppButton
              :label="$t('app.edit')"
              :button-style="'grey-outline'"
              @click="
                openInstallRequestRenewalSlotEtcEditDialog(
                  row.page_type,
                  row.slot_type,
                  row.slot_id
                )
              "
            />
            <AppButton
              :label="$t('app.delete')"
              :button-style="'red-outline'"
              @click="clickDeleteEtc(row)"
            />
          </template>
        </template>
      </AppTable>
    </div>
    <div
      class="InstallRequestRenewalDirectionDialogConfirm__request InstallRequestRenewalDirectionDialogConfirm__request--outline"
    >
      <div class="InstallRequestRenewalDirectionDialogConfirm__title">
        {{ $t('install_request.required') }}
      </div>
      <AppTipBox
        class="InstallRequestRenewalDirectionDialogConfirm__tip"
        :text="$t('tip')"
        :margin-bottom="0"
      />
      <AppTable
        class="InstallRequestRenewalDirectionDialogConfirm__required-widget-table"
        :columns="requiredWidgetColumns"
        :rows="requiredWidgetRows"
      >
        <template #cell="{ column, row }">
          <template v-if="column.id === 'preview'">
            <AppButton
              v-for="device in installDeviceTypes"
              :key="device"
              :label="$t(`app.${WidgetDeviceType.key(device).toLowerCase()}`)"
              :button-style="'grey-outline'"
              @click="row.preview_handler(device)"
            />
          </template>
        </template>
      </AppTable>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';
import ShopBuilder from '@/enums/ShopBuilder';
import InstallSlotType from '@/enums/InstallSlotType';
import WidgetPageType from '@/enums/WidgetPageType';
import SlotRequestCard from './components/SlotRequestCard';
import InstallRequestRenewalEtcHelper from '@/mixins/InstallRequestRenewalEtcHelper';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'InstallRequestRenewalDirectionDialogConfirm',
  components: { SlotRequestCard },
  mixins: [InstallRequestRenewalEtcHelper],
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapState('installRequest', ['installDeviceTypes']),
    InstallSlotType() {
      return InstallSlotType;
    },
    productDetailSlotTypes() {
      return InstallSlotType.PRODUCT_DETAIL_SLOT_TYPES;
    },
    mainSlotTypes() {
      return InstallSlotType.MAIN_SLOT_TYPES;
    },
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    requiredWidgetColumns() {
      return [
        {
          id: 'widget_type',
          label: this.$t('required_widget.widget_type'),
          width: '25%'
        },
        {
          id: 'direction',
          label: this.$t('required_widget.direction'),
          width: '45%'
        },
        {
          id: 'preview',
          label: this.$t('required_widget.preview'),
          width: '30%'
        }
      ];
    },
    requiredWidgetRows() {
      return [
        {
          widget_type: this.$t('required_widget_type.reviews_new'),
          direction: this.$t('required_widget_direction.reviews_new'),
          preview_handler: device =>
            this.openRequiredWidgetPreviewDialog(device, 'reviews_new')
        },
        {
          widget_type: this.$t('required_widget_type.managing_reviews'),
          direction: this.$t('required_widget_direction.managing_reviews'),
          preview_handler: device =>
            this.openRequiredWidgetPreviewDialog(device, 'managing_reviews')
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog', 'alert']),
    isProductDetailDefaultSlot(slot) {
      return slot === InstallSlotType.PRODUCT_DETAIL_DEFAULT;
    },
    isCountAndScoreCustomSlot(slot) {
      return slot === InstallSlotType.COUNT_AND_SCORE_CUSTOM;
    },
    pageType(slot) {
      if (this.productDetailSlotTypes.includes(slot))
        return WidgetPageType.PRODUCT_DETAIL;
      else if (this.mainSlotTypes.includes(slot)) return WidgetPageType.MAIN;
      else if (slot === InstallSlotType.REVIEW_BOARD)
        return WidgetPageType.REVIEW_BOARD;
      else return;
    },
    slotRequestCardProps(slot) {
      const key = InstallSlotType.key(slot).toLowerCase();
      return {
        imageUrl: `https://assets.cre.ma/m/admin/v2/install_request/${key}_slot@3x.png`,
        title: this.$t(`${key}.title`, [
          ShopBuilder.tk(this.currentBrand.shop_builder)
        ]),
        isRequired: this.isProductDetailDefaultSlot(slot),
        slotType: slot,
        pageType: this.pageType(slot),
        buttonLabel: this.isCountAndScoreCustomSlot(slot)
          ? this.$t('count_and_score_custom.button_label')
          : ''
      };
    },
    openInstallRequestRenewalSlotDialog(slot) {
      const slotKey = InstallSlotType.key(slot);
      const dialogKey = _.chain(slotKey)
        .camelCase()
        .upperFirst()
        .value();

      return this.openDialog(`InstallRequestRenewalSlot${dialogKey}Dialog`);
    },
    openRequiredWidgetPreviewDialog(device, widgetKey) {
      const deviceKey = WidgetDeviceType.key(device).toLowerCase();

      this.alert({
        title: this.$t('required_widget_guide.title'),
        message: this.$t(
          `required_widget_guide.${deviceKey}_${widgetKey}_message`
        ),
        width:
          device === WidgetDeviceType.PC ? DialogSize.WIDE : DialogSize.SMALL,
        closeButtonStyle: 'default',
        closeButtonLabel: this.$t('app.close')
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.InstallRequestRenewalDirectionDialogConfirm {
  margin-top: 32px;
  padding-bottom: 20px;
}

.InstallRequestRenewalDirectionDialogConfirm__request {
  padding: 24px;
  background-color: $color-background;
  border-radius: 6px;

  & + & {
    margin-top: 16px;
  }

  &--outline {
    border: 1px solid $color-grey-15;
  }
}

.InstallRequestRenewalDirectionDialogConfirm__title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.InstallRequestRenewalDirectionDialogConfirm__title {
  @include text-sub-title;
}

.InstallRequestRenewalDirectionDialogConfirm__slot,
.InstallRequestRenewalDirectionDialogConfirm__tip,
.InstallRequestRenewalDirectionDialogConfirm__etc-table,
.InstallRequestRenewalDirectionDialogConfirm__required-widget-table {
  margin-top: 12px;
}
</style>

<i18n locale="ko">
{
  "install_request": {
    "product_detail": "상품 상세 페이지",
    "main": "메인 페이지",
    "review_board": "전체 리뷰 페이지",
    "count_and_score": "리뷰 수 위젯",
    "etc": "기타 페이지",
    "required": "필수 설치 위젯"
  },
  "product_detail_default": {
    "title": "{0} 기본 리뷰 영역",
    "description": "{0}에서 기본으로 제공하던 리뷰 영역을 대체할 크리마 위젯을 선택해주세요."
  },
  "product_detail_product_thumbnail_after": {
    "title": "상품 섬네일 하단",
    "description": "상품 섬네일과 함께 고객의 포토 리뷰를 함께 노출해보세요."
  },
  "product_detail_desc_section_top": {
    "title": "상세 정보 영역 최상단",
    "description": "상품의 상세 정보를 보기 전 고객의 리뷰를 효과적으로 노출해보세요."
  },
  "main_bottom": {
    "title": "페이지 최하단",
    "description": "메인 페이지 최하단에 고객의 리뷰를 효과적으로 노출해보세요."
  },
  "main_custom": {
    "title": "직접 입력",
    "description": "메인 페이지 원하는 위치에 고객의 리뷰를 효과적으로 노출해보세요."
  },
  "review_board": {
    "title": "전체 리뷰 페이지",
    "description": "전체 리뷰 페이지에 고객의 리뷰를 효과적으로 노출해보세요."
  },
  "count_and_score": {
    "title": "상품 목록",
    "description": "상품에 리뷰 수와 평점을 노출하여 상품 주목도를 높여보세요."
  },
  "count_and_score_custom": {
    "title": "상품 상세 페이지",
    "description": "해당 페이지에 노출되는 리뷰 수 중 크리마 담당자가 찾기 어려운 위치에 있는 경우 알려주세요.",
    "button_label": "위치 입력"
  },
  "tip": "필수 설치 위젯은 설치 요청과 관계없이 설치됩니다.",
  "required_widget": {
    "widget_type": "위젯 종류",
    "direction": "설치 위치",
    "preview": "미리보기"
  },
  "required_widget_type": {
    "reviews_new": "리뷰 작성팝업",
    "managing_reviews": "내 리뷰 위젯"
  },
  "required_widget_direction": {
    "reviews_new": "메인, 상품 상세, 주문 조회, 마이 페이지",
    "managing_reviews": "내 게시글 관리 페이지 하단"
  },
  "required_widget_guide": {
    "title": "위젯 미리보기",
    "pc_reviews_new_message": "[![pc_popup_message](https://assets.cre.ma/m/admin/v2/preview_pc_review_popup_widget.png)](https://assets.cre.ma/m/admin/v2/preview_pc_review_popup_widget.png)",
    "mobile_reviews_new_message": "[![mobile_popup_message](https://assets.cre.ma/m/admin/v2/preview_mobile_review_popup_widget.png)](https://assets.cre.ma/m/admin/v2/preview_mobile_review_popup_widget.png)",
    "pc_managing_reviews_message": "[![pc_my_reviews_message](https://assets.cre.ma/m/admin/v2/preview_pc_my_reviews_managing_reviews_widget.png)](https://assets.cre.ma/m/admin/v2/preview_pc_my_reviews_managing_reviews_widget.png)",
    "mobile_managing_reviews_message": "[![mobile_my_reviews_message](https://assets.cre.ma/m/admin/v2/preview_mobile_my_reviews_managing_reviews_widget.png)](https://assets.cre.ma/m/admin/v2/preview_mobile_my_reviews_managing_reviews_widget.png)"
  }
}
</i18n>
