<template>
  <div class="ReviewMiniLineChart">
    <div v-if="title" class="ReviewMiniLineChart__title">
      {{ title }}
    </div>
    <AppChart
      :options="chartOptions"
      :tooltip="chartTooltip"
      @tooltip="onChartTooltip"
    />
    <div class="ReviewMiniLineChart__legend">
      <div
        v-for="(item, index) in legendItems"
        :key="index"
        class="ReviewMiniLineChart__legend-item"
      >
        <div
          :class="[
            'ReviewMiniLineChart__legend-item-marker',
            `ReviewMiniLineChart__legend-item-marker--${index}`
          ]"
        />
        <div class="ReviewMiniLineChart__legend-item-label">
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewMiniLineChart',
  props: {
    title: {
      type: String,
      default: null
    },
    series: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    },
    unit: {
      type: String,
      default: 'count',
      validator: v => ['count', 'percentage'].includes(v)
    },
    height: {
      type: Number,
      default: 120
    }
  },
  data() {
    return {
      chartTooltip: null
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          backgroundColor: 'transparent',
          borderWidth: 0,
          height: this.height,
          marginLeft: 0,
          marginRight: 0,
          type: 'line',
          animation: false
        },
        title: '',
        credits: { enabled: false },
        xAxis: {
          categories: this.categories
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          tickPositions: [],
          tickLength: 0
        },
        legend: { enabled: false },
        tooltip: { enabled: false },
        exporting: { enabled: false },
        plotOptions: {
          series: {
            animation: false,
            lineWidth: 1,
            shadow: false,
            states: {
              hover: {
                halo: {
                  size: 5
                }
              }
            },
            marker: {
              radius: 0,
              lineWidth: 0
            }
          }
        },
        series: this.series
      };
    },
    legendItems() {
      return this.series.map(item => ({
        label: item.name
      }));
    }
  },
  methods: {
    onChartTooltip({ index, series }) {
      this.chartTooltip = {
        title: this.categories[index],
        items: series.chart.series.map(({ data, name }) => ({
          symbol: 'circle',
          name,
          value: data[index].y,
          unit: this.unit,
          precision: 2
        }))
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.ReviewMiniLineChart {
  position: relative;
  display: inline-block;
  min-width: 198px;

  ::v-deep {
    .highcharts-xaxis {
      display: none;
    }

    .highcharts-graph {
      stroke-width: 1px;
    }

    .highcharts-color-0 {
      fill: $color-lavender;
      stroke: $color-lavender;
    }

    .highcharts-color-1 {
      fill: $color-flamingo;
      stroke: $color-flamingo;
    }

    .highcharts-color-2 {
      fill: $color-mint-green;
      stroke: $color-mint-green;
    }

    .highcharts-xaxis-labels {
      color: $color-grey-50;
      fill: $color-grey-50;
    }
  }
}

.ReviewMiniLineChart__title {
  @include text-caption-bold-dark;
  padding: 0px 10px;
}

.ReviewMiniLineChart__legend {
  padding: 0px 10px;
}

.ReviewMiniLineChart__legend-item {
  display: flex;
  align-items: center;
  gap: 7px;
}

.ReviewMiniLineChart__legend-item-marker {
  width: 10px;
  height: 10px;
  border-radius: 5px;

  &--0 {
    background-color: $color-lavender;
  }

  &--1 {
    background-color: $color-flamingo;
  }

  &--2 {
    background-color: $color-mint-green;
  }
}

.ReviewMiniLineChart__legend-item-label {
  @include text-caption-small;
}
</style>
