<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppTable
      class="SlotTable AppForm__group-field AppForm__group-field--mt8"
      :columns="columns"
      :rows="rows"
    >
      <template #cell="{ column, row }">
        <template v-if="column.id === 'order'">
          {{ row.order || '-' }}
        </template>
        <template v-else-if="column.id === 'widget_style'">
          <span>{{ row.widget_style_name }}</span>
        </template>
        <template v-else-if="column.id === 'select'">
          <AppSwitch
            :value="row.id"
            :checked="row.selected"
            @change="updateSelectedRowIds(row, $event)"
          />
        </template>
        <template v-else-if="column.id === 'actions'">
          <AppButtonDraggable
            :label="$t('app.sort')"
            :disabled="!row.selected"
            @sort="sort"
          />
        </template>
      </template>
    </AppTable>
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import { arrayMoveIndex } from '@/lib/array';
import WidgetType from '@/enums/WidgetType';
import WidgetStyle from '@/enums/WidgetStyle';

export default {
  name: 'SlotTable',
  props: {
    slotType: { type: Number, required: true }
  },
  data() {
    return {
      selectedRowIds: [],
      widgets: [],
      isLoading: false
    };
  },
  computed: {
    ...mapGetters('installRequest', ['currentWidgetsBySlotId']),
    columns() {
      return [
        {
          id: 'order',
          label: this.$t('form.order'),
          width: '25%'
        },
        {
          id: 'widget_style',
          label: this.$t('form.widget_style'),
          width: '25%'
        },
        {
          id: 'select',
          label: this.$t('app.select'),
          width: '25%'
        },
        {
          id: 'actions',
          label: this.$t('app.actions'),
          width: '25%'
        }
      ];
    },
    slotWidgets() {
      return this.currentWidgetsBySlotId.filter(
        i => i.slot_type === this.slotType
      );
    },
    rows() {
      let index = 0;
      return this.widgets.map(row => {
        if (this.selectedRowIds.includes(row.id)) {
          index += 1;
          return { ...row, order: index, selected: true };
        } else {
          return { ...row, order: null, selected: false };
        }
      });
    }
  },
  watch: {
    rows() {
      this.updateWidgetsBySlotId();
    }
  },
  created() {
    if (this.slotWidgets.length === 0) {
      this.isLoading = true;
      api
        .get('/install_request/onboarding/widgets', {
          params: {
            slot_type: this.slotType
          }
        })
        .then(({ data }) => {
          this.widgets = data.widgets.map(w => ({
            ...w,
            id: this.widgetKey(w),
            widget_style_name: this.widgetStyleName(w),
            slot_type: this.slotType
          }));
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.selectedRowIds = this.slotWidgets
        .filter(w => w.selected)
        .map(w => w.id);
      this.widgets = this.slotWidgets;
    }
  },
  methods: {
    ...mapMutations('installRequest', [
      'SET_WIDGETS_BY_SLOT_ID',
      'ADD_WIDGETS_BY_SLOT_ID'
    ]),
    widgetKey(widget) {
      return `${WidgetType.key(
        widget.widget_type
      ).toLowerCase()}-${WidgetStyle.key(
        widget.widget_sub_style || widget.widget_style
      ).toLowerCase()}`;
    },
    widgetStyleName(widget) {
      return widget.widget_type === WidgetType.PRODUCTS
        ? WidgetType.t(widget.widget_type)
        : `${WidgetStyle.t(
            widget.widget_sub_style || widget.widget_style
          )} ${this.$t('install_request.widget')}`;
    },
    updateSelectedRowIds(row, enabled) {
      const selectedWidgetIds = this.selectedRowIds;
      if (enabled) {
        selectedWidgetIds.push(row.id);
      } else {
        const index = selectedWidgetIds.findIndex(w => w === row.id);
        selectedWidgetIds.splice(index, 1);
      }

      this.selectedRowIds = selectedWidgetIds;
    },
    sort({ srcIndex, dstIndex }) {
      this.widgets = arrayMoveIndex(this.widgets, srcIndex, dstIndex);
    },
    updateWidgetsBySlotId() {
      const slotTypeWidgets = _.reject(
        this.currentWidgetsBySlotId,
        i => i.slot_type === this.slotType
      );
      this.SET_WIDGETS_BY_SLOT_ID(slotTypeWidgets);
      this.ADD_WIDGETS_BY_SLOT_ID(this.rows);
    }
  }
};
</script>

<i18n locale="ko">
{
  "form": {
    "order": "설치 순서",
    "widget_style": "위젯"
  }
}
</i18n>
