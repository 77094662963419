<template>
  <SlotModalForm
    class="InstallRequestRenewalSlotReviewBoardDialog"
    :title="$t('title')"
    :sub-title="$t('review_board')"
    is-maximized-by-default
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #head>
      <SlotInfoCard :image-url="cardImageUrl" :name="$t('review_board')" />
    </template>
    <template #group="{ id }">
      <template v-if="id === 'pc_review_board'">
        <SlotTable :device-type="WidgetDeviceType.PC" :slot-type="slotType" />
      </template>
      <template v-else-if="id === 'mobile_review_board'">
        <SlotTable
          :device-type="WidgetDeviceType.MOBILE"
          :slot-type="slotType"
        />
      </template>
    </template>
    <template #description="{ id }">
      <div v-if="id === 'review_board_install_option_type'">
        <AppFormHint
          class="InstallRequestRenewalSlotReviewBoardDialog__install-option-hint"
        >
          <AppMarked :text="`${$t('review_board_install_option_type.hint')}`" />
          <AppInlineButton
            button-style="caption"
            :label="$t('review_board_install_option_type.pc_hint')"
            @click="openPcInstallationGuideDialog"
          />
          <AppInlineButton
            button-style="caption"
            :label="$t('review_board_install_option_type.mobile_hint')"
            @click="openMobileInstallationGuideDialog"
          />
        </AppFormHint>
      </div>
    </template>
  </SlotModalForm>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import SlotModalForm from './components/SlotModalForm';
import SlotInfoCard from '../components/SlotInfoCard';
import SlotTable from './components/SlotTable';
import InstallSlotType from '@/enums/InstallSlotType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import ReviewBoardInstallOptionType from '@/enums/ReviewBoardInstallOptionType';
import DialogSize from '@/enums/DialogSize';
import WidgetPageType from '@/enums/WidgetPageType';
import ShopUrlValidationHelper from '@/mixins/ShopUrlValidationHelper';

export default {
  name: 'InstallRequestRenewalSlotReviewBoardDialog',
  components: {
    SlotModalForm,
    SlotInfoCard,
    SlotTable
  },
  mixins: [DialogFormView, ShopUrlValidationHelper],
  data() {
    return {
      pageType: WidgetPageType.REVIEW_BOARD,
      slotType: InstallSlotType.REVIEW_BOARD,
      cardImageUrl:
        'https://assets.cre.ma/m/admin/v2/install_request/review_board_slot@3x.png',
      oldInstallations: [],
      oldWidgets: []
    };
  },
  computed: {
    ...mapState('session', ['reviewSettings']),
    ...mapState('installRequest', ['installDeviceTypes', 'currentSlotId']),
    ...mapGetters('installRequest', [
      'currentDirectionsBySlotId',
      'currentWidgetsBySlotId',
      'currentInstallationsBySlotId',
      'hasInstallationsSelected'
    ]),
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    InstallSlotType() {
      return InstallSlotType;
    },
    formSections() {
      return [
        {
          id: 'custom_url',
          groups: [
            {
              id: 'tip',
              label: this.$t('custom_url.label'),
              type: 'tip_box',
              value: this.$t('custom_url.tip'),
              validate: [{ rule: 'required', value: () => true }]
            },
            {
              id: 'custom_url',
              label: null,
              type: 'text',
              placeholder: this.$t('custom_url.placeholder'),
              hint: this.$t('custom_url.hint', [
                this.reviewSettings.reviews_index_url || this.$t('app.none')
              ]),
              validate: ['required', ...this.shopUrlValidations]
            }
          ]
        },
        {
          id: 'review_board_install_option_type',
          groups: [
            {
              id: 'review_board_install_option_type',
              label: this.$t('review_board_install_option_type.label'),
              type: 'select',
              validate: ['required'],
              options: ReviewBoardInstallOptionType.options()
            }
          ]
        },
        {
          id: 'review_board_slot',
          label: this.$t('select_widget'),
          groups: [
            {
              id: 'tip',
              label: null,
              type: 'tip_box',
              value: this.$t('select_widget_tip')
            },
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.PC)
              ? [
                  {
                    id: 'pc_review_board',
                    label: this.$t('app.pc'),
                    validate: [
                      { rule: 'required', value: () => true },
                      {
                        rule: () =>
                          this.hasInstallationsSelected(WidgetDeviceType.PC),
                        errorMessage: this.$t('validations.required')
                      }
                    ]
                  }
                ]
              : []),
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)
              ? [
                  {
                    id: 'mobile_review_board',
                    label: this.$t('app.mobile'),
                    validate: [
                      { rule: 'required', value: () => true },
                      {
                        rule: () =>
                          this.hasInstallationsSelected(
                            WidgetDeviceType.MOBILE
                          ),
                        errorMessage: this.$t('validations.required')
                      }
                    ]
                  }
                ]
              : [])
          ]
        }
      ];
    },
    isDirectionChanged() {
      const direction = this.currentDirectionsBySlotId[0];
      return (
        this.formObject['custom_url'] !== direction?.custom_url ||
        this.formObject['review_board_install_option_type'] !==
          direction?.review_board_install_option_type
      );
    },
    isInstallationChanged() {
      const orgSortedInstallations = _.sortBy(this.oldInstallations, v => [
        v.id,
        v.widget_device_type
      ]);
      const sortedInstallations = _.sortBy(
        this.currentInstallationsBySlotId,
        v => [v.id, v.widget_device_type]
      );
      const result = !_.isEqual(orgSortedInstallations, sortedInstallations);
      return result;
    },
    isFormDataChanged() {
      return this.isInstallationChanged || this.isDirectionChanged;
    }
  },
  watch: {
    currentInstallationsBySlotId: {
      handler(installations) {
        if (!installations?.length) return;

        if (this.installDeviceTypes?.includes(WidgetDeviceType.PC)) {
          this.validateField('pc_review_board');
        }
        if (this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)) {
          this.validateField('mobile_review_board');
        }
      }
    }
  },
  created() {
    this.SET_CURRENT_PAGE_TYPE(this.pageType);
    this.ensureSlotId(this.slotType);
    this.oldWidgets = this.currentWidgetsBySlotId;
    this.oldInstallations = this.currentInstallationsBySlotId;
    this.setDirection();
  },
  methods: {
    ...mapActions('dialog', ['alert']),
    ...mapActions('installRequest', ['ensureSlotId']),
    ...mapMutations('installRequest', [
      'SET_CURRENT_PAGE_TYPE',
      'ADD_WIDGETS_BY_SLOT_ID',
      'REMOVE_WIDGETS_BY_SLOT_ID',
      'APPEND_DIRECTION_WITH_SLOT_ID',
      'REMOVE_DIRECTIONS_BY_SLOT_ID'
    ]),
    openPcInstallationGuideDialog() {
      this.alert({
        title: this.$t('guide.title'),
        message: this.$t('guide.pc_message'),
        width: DialogSize.WIDE,
        closeButtonStyle: 'default',
        closeButtonLabel: this.$t('app.close')
      });
    },
    openMobileInstallationGuideDialog() {
      this.alert({
        title: this.$t('guide.title'),
        message: this.$t('guide.mobile_message'),
        width: DialogSize.SMALL,
        closeButtonStyle: 'default',
        closeButtonLabel: this.$t('app.close')
      });
    },
    beforeCloseDialog() {
      this.REMOVE_WIDGETS_BY_SLOT_ID(this.currentSlotId);
      if (this.oldWidgets.length > 0) {
        this.ADD_WIDGETS_BY_SLOT_ID(this.oldWidgets);
      }
    },
    setDirection() {
      const direction = this.currentDirectionsBySlotId[0];

      if (direction) {
        this.$set(this.formObject, 'custom_url', direction.custom_url);
        this.$set(
          this.formObject,
          'review_board_install_option_type',
          direction.review_board_install_option_type
        );
      } else {
        this.$set(
          this.formObject,
          'review_board_install_option_type',
          ReviewBoardInstallOptionType.REPLACE_PAGE
        );
      }
    },
    appendDirections() {
      this.installDeviceTypes.forEach(deviceType => {
        const data = {
          device_type: deviceType,
          page_type: this.pageType,
          slot_type: this.slotType,
          custom_url: this.formObject['custom_url'],
          review_board_install_option_type: this.formObject[
            'review_board_install_option_type'
          ]
        };
        this.APPEND_DIRECTION_WITH_SLOT_ID(data);
      });
    },
    submit() {
      this.REMOVE_DIRECTIONS_BY_SLOT_ID(this.currentSlotId);
      this.appendDirections();
      this.close(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.InstallRequestRenewalSlotReviewBoardDialog__install-option-hint {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
</style>

<i18n locale="ko">
{
  "title": "설치 위젯 선택",
  "review_board": "전체 리뷰 페이지",
  "select_widget": "위젯 선택",
  "select_widget_tip": "설치할 위젯을 선택해 주세요. 여러개 위젯을 선택한 경우 설치 순서도 확인해주세요.",
  "custom_url" : {
    "label": "전체 리뷰 페이지 URL",
    "placeholder": "전체 리뷰 페이지 URL을 입력해주세요.",
    "hint": "현재 설정된 전체 리뷰 페이지 URL: {0}",
    "tip": "아래 예시를 참고하여 위젯을 설치할 전체 리뷰 페이지 URL을 입력해주세요.<br />1. 현재 설정된 전체 리뷰 페이지 URL을 계속 사용할 예정이라면 해당 URL 입력<br />2. 사이트 리뉴얼로 URL이 변경되거나, 현재 설정된 URL이 없는 경우 새롭게 설정할 URL 입력"
  },
  "review_board_install_option_type" : {
    "label": "설치 방법",
    "hint": "설치 방법에 관한 참고 이미지를 확인해보세요.",
    "pc_hint": "PC 보기",
    "mobile_hint": "모바일 보기"
  },
  "guide": {
    "title": "전체 리뷰 페이지 설치 방법 안내",
    "pc_message": "![](https://assets.cre.ma/m/admin/v2/position_preview_pc_review_board.png)",
    "mobile_message": "![](https://assets.cre.ma/m/admin/v2/position_preview_mobile_review_board.png)"
  }
}
</i18n>
