<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Multiple Values</label>
      </div>
      <AppMultipleSelectButton
        id="basic"
        v-model="valuesBasic"
        :options="OPTIONS_BASIC"
      />
      <div class="ui-section__description">
        Selected Value: {{ valuesBasic }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="invalid">Multiple Values</label>
      </div>
      <AppMultipleSelectButton
        id="invalid"
        v-model="valuesInvalid"
        invalid
        :options="OPTIONS_BASIC"
      />
      <div class="ui-section__description">
        Selected Value: {{ valuesInvalid }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="bit-flag">Multiple Values - Bit Flag</label>
      </div>
      <AppMultipleSelectButton
        id="bit-flag"
        v-model="valueBitFlag"
        packing-method="bit_flag"
        :options="OPTIONS_BIT_FLAG"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueBitFlag }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Multiple Values - Separate Buttons</label>
      </div>
      <AppMultipleSelectButton
        id="basic-separate-buttons"
        v-model="valuesBasicSeparateButtons"
        :options="OPTIONS_BASIC"
        is-separate-buttons
      />
      <div class="ui-section__description">
        Selected Value: {{ valuesBasicSeparateButtons }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiMultipleSelectButton',
  data() {
    return {
      OPTIONS_BASIC: [
        { label: '테스트', value: 1 },
        { label: 'ㅌㅅㅌ', value: 2 },
        { label: 'test', value: 3 },
        { label: 'tes', value: 4 },
        { label: '우왕', value: 5 },
        { label: '왕우', value: 6 },
        { label: '우웅', value: 7, disabled: true },
        { label: '그만', value: 8 }
      ],
      OPTIONS_BIT_FLAG: [
        { label: 'Option 1', value: 1 },
        { label: 'Option 2', value: 2 },
        { label: 'Option 3', value: 4 },
        { label: 'Option 4', value: 8 }
      ],
      valuesBasic: [],
      valuesInvalid: [],
      valueBitFlag: 4,
      valuesBasicSeparateButtons: []
    };
  }
};
</script>
