<template>
  <div class="ReviewSemiDonutChart">
    <div class="ReviewSemiDonutChart__chart-wrapper">
      <div class="ReviewSemiDonutChart__chart">
        <div
          class="ReviewSemiDonutChart__background"
          @mousemove="mousemove"
          @mouseout="mouseout"
        />
        <div
          v-if="mainPercentage > 0"
          class="ReviewSemiDonutChart__progress"
          :style="{ '--main-percentage': mainPercentage }"
        />
      </div>
      <div
        v-if="indicatorPercentage > 0"
        class="ReviewSemiDonutChart__indicator"
        :style="{ '--indicator-percentage': indicatorPercentage }"
      />
      <div v-if="mainData" class="ReviewSemiDonutChart__label">
        <AppNumber
          class="ReviewSemiDonutChart__numerator"
          :value="mainData.value"
        />
        <AppNumber
          class="ReviewSemiDonutChart__percentage"
          :value="mainPercentage"
          unit="percentage"
          placeholder="-%"
        />
      </div>
    </div>
    <div class="ReviewSemiDonutChart__legend">
      <div
        v-for="(item, index) in legendItems"
        :key="index"
        class="ReviewSemiDonutChart__legend-item"
      >
        <svg
          :class="[
            'ReviewSemiDonutChart__legend-marker',
            `ReviewSemiDonutChart__legend-marker--${item.type}`
          ]"
          viewBox="0 0 8 8"
        >
          <rect v-if="item.type === 'main'" width="8" height="8" />
          <line
            v-else-if="item.type === 'indicator'"
            x1="0"
            y1="4"
            x2="8"
            y2="4"
            stroke-width="2"
          />
        </svg>
        <div class="ReviewSemiDonutChart__legend-label">{{ item.label }}</div>
      </div>
    </div>
    <AppChartTooltip
      v-if="visibleTooltip && tooltip && tooltipPosition"
      v-bind="tooltip"
      :position="tooltipPosition"
    />
  </div>
</template>

<script>
const CHART_CONSTANTS = {
  CENTER_X: 40,
  CENTER_Y: 40,
  BORDER_WIDTH: 30,
  BORDER_START: 40,
  BORDER_END: 70
};

export default {
  name: 'ReviewSemiDonutChart',
  props: {
    data: { type: Array, required: true },
    tooltip: { type: Object, default: undefined }
  },
  data() {
    return {
      visibleTooltip: false,
      tooltipPosition: null
    };
  },
  computed: {
    mainData() {
      return this.data.find(e => e.type === 'main');
    },
    indicatorData() {
      return this.data.find(e => e.type === 'indicator');
    },
    mainPercentage() {
      if (!this.mainData) return null;

      return this.mainData.total
        ? Math.round((this.mainData.value / this.mainData.total) * 100)
        : null;
    },
    indicatorPercentage() {
      if (!this.indicatorData) return null;

      return this.indicatorData.total
        ? Math.round(
            (this.indicatorData.value / this.indicatorData.total) * 100
          )
        : null;
    },
    legendItems() {
      return this.data.map(item => ({
        label: item.label,
        type: item.type
      }));
    }
  },
  methods: {
    mousemove({ offsetX, offsetY }) {
      this.tooltipPosition = this.calculateTooltipPosition(offsetX, offsetY);
      this.visibleTooltip = this.isMouseInDonutArea(offsetX, offsetY);
      this.emitTooltip();
    },
    mouseout() {
      this.visibleTooltip = false;
      this.tooltipPosition = null;
    },
    calculateTooltipPosition(offsetX, offsetY) {
      const { x, y } = this.$el.getBoundingClientRect();
      return {
        x: offsetX + x + CHART_CONSTANTS.BORDER_WIDTH,
        y: offsetY + y + CHART_CONSTANTS.BORDER_WIDTH
      };
    },
    isMouseInDonutArea(offsetX, offsetY) {
      const distance = this.calculateDistanceFromCenter(offsetX, offsetY);
      return (
        distance >= CHART_CONSTANTS.BORDER_START &&
        distance <= CHART_CONSTANTS.BORDER_END
      );
    },
    calculateDistanceFromCenter(x, y) {
      return Math.sqrt(
        Math.pow(x - CHART_CONSTANTS.CENTER_X, 2) +
          Math.pow(y - CHART_CONSTANTS.CENTER_Y, 2)
      );
    },
    emitTooltip() {
      const mainValue = this.mainData.total ? `${this.mainPercentage}%` : '-';
      const indicatorValue = this.indicatorData.total
        ? `${this.indicatorPercentage}%`
        : '-';

      this.$emit('tooltip', { mainValue, indicatorValue });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

$circle-radius: 70px;
$circle-diameter: $circle-radius * 2;
$donut-width: 30px;

.ReviewSemiDonutChart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ReviewSemiDonutChart__chart-wrapper {
  position: relative;
  width: $circle-diameter;
}

.ReviewSemiDonutChart__chart {
  position: relative;
  width: $circle-diameter;
  height: $circle-radius;
  overflow: hidden;
  margin-bottom: 14px;
}

.ReviewSemiDonutChart__background,
.ReviewSemiDonutChart__progress {
  position: absolute;
  top: 0;
  left: 0;
  width: $circle-diameter;
  height: $circle-diameter;
  border-radius: 50%;
  border-style: solid;
  border-width: $donut-width;
  box-sizing: border-box;
}

.ReviewSemiDonutChart__background {
  border-color: $color-grey-25;
}

.ReviewSemiDonutChart__progress {
  clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
  transform: rotate(calc(-90deg + (var(--main-percentage) / 100 * 180deg)));
  border-color: $color-lavender;
  pointer-events: none;
}

.ReviewSemiDonutChart__label {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ReviewSemiDonutChart__numerator {
  @include text-sub-title;
}

.ReviewSemiDonutChart__percentage {
  @include text-content-light;
}

$donut-width: 30px;
$indicator-height: 34px;
$indicator-width: 2px;
$indicator-overflow: $indicator-height - $donut-width;
$indicator-overflow-top: calc($indicator-overflow / 2);

.ReviewSemiDonutChart__indicator {
  position: absolute;
  top: -($indicator-overflow-top);
  left: calc($circle-radius - ($indicator-width / 2));
  width: $indicator-width;
  height: $indicator-height;
  background-color: $color-flamingo;
  transform-origin: 50% ($circle-radius + $indicator-overflow-top);
  transform: rotate(
    calc(-90deg + (var(--indicator-percentage) / 100 * 180deg))
  );
  border-radius: 2px;
  pointer-events: none;
}

.ReviewSemiDonutChart__legend {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.ReviewSemiDonutChart__legend-marker {
  margin-right: 4px;
  width: 8px;

  &--main {
    fill: $color-lavender;
    stroke: $color-lavender;
  }

  &--indicator {
    fill: $color-flamingo;
    stroke: $color-flamingo;
  }
}

.ReviewSemiDonutChart__legend-item {
  display: flex;
}

.ReviewSemiDonutChart__legend-label {
  @include text-caption-small-dark;
}

@mixin highcharts-color($num, $color) {
  .highcharts-color-#{$num} {
    fill: $color;
    stroke: $color;
  }
}

::v-deep {
  @include highcharts-color(0, $color-lavender);
  @include highcharts-color(1, $color-flamingo);
}
</style>
