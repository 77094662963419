<template>
  <div class="ReviewColumnChart">
    <div class="ReviewColumnChart__header">
      <div class="ReviewColumnChart__header-title">
        {{ title }}
      </div>
      <span class="ReviewColumnChart__badge">
        {{ $t('badge') }}
      </span>
    </div>
    <div class="ReviewColumnChart__chart">
      <div class="ReviewColumnChart__legend">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="ReviewColumnChart__legend-item"
        >
          <span
            class="ReviewColumnChart__legend-item-color-box"
            :style="{ background: item.color }"
          />
          <span class="ReviewColumnChart__legend-item-label">{{
            item.label
          }}</span>
        </div>
      </div>
      <div class="ReviewColumnChart__chart-bar-chart">
        <div class="ReviewColumnChart__chart-bars">
          <div
            v-for="(item, index) in data"
            :key="index"
            class="ReviewColumnChart__chart-bar"
            :style="{
              backgroundColor: item.color,
              height: `${(item.value / maxValue) * 100}%`
            }"
          >
            <span class="ReviewColumnChart__chart-bar-value">{{
              $t('data_label', [item.value])
            }}</span>
          </div>
        </div>
      </div>
      <div v-if="xAxisLabel" class="ReviewColumnChart__chart-bars-x-axis-label">
        {{ xAxisLabel }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewColumnChart',
  props: {
    title: { type: String, required: true },
    xAxisLabel: { type: String, default: null },
    data: {
      type: Array,
      required: true,
      validator: value => {
        return value.every(
          item => 'label' in item && 'value' in item && 'color' in item
        );
      }
    }
  },
  computed: {
    maxValue() {
      return Math.max(...this.data.map(item => item.value));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewColumnChart__header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ReviewColumnChart__header-title {
  @include text-caption-bold-dark;
}

.ReviewColumnChart__badge {
  border: 1px solid $color-mint-green;
  background-color: $color-review-white;
  color: $color-mint-green;
  padding: 1px 5px;
  border-radius: 20px;
  font-size: 10px;
  line-height: 16px;
}

.ReviewColumnChart__chart {
  position: relative;
  margin-top: 14px;
}

.ReviewColumnChart__legend {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.ReviewColumnChart__legend-item {
  display: flex;
  align-items: center;
  gap: 7px;
}

.ReviewColumnChart__legend-item-color-box {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  flex-shrink: 0;
}

.ReviewColumnChart__legend-item-label {
  @include text-caption-small-dark;
}

.ReviewColumnChart__chart-bar-chart {
  position: relative;
  display: flex;
  justify-content: center;
}

.ReviewColumnChart__chart-bars {
  border-bottom: solid 1px $color-grey-32;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 135px;
  width: fit-content;
  gap: 20px;
  padding: 0 33px;
}

.ReviewColumnChart__chart-bar {
  position: relative;
  border-radius: 8px 8px 0 0;
  width: 32px;
  max-height: 85px;
}

.ReviewColumnChart__chart-bar-value {
  @include text-caption-small-dark;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.ReviewColumnChart__chart-bars-x-axis-label {
  @include text-caption-small-dark;
  margin-top: 10px;
  text-align: center;
}
</style>

<i18n locale="ko">
{
  "data_label": "{0}건",
  "badge": "우수"
}
</i18n>
