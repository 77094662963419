<template>
  <AppModalDefault
    class="InstallRequestOnboardingDirectionDialog"
    :title="$t('title')"
    is-maximized-by-default
    @close="clickCloseButton"
  >
    <template #sub-head>
      <AppProgressSteps
        class="InstallRequestOnboardingDirectionDialog__progress-step"
        :steps="steps"
        :current-step="installRequestCurrentStep"
      />
    </template>
    <template #body>
      <div class="InstallRequestOnboardingDirectionDialog__containter">
        <div class="InstallRequestOnboardingDirectionDialog__title">
          {{ stepName(installRequestCurrentStep) }}
        </div>
        <AppInfoBox
          v-if="infoBoxMessage()"
          class="InstallRequestOnboardingDirectionDialog__info-box"
        >
          <AppMarked :text="infoBoxMessage()" />
        </AppInfoBox>
        <Component :is="currentStepComponent" />
      </div>
    </template>
    <template #foot>
      <div class="InstallRequestOnboardingDirectionDialog__buttons">
        <AppButton
          v-if="!isFirstStep"
          :label="$t('button.prev')"
          button-style="grey-outline"
          @click="clickPrevPageButton"
        />
        <AppButton
          v-if="!isLastStep"
          :label="$t('button.next')"
          :disabled="!hasRequiredProductDetailInstallation"
          button-style="blue"
          @click="clickNextPageButton"
        />
        <AppButton
          v-if="isLastStep"
          :label="$t('button.complete')"
          button-style="blue"
          @click="clickCompleteButton"
        />
      </div>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import DialogView from '@/mixins/DialogView';
import InstallRequestType from '@/enums/InstallRequestType';
import InstallSlotType from '@/enums/InstallSlotType';
import InstallRequestProgressStep from '@/enums/InstallRequestProgressStep';
import InstallRequestOnboardingDirectionDialogProductDetail from './InstallRequestOnboardingDirectionDialogProductDetail';
import InstallRequestOnboardingDirectionDialogReviewBoard from './InstallRequestOnboardingDirectionDialogReviewBoard';
import InstallRequestOnboardingDirectionDialogMain from './InstallRequestOnboardingDirectionDialogMain';
import InstallRequestOnboardingDirectionDialogEtc from './InstallRequestOnboardingDirectionDialogEtc';
import InstallRequestOnboardingDirectionDialogCountAndScore from './InstallRequestOnboardingDirectionDialogCountAndScore';
import InstallRequestOnboardingDirectionDialogConfirm from './InstallRequestOnboardingDirectionDialogConfirm';

export default {
  name: 'InstallRequestOnboardingDirectionDialog',
  mixins: [DialogView],
  computed: {
    ...mapState('installRequest', [
      'installRequestCurrentStep',
      'bubbleVisibility'
    ]),
    ...mapGetters('installRequest', ['installationsToSave']),
    steps() {
      return InstallRequestProgressStep.values.map(
        InstallRequestProgressStep.t
      );
    },
    isFirstStep() {
      return (
        this.installRequestCurrentStep ===
        InstallRequestProgressStep.PRODUCT_DETAIL
      );
    },
    isLastStep() {
      return (
        this.installRequestCurrentStep === InstallRequestProgressStep.CONFIRM
      );
    },
    currentStepComponent() {
      switch (this.installRequestCurrentStep) {
        case InstallRequestProgressStep.REVIEW_BOARD:
          return InstallRequestOnboardingDirectionDialogReviewBoard;
        case InstallRequestProgressStep.MAIN:
          return InstallRequestOnboardingDirectionDialogMain;
        case InstallRequestProgressStep.ETC:
          return InstallRequestOnboardingDirectionDialogEtc;
        case InstallRequestProgressStep.COUNT_AND_SCORE:
          return InstallRequestOnboardingDirectionDialogCountAndScore;
        case InstallRequestProgressStep.CONFIRM:
          return InstallRequestOnboardingDirectionDialogConfirm;
        default:
          return InstallRequestOnboardingDirectionDialogProductDetail;
      }
    },
    hasRequiredProductDetailInstallation() {
      if (
        this.installRequestCurrentStep !==
        InstallRequestProgressStep.PRODUCT_DETAIL
      ) {
        return true;
      }

      return this.installationsToSave.some(
        installation =>
          installation.slot_type === InstallSlotType.PRODUCT_DETAIL_DEFAULT
      );
    }
  },
  created() {
    this.SET_ALL_BUBBLE_VISIBLE();
    this.SET_INSTALL_REQUEST_TYPE(InstallRequestType.ONBOARDING);
  },
  methods: {
    ...mapActions('dialog', ['confirm']),
    ...mapActions('installRequest', ['clearAllInstallRequest']),
    ...mapMutations('installRequest', [
      'SET_INSTALL_REQUEST_STEP',
      'SET_BUBBLE_INVISIBLE',
      'SET_ALL_BUBBLE_VISIBLE',
      'SET_INSTALL_REQUEST_TYPE'
    ]),
    stepName(step) {
      return InstallRequestProgressStep.t(step);
    },
    infoBoxMessage() {
      if (
        this.installRequestCurrentStep ===
        InstallRequestProgressStep.COUNT_AND_SCORE
      )
        return this.$t('widget_install_info_box.count_and_score');
      else if (
        this.installRequestCurrentStep === InstallRequestProgressStep.CONFIRM
      )
        return this.$t('widget_install_info_box.confirm');
      else return this.$t('widget_install_info_box.message');
    },
    clickPrevPageButton() {
      this.SET_INSTALL_REQUEST_STEP(this.installRequestCurrentStep - 1);
      this.resetScrollPosition();
    },
    clickNextPageButton() {
      if (this.bubbleVisibility.cardButton)
        this.SET_BUBBLE_INVISIBLE('cardButton');
      this.SET_INSTALL_REQUEST_STEP(this.installRequestCurrentStep + 1);
      this.resetScrollPosition();
    },
    resetScrollPosition() {
      this.$nextTick(() => {
        const container = this.$el.querySelector('.AppModal__body');

        if (container) {
          container.scrollTop = 0;
        }
      });
    },
    clickCompleteButton() {
      this.clearAllInstallRequest();
      this.close();
    },
    clickCloseButton() {
      this.confirm({
        title: this.$t('close_confirm.title'),
        message: this.$t('close_confirm.message'),
        closeButtonLabel: this.$t('close_confirm.button')
      }).then(result => {
        if (!result) return;

        this.clearAllInstallRequest();
        this.close();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/vars/_colors.scss';

::v-deep {
  .AppModalDefault__head {
    padding: 0;
  }

  .AppModalDefault__head-wrapper {
    padding: 13px 10px 13px 24px;
    border-bottom: 1px solid $color-grey-25;
  }

  .AppModalDefault__body {
    padding: 0;
    background-color: $color-grey-05;
  }

  $header-height: 121px;
  $footer-height: 64px;
  .AppModalDefault__body--maximized-modal {
    min-height: calc(100vh - ($header-height + $footer-height));
  }
}

.InstallRequestOnboardingDirectionDialog__progress-step {
  padding: 16px 0 16px 24px;
  border-bottom: 1px solid $color-grey-25;
}

.InstallRequestOnboardingDirectionDialog__containter {
  width: 900px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.InstallRequestOnboardingDirectionDialog__title {
  @include text-title;

  padding-top: 32px;
  text-align: center;
}

.InstallRequestOnboardingDirectionDialog__info-box {
  margin-top: 8px;
}

.InstallRequestOnboardingDirectionDialog__buttons {
  text-align: center;
}
</style>

<i18n locale="ko">
{
  "title": "위젯 설치 요청",
  "widget_install_info_box": {
    "count_and_score": "쇼핑몰 디자인(레이아웃)을 고려하여 설치할 위치와 위젯을 선택해주세요.<br /><b>상품 상세 페이지에 노출되는 리뷰 수는 크리마 리뷰 수로 교체해드립니다.</b>",
    "confirm": "요청 내용을 최종 확인해주세요. 접수된 수정 요청은 변경이 번거로우니 꼼꼼히 확인해주세요 🙌<br /><b>쇼핑몰 디자인(레이아웃)에 알맞지 않은 요청은 반영되지 않을 수 있습니다.</b>",
    "message": "쇼핑몰 디자인(레이아웃)을 고려하여 설치할 위치와 위젯을 선택해주세요."
  },
  "button": {
    "prev": "\u003C 이전으로 돌아가기",
    "next": "저장 후 다음으로 이동 \u003E",
    "complete": "요청 완료"
  },
  "close_confirm": {
    "title": "다음에 다시 하시겠습니까?",
    "message": "현재까지 입력한 정보는 저장되지 않습니다. 다음에 다시 하시겠습니까?",
    "button": "다음에 다시하기"
  }
}
</i18n>
