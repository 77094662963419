import { mapState } from 'vuex';
import punycode from 'punycode';

export default {
  computed: {
    ...mapState('session', ['currentBrand']),
    shopUrlValidations() {
      return [
        'url_format',
        { rule: 'check_url', async: true },
        {
          rule: v => this.checkUrlIncludeBrandUrlHost(v),
          errorMessage: this.$t('errors.not_including_brand_url_host_error', {
            brand_url_host: this.brandPunycodeHost
          })
        }
      ];
    },
    brandPunycodeHost() {
      const brandUrl = this.currentBrand.url;
      const brandUrlHost = new URL(brandUrl).hostname.replace(/^www./, '');

      return punycode.toASCII(brandUrlHost);
    }
  },
  methods: {
    checkUrlIncludeBrandUrlHost(value) {
      return !value || value.includes(this.brandPunycodeHost);
    }
  }
};
