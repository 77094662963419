<template>
  <AppModalDefault
    class="TheInstallRequestRenewalDialog"
    :title="$t('title')"
    is-maximized-by-default
    is-maximized-modal-page
    @close="clickCloseButton"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <div class="TheInstallRequestRenewalDialog__container">
          <div class="TheInstallRequestRenewalDialog__subtitle">
            {{ $t('subtitle') }}
          </div>
          <AppForm
            id="installRequestRenewal"
            class="TheInstallRequestRenewalDialog__form"
            object-id="install_request"
            v-bind="formProps"
            :submit-button="{
              submitLabel: $t('submit'),
              disabled: !installRequestStepCompleted,
              clickHandler: submit
            }"
            v-on="formEvents"
          >
            <template #group="{ id }">
              <template v-if="id === 'renewal_target_device' && isResponsive">
                <AppTipBox
                  class="TheInstallRequestRenewalDialog__responsive-tip"
                  :text="$t('renewal_target.device.responsive_tip')"
                />
                <AppMultipleSelectCheckbox
                  v-model="formObject.renewal_target_device"
                  :options="deviceOptions"
                  :label="$t('renewal_target.device.label')"
                />
              </template>
              <template v-else-if="id === 'renewal_design_complete_date'">
                <AppDatePicker
                  v-model="formObject.renewal_design_complete_date"
                  class="TheInstallRequestRenewalDialog__renewal-schedule-date"
                  :disabled="formObject.renewal_already_live"
                  :min-date="renewalScheduleMinDate"
                  :max-date="renewalScheduleMaxDate"
                />
                <AppCheckbox
                  v-model="formObject.renewal_already_live"
                  class="TheInstallRequestRenewalDialog__already-live"
                  :label="$t('renewal_schedule.already_live_label')"
                />
              </template>
            </template>
          </AppForm>
        </div>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import DialogView from '@/mixins/DialogView';
import FormView from '@/mixins/FormView';
import DialogSize from '@/enums/DialogSize';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import InstallRequestType from '@/enums/InstallRequestType';

export default {
  name: 'TheInstallRequestRenewalDialog',
  mixins: [DialogView, FormView],
  data() {
    return {
      isLoading: true,
      renewalScheduleMinDate: moment().toVal(),
      renewalScheduleMaxDate: moment()
        .add(60, 'days')
        .toVal(),
      deviceOptions: [
        {
          id: 'pc',
          label: this.$t('renewal_target.device.options.pc'),
          value: WidgetDeviceType.PC
        },
        {
          id: 'mobile',
          label: this.$t('renewal_target.device.options.mobile'),
          value: WidgetDeviceType.MOBILE
        }
      ],
      pcSkinList: [],
      mobileSkinList: []
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapState('installRequest', ['installRequestStepCompleted']),
    widgetRequestStatusBadgeLabel() {
      return this.$t(
        `install_request.status.${
          this.installRequestStepCompleted ? 'completed' : 'not_completed'
        }`
      );
    },
    formSections() {
      return [
        {
          id: 'manager_info',
          label: this.$t('manager_info.title'),
          groups: [
            {
              id: 'manager_info_tip',
              label: null,
              type: 'tip_box',
              value: this.$t('manager_info.tip')
            },
            {
              id: 'user_name',
              label: this.$t('manager_info.name.label'),
              type: 'text',
              placeholder: this.$t('manager_info.name.placeholder'),
              validate: ['required']
            },
            {
              id: 'email',
              label: this.$t('manager_info.email.label'),
              type: 'text',
              placeholder: this.$t('manager_info.email.placeholder'),
              validate: ['required', 'email_format']
            },
            {
              id: 'phone',
              label: this.$t('manager_info.phone.label'),
              type: 'text',
              placeholder: this.$t('manager_info.phone.placeholder'),
              validate: ['required', 'mobile_phone_format']
            }
          ]
        },
        {
          id: 'renewal_target',
          label: this.$t('renewal_target.title'),
          groups: this.renewalTargetFormGroups.filter(g => g)
        },
        {
          id: 'install_request',
          label: this.$t('install_request.title'),
          groups: [
            {
              id: 'install_request_tip',
              label: null,
              type: 'tip_box',
              value: this.$t('install_request.tip')
            },
            {
              id: 'install_request',
              label: this.$t('install_request.label'),
              titleBadge: {
                label: this.widgetRequestStatusBadgeLabel,
                badgeStyle: this.installRequestStepCompleted
                  ? 'default'
                  : 'red-outline',
                badgeSize: 'small'
              },
              type: 'button',
              value: this.$t('install_request.label'),
              disabled: this.installRequestDisabled,
              buttonTooltip: this.installRequestDisabled
                ? this.$t('install_request.disabled_tooltip')
                : null,
              clickHandler: this.openInstallRequestRenewalDirectionDialog
            }
          ]
        },
        {
          id: 'renewal_schedule',
          label: this.$t('renewal_schedule.title'),
          groups: [
            {
              id: 'renewal_design_complete_date',
              label: this.$t('renewal_schedule.label'),
              groupDescription: this.$t('renewal_schedule.description'),
              type: 'date',
              validate: this.formObject.renewal_already_live ? [] : ['required']
            }
          ]
        }
      ];
    },
    renewalTargetFormGroups() {
      return [
        {
          id: 'renewal_target_responsiveness',
          label: this.$t('renewal_target.responsiveness.label'),
          type: 'select_radio',
          options: [
            {
              id: 'is_responsive',
              label: this.$t('renewal_target.responsiveness.is_responsive'),
              value: true
            },
            {
              id: 'is_not_responsive',
              label: this.$t('renewal_target.responsiveness.is_not_responsive'),
              value: false
            }
          ],
          validate: ['required']
        },
        ...(this.isResponsive || this.isNotResponsive
          ? [
              {
                id: 'renewal_target_device',
                label: this.$t('renewal_target.device.label'),
                type: 'multiple_select_checkbox',
                options: this.deviceOptions,
                validate: ['required']
              }
            ]
          : []),
        ...(this.isResponsive
          ? this.responsiveSkinListGroups
          : this.notResponsiveSkinListGroups)
      ];
    },
    responsiveSkinListGroups() {
      return this.isPcSelected && this.isMobileSelected
        ? [
            {
              id: 'renewal_responsive_skin',
              label: this.$t('renewal_target.skin.responsive_label'),
              type: 'select_filterable',
              options: this.pcSkinList.concat(this.mobileSkinList),
              placeholder: this.$t('renewal_target.skin.placeholder'),
              validate: ['required']
            }
          ]
        : this.isPcSelected
        ? [
            {
              id: 'renewal_pc_skin',
              label: this.$t('renewal_target.skin.pc_label'),
              type: 'select_filterable',
              options: this.pcSkinList.concat(this.mobileSkinList),
              placeholder: this.$t('renewal_target.skin.placeholder'),
              validate: ['required']
            }
          ]
        : this.isMobileSelected
        ? [
            {
              id: 'renewal_mobile_skin',
              label: this.$t('renewal_target.skin.mobile_label'),
              type: 'select_filterable',
              options: this.pcSkinList.concat(this.mobileSkinList),
              placeholder: this.$t('renewal_target.skin.placeholder'),
              validate: ['required']
            }
          ]
        : [];
    },
    notResponsiveSkinListGroups() {
      return [
        ...(this.isPcSelected
          ? [
              {
                id: 'renewal_pc_skin',
                label: this.$t('renewal_target.skin.pc_label'),
                type: 'select_filterable',
                options: this.pcSkinList,
                placeholder: this.$t('renewal_target.skin.placeholder'),
                validate: ['required']
              }
            ]
          : []),
        ...(this.isMobileSelected
          ? [
              {
                id: 'renewal_mobile_skin',
                label: this.$t('renewal_target.skin.mobile_label'),
                type: 'select_filterable',
                options: this.mobileSkinList,
                placeholder: this.$t('renewal_target.skin.placeholder'),
                validate: ['required']
              }
            ]
          : [])
      ];
    },
    isResponsive() {
      return this.formObject.renewal_target_responsiveness === true;
    },
    isNotResponsive() {
      return this.formObject.renewal_target_responsiveness === false;
    },
    isPcSelected() {
      return this.formObject.renewal_target_device?.includes(
        WidgetDeviceType.PC
      );
    },
    isMobileSelected() {
      return this.formObject.renewal_target_device?.includes(
        WidgetDeviceType.MOBILE
      );
    },
    installRequestDisabled() {
      return !this.formObject.renewal_target_device?.length;
    }
  },
  watch: {
    'formObject.renewal_target_responsiveness': {
      handler: 'resetSkinValues'
    },
    'formObject.renewal_target_device': {
      handler: 'resetSkinValues'
    },
    'formObject.renewal_responsive_skin': {
      handler: 'updateSkinValues'
    }
  },
  mounted() {
    this.isLoading = true;
    api
      .get('install_request/renewal/skin_lists')
      .then(({ data }) => {
        this.pcSkinList = this.parseSkinList(data.pc_skin_list);
        this.mobileSkinList = this.parseSkinList(data.mobile_skin_list);
      })
      .finally(() => {
        this.isLoading = false;
      });
    this.SET_INSTALL_REQUEST_TYPE(InstallRequestType.RENEWAL);
  },
  methods: {
    ...mapActions('dialog', ['openDialog', 'confirm']),
    ...mapActions('installRequest', [
      'requestRenewalWidgetInstall',
      'clearAllInstallRequest'
    ]),
    ...mapMutations('installRequest', [
      'SET_INSTALL_DEVICE_TYPES',
      'SET_INSTALL_REQUEST_TYPE'
    ]),
    parseSkinList(skinList) {
      if (!skinList) return [];

      return skinList.map(skin => ({
        label: this.skinLabel(skin),
        value: skin.skin_code,
        disabled: !this.isSkinAvailable(skin)
      }));
    },
    skinLabel(skin) {
      const skinCode = `[${skin.skin_code}]`;
      const skinName = skin.skin_name;
      const published =
        skin.published_in === 'unpublished'
          ? ''
          : parseInt(skin.published_in) === this.currentBrand.cafe24_shop_no
          ? `[${this.$t('renewal_target.skin.published_in_current_mall')}]`
          : `[${this.$t('renewal_target.skin.published_in_multi_mall')}]`;

      return `${skinCode}${published} ${skinName}`;
    },
    isSkinAvailable(skin) {
      return (
        skin.published_in === 'unpublished' ||
        parseInt(skin.published_in) === this.currentBrand.cafe24_shop_no
      );
    },
    resetSkinValues(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.formObject.renewal_pc_skin = null;
        this.formObject.renewal_mobile_skin = null;
        this.formObject.renewal_responsive_skin = null;
      }
    },
    updateSkinValues(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.formObject.renewal_pc_skin = newVal;
        this.formObject.renewal_mobile_skin = newVal;
      }
    },
    openInstallRequestRenewalDirectionDialog() {
      this.SET_INSTALL_DEVICE_TYPES(this.formObject.renewal_target_device);
      this.openDialog('InstallRequestRenewalDirectionDialog');
    },
    async submit() {
      await this.setInstallRequestDate();
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'check_confirm',
          title: this.$t('submit_confirm.title'),
          markdownText: this.$t('submit_confirm.message_html', [
            this.formObject.install_request_date
          ]),
          checkboxLabel: this.$t('submit_confirm.checkbox'),
          closeButtonLabel: this.$t('submit_confirm.confirm_button'),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => this.saveInstallRequest());
      });
    },
    async setInstallRequestDate() {
      this.isLoading = true;

      const designCompleteDate = this.formObject.renewal_already_live
        ? null
        : this.formObject.renewal_design_complete_date;

      return await api
        .get('install_request/renewal/available_dates', {
          params: {
            design_complete_date: designCompleteDate
          }
        })
        .then(res => {
          this.formObject.install_request_date = res.data.available_date;
          this.formObject.one_day_renewal_available =
            res.data.one_day_renewal_available;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    saveInstallRequest() {
      this.isLoading = true;
      this.requestRenewalWidgetInstall(this.formObject)
        .then(() => {
          this.clearAllInstallRequest();
          this.close();
        })
        .catch(() => (this.isLoading = false));
    },
    clickCloseButton() {
      this.confirm({
        title: this.$t('close_confirm.title'),
        message: this.$t('close_confirm.message'),
        closeButtonLabel: this.$t('close_confirm.button')
      }).then(result => {
        if (!result) return;

        this.clearAllInstallRequest();
        this.close();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.TheInstallRequestRenewalDialog__container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.TheInstallRequestRenewalDialog__subtitle {
  @include text-sub-title;
}

.TheInstallRequestRenewalDialog__responsive-tip {
  margin-top: 4px;
}

.TheInstallRequestRenewalDialog__renewal-schedule-date {
  margin: 4px 0;
}

.TheInstallRequestRenewalDialog__already-live {
  display: block;
}

::v-deep {
  .TheInstallRequestRenewalDialog__container {
    .AppForm__section {
      width: 680px;
      margin: 16px auto 32px auto;
      padding: 24px;
      border-radius: 4px;
      border-top: 0;
      background-color: #fff;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .AppForm__form-controls {
      text-align: center;
    }
  }
}
</style>

<style lang="scss">
@import '@/scss/vars/_colors.scss';
.AppMessageDialog__text-viewer {
  .TheInstallRequestRenewalDialog__confirm-list {
    margin: 0;
    padding-left: 20px;
  }
  .TheInstallRequestRenewalDialog__confirm-date {
    color: $color-blue;
  }
}
</style>

<i18n locale="ko">
{
  "title": "리뉴얼 위젯 설치 요청",
  "subtitle": "리뉴얼 위젯 설치 요청을 위해 아래 내용을 입력해주세요.",
  "submit": "리뉴얼 위젯 설치 요청",
  "manager_info": {
    "title": "담당자 정보",
    "tip": "리뉴얼 작업과 관련 주된 소통은 이메일로 진행됩니다.",
    "name": {
      "label": "담당자 이름",
      "placeholder": "담당자 이름을 입력해주세요."
    },
    "email": {
      "label": "담당자 이메일",
      "placeholder": "연락 가능한 이메일을 입력해주세요."
    },
    "phone": {
      "label": "담당자 휴대전화 번호",
      "placeholder": "연락 가능한 휴대전화 번호를 입력해주세요."
    }
  },
  "renewal_target": {
    "title": "리뉴얼 적용 정보",
    "responsiveness": {
      "label": "새롭게 적용하는 리뉴얼 스킨이 반응형인가요?",
      "is_responsive": "네",
      "is_not_responsive": "아니오"
    },
    "device": {
      "label": "리뉴얼 범위를 선택해주세요.",
      "responsive_tip": "리뉴얼 스킨이 반응형이더라도 PC, 모바일 위젯 설치는 각각 진행되므로 필요하다면 모두 선택해주세요.<br />만약 PC에서 모바일 레이아웃을 사용 중이라면, 모바일 위젯 설치 요청만 반영됩니다.",
      "options": {
        "pc": "PC",
        "mobile": "모바일"
      }
    },
    "skin": {
      "label": "위젯을 설치할 리뉴얼 스킨 주소를 입력해주세요.",
      "pc_label": "PC 스킨",
      "mobile_label": "모바일 스킨",
      "responsive_label": "PC/모바일 스킨",
      "placeholder": "스킨을 선택해주세요.",
      "published_in_current_mall": "적용 중",
      "published_in_multi_mall": "멀티몰에 적용 중"
    }
  },
  "install_request": {
    "title": "리뉴얼 위젯 설치 요청",
    "tip": "리뉴얼 위젯 설치는 이미 설치된 크리마 위젯을 모두 제거하고 요청 내용으로 새로 설치합니다.<br />따라서 새로운 스킨에 이미 크리마 위젯이 노출되고 있어도 꼭 설치 요청 내용에 포함해주세요.",
    "label": "위젯 설치 요청",
    "disabled_tooltip": "리뉴얼 정보를 먼저 입력해주세요.",
    "status": {
      "not_completed": "진행 전",
      "completed": "입력 완료"
    }
  },
  "renewal_schedule": {
    "title": "디자인 작업 완료일",
    "label": "리뉴얼 스킨 디자인 작업 완료일을 입력해주세요.",
    "description": "디자인 작업 완료일을 참고하여 리뉴얼 위젯 설치 작업 예상 완료일을 산정합니다.",
    "date_label": "날짜 선택",
    "already_live_label": "리뉴얼 스킨을 이미 적용한 경우 체크해주세요."
  },
  "close_confirm": {
    "title": "다음에 다시 하시겠습니까?",
    "message": "현재까지 입력한 정보는 저장되지 않습니다. 다음에 다시 하시겠습니까?",
    "button": "다음에 다시하기"
  },
  "submit_confirm": {
    "title": "설치 요청을 완료하시겠습니까?",
    "message_html": "완료 전 중요 안내사항을 확인해주세요.<ol class='TheInstallRequestRenewalDialog__confirm-list'><li>설치 요청 완료 후 변경은 어렵습니다. 신중하게 검토해주세요.</li><li><b>현재 예상 설치 완료일은 <span class='TheInstallRequestRenewalDialog__confirm-date'>{0}</span> 입니다.</b> 단, 작업량에 따라 변동이 있을 수 있습니다.</li><li>위젯 설치 완료시 이메일 및 문자로 안내드리며, 쇼핑몰 적용 전 미리보기 기능이 제공됩니다.</li><li>리뉴얼 작업 진행 중 특이사항이 발생할 경우, 담당자가 메일을 통해 안내 드릴 예정입니다.</li></ol>",
    "checkbox": "안내사항 확인 후 요청합니다.",
    "confirm_button": "완료"
  }
}
</i18n>
