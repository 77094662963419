<template>
  <div>
    <SummaryBoxEasyJoinWithBrand
      v-if="isIdleEasyJoinAvailable"
      class="ReviewIntro__summary-box"
    />
    <AppIntroContent
      :document-url="reviewIntroLink"
      :images="reviewIntroImages"
      :image-width="1200"
    />
  </div>
</template>

<style lang="scss">
.ReviewIntro__summary-box {
  margin-bottom: 28px;
}
</style>

<script>
import SummaryBoxEasyJoinWithBrand from './components/SummaryBoxEasyJoinWithBrand';
import { mapGetters } from 'vuex';

export default {
  name: 'ReviewIntro',
  components: { SummaryBoxEasyJoinWithBrand },
  computed: {
    ...mapGetters('session', ['isShopifyBrand', 'isIdleEasyJoinAvailable']),
    reviewIntroLink() {
      return this.isShopifyBrand
        ? 'https://cremafactory.notion.site/SERVICE-INTRODUCTION-132504922fd8456bb4507d9fb2d8ce2e'
        : 'https://sclu.io/share/bulk/file/redirect?hashId=ff0694dbd73c19a0&historyId=fhzbSuayY';
    },
    reviewIntroImages() {
      return this.isShopifyBrand
        ? [
            {
              url:
                'https://assets.cre.ma/m/admin/crema-review-intro-v4-shopify-01.png'
            }
          ]
        : [
            {
              url:
                'http://assets.cre.ma/m/241120_admin_CREMA_Review_introduction1.png'
            },
            {
              url:
                'http://assets.cre.ma/m/241120_admin_CREMA_Review_introduction2.png',
              external_link: 'https://bit.ly/3CyL0oF'
            },
            {
              url:
                'http://assets.cre.ma/m/241120_admin_CREMA_Review_introduction3.png'
            },
            {
              url:
                'http://assets.cre.ma/m/241120_admin_CREMA_Review_introduction4-1.png',
              external_link: 'https://bit.ly/3CB0I2N'
            }
          ];
    }
  }
};
</script>
