var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"form-props":Object.assign({}, {formStyle: 'wide'}, _vm.formProps)},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
var errors = ref.errors;
return [(id === 'nth_step_review_allowed_days_after_send')?[_c('i18n',{attrs:{"path":"nth_step_review_allowed_days_after_send.description"}},[_c('AppNumberInput',{attrs:{"name":"campaign_settings[nth_step_review_allowed_days_after_send]","digits":3,"invalid":!!errors.nth_step_review_allowed_days_after_send,"inline":""},on:{"blur":function($event){return _vm.validateField('nth_step_review_allowed_days_after_send')},"change":function($event){return _vm.validateField('nth_step_review_allowed_days_after_send')}},model:{value:(_vm.formObject.nth_step_review_allowed_days_after_send),callback:function ($$v) {_vm.$set(_vm.formObject, "nth_step_review_allowed_days_after_send", $$v)},expression:"formObject.nth_step_review_allowed_days_after_send"}}),_c('AppFormError',{attrs:{"error":errors.nth_step_review_allowed_days_after_send}})],1)]:(id === 'nth_step_review_mileage_expiry_days')?[_c('i18n',{attrs:{"path":"nth_step_review_mileage_expiry_days.description"}},[_c('AppNumberInput',{attrs:{"name":"campaign_settings[nth_step_review_mileage_expiry_days]","digits":3,"invalid":!!errors.nth_step_review_mileage_expiry_days,"inline":""},on:{"blur":function($event){return _vm.validateField('nth_step_review_mileage_expiry_days')},"change":function($event){return _vm.validateField('nth_step_review_mileage_expiry_days')}},model:{value:(_vm.formObject.nth_step_review_mileage_expiry_days),callback:function ($$v) {_vm.$set(_vm.formObject, "nth_step_review_mileage_expiry_days", $$v)},expression:"formObject.nth_step_review_mileage_expiry_days"}}),_c('AppFormError',{attrs:{"error":errors.nth_step_review_mileage_expiry_days}})],1)]:(id === 'nth_step_review_additional_mileage_cents')?[_c('label',{domProps:{"textContent":_vm._s(
          _vm.$t('nth_step_review_additional_mileage_cents.description', [
            _vm.formObject.nth_step_review_additional_mileage_cents
          ])
        )}}),_c('AppFormHint',{attrs:{"message":_vm.$t('nth_step_review_additional_mileage_cents.hint')}}),_c('AppExternalLinkWithIcon',{attrs:{"to":_vm.pathWithBrandParams('/v2/review/settings/mileage')}},[_c('AppInlineButton',{attrs:{"button-style":"caption","label":_vm.$t('nth_step_review_additional_mileage_cents.link')}})],1)]:_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }