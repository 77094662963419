<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppTable
      class="SlotTableProductDetailDefault AppForm__group-field AppForm__group-field--mt8"
      :columns="columns"
      :rows="rows"
    >
      <template #cell="{ column, row }">
        <template v-if="column.id === 'widget_style'">
          <span>{{ row.widget_style_name }}</span>
          <AppBadge
            v-if="row.widget_id"
            class="SlotTableProductDetailDefault__badge"
            badge-style="mint-green-outline"
            badget-size="small"
            :label="$t('using_widget')"
          />
        </template>
        <template v-else-if="column.id === 'select'">
          <AppRadio
            v-model="installationId"
            :name="`product_detail_default_installation_${deviceType}`"
            :value="row.id"
          />
        </template>
      </template>
    </AppTable>
  </AppAjaxContent>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import WidgetStyle from '@/enums/WidgetStyle';
import InstallSlotType from '@/enums/InstallSlotType';
import InstallRequestRenewalSlotTableView from '@/mixins/InstallRequestRenewalSlotTableView';

export default {
  name: 'SlotTableProductDetailDefault',
  mixins: [InstallRequestRenewalSlotTableView],
  data() {
    return {
      installationId: null,
      slotType: InstallSlotType.PRODUCT_DETAIL_DEFAULT
    };
  },
  computed: {
    ...mapGetters('installRequest', ['currentWidgetsBySlotId']),
    columns() {
      return [
        {
          id: 'widget_style',
          label: this.$t('form.widget_style'),
          width: '30%'
        },
        {
          id: 'widget_info',
          type: 'html',
          label: this.$t('form.widget_info'),
          width: '40%'
        },
        {
          id: 'select',
          label: this.$t('app.select'),
          width: '30%'
        }
      ];
    },
    selectedSlotInstallation() {
      return this.currentWidgetsBySlotId.find(
        i =>
          i.widget_device_type === this.deviceType &&
          i.slot_type === this.slotType &&
          i.selected
      );
    },
    rows() {
      return this.widgets.map(widget => ({
        ...widget,
        selected: widget.id === this.installationId
      }));
    }
  },
  watch: {
    rows() {
      this.updateWidgetsBySlotId();
    }
  },
  methods: {
    ...mapMutations('installRequest', ['ADD_WIDGETS_BY_SLOT_ID']),
    ...mapActions('installRequest', ['removeDeviceSlotWidgetsBySlotId']),
    setDefaultWidget() {
      if (this.selectedSlotInstallation)
        this.installationId = this.selectedSlotInstallation.id;
      else {
        const defaultWidget = this.widgets.find(
          w => w.widget_style === WidgetStyle.LIST
        );
        this.installationId = defaultWidget.id;
      }
    },
    updateWidgetsBySlotId() {
      this.removeDeviceSlotWidgetsBySlotId({
        deviceType: this.deviceType,
        slotType: this.slotType
      });
      this.ADD_WIDGETS_BY_SLOT_ID(this.rows);
    }
  }
};
</script>

<style lang="scss" scoped>
.SlotTableProductDetailDefault__badge {
  margin-left: 4px;
}
</style>

<i18n locale="ko">
{
  "form": {
    "widget_style": "위젯",
    "widget_info": "위젯 정보"
  },
  "widget_style": "{0} 위젯",
  "widget_info": "위젯 ID: {0}<br>위젯 이름: {1}",
  "using_widget": "사용 중"
}
</i18n>
