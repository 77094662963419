<template>
  <AppAjaxContent :is-loading="isLoading">
    <template>
      <AppForm
        :id="SETTINGS_ID"
        v-bind="formProps"
        object-id="settings"
        submit-button
        v-on="formEvents"
      >
        <template #group="{ id, subFields }">
          <AppCheckbox
            v-for="subField in subFields"
            :key="subField"
            v-model="formObject[subField]"
            :disabled="!formObject[id]"
            :name="`settings[${subField}]`"
            input-style="block"
            class="ReviewSettingsSuperAdminVueWidget__sub-field"
            :label="$t(subField)"
          />
        </template>
      </AppForm>
    </template>
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';

export default {
  name: 'ReviewSettingsSuperAdminVueWidget',
  mixins: [FormView, SettingsView],
  data() {
    return {
      SETTINGS_ID: 'review_settings_super_admin_vue_widget',
      isLoading: true
    };
  },
  computed: {
    groupOptions() {
      return {
        show_vue_widget_setting_list: { type: 'checkbox' },
        show_vue_widget_setting_gallery: { type: 'checkbox' },
        show_vue_widget_setting_main_gallery: { type: 'checkbox' },
        show_vue_widget_setting_best_gallery: { type: 'checkbox' },
        show_vue_widget_setting_square_gallery: { type: 'checkbox' },
        show_vue_widget_setting_horizontal_scroll: { type: 'checkbox' },
        show_vue_widget_setting_external: { type: 'checkbox' },
        show_vue_widget_setting_photo_thumbnail: { type: 'checkbox' },
        show_vue_widget_setting_board: { type: 'checkbox' },
        show_vue_widget_setting_product_thumbnail: { type: 'checkbox' },
        enable_vue_widget_list: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_reviews_list',
            'enable_vue_widget_mo_product_reviews_list',
            'enable_vue_widget_pc_reviews_list',
            'enable_vue_widget_pc_product_reviews_list'
          ]
        },
        enable_vue_widget_gallery: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_reviews_gallery',
            'enable_vue_widget_mo_product_reviews_gallery',
            'enable_vue_widget_pc_reviews_gallery',
            'enable_vue_widget_pc_product_reviews_gallery'
          ]
        },
        enable_vue_widget_main_gallery: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_reviews_main_gallery',
            'enable_vue_widget_pc_reviews_main_gallery'
          ]
        },
        enable_vue_widget_best_gallery: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_reviews_best_gallery',
            'enable_vue_widget_pc_reviews_best_gallery'
          ]
        },
        enable_vue_widget_horizontal_scroll: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_reviews_horizontal_scroll',
            'enable_vue_widget_mo_product_reviews_horizontal_scroll',
            'enable_vue_widget_pc_reviews_horizontal_scroll',
            'enable_vue_widget_pc_product_reviews_horizontal_scroll'
          ]
        },
        enable_vue_widget_square_gallery: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_reviews_square_gallery',
            'enable_vue_widget_pc_reviews_square_gallery'
          ]
        },
        enable_vue_widget_external: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_product_reviews_external',
            'enable_vue_widget_pc_product_reviews_external'
          ]
        },
        enable_vue_widget_photo_thumbnail: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_product_reviews_photo_thumbnail',
            'enable_vue_widget_pc_product_reviews_photo_thumbnail'
          ]
        },
        enable_vue_widget_board: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_reviews_board',
            'enable_vue_widget_mo_product_reviews_board',
            'enable_vue_widget_pc_product_reviews_board'
          ]
        },
        enable_vue_widget_product_options: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_product_reviews_product_options',
            'enable_vue_widget_pc_product_reviews_product_options'
          ]
        },
        enable_vue_widget_keyword: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_product_reviews_keyword',
            'enable_vue_widget_pc_product_reviews_keyword'
          ]
        },
        enable_vue_widget_detachable_board: {
          type: 'checkbox',
          subFields: []
        },
        enable_vue_widget_products: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_products',
            'enable_vue_widget_pc_products'
          ]
        },
        enable_vue_widget_reviews_new: {
          type: 'checkbox',
          subFields: []
        },
        enable_vue_widget_reviews_show: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_reviews_show',
            'enable_vue_widget_pc_reviews_show'
          ]
        },
        enable_vue_widget_reviews_photo_reviews_popup: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_reviews_photo_reviews_popup',
            'enable_vue_widget_pc_reviews_photo_reviews_popup'
          ]
        },
        enable_vue_widget_product_reviews_media_collage_popup: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_product_reviews_media_collage_popup',
            'enable_vue_widget_pc_product_reviews_media_collage_popup'
          ]
        },
        enable_vue_widget_count_and_score: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_count_and_score',
            'enable_vue_widget_pc_count_and_score'
          ]
        },
        enable_vue_widget_managing_reviews: {
          type: 'checkbox',
          subFields: [
            'enable_vue_widget_mo_managing_reviews',
            'enable_vue_widget_pc_managing_reviews'
          ]
        },
        enable_vue_widget_target_product_reviews: {
          type: 'checkbox'
        }
      };
    }
  },
  mounted() {
    api
      .get('/review/vue_widget_settings')
      .then(({ data }) => (this.orgFormObject = data.settings))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/review/vue_widget_settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => (this.orgFormObject = { ...this.formObject }))
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewSettingsSuperAdminVueWidget__sub-field {
  margin-top: 4px;
  margin-left: 12px;
}
</style>

<i18n locale="ko">
{
  "enable_vue_widget_mo_reviews_list": "모바일, 전체 리뷰",
  "enable_vue_widget_mo_product_reviews_list": "모바일, 상품 리뷰",
  "enable_vue_widget_pc_reviews_list": "PC, 전체 리뷰",
  "enable_vue_widget_pc_product_reviews_list": "PC, 상품 리뷰",
  "enable_vue_widget_mo_reviews_gallery": "모바일, 전체 리뷰",
  "enable_vue_widget_mo_product_reviews_gallery": "모바일, 상품 리뷰",
  "enable_vue_widget_pc_reviews_gallery": "PC, 전체 리뷰",
  "enable_vue_widget_pc_product_reviews_gallery": "PC, 상품 리뷰",
  "enable_vue_widget_mo_reviews_main_gallery": "모바일, 전체 리뷰",
  "enable_vue_widget_pc_reviews_main_gallery": "PC, 전체 리뷰",
  "enable_vue_widget_mo_reviews_best_gallery": "모바일, 전체 리뷰",
  "enable_vue_widget_pc_reviews_best_gallery": "PC, 전체 리뷰",
  "enable_vue_widget_mo_reviews_horizontal_scroll": "모바일, 전체 리뷰",
  "enable_vue_widget_mo_product_reviews_horizontal_scroll": "모바일, 상품 리뷰",
  "enable_vue_widget_pc_reviews_horizontal_scroll": "PC, 전체 리뷰",
  "enable_vue_widget_pc_product_reviews_horizontal_scroll": "PC, 상품 리뷰",
  "enable_vue_widget_mo_reviews_square_gallery": "모바일, 전체 리뷰",
  "enable_vue_widget_pc_reviews_square_gallery": "PC, 전체 리뷰",
  "enable_vue_widget_mo_product_reviews_external": "모바일, 상품 리뷰",
  "enable_vue_widget_pc_product_reviews_external": "PC, 상품 리뷰",
  "enable_vue_widget_mo_product_reviews_photo_thumbnail": "모바일, 상품 리뷰",
  "enable_vue_widget_pc_product_reviews_photo_thumbnail": "PC, 상품 리뷰",
  "enable_vue_widget_mo_reviews_board": "모바일, 전체 리뷰",
  "enable_vue_widget_mo_product_reviews_board": "모바일, 상품 리뷰",
  "enable_vue_widget_pc_product_reviews_board": "PC, 상품 리뷰",
  "enable_vue_widget_mo_product_reviews_product_options": "모바일, 상품 리뷰",
  "enable_vue_widget_pc_product_reviews_product_options": "PC, 상품 리뷰",
  "enable_vue_widget_mo_product_reviews_keyword": "모바일, 상품 리뷰",
  "enable_vue_widget_pc_product_reviews_keyword": "PC, 상품 리뷰",
  "enable_vue_widget_mo_products": "모바일, 상품 목록",
  "enable_vue_widget_pc_products": "PC, 상품 목록",
  "enable_vue_widget_mo_reviews_show": "모바일, 상세 팝업",
  "enable_vue_widget_pc_reviews_show": "PC, 상세 팝업",
  "enable_vue_widget_mo_reviews_photo_reviews_popup": "모바일, 강조 팝업",
  "enable_vue_widget_pc_reviews_photo_reviews_popup": "PC, 강조 팝업",
  "enable_vue_widget_mo_product_reviews_media_collage_popup": "모바일, 모아보기 팝업",
  "enable_vue_widget_pc_product_reviews_media_collage_popup": "PC, 모아보기 팝업",
  "enable_vue_widget_mo_count_and_score": "모바일, 기타 리뷰 위젯",
  "enable_vue_widget_pc_count_and_score": "PC, 기타 리뷰 위젯",
  "enable_vue_widget_mo_managing_reviews": "모바일, 기타 리뷰 위젯",
  "enable_vue_widget_pc_managing_reviews": "PC, 기타 리뷰 위젯"
}
</i18n>
