import KakaoStyleReviewImportStatus from '@/enums/KakaoStyleReviewImportStatus';

const state = {
  reviewImportStatus: null
};

const getters = {
  isReviewImportDisabled: ({ reviewImportStatus }) =>
    reviewImportStatus !== KakaoStyleReviewImportStatus.ENABLED
};

const mutations = {
  SET_REVIEW_IMPORT_STATUS(state, reviewImportStatus) {
    state.reviewImportStatus = reviewImportStatus;
  }
};

export default { namespaced: true, state, getters, mutations };
