<template>
  <SlotModalForm
    class="InstallRequestRenewalSlotEtcAddDialog"
    :title="$t('title')"
    :sub-title="WidgetPageType.t(pageType)"
    is-maximized-by-default
    :form-props="formProps"
    v-on="formEvents"
    @change-group="formGroupChanged"
  >
    <template #group="{ id }">
      <template v-if="id === 'pc_etc'">
        <SlotTable :device-type="WidgetDeviceType.PC" :slot-type="slotType" />
      </template>
      <template v-else-if="id === 'mobile_etc'">
        <SlotTable
          :device-type="WidgetDeviceType.MOBILE"
          :slot-type="slotType"
        />
      </template>
    </template>
    <template #description="{ id }">
      <div
        v-if="id === 'pc_custom_comment' || id === 'mobile_custom_comment'"
        class="AppForm__description"
      >
        <AppInlineButton
          button-style="caption"
          :label="$t('attachment_guide')"
          @click="openAlertDialog"
        />
      </div>
    </template>
  </SlotModalForm>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import InstallRequestRenewalCustomImageHelper from '@/mixins/InstallRequestRenewalCustomImageHelper';
import SlotModalForm from './components/SlotModalForm';
import WidgetPageType from '@/enums/WidgetPageType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import InstallSlotType from '@/enums/InstallSlotType';
import DialogSize from '@/enums/DialogSize';
import ShopUrlValidationHelper from '@/mixins/ShopUrlValidationHelper';

export default {
  name: 'InstallRequestRenewalSlotEtcAddDialog',
  components: {
    SlotModalForm
  },
  mixins: [
    DialogFormView,
    ShopUrlValidationHelper,
    InstallRequestRenewalCustomImageHelper
  ],
  props: {
    pageType: { type: Number, required: true },
    slotType: { type: Number, required: true }
  },
  computed: {
    ...mapState('installRequest', ['installDeviceTypes', 'currentSlotId']),
    ...mapGetters('installRequest', [
      'currentInstallationsBySlotId',
      'etcDirections',
      'hasInstallationsSelected'
    ]),
    WidgetPageType() {
      return WidgetPageType;
    },
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    InstallSlotType() {
      return InstallSlotType;
    },
    formSections() {
      return [
        {
          id: 'custom_url',
          label: null,
          groups: [
            {
              id: 'custom_url',
              label: this.$t('custom_url.label'),
              type: 'text',
              placeholder: this.$t('custom_url.placeholder'),
              validate: [
                'required',
                {
                  rule: value => !this.isUrlDuplicated(value),
                  errorMessage: this.$t('custom_url.duplicate_error')
                },
                ...this.shopUrlValidations
              ]
            }
          ]
        },
        ...(this.installDeviceTypes?.includes(WidgetDeviceType.PC)
          ? [
              {
                id: 'pc_direction',
                groups: [
                  {
                    id: 'tip',
                    label: this.$t('direction.pc'),
                    type: 'tip_box',
                    value: this.$t('direction.tip'),
                    validate: [{ rule: 'required', value: () => true }]
                  },
                  {
                    id: 'pc_custom_image_url',
                    label: null,
                    type: 'image',
                    notRemovable: true,
                    placeholder:
                      this.pcCustomImageFileName ||
                      this.$t('direction.placeholder.pc_image_url'),
                    validate: ['required']
                  },
                  {
                    id: 'pc_custom_comment',
                    label: null,
                    type: 'text',
                    placeholder: this.$t('direction.placeholder.comment'),
                    validate: ['required']
                  }
                ]
              }
            ]
          : []),
        ...(this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)
          ? [
              {
                id: 'mobile_direction',
                groups: [
                  {
                    id: 'tip',
                    label: this.$t('direction.mobile'),
                    type: 'tip_box',
                    value: this.$t('direction.tip'),
                    validate: [{ rule: 'required', value: () => true }]
                  },
                  {
                    id: 'mobile_custom_image_url',
                    label: null,
                    type: 'image',
                    notRemovable: true,
                    placeholder:
                      this.mobileCustomImageFileName ||
                      this.$t('direction.placeholder.mobile_image_url'),
                    validate: ['required']
                  },
                  {
                    id: 'mobile_custom_comment',
                    label: null,
                    type: 'text',
                    placeholder: this.$t('direction.placeholder.comment'),
                    validate: ['required']
                  }
                ]
              }
            ]
          : []),
        {
          id: 'etc_slot',
          label: this.$t('select_widget'),
          groups: [
            {
              id: 'tip',
              label: null,
              type: 'tip_box',
              value: this.$t('select_widget_tip')
            },
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.PC)
              ? [
                  {
                    id: 'pc_etc',
                    label: this.$t('app.pc'),
                    validate: [
                      { rule: 'required', value: () => true },
                      {
                        rule: () =>
                          this.hasInstallationsSelected(WidgetDeviceType.PC),
                        errorMessage: this.$t('validations.required')
                      }
                    ]
                  }
                ]
              : []),
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)
              ? [
                  {
                    id: 'mobile_etc',
                    label: this.$t('app.mobile'),
                    validate: [
                      { rule: 'required', value: () => true },
                      {
                        rule: () =>
                          this.hasInstallationsSelected(
                            WidgetDeviceType.MOBILE
                          ),
                        errorMessage: this.$t('validations.required')
                      }
                    ]
                  }
                ]
              : [])
          ]
        }
      ];
    },
    isDirectionChanged() {
      return this.installDeviceTypes.some(deviceType => {
        return !(
          _.isNil(this.formObject['custom_url']) &&
          _.isNil(this.formObject[this.customImageUrlKey(deviceType)]) &&
          _.isNil(this.formObject[this.customCommentKey(deviceType)])
        );
      });
    },
    isInstallationChanged() {
      return this.currentInstallationsBySlotId.length > 0;
    },
    isFormDataChanged() {
      return this.isInstallationChanged || this.isDirectionChanged;
    }
  },
  watch: {
    currentInstallationsBySlotId: {
      handler(installations) {
        if (!installations?.length) return;

        if (this.installDeviceTypes?.includes(WidgetDeviceType.PC)) {
          this.validateField('pc_etc');
        }
        if (this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)) {
          this.validateField('mobile_etc');
        }
      }
    }
  },
  created() {
    this.SET_CURRENT_PAGE_TYPE(this.pageType);
    this.createSlotId();
  },
  methods: {
    ...mapActions('dialog', ['alert']),
    ...mapActions('installRequest', ['createSlotId']),
    ...mapMutations('installRequest', [
      'SET_CURRENT_PAGE_TYPE',
      'ADD_WIDGETS_BY_SLOT_ID',
      'REMOVE_WIDGETS_BY_SLOT_ID',
      'APPEND_DIRECTION_WITH_SLOT_ID'
    ]),
    openAlertDialog() {
      this.alert({
        title: this.$t('attachment_guide'),
        message: this.$t('alert_message'),
        width: DialogSize.MIDDLE,
        closeButtonStyle: 'default',
        closeButtonLabel: this.$t('app.close')
      });
    },
    beforeCloseDialog() {
      this.REMOVE_WIDGETS_BY_SLOT_ID(this.currentSlotId);
    },
    appendDirections() {
      this.installDeviceTypes.forEach(deviceType => {
        this.APPEND_DIRECTION_WITH_SLOT_ID({
          device_type: deviceType,
          page_type: this.pageType,
          slot_type: this.slotType,
          custom_url: this.formObject['custom_url'],
          custom_image: this.formObject[this.customImageUrlKey(deviceType)],
          custom_comment: this.formObject[this.customCommentKey(deviceType)],
          custom_image_file_name: this.customImageFileName(deviceType),
          custom_image_file_type: this.customImageFileType(deviceType)
        });
      });
    },
    submit() {
      this.appendDirections();
      this.close(true);
    },
    isUrlDuplicated(url) {
      return this.etcDirections.some(direction => direction.custom_url === url);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppForm__section:first-child {
    padding: 0;
  }

  #etc_slot--mobile_etc {
    margin-top: 32px;
  }

  .AppForm__section + .AppForm__section {
    padding-bottom: 0;
  }
}
</style>

<i18n locale="ko">
{
  "title": "설치 위젯 선택",
  "select_widget": "위젯 선택",
  "select_widget_tip": "설치할 위젯을 선택해 주세요. 여러개 위젯을 선택한 경우 설치 순서도 확인해주세요.",
  "custom_url":{
    "label": "페이지 URL",
    "placeholder": "페이지 URL을 입력해주세요.",
    "duplicate_error": "이미 등록된 URL이 있습니다. 다른 URL을 입력해주세요."
  },
  "attachment_guide": "이미지 첨부 가이드",
  "alert_message": "아래 이미지와 같이 위젯을 설치하고 싶은 위치가 표시된 이미지를 첨부해주세요.<br><br>![](https://assets.cre.ma/m/admin/v2/install_request/attachment_guide_sample@3x.png)",
  "direction" : {
    "pc": "PC 설치 위치",
    "mobile": "모바일 설치 위치",
    "tip": "설치와 무관한 내용은 반영되지 않습니다. 설치가 어려운 위치는 설치가 진행되지 않을 수 있습니다.",
    "placeholder": {
      "pc_image_url": "PC 설치 위치 이미지 첨부",
      "mobile_image_url": "모바일 설치 위치 이미지 첨부",
      "comment": "크리마 팀이 참고할 코멘트가 있다면 남겨주세요."
    }
  }
}
</i18n>
