<template>
  <div class="InstallRequestRenewalDirectionDialogEtc">
    <AppDropdownMenuButton
      class="InstallRequestRenewalDirectionDialogEtc_add-page-button"
      button-style="blue-outline"
      :label="$t('install_request.renewal.etc.add_page')"
      :menu-items="etcDropdownMenuItems"
    />
    <AppTable
      :columns="etcColumns"
      :rows="etcRows"
      :no-data="$t('install_request.renewal.etc.no_data')"
    >
      <template #cell="{ column, row }">
        <template v-if="column.id === 'actions'">
          <AppButton
            :label="$t('app.edit')"
            :button-style="'grey-outline'"
            @click="
              openInstallRequestRenewalSlotEtcEditDialog(
                row.page_type,
                row.slot_type,
                row.slot_id
              )
            "
          />
          <AppButton
            :label="$t('app.delete')"
            :button-style="'red-outline'"
            @click="clickDeleteEtc(row)"
          />
        </template>
      </template>
    </AppTable>
  </div>
</template>

<script>
import InstallRequestRenewalEtcHelper from '@/mixins/InstallRequestRenewalEtcHelper';

export default {
  name: 'InstallRequestRenewalDirectionDialogEtc',
  mixins: [InstallRequestRenewalEtcHelper]
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

::v-deep {
  .AppTable {
    background-color: $color-grey-05;

    .AppTable__body-row:hover {
      background-color: $color-grey-15;
    }
  }
}

.InstallRequestRenewalDirectionDialogEtc_add-page-button {
  margin: 32px 0 24px;
}
</style>
