<template>
  <AppTabContent menu-item-id="review_data">
    <RouterView />
  </AppTabContent>
</template>

<script>
export default {
  name: 'ReviewData'
};
</script>
