import { mapState, mapGetters } from 'vuex';
import api from '@/lib/api';
import WidgetType from '@/enums/WidgetType';
import WidgetStyle from '@/enums/WidgetStyle';

export default {
  props: {
    deviceType: { type: Number, required: true }
  },
  data() {
    return {
      widgets: [],
      isLoading: false
    };
  },
  computed: {
    ...mapState('installRequest', ['currentPageType']),
    ...mapGetters('installRequest', ['currentWidgetsBySlotId'])
  },
  created() {
    this.isLoading = true;
    api
      .get('/install_request/renewal/widgets', {
        params: {
          page_type: this.currentPageType,
          slot_type: this.slotType,
          device_type: this.deviceType
        }
      })
      .then(({ data }) => {
        this.widgets = this.installableSlotWidgets(data);
        this.setDefaultWidget();
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    installableSlotWidgets(data) {
      const savedWidgets = this.savedInstallableSlotWidgets();
      const savedWidgetIds = savedWidgets.map(w => w.id);
      const newWidgets = data.widgets
        .filter(w => !savedWidgetIds.includes(this.widgetKey(w)))
        .map(w => ({
          ...w,
          id: this.widgetKey(w),
          widget_id: w.id,
          page_type: this.currentPageType,
          slot_type: this.slotType,
          widget_style_name: this.widgetStyleName(w),
          widget_info: w.id ? this.$t('widget_info', [w.id, w.name]) : '-'
        }));
      return [...savedWidgets, ...newWidgets];
    },
    savedInstallableSlotWidgets() {
      return this.currentWidgetsBySlotId.filter(
        i =>
          i.widget_device_type === this.deviceType &&
          i.slot_type === this.slotType
      );
    },
    widgetKey(widget) {
      return (
        widget.id ||
        `${WidgetType.key(widget.widget_type).toLowerCase()}-${WidgetStyle.key(
          widget.widget_sub_style || widget.widget_style
        ).toLowerCase()}`
      );
    },
    widgetStyleName(widget) {
      return widget.widget_type === WidgetType.PRODUCTS
        ? WidgetType.t(widget.widget_type)
        : `${WidgetStyle.t(
            widget.widget_sub_style || widget.widget_style
          )} ${this.$t('install_request.widget')}`;
    },
    setDefaultWidget() {}
  }
};
