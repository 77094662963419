<template>
  <div class="SmartstoreAgreement">
    <SmartstoreSummaryBox />
    <div
      id="smartstore-review-import-guide"
      class="SmartstoreAgreement__guide-wrapper"
    >
      <AppText class="SmartstoreAgreement__guide" :text="$t('guide')" />
    </div>
    <AppForm
      object-id="agreement"
      v-bind="formProps"
      :submit-button="{
        submitLabel: $t('submit'),
        submittingLabel: $t('submitting'),
        disabled: !formObject.accepted_terms
      }"
      class="SmartstoreAgreement__form"
      v-on="formEvents"
    >
      <template #group="{ id }">
        <div v-if="id === 'invite'" class="AppForm__group-field">
          <AppText :text="$t('invite.message')" />
          <AppImage
            class="SmartstoreAgreement__invite_image"
            src="https://assets.cre.ma/m/v1/smartstore_invite.png"
          />
        </div>
      </template>
    </AppForm>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapMutations, mapActions } from 'vuex';
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import SmartstoreStatus from '@/enums/SmartstoreStatus';
import SmartstoreSummaryBox from './SmartstoreSummaryBox';

export default {
  name: 'SmartstoreAgreement',
  components: { SmartstoreSummaryBox },
  mixins: [FormView],
  data() {
    return { value: false, detectFormDataChange: false };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'invite',
              label: this.$t('invite.title')
            },
            {
              id: 'notes',
              type: 'static',
              label: this.$t('notes.title'),
              value: this.$t('notes.message')
            },
            {
              id: 'smartstore_name',
              type: 'text',
              label: this.$t('smartstore_name'),
              placeholder: this.$t('smartstore_name_placeholder'),
              validate: ['required']
            },
            {
              id: 'smartstore_url',
              type: 'url',
              label: this.$t('smartstore_url'),
              hint: this.$t('smartstore_url_hint'),
              placeholder: this.$t('smartstore_url_placeholder'),
              validate: [
                'required',
                {
                  rule: v =>
                    /^https:\/\/(smartstore|brand)\.naver\.com\/[a-zA-Z0-9-_]+\/?$/.test(
                      v
                    ),
                  errorMessage: this.$t('validations.url_format')
                }
              ]
            },
            {
              id: 'accepted_terms',
              type: 'agreement_checkbox',
              label: this.$t('agree_terms'),
              termsLink: 'https://assets.cre.ma/m/v1/smartstore_sync_terms.pdf'
            }
          ]
        }
      ];
    }
  },
  created() {
    this.orgFormObject = {
      smartstore_url: '',
      accepted_terms: false
    };
    this.formObject = { ...this.orgFormObject };
  },
  methods: {
    ...mapActions('dialog', ['alert', 'confirm']),
    ...mapMutations('smartstore', ['SET_SMARTSTORE_STATUS']),
    submit(formData) {
      this.confirm({
        title: this.$t('confirm.title'),
        message: this.$t('confirm.message'),
        closeButtonLabel: this.$t('confirm.label')
      }).then(result => {
        if (!result) return;

        this.doSubmit(formData);
      });
    },
    doSubmit(formData) {
      this.isSubmitting = true;
      api
        .post('/smartstore/agreements', formData, { silent: true })
        .then(() => {
          this.SET_SMARTSTORE_STATUS(SmartstoreStatus.PRODUCT_MATCH_PENDING);
        })
        .catch(({ response, errorHandler }) => {
          if (_.inRange(response.status, 400, 500)) {
            this.alert({
              title: this.$t('error_title'),
              message: this.$t('error_unknown')
            });
          } else errorHandler();
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.SmartstoreAgreement {
  margin-top: 32px;
}

.SmartstoreAgreement__guide-wrapper {
  position: relative;
  margin-top: 32px;
}

.SmartstoreAgreement__form {
  margin-top: 32px;
}

.SmartstoreAgreement__invite_image {
  width: 1024px;
}

.SmartstoreAgreement__submit {
  margin-top: 36px;
}

.SmartstoreAgreement__agree_own_smartstore_usage {
  color: $color-red;
}

.SmartstoreAgreement__agree_terms {
  margin-left: 8px;
}
</style>

<i18n locale="ko">
{
  "guide": "**스마트스토어 관리자 페이지에서 크리마를 대행사로 초대해주세요.**\n초대한 대행사 계정은 스마트스토어를 사용하는 동안에는 유지돼야 합니다.",
  "invite": {
    "title": "스마트스토어 대행사 초대 방법",
    "message": "1. 스마트스토어센터 관리자 페이지 : 판매자 정보 > 매니저 관리\n2. 매니저 관리 화면 오른쪽 상단의 매니저 초대 클릭\n3. 크리마(010-3437-6008)를 [대행사] 권한으로 초대하기"
  },
  "notes": {
    "title": "신청 전 참고",
    "message": "1. 크리마의 스마트스토어 리뷰 연동 기능을 사용하는 경우에는 호스팅사 내 스마트스토어 연동 기능 사용을 중단해주세요.<br />2. 네이버에서 제공하는 리뷰 csv파일에 포함된 정보만 연동가능하기 때문에 리뷰의 첫 번째 첨부이미지만 연동됩니다. (동영상 리뷰 연동 불가)<br />3. 리뷰 서비스 종료 시, 스마트스토어 리뷰는 호스팅사에 연동 되지 않습니다."
  },
  "confirm": {
    "title": "대행사 권한 초대를 진행하셨나요?",
    "message": "리뷰 연동을 진행하려면 대행사 권한을 필수로 초대해야 합니다.\n초대를 완료한 후, 신청을 완료해주세요!",
    "label": "신청하기"
  },
  "smartstore_url": "스마트스토어 메인 페이지 주소(URL)",
  "smartstore_url_placeholder": "리뷰를 가져올 네이버 스마트스토어의 주소를 입력해주세요.",
  "smartstore_url_hint": "예) `https://smartstore.naver.com/crema`, `https://brand.naver.com/crema`",
  "smartstore_name": "스마트스토어 이름",
  "smartstore_name_placeholder": "리뷰를 가져올 네이버 스마트스토어의 이름(띄워쓰기 포함)을 입력해주세요.",
  "accepted_terms": "스마트스토어 리뷰 연동 서비스 동의",
  "agree_terms": "[필수] 스마트스토어 리뷰 연동 서비스에 동의합니다.",
  "agree_own_smartstore_usage": "자사 쇼핑몰이 소유한 네이버 스마트스토어의 리뷰만 연동이 가능합니다. 이를 지키지 않아 발생하는 문제의 책임은 신청인에게 있음에 동의합니다.",
  "submit": "스마트스토어 리뷰 연동 신청",
  "submitting": "스마트스토어 리뷰 연동 신청 중..",
  "error_title": "스마트스토어 리뷰 연동 신청 실패",
  "error_unknown": "입력하신 스마트스토어의 소유권을 확인하던 중 오류가 발생했습니다. URL을 다시 한번 확인 부탁드립니다.\n크리마와 계약한 사업자 외의 스마트스토어에 대해 연동을 원하신다면, 크리마 고객센터로 문의해주시기 바랍니다.\n고객센터: 관리자 페이지 하단의 물음표 버튼 > 서비스 문의"
}
</i18n>
