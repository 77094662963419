<template>
  <AppDropdownMenuButton v-bind="buttonProps" />
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import HttpStatus from '@/enums/HttpStatus';

export default {
  name: 'ReviewNewReviewsManageButton',
  props: {
    review: { type: Object, required: true },
    resourceParams: { type: Object, default: () => ({}) }
  },
  computed: {
    buttonProps() {
      const menuItems = [[this.menuItemNote]];

      if (this.review.nthReview || this.review.nthPhotoReview) {
        menuItems.push([this.menuItemNth]);
      }

      menuItems.push([this.menuItemMileageLog]);
      menuItems.push([this.menuItemDisplay, this.menuItemDelete]);

      return {
        label: this.$t('app.manage'),
        tooltip: this.$t('tooltip'),
        menuItems
      };
    },
    menuItemNote() {
      return {
        label: this.$t('manage.note'),
        selected: this.review.note,
        clickHandler: this.editNote
      };
    },
    menuItemNth() {
      let label;

      if (this.review.nthReview && this.review.nthPhotoReview) {
        label = this.$t('manage.disqualify_nth_review_and_photo_review');
      } else if (this.review.nthReview) {
        label = this.$t('manage.disqualify_first_review');
      } else {
        label = this.$t('manage.disqualify_nth_photo_review');
      }

      return { label, clickHandler: this.disqualify };
    },
    menuItemMileageLog() {
      return {
        label: this.$t('manage.mileage_logs'),
        clickHandler: this.openTheLogsDialog,
        superAdmin: true
      };
    },
    menuItemDisplay() {
      const isReviewDisplaying =
        this.review.force_display ||
        (this.review.display && this.review.product_visible);

      return {
        label: this.$t(
          isReviewDisplaying ? 'manage.hide_review' : 'manage.show_review'
        ),
        selected: !isReviewDisplaying,
        clickHandler: args =>
          this.updateDisplay({ ...args, display: !isReviewDisplaying })
      };
    },
    menuItemDelete() {
      return {
        label: this.$t('manage.delete_review'),
        style: 'danger',
        clickHandler: this.delete
      };
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('review', [
      'fetchCurrentPageReviews',
      'updateDisplayReview',
      'deleteReview'
    ]),
    editNote({ close }) {
      this.openDialog([
        'ReviewReviewsNoteEditDialog',
        { review: this.review }
      ]).then(eventBus => {
        eventBus.$on('change', review => (this.review = review));
      });
      close();
    },
    disqualify({ close }) {
      const { review_index, photo_review_index } = this.review;
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          width: '440px',
          title: this.$t('manage.disqualify_review_confirm.title'),
          markdownText: this.$t('manage.disqualify_review_confirm.content'),
          closeButtonLabel: this.$t(
            'manage.disqualify_review_confirm.disqualify'
          ),
          cancelButtonLabel: this.$t('manage.disqualify_review_confirm.cancel')
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => {
          api
            .patch(`/review/reviews/${this.review.id}/disqualify`, {
              review_index,
              photo_review_index
            })
            .then(response => {
              if (response.status === HttpStatus.OK)
                this.fetchCurrentPageReviews();
            });
        });
      });
      close();
    },
    openTheLogsDialog({ close }) {
      this.openDialog(['TheLogsDialog', { reviewId: this.review.id }]);
      close();
    },
    updateDisplay({ close, display }) {
      this.updateDisplayReview({
        reviewId: this.review.id,
        display,
        successMessage: this.$t(
          display ? 'manage.show_review_success' : 'manage.hide_review_success'
        )
      }).then(response => {
        if (response.status === HttpStatus.OK) this.fetchCurrentPageReviews();
      });
      close();
    },
    delete({ close }) {
      if (!confirm(this.$t('manage.delete_review_confirmation'))) return;

      this.deleteReview({
        reviewId: this.review.id,
        resourceParams: this.resourceParams,
        successMessage: this.$t('manage.delete_review_success')
      }).then(() => this.$emit('delete'));
      close();
    }
  }
};
</script>

<i18n locale="ko">
{
  "tooltip": "버튼을 클릭해 숨김, 삭제, 메모 등\n리뷰 관리를 할 수 있습니다.",
  "manage": {
    "note": "메모 (리뷰 관리를 위한 메모 작성)",
    "disqualify_nth_review_and_photo_review": "선착순 리뷰에서 제외",
    "disqualify_first_review": "최초 작성 리뷰에서 제외",
    "disqualify_nth_photo_review": "선착순 리뷰에서 제외",
    "disqualify_review_confirm": {
      "title": "선착순 리뷰 적립금 대상에서 제외하시겠습니까?",
      "content": "선택된 리뷰에 선착순 리뷰 적립금을 지급하지 않습니다. <br>(이미 지급된 적립금은 회수되지 않으니 직접 차감해주세요)",
      "disqualify": "제외",
      "cancel": "취소"
    },
    "mileage_logs": "적립금 관련 이력 보기",
    "hide_review": "리뷰 숨김",
    "hide_review_success": "리뷰를 숨김 처리했습니다.",
    "show_review": "리뷰 숨김 해제",
    "show_review_success": "리뷰 숨김을 해제했습니다.",
    "delete_review": "리뷰 삭제",
    "delete_review_confirmation": "삭제하면 복구가 불가능합니다. 정말로 삭제하시겠습니까?",
    "delete_review_success": "리뷰를 삭제했습니다."
  }
}
</i18n>
