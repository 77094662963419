var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":!_vm.data}},[(_vm.data)?_c('AppForm',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
var inputId = ref.inputId;
var errors = ref.errors;
return [(id === 'allowed_author_type')?[_c('input',{attrs:{"type":"hidden","name":"management_settings[allowed_author_type]"},domProps:{"value":_vm.formObject.allowed_author_type}}),_c('AppSelectRadio',{attrs:{"id":inputId,"options":_vm.ALLOWED_AUTHOR_TYPE_OPTIONS},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var value = ref.value;
var checked = ref.checked;
return [(
                _vm.formObject.enable_review_allowed_author_type_delivery_finished &&
                  value === _vm.ReviewAllowedAuthorType.BUYER_ONLY
              )?[_c('i18n',{attrs:{"path":"review_allowed_author_type_dropdown"}},[_c('AppSelect',{attrs:{"disabled":!checked,"options":_vm.ALLOWED_SINCE_OPTIONS},model:{value:(_vm.formObject.allowedSince),callback:function ($$v) {_vm.$set(_vm.formObject, "allowedSince", $$v)},expression:"formObject.allowedSince"}})],1)]:_vm._e()]}},{key:"sub-item",fn:function(ref){
              var value = ref.value;
              var subOptionChecked = ref.checked;
return [(value === _vm.ReviewAllowedAuthorType.BUYER_ONLY)?_c('div',{staticClass:"AppForm__sub-group"},[_c('div',{staticClass:"AppForm__sub-group-field"},[_c('input',{attrs:{"type":"hidden","name":"management_settings[allowed_days_from_delivery]","disabled":!subOptionChecked},domProps:{"value":_vm.formObject.allowed_days_from_delivery}}),_c('AppSelectRadio',{attrs:{"disabled":!subOptionChecked,"options":_vm.ALLOWED_UNTIL_OPTIONS},scopedSlots:_vm._u([{key:"label",fn:function(ref){
              var value = ref.value;
              var checked = ref.checked;
return [(value === 'limited')?[_c('i18n',{attrs:{"path":"allowed_days_type.limited"}},[_c('AppNumberInput',{attrs:{"disabled":!checked || !subOptionChecked,"digits":2,"invalid":!!errors.allowedDays},on:{"blur":function($event){return _vm.validateField('allowedDays')},"change":function($event){return _vm.validateField('allowedDays')}},model:{value:(_vm.formObject.allowedDays),callback:function ($$v) {_vm.$set(_vm.formObject, "allowedDays", $$v)},expression:"formObject.allowedDays"}})],1),_c('AppFormError',{attrs:{"error":errors.allowedDays}})]:_vm._e()]}}],null,true),model:{value:(_vm.formObject.allowedUntil),callback:function ($$v) {_vm.$set(_vm.formObject, "allowedUntil", $$v)},expression:"formObject.allowedUntil"}}),_c('div',{staticClass:"AppForm__sub-group-hint"},[_vm._v(" "+_vm._s(_vm.$t('allowed_days_hint'))+" ")])],1)]):_vm._e()]}}],null,true),model:{value:(_vm.formObject.allowedAuthorType),callback:function ($$v) {_vm.$set(_vm.formObject, "allowedAuthorType", $$v)},expression:"formObject.allowedAuthorType"}})]:(id === 'duplicate_review_type')?[_c('AppSelectRadio',{attrs:{"name":"management_settings[duplicate_review_type]","options":_vm.DuplicateReviewType.options()},scopedSlots:_vm._u([{key:"sub-item",fn:function(ref){
              var value = ref.value;
              var subOptionChecked = ref.checked;
return [(
                value === _vm.DuplicateReviewType.ALLOW_REVIEW_BY_PRODUCT_COUNT
              )?_c('div',{staticClass:"AppForm__sub-group"},[_c('div',{staticClass:"AppForm__sub-group-field"},[_c('AppCheckbox',{attrs:{"disabled":!subOptionChecked,"name":"management_settings[show_review_popup_by_products_count]","label":_vm.$t(
                      'duplicate_review_type.show_review_popup_by_products_count'
                    )},model:{value:(_vm.formObject.show_review_popup_by_products_count),callback:function ($$v) {_vm.$set(_vm.formObject, "show_review_popup_by_products_count", $$v)},expression:"formObject.show_review_popup_by_products_count"}})],1)]):_vm._e()]}}],null,true),model:{value:(_vm.formObject.duplicate_review_type),callback:function ($$v) {_vm.$set(_vm.formObject, "duplicate_review_type", $$v)},expression:"formObject.duplicate_review_type"}})]:(id === 'review_option_types')?[_c('div',{staticClass:"AppForm__group-description"},[_vm._v(" "+_vm._s(_vm.$t('review_option_types.group_description'))+" ")]),_c('div',{staticClass:"AppForm__group-field"},[(_vm.isReviewOptionTypesButtonAccessible)?_c('div',{class:[
              'table-head-control',
              _vm.isShopifyBrand ? '' : 'super-admin__item'
            ]},[_c('AppButton',{attrs:{"label":_vm.$t('review_option_types.settings_button')},on:{"click":_vm.openReviewOptionTypeSettingsDialog}})],1):_vm._e(),_c('AppSmallDeviceScrollable',[_c('AppTable',{attrs:{"table-id":"review-option-types-table","columns":[
                {
                  id: 'id',
                  label: _vm.$t('review_option_types.id'),
                  align: 'left'
                },
                {
                  id: 'name',
                  label: _vm.$t('review_option_types.name'),
                  align: 'left'
                },
                {
                  id: 'field_type',
                  label: _vm.$t('review_option_types.field_type'),
                  align: 'left'
                },
                {
                  id: 'search_options_with_categories',
                  label: _vm.$t(
                    'review_option_types.search_options_with_categories'
                  ),
                  align: 'left'
                },
                {
                  id: 'storable',
                  label: _vm.$t('review_option_types.storable'),
                  type: 'check'
                },
                {
                  id: 'searchable',
                  label: _vm.$t('review_option_types.searchable'),
                  type: 'check'
                },
                {
                  id: 'displayable',
                  label: _vm.$t('review_option_types.displayable'),
                  type: 'check'
                },
                {
                  id: 'required',
                  label: _vm.$t('review_option_types.required'),
                  type: 'check'
                }
              ],"rows":_vm.data.review_option_types.map(function (optionType) { return (Object.assign({}, optionType,
                  {search_options_with_categories: optionType.search_options
                    .map(function (o) { return o[0]; })
                    .join(', ')})); }),"no-data":_vm.$t('review_option_types.no_data')},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
                var row = ref.row;
                var column = ref.column;
                var value = ref.value;
return [(column.id === 'name')?[_c('span',[_c('ul',[_c('li',{staticClass:"table-line"},[(row.global_review_option_type_name)?_c('AppBadge',{attrs:{"badge-style":"grey-outline","label":row.global_review_option_type_name}}):_c('AppBadge',{attrs:{"label":_vm.$t('review_option_types.no_user_property_type')}})],1),_c('li',{staticClass:"table-line--mt4"},[_vm._v(" "+_vm._s(value)+" ")])])])]:(column.id === 'field_type')?[_c('span',[_vm._v(" "+_vm._s(_vm.$t(("review_option_types.field_types." + value)))+" ")])]:(column.id === 'search_options_with_categories')?[_c('ul',[_c('li',{staticClass:"table-line"},[_vm._v(" "+_vm._s(row.search_options_with_categories)+" ")]),_c('li',{staticClass:"table-line table-line--mt8"},[_c('span',[_vm._v(" "+_vm._s(_vm.optionTypeCategoriesDescription(row))+" ")])])])]:_vm._e()]}}],null,true)})],1)],1)]:(id === 'category_ids_without_review_contents')?[_c('div',{staticClass:"AppForm__group-description"},[_vm._v(" "+_vm._s(_vm.$t('category_ids_without_review_contents.group_description'))+" "),_c('AppInlineButton',{attrs:{"label":_vm.$t('app.detail'),"button-style":"caption"},on:{"click":_vm.openCategoryIdsWithoutReviewContentsMessageDialog}})],1),_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelectProductCategory',{attrs:{"name":"management_settings[category_ids_without_review_contents][]","multiple":""},model:{value:(_vm.formObject.category_ids_without_review_contents),callback:function ($$v) {_vm.$set(_vm.formObject, "category_ids_without_review_contents", $$v)},expression:"formObject.category_ids_without_review_contents"}})],1)]:(id === 'message_dictionary')?[_c('div',{staticClass:"AppForm__group-field"},[_c('div',{staticClass:"table-head-control"},[_c('AppButton',{attrs:{"label":_vm.$t('message_dictionary.add_button')},on:{"click":_vm.addMessageDictionaryRule}})],1),_c('AppSmallDeviceScrollable',[_c('AppTable',{attrs:{"valign-baseline":"","columns":_vm.isShopifyBrand
                  ? [
                      {
                        id: 'old_word',
                        label: _vm.$t('message_dictionary.old_word')
                      },
                      {
                        id: 'new_word',
                        label: _vm.$t('message_dictionary.new_word')
                      },
                      {
                        id: 'actions',
                        label: _vm.$t('app.actions')
                      }
                    ]
                  : [
                      {
                        id: 'category',
                        label: _vm.$t('message_dictionary.category')
                      },
                      {
                        id: 'old_word',
                        label: _vm.$t('message_dictionary.old_word')
                      },
                      {
                        id: 'new_word',
                        label: _vm.$t('message_dictionary.new_word')
                      },
                      {
                        id: 'actions',
                        label: _vm.$t('app.actions')
                      }
                    ],"rows":_vm.formObject.message_dictionary,"no-data":_vm.$t('message_dictionary.no_data')},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
                    var row = ref.row;
                    var column = ref.column;
                    var rowIndex = ref.rowIndex;
return [(column.id === 'category')?[_c('AppSelectProductCategory',{attrs:{"placeholder":_vm.$t('app.all_categories'),"name":"management_settings[message_dictionary][][product_category_id]"},model:{value:(
                      _vm.formObject.message_dictionary[rowIndex]
                        .product_category_id
                    ),callback:function ($$v) {_vm.$set(_vm.formObject.message_dictionary[rowIndex]
                        , "product_category_id", $$v)},expression:"\n                      formObject.message_dictionary[rowIndex]\n                        .product_category_id\n                    "}})]:(column.id === 'old_word')?[_c('AppTextInput',{attrs:{"invalid":!!errors[("message_dictionary[" + (row.id) + "][old_word]")],"name":"management_settings[message_dictionary][][old_word]"},on:{"blur":function($event){return _vm.validateField(("message_dictionary[" + (row.id) + "][old_word]"))},"change":function($event){return _vm.validateField(("message_dictionary[" + (row.id) + "][old_word]"))}},model:{value:(row.old_word),callback:function ($$v) {_vm.$set(row, "old_word", $$v)},expression:"row.old_word"}}),_c('AppFormError',{attrs:{"error":errors[("message_dictionary[" + (row.id) + "][old_word]")]}})]:(column.id === 'new_word')?[_c('AppTextInput',{attrs:{"invalid":!!errors[("message_dictionary[" + (row.id) + "][new_word]")],"name":"management_settings[message_dictionary][][new_word]"},on:{"blur":function($event){return _vm.validateField(("message_dictionary[" + (row.id) + "][new_word]"))},"change":function($event){return _vm.validateField(("message_dictionary[" + (row.id) + "][new_word]"))}},model:{value:(row.new_word),callback:function ($$v) {_vm.$set(row, "new_word", $$v)},expression:"row.new_word"}}),_c('AppFormError',{attrs:{"error":errors[("message_dictionary[" + (row.id) + "][new_word]")]}})]:(column.id === 'actions')?[_c('AppButton',{attrs:{"button-style":"red-outline","label":_vm.$t('app.delete')},on:{"click":function($event){return _vm.formObject.message_dictionary.splice(rowIndex, 1)}}})]:_vm._e()]}}],null,true)})],1),(!_vm.formObject.message_dictionary.length)?_c('input',{attrs:{"type":"hidden","name":"management_settings[message_dictionary][]"}}):_vm._e()],1)]:_vm._e()]}}],null,false,3262591203)},'AppForm',_vm.managementFormProps,false),_vm.formEvents)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }