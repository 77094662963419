<template>
  <div class="ReviewDataRequestHistory">
    <AppInfoBox>
      {{ this.$t('info_msg') }}
    </AppInfoBox>
    <AppResourceTable
      class="TheBizMessageTemplateInspectedDialog"
      :columns="COLUMNS"
      :rows="rows"
    />
  </div>
</template>

<script>
import api from '@/lib/api';
import DataRequestType from '@/enums/DataRequestType';

export default {
  name: 'ReviewDataRequestHistory',
  data() {
    return {
      dataRequests: [],
      COLUMNS: [
        {
          id: 'created_at',
          label: this.$t('columns.created_at'),
          type: 'datetime',
          align: 'left'
        },
        {
          id: 'requestor',
          label: this.$t('columns.requestor')
        },
        {
          id: 'data_type',
          label: this.$t('columns.data_type')
        },
        {
          id: 'date_range',
          label: this.$t('columns.date_range')
        },
        {
          id: 'sent_email',
          label: this.$t('columns.sent_email')
        }
      ]
    };
  },
  computed: {
    rows() {
      return this.dataRequests.map(dataRequest => ({
        ...dataRequest,
        data_type: DataRequestType.t(dataRequest.data_type),
        date_range: `${dataRequest.start_date} ~ ${dataRequest.end_date}`
      }));
    }
  },
  created() {
    api.get('/review/data_requests').then(({ data }) => {
      this.dataRequests = data.data_requests;
    });
  }
};
</script>

<i18n locale="ko">
{
  "columns": {
    "created_at": "요청일자",
    "requestor": "요청한 계정",
    "data_type": "요청 데이터 종류",
    "date_range": "요청한 데이터 기간",
    "sent_email": "발송 이메일 주소"
  },
  "info_msg": "오늘을 포함한 365일간의 발송 이력을 조회할 수 있습니다."
}
</i18n>
