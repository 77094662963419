var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"InstallRequestRenewalDirectionDialogEtc"},[_c('AppDropdownMenuButton',{staticClass:"InstallRequestRenewalDirectionDialogEtc_add-page-button",attrs:{"button-style":"blue-outline","label":_vm.$t('install_request.renewal.etc.add_page'),"menu-items":_vm.etcDropdownMenuItems}}),_c('AppTable',{attrs:{"columns":_vm.etcColumns,"rows":_vm.etcRows,"no-data":_vm.$t('install_request.renewal.etc.no_data')},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [(column.id === 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('app.edit'),"button-style":'grey-outline'},on:{"click":function($event){return _vm.openInstallRequestRenewalSlotEtcEditDialog(
              row.page_type,
              row.slot_type,
              row.slot_id
            )}}}),_c('AppButton',{attrs:{"label":_vm.$t('app.delete'),"button-style":'red-outline'},on:{"click":function($event){return _vm.clickDeleteEtc(row)}}})]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }