<template>
  <AppDataSection :title="$t('title')">
    <div
      class="ReviewMessagesNthStepReviewCampaignMileageSettings__button-wrapper"
    >
      <AppButton
        :label="$t('app.settings')"
        @click="openMileageSettingsDialog"
      />
    </div>
    <AppMarked
      :text="
        $t('content', [
          campaignSettings.nth_step_review_allowed_days_after_send,
          campaignSettings.nth_step_review_mileage_expiry_days,
          campaignSettings.nth_step_review_additional_mileage_cents
        ])
      "
    />
  </AppDataSection>
</template>

<script>
import api from '@/lib/api';
import { mapActions } from 'vuex';

export default {
  name: 'ReviewMessagesNthStepReviewCampaignMileageSettings',
  props: {
    campaignId: { type: Number, required: true }
  },
  data() {
    return {
      campaignSettings: {}
    };
  },
  mounted() {
    api
      .get(`/review/campaigns/${this.campaignId}/settings`)
      .then(({ data }) => (this.campaignSettings = data.settings));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openMileageSettingsDialog() {
      this.openDialog([
        'ReviewMessagesNthStepReviewCampaignMileageDialog',
        {
          campaignId: this.campaignId,
          campaignSettings: this.campaignSettings
        }
      ]).then(eventBus =>
        eventBus.$on('submit', newSettings => {
          this.campaignSettings = {
            ...this.campaignSettings,
            ...newSettings
          };
        })
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewMessagesNthStepReviewCampaignMileageSettings__button-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
}

.ReviewMessagesNthStepReviewCampaignCategorySettings__categories {
  @include text-label;
}
</style>

<i18n locale="ko">
{
  "title": "리뷰 작성 및 적립금 지급",
  "content": "쇼핑몰 회원만 메시지 발송 완료 후, <strong>{0}</strong>일 까지 리뷰 작성 1회 허용<br />메시지 발송 완료 후, <strong>{1}</strong>일 이내 작성한 리뷰만 적립금 <strong>{2}원</strong> 지급"
}
</i18n>
