<template>
  <AppModalDefault
    :title="$t('title')"
    class="ReviewDashboardGodoReviewsCountUpdateDialog"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <AppInfoBox class="ReviewDashboardGodoReviewsCountUpdateDialog__info">
          <i18n path="info">
            <span
              class="ReviewDashboardGodoReviewsCountUpdateDialog__info-highlight"
            >
              {{ $t('info_highlight') }}
            </span>
          </i18n>
        </AppInfoBox>
        <AppImage
          src="//assets.cre.ma/m/admin/v2/godo_reviews_score_update_guidance.png"
          :resolutions="[2]"
        />
      </AppAjaxContent>
    </template>
    <template #foot>
      <AppButtonSubmit :submit-label="$t('submit_label')" @click="submit" />
      <AppButton :label="$t('app.close')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';
import { mapActions } from 'vuex';

export default {
  name: 'ReviewDashboardGodoReviewsCountUpdateDialog',
  mixins: [DialogView],
  data: () => ({ isLoading: false }),
  methods: {
    ...mapActions('easyJoin', ['updateOnboardingProgress']),
    submit() {
      this.isLoading = true;

      this.updateOnboardingProgress({
        godo_reviews_count_updated: true
      })
        .then(() => {
          this.close();
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.ReviewDashboardGodoReviewsCountUpdateDialog__info {
  margin-bottom: 20px;
}

.ReviewDashboardGodoReviewsCountUpdateDialog__info-highlight {
  font-weight: bold;
  color: $color-blue;
}
</style>

<i18n locale="ko">
  {
    "title": "크리마리뷰 상품평 개수 업데이트 방법",
    "info": "네이버 쇼핑 리뷰 수 연동시 크리마 리뷰 수 정보를 반영하기 위해 {0} 해주세요.",
    "info_highlight": "반드시 업데이트",
    "submit_label": "업데이트 완료"
  }
</i18n>
