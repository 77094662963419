<template>
  <div class="InstallRequestOnboardingDirectionDialogCountAndScore">
    InstallRequestOnboardingDirectionDialogCountAndScore
  </div>
</template>

<script>
export default {
  name: 'InstallRequestOnboardingDirectionDialogCountAndScore'
};
</script>
