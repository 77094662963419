<template>
  <span :class="[`ReviewRateChange`, `ReviewRateChange--${status}`]">
    <AppSvgIcon
      class="ReviewRateChange__icon"
      :name="iconName"
      :width="9"
      :height="9"
    />
    <span class="ReviewRateChange__value">{{ value }}%</span>
  </span>
</template>

<script>
export default {
  name: 'ReviewRateChange',
  props: {
    value: {
      type: Number,
      required: true
    },
    status: {
      type: String,
      default: 'neutral',
      validator: v => ['positive', 'negative', 'neutral'].includes(v)
    }
  },
  computed: {
    iconName() {
      switch (this.status) {
        case 'positive':
          return 'icon-positive-arrow';
        case 'negative':
          return 'icon-negative-arrow';
        case 'neutral':
          return 'icon-neutral';
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewRateChange {
  @include text-content-tag;

  &--negative {
    color: $color-red;
  }

  &--positive {
    color: $color-mint-green-dark;
  }

  &--neutral {
    color: $color-grey-50;
  }
}

.ReviewRateChange__icon {
  vertical-align: middle;
}

.ReviewRateChange__value {
  padding-left: 4px;
}
</style>
