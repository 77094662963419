<template>
  <AppAjaxContent
    :is-loading="isLoading"
    class="ReviewMessagesNthStepReviewCampaignStatCards"
  >
    <AppStatCard
      class="ReviewMessagesNthStepReviewCampaignStatCards__card"
      :title="$t('stats.review_message_rate', [1])"
      v-bind="
        nthStepReviewRate(
          stats.first_step_reviews_count,
          stats.sub_orders_count
        )
      "
    />
    <AppStatCard
      class="ReviewMessagesNthStepReviewCampaignStatCards__card"
      :title="$t('stats.review_message_rate', [2])"
      v-bind="
        nthStepReviewRate(
          stats.second_step_reviews_count,
          stats.second_step_messages_sent_count
        )
      "
    />
    <AppStatCard
      class="ReviewMessagesNthStepReviewCampaignStatCards__card"
      :title="$t('stats.review_message_rate_over_third_step')"
      v-bind="
        nthStepReviewRate(
          stats.over_third_step_reviews_count,
          stats.over_third_step_messages_sent_count
        )
      "
    />
    <AppStatCard
      v-if="isSuperAdminAccessible"
      :class="[
        'ReviewMessagesNthStepReviewCampaignStatCards__card',
        'super-admin__item'
      ]"
      :title="$t('stats.more_reviews_click')"
      :stat="stats.nth_step_click_more_reviews_count"
      :unit="StatUnit.COUNT"
      :tooltip="$t('stats.tooltip')"
    />
    <div class="ReviewMessagesNthStepReviewCampaignStatCards__hint">
      {{
        $t('date_range', [
          formatDate(stats.start_date),
          formatDate(stats.end_date)
        ])
      }}
    </div>
  </AppAjaxContent>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/lib/api';
import StatUnit from '@/enums/StatUnit';
import ReviewCampaignType from '@/enums/ReviewCampaignType';

export default {
  name: 'ReviewMessagesNthStepReviewCampaignStatCards',
  data: () => ({ stats: {}, isLoading: true }),
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    StatUnit() {
      return StatUnit;
    }
  },
  mounted() {
    api
      .get('review/campaigns/report', {
        params: {
          campaign_type: ReviewCampaignType.NTH_STEP_REVIEW
        }
      })
      .then(({ data }) => (this.stats = data.report))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    nthStepReviewRate(numerator, denominator) {
      if (!denominator) return { detail: this.$t('stats.empty') };

      return {
        stat: numerator / denominator,
        detail: `${numerator.toLocaleString()} / ${denominator.toLocaleString()}`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewMessagesNthStepReviewCampaignStatCards__card {
  width: 260px;
  display: inline-block;

  & + & {
    margin-left: 8px;
    margin-top: 0;
  }
}

.ReviewMessagesNthStepReviewCampaignStatCards__hint {
  @include text-caption;
  margin-top: 4px !important;
}
</style>

<i18n locale="ko">
{
  "stats": {
    "review_message_rate": "{0}차 리뷰 작성률",
    "review_message_rate_over_third_step": "3차 이상 리뷰 작성률",
    "empty": "효과 수집 중입니다.",
    "more_reviews_click": "중간리뷰 더보기 클릭 수",
    "tooltip": "3차 리뷰 이상 작성 시 리뷰 위젯에 노출되는 '중간 리뷰 더보기' 클릭 수를 통해 잠재 고객이 n차 리뷰 내용에 얼마나 관심있는지 알 수 있습니다."
  },
  "date_range": "*. 효과 수집을 완료한 {0} ~ {1} 사이의 값입니다."
}
</i18n>
