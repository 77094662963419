<template>
  <AppRadioList :cols="cols" :items="currentItems">
    <template #item="{ item: option }">
      <AppRadio
        v-model="currentValue"
        :class="{ 'super-admin__item': option.superAdmin }"
        :name="name"
        :value="option.value"
        :invalid="invalid"
        :disabled="disabled || option.disabled"
        :label="option.label"
        :tooltip="option.tooltip"
        :detail-button-click-handler="option.detailButtonClickHandler"
        :input-style="$scopedSlots['sub-item'] ? 'block' : null"
        :input-position="inputPosition"
      >
        <template #label="labelProps">
          <slot name="label" v-bind="labelProps" />
        </template>
        <template v-if="$scopedSlots['sub-item']" #sub-item="subItemProps">
          <slot name="sub-item" v-bind="subItemProps" />
        </template>
      </AppRadio>
    </template>
  </AppRadioList>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppSelectRadio',
  model: {
    event: 'change'
  },
  props: {
    name: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    cols: {
      type: Number,
      default: 1
    },
    invalid: {
      type: Boolean,
      default: false
    },
    inputPosition: { type: String, default: null }
  },
  data() {
    return {
      currentValue: this.value
    };
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    currentItems() {
      return this.options
        .filter(o => this.isSuperAdminAccessible || o.superAdmin !== true)
        .map(o => ({ ...o, id: o.value }));
    }
  },
  watch: {
    value() {
      this.currentValue = this.value;
    },
    currentValue() {
      this.$emit('change', this.currentValue);
    }
  }
};
</script>
