<template>
  <div class="InstallRequestOnboardingDirectionDialogConfirm">
    InstallRequestOnboardingDirectionDialogConfirm
  </div>
</template>

<script>
export default {
  name: 'InstallRequestOnboardingDirectionDialogConfirm'
};
</script>
