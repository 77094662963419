<template>
  <SlotModalForm
    class="InstallRequestOnboardingSlotProductDetailProductThumbnailAfterDialog"
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    is-maximized-by-default
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #head>
      <SlotInfoCard :image-url="cardImageUrl" :name="cardName" />
    </template>
  </SlotModalForm>
</template>

<script>
import InstallSlotType from '@/enums/InstallSlotType';
import DialogFormView from '@/mixins/DialogFormView';
import SlotModalForm from './components/SlotModalForm';
import SlotInfoCard from '../components/SlotInfoCard';

export default {
  name: 'InstallRequestOnboardingSlotProductDetailProductThumbnailAfterDialog',
  components: {
    SlotModalForm,
    SlotInfoCard
  },
  mixins: [DialogFormView],
  data() {
    return {
      cardImageUrl:
        'https://assets.cre.ma/m/admin/v2/install_request/product_detail_product_thumbnail_after_slot.png',
      cardName: InstallSlotType.t(
        InstallSlotType.PRODUCT_DETAIL_PRODUCT_THUMBNAIL_AFTER
      )
    };
  }
};
</script>

<i18n locale="ko">
{
  "title": "설치 위젯 선택",
  "sub_title": "상품 상세 페이지"
}
</i18n>
