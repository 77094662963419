var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppSearchbar',{on:{"submit":_vm.searchResource},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('li',[(!_vm.reviewSettings.hide_review_csv_button)?_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('download_csv'),"button-style":"grey","tooltip":_vm.isCsvDownloadable ? null : _vm.$t('download_csv_tooltip'),"menu-items":_vm.downloadCsvMenuItems,"disabled":!_vm.isCsvDownloadable}}):_vm._e()],1)]},proxy:true},{key:"right",fn:function(){return [_c('li',[_c('AppSearchWithType',{attrs:{"search-types":_vm.SEARCH_TYPES},model:{value:(_vm.searchWithType),callback:function ($$v) {_vm.searchWithType=$$v},expression:"searchWithType"}})],1),_c('li',[_c('AppDateRangeWithType',{attrs:{"date-types":_vm.DATE_TYPES},on:{"apply":_vm.searchResource},model:{value:(_vm.dateRangeWithType),callback:function ($$v) {_vm.dateRangeWithType=$$v},expression:"dateRangeWithType"}})],1),_c('li',[_c('AppButtonToggle',{attrs:{"label":_vm.$t('app.advanced_search')},model:{value:(_vm.advancedSearchVisible),callback:function ($$v) {_vm.advancedSearchVisible=$$v},expression:"advancedSearchVisible"}})],1)]},proxy:true},{key:"advanced-search",fn:function(){return [_c('ReviewReviewsAdvancedSearch',{attrs:{"visible":_vm.advancedSearchVisible},on:{"submit":_vm.advancedSearchResource,"review-tags-length":function($event){_vm.reviewTagsLength = $event}},model:{value:(_vm.resourceParams),callback:function ($$v) {_vm.resourceParams=$$v},expression:"resourceParams"}})]},proxy:true}])}),_c(_vm.VIRTUAL_LIST_PERS.includes(_vm.reviews.per)
        ? 'AppVirtualListResourceTable'
        : 'AppResourceTable',{tag:"Component",class:{
      'reviews-table--coupon':
        _vm.reviewSettings.review_point_type === _vm.ReviewPointType.COUPON
    },attrs:{"table-id":"reviews-table","event-bus":_vm.tableEventBus,"enable-refresh":"","enable-column-settings":"","custom-buttons":[
      _vm.sortButton,
      {
        label: _vm.$t('bookmark.go_to_bookmark'),
        icon: 'icon-bookmark',
        tooltip: _vm.reviewSettings.bookmarked_review_id
          ? null
          : _vm.$t('bookmark.disabled_tooltip'),
        clickHandler: _vm.goToBookmark,
        disabled: !_vm.reviewSettings.bookmarked_review_id
      }
    ],"resources":_vm.reviews,"min-row-height":140,"advanced-search-height":1316,"advanced-search-visible":_vm.advancedSearchVisible,"columns":_vm.columns,"rows":_vm.rows,"batch-buttons":_vm.batchButtons,"pers":[20, 50, 100, 200, 500]},on:{"refresh":_vm.refreshResource,"paginate":_vm.reviewsPaginate,"select-rows":function($event){_vm.selectedReviews = $event}},scopedSlots:_vm._u([(_vm.laboratory.ai_recommendation_sort && _vm.searchbarBubbleVisibility)?{key:"searchbar-bubble",fn:function(){return [_c('AppBubble',{staticClass:"ReviewReviews__searchbar-bubble",on:{"click":_vm.hideSearchbarBubble}},[_c('div',{staticClass:"ReviewReviews__searchbar-bubble-title"},[_vm._v(" "+_vm._s(_vm.$t('searchbar_bubble.title'))+" ")]),_c('div',{staticClass:"ReviewReviews__searchbar-bubble-message"},[_vm._v(" "+_vm._s(_vm.$t('searchbar_bubble.message'))+" "),_c('AppInlineButton',{staticClass:"ReviewReviews__searchbar-bubble-button",attrs:{"label":_vm.$t('app.detail'),"button-style":"caption"},on:{"click":_vm.openAiRecommendationSortHelpDialog}})],1)])]},proxy:true}:null,{key:"cell",fn:function(ref){
    var row = ref.row;
    var column = ref.column;
    var value = ref.value;
return [(column === 'date')?_c('ReviewReviewsDateCell',{attrs:{"review":row}}):(column === 'customer')?_c('ReviewReviewsCustomerCell',{attrs:{"review":row}}):(column === 'product')?_c('ReviewReviewsProductCell',{attrs:{"review":row}}):(column === 'review')?_c('ReviewReviewsReviewCell',{attrs:{"review":row}}):(column === 'status')?_c('ReviewReviewsStatusCell',{attrs:{"review":row}}):(column === 'tags')?_c('ReviewReviewsTagsCell',{attrs:{"review":row}}):(column === 'ai_recommendation_score')?_c('ReviewReviewsAiRecommendationScoreCell',{attrs:{"review":row}}):(column === 'actions')?_c('ReviewReviewsActionsCell',{attrs:{"review":row,"resource-params":_vm.orgResourceParams}}):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }