<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <ReviewStatCard icon="icon-new" :label="$t('new.label')" :value="10" />
      <ReviewStatCard
        icon="icon-mileage"
        :label="$t('mileage.label')"
        :value="21"
      />
      <ReviewStatCard
        icon="icon-comment"
        :label="$t('comment.label')"
        :value="15"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">With Total Value Usage</div>
      <ReviewStatCard
        icon="icon-negative-review"
        :label="$t('negative_review.label')"
        :value="{
          currentValue: 21,
          totalValue: 30,
          valueInfo: '(관리전/전체)'
        }"
      />
      <ReviewStatCard
        icon="icon-reported-review"
        :label="$t('reported_review.label')"
        :value="{
          currentValue: 21,
          totalValue: 21,
          valueInfo: '(관리전/전체)'
        }"
      />
      <ReviewStatCard
        icon="icon-keyword-review"
        :label="$t('keyword_review.label')"
        :value="{
          currentValue: 21,
          totalValue: 21,
          valueInfo: '(관리전/전체)'
        }"
      />
    </div>
  </div>
</template>

<script>
import ReviewStatCard from '../review/components/ReviewStatCard';

export default {
  name: 'UiReviewStatCard',
  components: { ReviewStatCard }
};
</script>

<i18n locale="ko">
{
  "new": {
    "label": "신규 리뷰"
  },
  "mileage": {
    "label": "적립금 지급 필요 리뷰"
  },
  "comment": {
    "label": "댓글 작성 필요 리뷰"
  },
  "negative_review": {
    "label": "AI 부정 리뷰"
  },
  "reported_review": {
    "label": "신고 리뷰"
  },
  "keyword_review": {
    "label": "특정 검색어 리뷰"
  }
}
</i18n>
