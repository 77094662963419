<template>
  <AppModalDefault
    :title="$t('menu.payment_dialog')"
    :width="isSuperAdminAccessible ? 'wide' : 'middle'"
    body-style="tabs-scrollable"
    :close-button-disabled="isSubmitting"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading || isSubmitting">
        <template #default>
          <AppTabsScrollable ref="scrollableTabs" :tabs="sections" />
          <AppForm
            object-id="payment"
            :sections="sections"
            section-style="narrow"
            v-bind="formProps"
            v-on="formEvents"
          >
            <input
              type="hidden"
              name="refund_term_agreement"
              :value="formObject.refund_term_agreement"
            />
            <input type="hidden" name="payment[name]" :value="paymentName" />
            <input type="hidden" name="payment[price]" :value="totalPrice" />
            <input
              type="hidden"
              name="payment[payment_method]"
              :value="paymentMethod"
            />
            <template #group="{ id, inputName }">
              <template v-if="id === 'special_package_ids'">
                <div class="AppForm__group-field">
                  <ThePaymentDialogPackagesTable
                    v-model="formObject.special_package_ids"
                    :packages="specialPackages"
                    multiple
                    :name="inputName"
                    @refresh="fetchResource"
                  />
                </div>
              </template>
              <template v-else-if="id === 'review_package_id'">
                <div class="AppForm__group-field">
                  <ThePaymentDialogReviewPackagesTable
                    v-model="formObject.review_package_ids"
                    :packages="reviewPackages"
                    :name="inputName"
                    @refresh="fetchResource"
                    @go-to-review-intro="goToReviewIntro"
                  />
                </div>
                <div
                  v-if="isReviewExpiredButOperating"
                  class="ThePaymentDialog__hint"
                >
                  {{ $t('hint.review_packages') }}
                </div>
              </template>
              <template v-else-if="id === 'review_sms_package_id'">
                <div class="AppForm__group-field">
                  <ThePaymentDialogPackagesTable
                    v-model="formObject.review_sms_package_ids"
                    :packages="reviewSmsPackages"
                    :name="inputName"
                    :description="
                      $t('hint.review_sms_packages', [
                        reviewSettings.sms_unit_price,
                        reviewSettings.biz_message_unit_price
                      ])
                    "
                  />
                </div>
              </template>
              <template v-else-if="id === 'smartstore_starter_package_id'">
                <div class="AppForm__group-field">
                  <ThePaymentDialogPackagesTable
                    v-model="formObject.smartstore_starter_package_ids"
                    :packages="smartstoreReviewStarterPackages"
                    :name="inputName"
                    :disabled-all="!isSmartstorePackagesBuyable"
                  />
                </div>
              </template>
              <template v-else-if="id === 'smartstore_daily_package_id'">
                <div class="AppForm__group-field">
                  <ThePaymentDialogPackagesTable
                    v-model="formObject.smartstore_daily_package_ids"
                    :packages="smartstoreReviewDailyPackages"
                    :name="inputName"
                    :disabled-all="!isSmartstorePackagesBuyable"
                  />
                </div>
              </template>
              <template v-else-if="id === 'target_package_id'">
                <div class="AppForm__group-field">
                  <ThePaymentDialogTargetPackagesTable
                    :target-package-ids="formObject.target_package_ids"
                    :target-packages="targetPackages"
                    :name="inputName"
                    :next-dau-calculation-date="
                      data.target_billing_info.next_dau_calculation_date
                    "
                    :sms-price-cents="data.target_price_attributes.sms_price"
                    @change="formObject.target_package_ids = $event"
                  />
                </div>
              </template>
              <template v-else-if="id === 'credit_package_id'">
                <div class="AppForm__group-field">
                  <ThePaymentDialogCreditPackagesTable
                    :credit-package-ids="formObject.credit_package_ids"
                    :credit-packages="creditPackages"
                    :name="inputName"
                    :price-attributes="data.target_price_attributes"
                    @change="formObject.credit_package_ids = $event"
                  />
                </div>
              </template>
              <template v-else-if="id === 'fit_package_id'">
                <div class="AppForm__group-field">
                  <ThePaymentDialogPackagesTable
                    v-model="formObject.fit_package_ids"
                    :packages="fitPackages"
                    :name="inputName"
                    @refresh="fetchResource"
                  />
                </div>
              </template>
            </template>
          </AppForm>
        </template>
      </AppAjaxContent>
    </template>
    <template #foot>
      <div>
        <AppDataList :data="totalPaymentData" gap="narrow">
          <template #value="datum">
            <template v-if="datum.id === 'total_packages'">
              <template v-for="(p, i) in selectedPackages">
                <span :key="p.id" class="ThePaymentDialog__total-package-item">
                  {{ i === 0 ? '' : '+' }}
                  {{ p.name }}
                  <AppNumber
                    :value="p.final_price_with_vat"
                    unit="currency.krw"
                  />
                </span>
                {{ ' ' }}
              </template>
              <span v-if="selectedPackages.length === 0">-</span>
            </template>
          </template>
        </AppDataList>
      </div>
      <div class="ThePaymentDialog__actions">
        <AppButtonSubmit
          v-for="(option, i) in PaymentMethod.availableOptions(
            brandSettings.enable_danal_vaccount
          )"
          :key="i"
          :submit-label="option.label"
          :submitting-label="$t('foot.button_paying')"
          :is-submitting="isSubmitting && paymentMethod === option.value"
          :disabled="
            isSubmitting ||
              isLoading ||
              !refundTermAgreementChecked ||
              !selectedPackages.length
          "
          :error-summary="errorSummary"
          @click="clickPay(option.value)"
        />
        <div class="ThePaymentDialog__term">
          <AppCheckbox
            v-model="formObject.refund_term_agreement"
            :invalid="!!refundTermAgreementErrorMessage"
          >
            <template #label>
              <div>
                {{ $t('refund_term_agreement') }}
                <AppButton
                  :label="$t('terms.link')"
                  button-style="blue-clear"
                  type="external_link"
                  to="https://assets.cre.ma/m/Paymentrefundpolicy202403.pdf"
                />
              </div>
            </template>
          </AppCheckbox>
          <AppFormError :error="refundTermAgreementErrorMessage" />
        </div>
      </div>
    </template>
  </AppModalDefault>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import payment from '@/lib/payment';
import BrandType from '@/enums/BrandType';
import BrandScale from '@/enums/BrandScale';
import PackageType from '@/enums/PackageType';
import PaymentMethod from '@/enums/PaymentMethod';
import DialogFormView from '@/mixins/DialogFormView';
import ThePaymentDialogPackagesTable from './ThePaymentDialogPackagesTable';
import ThePaymentDialogReviewPackagesTable from './ThePaymentDialogReviewPackagesTable';
import ThePaymentDialogTargetPackagesTable from './ThePaymentDialogTargetPackagesTable';
import ThePaymentDialogCreditPackagesTable from './ThePaymentDialogCreditPackagesTable';
import ThePaymentDialogDanal from './ThePaymentDialogDanal';

export default {
  name: 'ThePaymentDialog',
  components: {
    ThePaymentDialogPackagesTable,
    ThePaymentDialogReviewPackagesTable,
    ThePaymentDialogTargetPackagesTable,
    ThePaymentDialogCreditPackagesTable
  },
  mixins: [DialogFormView, ThePaymentDialogDanal],
  props: { activeTabId: { type: String, default: null } },
  data() {
    return {
      detectFormDataChange: false,
      isLoading: true,
      paymentMethod: PaymentMethod.UNKNOWN,
      data: {},
      orgFormObject: {
        special_package_ids: [],
        review_package_ids: [],
        review_sms_package_ids: [],
        smartstore_starter_package_ids: [],
        smartstore_daily_package_ids: [],
        credit_package_ids: [],
        fit_package_ids: [],
        target_package_ids: []
      }
    };
  },
  computed: {
    ...mapState('session', [
      'currentBrand',
      'reviewSettings',
      'reviewStatus',
      'brandSettings'
    ]),
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('payment', ['packageName']),
    ...mapGetters('session', [
      'isReviewExpiredButOperating',
      'isTargetSpecialPackagePublished',
      'isTargetOperating',
      'isTargetExpired',
      'isReviewSmartstoreMenuAccessible'
    ]),
    isTargetPackageBuyable() {
      return !this.isTargetSpecialPackagePublished;
    },
    isTargetCashBuyable() {
      return this.isTargetOperating;
    },
    PaymentMethod() {
      return PaymentMethod;
    },
    sections() {
      return [
        this.specialPackages.length
          ? {
              id: 'special_packages',
              label: this.$t('section.special_packages'),
              groups: [
                { id: 'special_package_ids', label: null, multiple: true }
              ]
            }
          : null,
        {
          id: 'review_packages',
          label: this.$t('section.review_packages'),
          groups: [
            this.currentBrand.brand_type === BrandType.BRAND &&
            this.currentBrand.brand_scale === BrandScale.BRAND_OVER_100K_DAU
              ? {
                  id: 'review_package_over_dau_100k_description',
                  type: 'infobox',
                  value: this.$t('review_package_over_dau_100k_description'),
                  label: null
                }
              : null,
            this.currentBrand.brand_type === BrandType.SOHO
              ? {
                  id: 'review_package_soho_description',
                  type: 'infobox',
                  value: this.$t('review_package_soho_description'),
                  label: null
                }
              : null,
            { id: 'review_package_id', label: null }
          ].filter(e => e)
        },
        {
          id: 'review_sms_packages',
          label: this.$t('section.review_sms_packages'),
          groups: [{ id: 'review_sms_package_id', label: null }],
          description: this.$t('hint.review_sms_packages')
        },
        this.isSuperAdminAccessible || this.isReviewSmartstoreMenuAccessible
          ? {
              id: 'review_additional_services',
              label: this.$t('section.review_additional_services'),
              groups: [
                {
                  id: 'review_additional_services_description',
                  type: 'infobox',
                  value: this.$t('review_additional_services_description'),
                  label: null
                },
                {
                  id: 'smartstore_starter_package_id',
                  label: this.$t('section.smartstore_starter')
                },
                {
                  id: 'smartstore_daily_package_id',
                  label: this.$t('section.smartstore_daily')
                }
              ]
            }
          : null,
        this.isTargetPackageBuyable
          ? {
              id: 'target_packages',
              label: this.$t('section.target_packages'),
              groups: [{ id: 'target_package_id', label: null }]
            }
          : null,
        this.isTargetCashBuyable
          ? {
              id: 'credit_packages',
              label: this.$t('section.credit_packages'),
              groups: [{ id: 'credit_package_id', label: null }]
            }
          : null,
        {
          id: 'fit_packages',
          label: this.$t('section.fit_packages'),
          groups: [{ id: 'fit_package_id', label: null }]
        }
      ].filter(e => e);
    },
    selectedPackages() {
      if (this.isLoading) return [];

      const {
        special_package_ids,
        review_package_ids,
        review_sms_package_ids,
        smartstore_starter_package_ids,
        smartstore_daily_package_ids,
        target_package_ids,
        credit_package_ids,
        fit_package_ids
      } = this.formObject;

      return [
        ...this.specialPackages.filter(p => special_package_ids.includes(p.id)),
        ...this.reviewPackages.filter(p => review_package_ids.includes(p.id)),
        ...this.reviewSmsPackages.filter(p =>
          review_sms_package_ids.includes(p.id)
        ),
        ...this.smartstoreReviewStarterPackages.filter(p =>
          smartstore_starter_package_ids.includes(p.id)
        ),
        ...this.smartstoreReviewDailyPackages.filter(p =>
          smartstore_daily_package_ids.includes(p.id)
        ),
        ...this.targetPackages.filter(p => target_package_ids.includes(p.id)),
        ...this.creditPackages.filter(p => credit_package_ids.includes(p.id)),
        ...this.fitPackages.filter(p => fit_package_ids.includes(p.id))
      ];
    },
    totalPrice() {
      return _.sumBy(
        [this.cafe24FeePackage, ...this.selectedPackages],
        'final_price_with_vat'
      );
    },
    paymentName() {
      const packages = this.selectedPackages;
      if (packages.length === 0) return '';

      return packages.length === 1
        ? packages[0].name
        : this.$t('payment_name', [packages[0].name, packages.length - 1]);
    },
    totalPaymentData() {
      const orgPrice = this.totalPrice / (1 + payment.VAT_RATE);
      const { cafe24FeePackage } = this;
      return [
        { id: 'total_packages', label: this.$t('foot.total_packages_label') },
        ...(cafe24FeePackage
          ? [
              {
                id: 'cafe24_fee',
                label: this.$t('foot.cafe24_fee_label'),
                labelLink: {
                  label: this.$t('foot.cafe24_fee_link_label'),
                  link:
                    'https://cremafactory.notion.site/0f0b96b4ba6d4ea182e770d63c3b9f6d?pvs=4'
                },
                value:
                  cafe24FeePackage.original_price_percentage ===
                  cafe24FeePackage.final_price_percentage
                    ? this.$t('foot.cafe24_fee_same', [
                        cafe24FeePackage.final_price_percentage,
                        cafe24FeePackage.name,
                        cafe24FeePackage.final_price_with_vat
                      ])
                    : this.$t('foot.cafe24_fee_different', [
                        cafe24FeePackage.original_price_percentage,
                        cafe24FeePackage.final_price_percentage,
                        cafe24FeePackage.name,
                        cafe24FeePackage.final_price_with_vat
                      ]),
                type: 'html'
              }
            ]
          : []),
        {
          label: this.$t('foot.total_price_label'),
          value:
            this.selectedPackages.length && cafe24FeePackage
              ? this.$t('foot.total_price_with_cafe24_fee', [
                  this.totalPrice,
                  orgPrice - cafe24FeePackage.final_price,
                  cafe24FeePackage.final_price,
                  orgPrice * payment.VAT_RATE
                ])
              : this.selectedPackages.length
              ? this.$t('foot.total_price', [
                  this.totalPrice,
                  orgPrice,
                  orgPrice * payment.VAT_RATE
                ])
              : '-'
        }
      ];
    },
    specialPackages() {
      return this.data.special_packages.map(p => ({
        ...p,
        final_price: p.price,
        final_price_with_vat: payment.priceWithVat(p.price),
        sms_price: this.data.target_price_attributes.sms_price,
        promotion: p.monthly_bonus_point ? 'monthly_bonus_point' : ''
      }));
    },
    reviewPackages() {
      return this.data.review_packages.map(p => {
        const promotion = p.is_special_offer
          ? 'special_offer'
          : p.price !== p.final_price
          ? 'long_term_discount'
          : null;
        return {
          ...p,
          name: this.packageName(p),
          final_price_with_vat: payment.priceWithVat(p.final_price),
          promotion
        };
      });
    },
    fitPackages() {
      return this.data.fit_packages.map(p => {
        const promotion = p.is_special_offer
          ? 'special_offer'
          : p.price !== p.final_price
          ? 'long_term_discount'
          : null;
        return {
          ...p,
          name: this.packageName(p),
          final_price_with_vat: payment.priceWithVat(p.final_price),
          promotion
        };
      });
    },
    targetPackages() {
      return this.data.target_packages.map(p => ({
        ...p,
        name: this.packageName(p),
        final_price: p.price,
        final_price_with_vat: payment.priceWithVat(p.price),
        sms_price: this.data.target_price_attributes.sms_price,
        promotion: 'monthly_bonus_point'
      }));
    },
    creditPackages() {
      return this.data.credit_packages.map(p => {
        return {
          ...p,
          name: this.packageName(p),
          final_price: p.price,
          final_price_with_vat: payment.priceWithVat(p.price),
          promotion: p.free_credit > 0 ? 'bonus_credits' : null
        };
      });
    },
    reviewSmsPackages() {
      return this.data.review_sms_packages.map(p => ({
        ...p,
        name: this.packageName(p),
        final_price: p.price,
        final_price_with_vat: payment.priceWithVat(p.price)
      }));
    },
    smartstoreReviewStarterPackages() {
      return this.data.smartstore_starter_packages.map(pkg => ({
        ...pkg,
        name: this.packageName(pkg),
        final_price_with_vat: payment.priceWithVat(pkg.final_price),
        promotion: pkg.final_price < pkg.price ? 'discount' : null
      }));
    },
    smartstoreReviewDailyPackages() {
      return this.data.smartstore_daily_packages.map(pkg => ({
        ...pkg,
        name: this.packageName(pkg),
        final_price_with_vat: payment.priceWithVat(pkg.final_price),
        promotion: pkg.final_price < pkg.price ? 'discount' : null
      }));
    },
    cafe24FeePackage() {
      if (!this.data.cafe24_fee_package) return null;
      const { cafe24_fee_package } = this.data;

      const parentPackages = this.selectedPackages.filter(
        ({ package_type }) => package_type === PackageType.REVIEW
      );
      if (!parentPackages.length) return null;

      const finalPrice =
        _.sumBy(parentPackages, 'final_price') *
        cafe24_fee_package.final_price_ratio;
      return {
        ...cafe24_fee_package,
        name: parentPackages.map(({ name }) => name).join(' + '),
        final_price: finalPrice,
        final_price_with_vat: payment.priceWithVat(finalPrice)
      };
    },
    refundTermAgreementChecked() {
      return this.formObject.refund_term_agreement;
    },
    refundTermAgreementErrorMessage() {
      return this.refundTermAgreementChecked
        ? null
        : { errorMessage: this.$t('validations.required') };
    },
    isSmartstorePackagesBuyable() {
      return (
        this.data.paid_for_review_packages ||
        this.formObject.review_package_ids.length > 0
      );
    }
  },
  created() {
    this.fetchResource();
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    ...mapActions('session', ['fetchSession']),
    fetchResource() {
      api
        .get('/payment/payments/new')
        .then(({ data }) => {
          this.data = data;
          this.orgFormObject = {
            ...this.orgFormObject,
            refund_term_agreement: data.refund_term_agreement
          };
          this.isLoading = false;
          if (this.activeTabId) {
            this.$nextTick(() =>
              this.$refs.scrollableTabs.clickTab(this.activeTabId)
            );
          }
        })
        .catch(() => this.closeDialog());
    },
    close() {
      if (this.isSubmitting) return;

      this.closeDialog();
    },
    submit(formData) {
      this.isSubmitting = true;
      const isSelectedReviewPackage =
        this.formObject.review_package_ids.length > 0;

      const doPay =
        this.paymentMethod === PaymentMethod.BANK
          ? this.payWithBank(formData)
          : this.createPaymentAndPayWithDanal(formData);

      doPay
        .then(paymentId => {
          this.isSubmitting = false;
          this.close(true);
          if (this.isReviewExpiredButOperating && isSelectedReviewPackage) {
            this.openDialog([
              'ThePaymentReviewPackageInfoDialog',
              {
                reviewPackage: this.selectedPackages.filter(
                  p => p.package_type === PackageType.REVIEW
                )[0],
                reviewExpiresAt: this.reviewStatus.review_expires_at
              }
            ]).then(eventBus =>
              eventBus.$on('close', () =>
                this.openPaymentHistoryDialog(paymentId)
              )
            );
          } else this.openPaymentHistoryDialog(paymentId);
        })
        .catch(error => {
          this.isSubmitting = false;

          const errorMessage =
            _.isString(error) && this.$te(`payment_error.${error}`)
              ? this.$t(`payment_error.${error}`)
              : this.$t('payment_error.failed');
          alert(errorMessage);
        })
        .finally(this.fetchSession);
    },
    openPaymentHistoryDialog(paymentId) {
      this.openDialog('ThePaymentHistoryDialog');
      if (this.paymentMethod === PaymentMethod.VACCOUNT)
        this.openDialog([
          'ThePaymentVaccountInfoDialog',
          { paymentId, showPaymentCompletedMessage: true }
        ]);
    },
    createPaymentAndPayWithDanal(formData) {
      return new Promise((resolve, reject) => {
        api
          .post('/payment/payments', formData, { silent: true })
          .then(({ data }) => {
            const paymentId = data.payment.id;
            return this.payWithDanal(paymentId).then(
              () => resolve(paymentId),
              reject
            );
          })
          .catch(reject);
      });
    },
    payWithBank(formData) {
      return new Promise((resolve, reject) => {
        const args = [
          'ThePaymentBankFormDialog',
          {
            packages: this.selectedPackages,
            price: this.totalPrice
          }
        ];
        this.openDialog(args).then(eventBus => {
          eventBus.$on('save', payment_sender_name => {
            formData.append('payment[sender_name]', payment_sender_name);
            api
              .post('/payment/payments', formData, { silent: true })
              .then(resolve, reject);
          });
          eventBus.$on('close', () => (this.isSubmitting = false));
        });
      });
    },
    getServiceSpecialOffers(service) {
      return this.data[`${service}_packages`]
        .filter(v => v.is_special_offer === true)
        .map(v => v.id);
    },
    checkSpecialOfferSelected(service) {
      const selectedPackageId = this.formObject[`${service}_package_ids`][0];
      const speicalOffers = this.getServiceSpecialOffers(service);
      if (
        selectedPackageId &&
        speicalOffers.length &&
        !speicalOffers.includes(selectedPackageId)
      ) {
        alert(
          this.$t('payment_warning.special_offer_exists', {
            service_name: this.$t(`payment_service.${service}`)
          })
        );
        return false;
      }
      return true;
    },
    checkServiceSpecialOffer() {
      return (
        this.checkSpecialOfferSelected('review') &&
        this.checkSpecialOfferSelected('fit')
      );
    },
    clickPay(paymentMethod) {
      if (this.checkServiceSpecialOffer()) {
        this.paymentMethod = paymentMethod;
        setTimeout(() => this.formEventBus.$emit('submit'));
      }
    },
    goToReviewIntro() {
      this.close();

      if (this.$route.path !== '/review/intro') {
        const { query, hash } = this.$route;
        this.$router.push({ path: '/review/intro', query, hash });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ThePaymentDialog__hint {
  @include text-caption;
  margin-top: 8px;
  color: $color-red;
}

.ThePaymentDialog__actions {
  margin-top: 12px;
}

.ThePaymentDialog__total-package-item {
  white-space: nowrap;
}

.ThePaymentDialog__term {
  display: inline-block;
  vertical-align: text-top;
  margin-left: 12px;

  ::v-deep {
    .AppButton {
      vertical-align: unset;
    }
    .AppButton__button {
      border: none;
      padding: 0;
      margin-left: 8px;
    }
  }
}
</style>

<i18n locale="ko">
{
  "section": {
    "special_packages": "특별 결제",
    "review_packages": "크리마 리뷰",
    "review_sms_packages": "크리마 리뷰 추가 메시지",
    "review_additional_services": "리뷰 부가서비스",
    "smartstore_starter": "스마트스토어 과거 리뷰 연동",
    "smartstore_daily": "스마트스토어 매일 리뷰 연동",
    "target_packages": "크리마 타겟",
    "credit_packages": "크리마 타겟 캐시",
    "fit_packages": "크리마 핏"
  },
  "foot": {
    "total_packages_label": "선택 상품",
    "cafe24_fee_label": "카페24 API 사용 수수료",
    "cafe24_fee_link_label": "카페24 API 사용 수수료란?",
    "cafe24_fee_different": "[카페24 API 사용 수수료 <del>{0}%</del> {1}%] {2} {3}원",
    "cafe24_fee_same": "[카페24 API 사용 수수료 {0}%] {1} {2}원",
    "total_price_label": "총 결제 금액",
    "total_price": "{0}원 (상품가격 {1}원 + 부가세 {2}원)",
    "total_price_with_cafe24_fee": "{0}원 (상품가격 {1}원 + 카페24 API 사용 수수료 {2}원 + 부가세 {3}원)",
    "button_paying": "결제 처리 중.."
  },
  "hint": {
    "review_packages": "서비스 반영일은 결제일 기준이 아닌, 기간만료 이후 날짜부터 반영됩니다.",
    "review_sms_packages": "리뷰 문자는 {0}원, 알림톡은 {1}원이 차감됩니다."
  },
  "confirm_pay_with_bank": "무통장입금으로 결제하시겠습니까?",
  "payment_name": "{0} 외 {1}개",
  "payment_service" : {
    "review": "리뷰",
    "fit": "핏"
  },
  "payment_warning": {
    "special_offer_exists" : "{service_name} 상품에 대한 특별가가 생성된 업체입니다.\n지금 선택하신 결제는 정상가에 해당하므로, 생성된 특별 혜택가로 결제를 진행해주세요."
  },
  "payment_error": {
    "invalid_request": "잘못된 요청입니다.",
    "cancelled": "결제가 취소되었습니다.",
    "failed": "결제가 실패했습니다."
  },
  "refund_term_agreement": "결제/환불 정책 동의",
  "review_package_soho_description": "크리마 리뷰의 상품 가격은 매달 1일 쇼핑몰 규모*에 따라 재산정됩니다. (쇼핑몰 규모 : 최근 3개월 평균 일배송건 기준) ",
  "review_additional_services_description": "해당 상품은 크리마 리뷰의 부가서비스이므로 크리마 리뷰를 함께 결제하거나, 이미 결제하여 사용하는 경우에만 추가 결제가 가능합니다.",
  "review_package_over_dau_100k_description": "DAU 10만 초과 고객의 경우, 가격은 개별 협의 후 안내해 드리겠습니다."
}
</i18n>
