<template>
  <AppModalDefault
    :title="$t('title')"
    :sub-title="$t('sub_title', { id: batchJobId })"
    width="middle"
    @close="close"
  >
    <AppAjaxContent slot="body" :is-loading="isLoading">
      <template #default>
        <BatchJobSummary v-bind="{ batchJob }" />
        <BatchJobResultTable
          v-bind="{ batchJob, successItemsColumns, failedItemsColumns }"
        >
          <template #failed-cell="{ row, column }">
            <template v-if="column.id === 'error'">
              {{ error(row) }}
            </template>
          </template>
        </BatchJobResultTable>
      </template>
    </AppAjaxContent>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import BatchJobSummary from '@/views/components/BatchJobSummary';
import BatchJobResultTable from '@/views/components/BatchJobResultTable';
import BatchJobResultCode from '@/enums/BatchJobResultCode';
import DialogView from '@/mixins/DialogView';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

export default {
  name: 'ProductBatchJobSmartstoreProductMatchDialog',
  components: { BatchJobSummary, BatchJobResultTable },
  mixins: [DialogView, ResourceViewNoRoute],
  props: { batchJobId: { type: Number, required: true } },
  data() {
    return {
      successItemsColumns: [
        { id: 'product_code', label: this.$t('product_code') },
        {
          id: 'product',
          label: this.$t('product'),
          type: 'product'
        },
        {
          id: 'smartstore_product_no',
          label: this.$t('smartstore_product_no')
        },
        {
          id: 'smartstore_origin_product_no',
          label: this.$t('smartstore_origin_product_no')
        }
      ],
      failedItemsColumns: [
        { id: 'product_code', label: this.$t('product_code') },
        {
          id: 'product',
          label: this.$t('product'),
          type: 'product'
        },
        {
          id: 'smartstore_product_no',
          label: this.$t('smartstore_product_no')
        },
        {
          id: 'smartstore_origin_product_no',
          label: this.$t('smartstore_origin_product_no')
        },
        { id: 'error', label: this.$t('error_reason') }
      ],
      isLoading: true,
      batchJob: null
    };
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(`/batch_jobs/${this.batchJobId}`, { params })
        .then(({ data }) => (this.batchJob = data.batch_job))
        .finally(() => (this.isLoading = false));
    },
    error(row) {
      switch (row.result_code) {
        case BatchJobResultCode.FAILURE_EMPTY_SMARTSTORE_PRODUCT_NO:
          return this.$t('error.failure_empty_smartstore_product_no');
        case BatchJobResultCode.FAILURE_INVALID_SMARTSTORE_PRODUCT_NO:
          return this.$t('error.failure_invalid_smartstore_product_no');
        case BatchJobResultCode.FAILURE_DUPLICATE_SMARTSTORE_PRODUCT_NO:
          return this.$t('error.failure_duplicate_smartstore_product_no');
        case BatchJobResultCode.FAILURE_CRAWL_SMARTSTORE_ORIGIN_PRODUCT_NO:
          return this.$t('error.failure_crawl_smartstore_origin_product_no');
        default:
          return row.error_message;
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "일괄 등록 처리 결과",
  "sub_title": "#{id} 상세 내역",
  "product_code": "쇼핑몰 상품번호",
  "product": "쇼핑몰 상품",
  "smartstore_product_no": "상품번호<br>(스마트스토어)",
  "smartstore_origin_product_no": "스마트스토어<br>내부수정코드",
  "error_reason": "오류 원인",
  "error": {
    "failure_empty_smartstore_product_no": "상품번호(스마트스토어) 미입력",
    "failure_invalid_smartstore_product_no": "상품번호(스마트스토어)에 숫자가 아닌 문자 포함",
    "failure_duplicate_smartstore_product_no": "상품번호(스마트스토어) 다른 상품에 이미 연결됨",
    "failure_crawl_smartstore_origin_product_no": "스마트스토어 내부수정코드 가져오기 실패"
  }
}
</i18n>
