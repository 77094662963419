<template>
  <div class="ReviewNewReviewsFirstWrittenReview">
    <div class="ReviewNewReviewsFirstWrittenReview__title">
      {{ $t('title') }}
    </div>
    <div class="ReviewNewReviewsFirstWrittenReview__description">
      {{ $t('description') }}
      <a
        class="ReviewNewReviewsFirstWrittenReview__detail"
        @click="openFirstWrittenReviewInfoDialog"
      >
        <u>{{ $t('app.detail') }}</u>
      </a>
    </div>
    <div class="ReviewNewReviewsFirstWrittenReview__chart">
      <ReviewSemiDonutChart
        :data="chartData"
        :tooltip="chartTooltip"
        @tooltip="onChartTooltip"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ReviewSemiDonutChart from '@/views/review/components/ReviewSemiDonutChart';

export default {
  name: 'ReviewNewReviewsFirstWrittenReview',
  components: { ReviewSemiDonutChart },
  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null }
  },
  data() {
    return {
      chartData: null,
      chartTooltip: null
    };
  },
  computed: {
    ...mapState('session', ['currentBrand'])
  },
  created() {
    this.chartData = [
      {
        label: this.currentBrand.name,
        type: 'main',
        value: 1,
        total: 4
      },
      {
        label: this.$t('market_average'),
        type: 'indicator',
        value: 1,
        total: 3
      }
    ];
  },
  methods: {
    ...mapActions('dialog', ['alert']),
    openFirstWrittenReviewInfoDialog() {
      this.alert({
        title: this.$t('first_written_review_info.title'),
        message: this.$t('first_written_review_info.message')
      });
    },
    onChartTooltip({ mainValue, indicatorValue }) {
      const { formatDateWithWday } = this.$options.filters;

      this.chartTooltip = {
        title: this.$t('chart_tooltip', {
          start_date: formatDateWithWday(this.startDate),
          end_date: formatDateWithWday(this.endDate)
        }),
        items: [
          { symbol: 'square', name: this.currentBrand.name, value: mainValue },
          {
            symbol: 'line',
            name: this.$t('market_average'),
            value: indicatorValue
          }
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewNewReviewsFirstWrittenReview {
  height: 236px;
  min-width: 255px;
  max-width: 310px;
  padding: 18px 22px;
  border-radius: 10px;
  border: 1px solid $color-grey-25;
  background-color: $color-review-white;
}

.ReviewNewReviewsFirstWrittenReview__title {
  @include text-caption-bold-dark;
}

.ReviewNewReviewsFirstWrittenReview__description {
  @include text-caption-small;

  margin-top: 8px;
  white-space: pre-line;
}

.ReviewNewReviewsFirstWrittenReview__detail {
  @include text-caption-bold-dark;
}

.ReviewNewReviewsFirstWrittenReview__chart {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>

<i18n locale="ko">
{
  "title": "첫 작성 리뷰",
  "description": "쇼핑몰의 성장 가능성을 진단할 수 있는\n 지표입니다",
  "first_written_review_info": {
    "title": "처음 작성한 리뷰의 의미",
    "message": "<b>가입 후 첫 리뷰를 작성했다는 것은 다음을 의미합니다.</b><ul><li>제품에 대한 만족도가 높았다는 신호</li><li>브랜드와의 관계 형성에 긍정적인 태도</li><li>재구매 가능성이 높은 충성고객으로 발전 할 수 있는 잠재력</li></ul><br />신규 고객과 <b>긍정적인 관계 형성 수준을 가늠</b>하고, 고객의 첫 리뷰를<br />확인하고 <b>적극 관리하여 충성고객</b>을 만들어 보세요."
  },
  "chart_tooltip": "{start_date} ~ {end_date}",
  "market_average": "업계 평균"
}
</i18n>
