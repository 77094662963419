<template>
  <div class="ReviewBulkReviewCsvsRegister">
    <AppSummaryBox class="ReviewBulkReviewCsvsRegister__summary">
      <template #title>{{ $t('summary.title') }}</template>
      <AppText
        class="ReviewBulkReviewCsvsRegister__summary-content"
        :text="$t('summary.content')"
      />
      <AppExternalLink
        class="ReviewBulkReviewCsvsRegister__summary-link"
        :label="$t('summary.link')"
        :to="smartStoreLink"
      />
    </AppSummaryBox>
    <AppForm
      v-bind="formProps"
      object-id="bulk_review_csv"
      :is-submitting="isSubmitting"
      :submit-button="{
        submitLabel: $t('app.upload'),
        submittingLabel: $t('app.uploading')
      }"
      v-on="formEvents"
      @change-group="formGroupChanged"
    >
      <template #group="{ id }">
        <template v-if="id === 'csv_file_guide'">
          <AppContainer>
            <div class="ReviewBulkReviewCsvsRegister__csv-file-guide-wrapper">
              <i18n path="csv_file_guide.content1">
                <AppExternalLink :to="csvStandardFileLink" link-style="blue">
                  {{ $t('csv_file_guide.link') }}
                </AppExternalLink>
              </i18n>
              <AppImage
                class="ReviewBulkReviewCsvsRegister__csv-file-guide-image"
                :src="
                  isAdminLocaleEn
                    ? 'https://assets.cre.ma/m/admin/v2/csv_file_guide1_en.png'
                    : 'https://assets.cre.ma/m/admin/v2/csv_file_guide1@3x.png'
                "
              />
            </div>
            <div class="ReviewBulkReviewCsvsRegister__csv-file-guide-wrapper">
              <!-- eslint-disable-next-line -->
              <div v-html="$t('csv_file_guide.content2')" />
              <AppImage
                class="ReviewBulkReviewCsvsRegister__csv-file-guide-image--middle"
                :src="
                  isAdminLocaleEn
                    ? 'https://assets.cre.ma/m/admin/v2/csv_file_guide2_en.png'
                    : 'https://assets.cre.ma/m/admin/v2/csv_file_guide2@3x.png'
                "
              />
            </div>
            <div class="ReviewBulkReviewCsvsRegister__csv-file-guide-wrapper">
              <!-- eslint-disable-next-line -->
              <div v-html="$t('csv_file_guide.content3')" />
              <AppImage
                class="ReviewBulkReviewCsvsRegister__csv-file-guide-image"
                :src="
                  isAdminLocaleEn
                    ? 'https://assets.cre.ma/m/admin/v2/csv_file_guide3_en.png'
                    : 'https://assets.cre.ma/m/admin/v2/csv_file_guide3@3x.png'
                "
              />
            </div>
          </AppContainer>
        </template>
      </template>
      <input
        type="hidden"
        name="bulk_review_csv[locale]"
        :value="isAdminLocaleEn ? 'en-US' : 'ko-KR'"
      />
    </AppForm>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import BrandCsvType from '@/enums/BrandCsvType';
import DialogSize from '@/enums/DialogSize';
import FormView from '@/mixins/FormView';

export default {
  name: 'ReviewBulkReviewCsvsRegister',
  mixins: [FormView],
  data() {
    return {
      showDownloadCsvForm: false,
      detectFormDataChange: false
    };
  },
  computed: {
    ...mapState(['env']),
    ...mapState('session', ['currentBrand']),
    ...mapGetters('session', [
      'isCafe24Brand',
      'isShopifyBrand',
      'adminLocale'
    ]),
    smartStoreLink() {
      const env = this.env === 'production' ? '' : location.host[0];
      return `${window.location.protocol}//${env}admin.cre.ma/v2/review/import/smartstore?brand_id=${this.currentBrand.id}`;
    },
    bulkReviewCsvsHistoryLink() {
      const env = this.env === 'production' ? '' : location.host[0];
      return `${window.location.protocol}//${env}admin.cre.ma/v2/review/bulk_review_csvs/history?brand_id=${this.currentBrand.id}`;
    },
    csvStandardFileLink() {
      return this.isAdminLocaleEn
        ? 'https://docs.google.com/spreadsheets/d/1gkl2oYX2ObqeMuhRN8sRysA6JnaCIR2SDaTm06nNRsc/copy?gid=0#gid=0'
        : 'https://docs.google.com/spreadsheets/d/19jQKV0XhK2JUcXCDo6Xjd2VWhiX2c6zZ2Jxar56LVtw/copy?usp=sharing';
    },
    isAdminLocaleEn() {
      return this.adminLocale === 'en';
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'brand_csv_type',
              type: 'select',
              label: this.$t('csv_type_input_title'),
              placeholder: this.$t('csv_type_placeholder'),
              options: this.isShopifyBrand
                ? BrandCsvType.options([
                    BrandCsvType.BULK_SHOPIFY_REVIEW,
                    BrandCsvType.BULK_JUDGE_ME_REVIEW,
                    BrandCsvType.BULK_REVIEW
                  ])
                : BrandCsvType.options([
                    BrandCsvType.BULK_V_REVIEW,
                    ...(this.isCafe24Brand
                      ? [BrandCsvType.BULK_ALPHA_REVIEW]
                      : []),
                    BrandCsvType.BULK_SNAP_REVIEW,
                    BrandCsvType.BULK_REVIEW
                  ]),
              validate: ['required']
            },
            this.showDownloadCsvForm
              ? {
                  id: 'csv_file_guide',
                  label: this.$t('csv_file_guide.label')
                }
              : null,
            {
              id: 'file',
              type: 'file',
              label: this.$t('csv_upload_file_input_title'),
              placeholder: this.$t('csv_upload_file_input_placeholder'),
              accept: '.csv',
              validate: ['required']
            }
          ].filter(v => v)
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog', 'alert', 'confirm']),
    ...mapActions('reviewBulkReviewCsv', ['createBulkReviewCsv']),
    openErrorAlert(errorKey, errorArgs = []) {
      this.alert({
        title: this.$t('alert.title'),
        width: DialogSize.SMALL,
        message: this.$t(`alert.message.${errorKey}`, errorArgs)
      });
    },
    openUploadCompleteConfirm() {
      this.confirm({
        title: this.$t('confirm.title'),
        message: this.$t('confirm.message'),
        closeButtonLabel: this.$t('confirm.button')
      }).then(result => {
        if (!result) return;

        window.location.href = this.bulkReviewCsvsHistoryLink;
        this.close();
      });
    },
    submit(formData) {
      this.isSubmitting = true;
      this.createBulkReviewCsv({ formData })
        .then(() => {
          this.openUploadCompleteConfirm();
        })
        .catch(error => {
          const errorMessage = error.response.data.errors[0];
          if (errorMessage === 'invalid image url found') {
            this.openErrorAlert('invalid_image_url_error');
          } else if (errorMessage.match('invalid header')) {
            const invalidHeaders = errorMessage.split(' - ')[1];
            this.openErrorAlert('invalid_header_error', [invalidHeaders]);
          } else if (errorMessage === 'row count exceeded') {
            this.openErrorAlert('row_count_exceeded_error');
          } else if (errorMessage === 'invalid encoding') {
            this.openErrorAlert('invalid_encoding_error');
          }
        })
        .finally(() => (this.isSubmitting = false));
    },
    formGroupChanged(group) {
      if (group.id === 'brand_csv_type') {
        this.showDownloadCsvForm = group.value === BrandCsvType.BULK_REVIEW;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.ReviewBulkReviewCsvsRegister__summary {
  margin-bottom: 32px;
}

.ReviewBulkReviewCsvsRegister__summary-content {
  display: inline;
}

::v-deep .AppText {
  ol {
    padding-inline-start: 20px;
  }

  li {
    list-style: disc;
  }
}

.ReviewBulkReviewCsvsRegister__summary-link {
  display: inline;
  color: $color-grey-50 !important;
  text-decoration: underline;
}

.ReviewBulkReviewCsvsRegister__csv-file-guide-wrapper {
  & + & {
    margin-top: 32px;
  }

  a {
    margin-right: -3px;
    text-decoration: underline;
  }
}

@mixin csv-file-guide-image($padding) {
  width: 100%;
  background-color: $color-grey-15;
  padding: 24px $padding;
  text-align: center;
}

.ReviewBulkReviewCsvsRegister__csv-file-guide-image {
  @include csv-file-guide-image(20%);
}

.ReviewBulkReviewCsvsRegister__csv-file-guide-image--middle {
  @include csv-file-guide-image(10%);
}
</style>

<i18n locale="ko">
{
  "csv_type_input_title": "등록할 리뷰의 출처",
  "csv_type_placeholder": "리뷰 출처 선택",
  "csv_file_guide": {
    "label": "리뷰 CSV 파일 가이드",
    "link": "리뷰 등록 CSV 표준 파일",
    "content1": "1. {0}에 접속하여 [사본 만들기] 버튼을 클릭하세요.",
    "content2": "2. 리뷰 내용 입력 방법<br />&nbsp;&nbsp;• 리뷰는 <span style='color: #7a6eff;'>5행부터 입력</span>해주세요.<br />&nbsp;&nbsp;&nbsp;&nbsp;• <span style='color: #7a6eff;'>파란색 배경의 필수 항목</span>을 반드시 작성해야 리뷰가 등록됩니다.<br />&nbsp;&nbsp;• 파일 상단의 <span style='color: #7a6eff;'>1 ~ 4행 헤더 영역은 제공된 양식을 그대로 유지</span>해야 합니다.<br />&nbsp;&nbsp;&nbsp;&nbsp;• <span style='color: #7a6eff;'>이 영역을 수정 또는 삭제할 경우, 리뷰가 정상적으로 처리되지 않습니다.</span>",
    "content3": "3. CSV 형식으로 파일을 다운로드 한 후 업로드해주세요.<br />&nbsp;&nbsp;• 다운로드시 인코딩 옵션이 UTF-8로 설정되었는지 확인해주세요."
  },
  "csv_download_button_description": "하단 버튼을 클릭해 파일을 다운로드하고, 양식에 맞게 파일을 수정하여 업로드해주세요. 양식이 다를 경우 문제가 발생할 수 있습니다.",
  "csv_download_button_label": "예시 CSV 파일 다운로드",
  "csv_upload_file_input_title": "리뷰 CSV 파일 업로드",
  "csv_upload_file_input_placeholder": "업로드할 CSV 파일을 등록해주세요.",
  "summary": {
    "title": "📤 크리마에 등록할 리뷰 CSV 파일을 업로드해주세요.",
    "content": "CSV 파일을 업로드 해 원하는 리뷰를 직접 등록할 수 있습니다.<br />포토/동영상 리뷰는 순차적으로 처리되어 업로드에 시간이 소요될 수 있습니다.<br />네이버 스마트스토어 리뷰는 별도 유료 부가서비스 기능을 통해 연동할 수 있습니다. ",
    "link": "스마트스토어 리뷰 연동 바로 가기"
  },
  "alert": {
    "title": "CSV 파일 업로드에 실패했습니다.",
    "message": {
      "invalid_image_url_error": "스마트스토어 리뷰는 리뷰일괄등록이 불가합니다.<br />'스마트스토어 리뷰 연동' 메뉴를 통해 등록을 진행해주세요.",
      "invalid_header_error": "업로드하신 파일의 {0} 헤더명이 수정되어 등록이 불가합니다.<br />파일을 다시 확인하여 원본 형식을 유지한 상태로 업로드해 주세요.",
      "row_count_exceeded_error": "10만건 이상의 리뷰는 등록이 불가합니다.<br />나누어서 다시 등록해주세요.",
      "invalid_encoding_error": "업로드한 파일의 인코딩이 utf-8이 아닙니다.<br />텍스트 인코딩을 유니코드(uft-8)로 선택하여 csv를 생성해 다시 업로드해주세요."
    }
  },
  "confirm": {
    "title": "CSV 파일을 업로드하였습니다.",
    "message": "등록내역으로 이동하여 상세 내역을 확인하시겠습니까?",
    "button": "등록내역 확인하기"
  }
}
</i18n>
