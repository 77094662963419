<template>
  <div class="AppMultipleSelectButton">
    <input
      v-if="name && packingMethod !== 'array'"
      type="hidden"
      :name="name"
      :disabled="disabled"
      :value="packFromArray(currentValue)"
    />
    <AppButtonToggleGroup
      :buttons="buttons"
      :is-separate-buttons="isSeparateButtons"
      @select="change($event)"
    />
  </div>
</template>

<script>
import MultipleSelectable from '@/mixins/MultipleSelectable';

export default {
  name: 'AppMultipleSelectButton',
  mixins: [MultipleSelectable],
  props: {
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    isSeparateButtons: { type: Boolean, default: false }
  },
  computed: {
    buttons() {
      return this.options.map(option => ({
        id: option.value,
        value: this.isSelected(option),
        disabled: this.disabled || option.disabled,
        buttonStyle: this.invalid ? 'red-outline' : undefined,
        label: option.label
      }));
    }
  },
  methods: {
    change(value) {
      const option = this.options.find(o => o.value === value);
      this.isSelected(option) ? this.unselect(option) : this.select(option);
    }
  }
};
</script>
