<template>
  <div class="ReviewMessagesMemberReviewDispatchRecipient">
    <i18n :path="dispatchDescription">
      <AppSelect
        v-model="message.dispatch_days_type"
        name="message[dispatch_days_type]"
        :options="ReviewCampaignDispatchDaysType.options()"
        inline
      />
      <AppNumberInput
        v-model="message.dispatch_days"
        name="message[dispatch_days]"
        inline
        :digits="3"
        :invalid="!!error"
        @blur="$emit('validate')"
        @change="$emit('validate')"
      />
    </i18n>
    <AppFormHint :message="$t('hint', [message.mileage_expiry_days])" />
    <AppFormError :error="error" />
  </div>
</template>

<script>
import ReviewCampaignDispatchDaysType from '@/enums/ReviewCampaignDispatchDaysType';

export default {
  name: 'ReviewMessagesMemberReviewDispatchRecipient',
  props: {
    message: { type: Object, default: () => {} },
    error: { type: Object, default: null }
  },
  computed: {
    dispatchDescription() {
      return `description.${ReviewCampaignDispatchDaysType.toKey(
        this.message.dispatch_days_type
      ).toLowerCase()}`;
    },
    ReviewCampaignDispatchDaysType: () => ReviewCampaignDispatchDaysType
  }
};
</script>

<i18n locale="ko">
{
  "description": {
    "after_delivered": "{0} {1}일 지난 고객 중 리뷰 미작성 고객",
    "before_mileage_expiration": "{0} {1}일 남은 고객 중 리뷰 미작성 고객"
  },
  "hint": "Tip. 현재 설정된 적립금 지급 기간은 배송 완료 후 {0}일 입니다."
}
</i18n>
